import { SubscriptionPeriod } from '../../../../../types/Subscription';
import React from 'react';
import { SubscriptionPlan } from '../../../data/Plans';

export const PickerPaymentResultForBasic: React.FC<{
  plan: SubscriptionPlan;
  trialAllowed: boolean;
  period: SubscriptionPeriod;
  discountValue: number;
}> = ({ plan, trialAllowed, period, discountValue }) => {
  const getFinalPrice = () => {
    if (discountValue !== null) {
      return (
        <>
          <span className={'text-gradient-yellow-green'}>
            ( minus rabat {getNormalPrice() - discountValue} zł ){' = '}
          </span>
          <span className={'text-gradient-blue-green'}>{discountValue}</span>
        </>
      );
    }
    return getNormalPrice();
  };

  function getNormalPrice() {
    return period === SubscriptionPeriod.ANNUAL ? plan.annualPrice : plan.monthlyPrice;
  }
  return trialAllowed ? (
    <>
      <div className="row px-3 justify-content-between">
        <div className="mt-2 float-left">Do zapłaty przy pozostaniu:</div>
        <div className="mt-2 float-right">{getFinalPrice()} zł</div>
      </div>
      <div className="row px-3 justify-content-between">
        <div className="float-left text-bold mb-2">Do zapłaty dziś:</div>
        <div className="float-right mb-2">0 zł</div>
      </div>
    </>
  ) : (
    <div className="row px-3 justify-content-between">
      <div className="float-left text-bold mt-2 ">Do zapłaty:</div>
      <div className="float-right mb-2 mt-2"> {getFinalPrice()} zł</div>
    </div>
  );
};
