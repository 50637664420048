const AdminMenu = [
  {
    heading: 'Start',
    translate: 'sidebar.heading.START'
  },
  {
    name: 'Companies',
    icon: 'icon-grid',
    path: '/admin_console/companies/active',
    translate: 'sidebar.nav.element.COMPANIES.ACTIVE'
  },
  {
    name: 'Companies',
    icon: 'icon-grid',
    path: '/admin_console/companies/inactive',
    translate: 'sidebar.nav.element.COMPANIES.INACTIVE'
  },
  {
    name: 'Users',
    icon: 'icon-user',
    path: '/admin_console/users',
    translate: 'sidebar.nav.element.USERS'
  },
  {
    name: 'Plans',
    icon: 'icon-speedometer',
    path: '/admin_console/plans',
    translate: 'sidebar.nav.element.PLANS'
  },
  {
    name: 'Discount cards',
    icon: 'icon-credit-card',
    path: '/admin_console/discount_cards',
    translate: 'sidebar.nav.element.DISCOUNT_CARDS'
  }
];

export default AdminMenu;
