import React, { FC } from 'react';

export const WelcomeCard: FC<{ children: React.ReactNode }> = ({ children }) => {
  return (
    <div className="block-center mt-5 wd-xl" style={{ overflow: 'visible' }}>
      <div className="card card-flat">
        <div
          className="card-header text-center bg-dark"
          style={{ borderTopLeftRadius: '10px', borderTopRightRadius: '10px' }}
        >
          <div>
            <img
              className="block-center rounded"
              src={'img/logo-simple.png'}
              alt="Logo"
              style={{ maxHeight: '25px' }}
            />
          </div>
        </div>
        <div className="card-body">{children}</div>
      </div>
      <div className="p-3 text-center">
        <img
          className="img-fluid "
          src={`img/logo-${
            JSON.parse(localStorage.getItem('angle-store-key'))?.['settings']?.darkMode
              ? 'white'
              : 'dark'
          }.png`}
          style={{ height: '3rem' }}
          alt="Mistrz wina"
        />
        <br />
        <span>Osiągnij poziom mistrzowski w produkcji wina!</span>
      </div>
    </div>
  );
};
