import React from 'react';
import { useTranslation } from 'react-i18next';
import { SectionWrapper } from '../../../../../layouts/SectionWrapper';
import { ServiceError } from '../../../../../types/Service';
import { DiscountCardReadModel } from '../../../hooks/useDiscountService';
import { SelectedDiscountCardMode } from '../hooks/useDiscountCards';
import { useUpdateDiscountCard } from '../hooks/useUpdateDiscountCard';
import { FormErrorMessage } from '../../../../../components/notifications/FormErrorMessage';
import { InputColumn } from '../../../../../components/forms/InputColumn';
import SelectSubscriptionType from '../../companies/SelectSubscriptionType';
import { usePushHistory } from '../../../../../hooks/usePushHistory';

const Header = ({
  selectedDiscountCard,
  mode
}: {
  selectedDiscountCard: DiscountCardReadModel;
  mode: SelectedDiscountCardMode;
}) => {
  const { t } = useTranslation();
  return (
    <div className={'d-flex justify-content-between align-items-top'}>
      <div className="float-left"></div>
      <div className="mb-2"></div>
      <small className="text-muted float right">
        {selectedDiscountCard.valid && mode === 'card' ? (
          <span className={'text-green'}>
            {t('discountCard.selected.valid')} od {selectedDiscountCard.startDate} do{' '}
            {selectedDiscountCard.validDate}
          </span>
        ) : null}
        {!selectedDiscountCard.valid && mode === 'card' ? (
          <span className={'text-danger'}>{t('discountCard.selected.invalid')}</span>
        ) : null}
        {mode === 'edit' ? <span>{t('discountCard.edit')}</span> : null}
      </small>
    </div>
  );
};

const Row = ({
  title,
  value,
  hide
}: {
  title: string;
  value: string | React.ReactNode;
  hide?: boolean;
}) => {
  if (hide) return null;
  return (
    <>
      <h4 className={'pt-2'}>{title}</h4>
      <div className="dropdown-divider"></div>
      <p className={'text-gradient-blue-green'}>{value}</p>
    </>
  );
};

export const CardSelected: React.FC<{
  selectedDiscountCard: DiscountCardReadModel;
  selectedDiscountCardMode: SelectedDiscountCardMode;
  updateDiscountCard: (code: string, card: DiscountCardReadModel) => void;
  loading: boolean;
  error: ServiceError;
}> = ({ selectedDiscountCard, selectedDiscountCardMode, updateDiscountCard, loading, error }) => {
  const { t } = useTranslation();
  const [discountCard, updateField] = useUpdateDiscountCard(selectedDiscountCard);
  const { pushHistory } = usePushHistory();

  return (
    <SectionWrapper loading={loading} hideSpinner>
      <div className="card">
        <div className="card-body pb-1">
          {selectedDiscountCardMode === 'card' ? (
            <>
              <Header selectedDiscountCard={selectedDiscountCard} mode={selectedDiscountCardMode} />
              <Row title={selectedDiscountCard.name} value={selectedDiscountCard.code} />
              <Row
                title={t('discountCard.forSubscriber')}
                value={
                  <button
                    className={'btn btn-link p-0'}
                    onClick={() =>
                      pushHistory(`/admin_console/company/${discountCard.subscriberId}`)
                    }
                  >
                    {discountCard.subscriberName}
                  </button>
                }
                hide={!selectedDiscountCard.subscriberId}
              />
              <Row
                title={t('discountCard.subscriptionType')}
                value={t(
                  `plans.subscription.type.${selectedDiscountCard.subscriptionType || 'all'}`
                )}
              />
              <Row
                title={t('discountCard.price')}
                value={`${selectedDiscountCard.discountPrice} PLN`}
                hide={!selectedDiscountCard.discountPrice}
              />
              <Row
                title={t('discountCard.percentage')}
                value={`${selectedDiscountCard.discountPercent} %`}
                hide={!selectedDiscountCard.discountPercent}
              />
              <Row
                title={t('discountCard.usage')}
                value={`${selectedDiscountCard.usageCount} / ${selectedDiscountCard.maxUsageCount}`}
                hide={!selectedDiscountCard.maxUsageCount}
              />
              <Row
                title={t('discountCard.usage')}
                value={`${selectedDiscountCard.usageCount}`}
                hide={!!selectedDiscountCard.maxUsageCount}
              />
              <FormErrorMessage
                error={error}
                messageType={'details'}
                clazzName={'p-1'}
                key={selectedDiscountCard.discountCardId}
              />
            </>
          ) : null}
          {selectedDiscountCardMode === 'edit' ? (
            <>
              <Header selectedDiscountCard={selectedDiscountCard} mode={selectedDiscountCardMode} />
              <InputColumn
                label={t('discountCards.form.name')}
                onChange={(e) => updateField({ name: e.target.name, value: e.target.value })}
                name={'name'}
                value={discountCard?.name}
                placeholder={t('discountCards.form.name')}
                error={error}
              />
              <SelectSubscriptionType
                label={t('discountCards.form.subscriptionType')}
                value={selectedDiscountCard?.subscriptionType}
                name={'subscriptionType'}
                onChange={(s, v) => updateField({ name: s, value: v.value })}
                error={error}
                selectType={'column'}
                optional
              />
              {selectedDiscountCard.discountPrice ? (
                <InputColumn
                  label={t('discountCards.form.discountPrice')}
                  onChange={(e) => updateField({ name: e.target.name, value: e.target.value })}
                  name={'discountPrice'}
                  type={'number'}
                  value={selectedDiscountCard?.discountPrice}
                  placeholder={t('discountCards.form.discountPrice')}
                  error={error}
                />
              ) : null}
              {selectedDiscountCard.discountPercent ? (
                <InputColumn
                  label={t('discountCards.form.discountPercent')}
                  onChange={(e) => updateField({ name: e.target.name, value: e.target.value })}
                  name={'discountPercent'}
                  type={'number'}
                  value={selectedDiscountCard?.discountPercent}
                  placeholder={t('discountCards.form.discountPercent')}
                  error={error}
                />
              ) : null}
              <InputColumn
                label={t('discountCards.form.maxUsageCount')}
                onChange={(e) => updateField({ name: e.target.name, value: e.target.value })}
                name={'maxUsageCount'}
                type={'number'}
                value={discountCard?.maxUsageCount}
                placeholder={t('discountCards.form.maxUsageCount')}
                error={error}
                key={selectedDiscountCard.valid ? '1' : '2'}
                optional
              />
              <button
                className="btn btn-primary ml-3 mb-3 mt-2"
                type="button"
                onClick={() => updateDiscountCard(discountCard.code, discountCard)}
              >
                {t('bottle.form.submit.update')}
              </button>
            </>
          ) : null}
        </div>
      </div>
    </SectionWrapper>
  );
};
