import React from 'react';
import { useTranslation } from 'react-i18next';
import { DiscountTab } from '../AdminDiscountPage';

const TabButton: React.FC<{
  tab: DiscountTab;
  label: string;
  activeTab: DiscountTab;
  onClick: ({ newTab }: { newTab: DiscountTab }) => void;
}> = ({ tab, label, activeTab, onClick }) => {
  function handleClickButton() {
    onClick({ newTab: tab });
  }

  return (
    <button
      className={`mr-2 btn btn-sm ${activeTab !== tab ? 'btn-info' : 'btn-danger'}`}
      type="button"
      onClick={handleClickButton}
    >
      {activeTab === tab ? (
        <>
          <em className="mr-1 fas fa-minus" /> {label}
        </>
      ) : (
        <>
          <em className="mr-1 fas fa-plus" /> {label}
        </>
      )}
    </button>
  );
};

export const DiscountCardListButtonsBar: React.FC<{
  onClick: ({ newTab }: { newTab: DiscountTab }) => void;
  activeTab: DiscountTab;
}> = ({ onClick, activeTab }) => {
  const { t } = useTranslation();

  return (
    <div className="mb-3">
      <div>
        <TabButton
          tab="form"
          label={t('discount.page.tab.addDiscountCard')}
          activeTab={activeTab}
          onClick={onClick}
        />
      </div>
    </div>
  );
};
