import React, { useContext } from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { AuthContext } from '../../../platform/AuthContext';
import usePlanPicker from '../../../subscription/hooks/usePlanPicker';
import { SubscriptionType } from '../../../../types/Subscription';
import { LockIconInfo } from '../../../../components/common/lock/LockIconInfo';
import { PdfButtonComponent } from '../../../reports/components/PdfButtonComponent';
import { ReportType, useGetPdf } from '../../hooks/useGetPdf';

export const StorageWinesListButtonsBar: React.FC<{ reportAvailable: boolean }> = ({
  reportAvailable
}) => {
  const { t } = useTranslation();

  const {
    subscriptionInfo: { accessInfo }
  } = useContext(AuthContext);

  const { isValidSubscription: isValidSubscriptionForAddWine } = usePlanPicker({
    subscriptionType: SubscriptionType.NORMAL,
    fromBlockingContent: true,
    blockingMessage: 'Przejdź na wyższy plan, aby dodać wino',
    itemIndexToHighlight: 7
  });

  const { getPdf, loading } = useGetPdf({
    subscriptionType: SubscriptionType.PREMIUM,
    reportType: ReportType.STORAGE_SUMMARY
  });

  function handleAddWine(e: React.MouseEvent) {
    if (!isValidSubscriptionForAddWine()) {
      e.preventDefault();
    }
  }

  return (
    <div className="mb-3">
      <div>
        <Link
          to={{
            pathname: '/mv/wine_storage/e/0',
            state: { from: '/mv/board/storage' }
          }}
          className="btn btn-sm btn-info"
          type="button"
          onClick={handleAddWine}
        >
          <em className="mr-1 fas fa-plus" /> wino
          <LockIconInfo subscriptionTypeNeeded={SubscriptionType.NORMAL} accessInfo={accessInfo} />
        </Link>
        <Link
          to={'/mv/wine_storage/all'}
          className="btn btn-sm btn-secondary"
          type="button"
          title={t('button.show.list')}
        >
          <em className="fas fa-outdent" />
        </Link>
        <PdfButtonComponent
          reportAvailable={reportAvailable}
          getPdf={getPdf}
          loading={loading}
          subscriptionTypeNeeded={SubscriptionType.PREMIUM}
        />
      </div>
    </div>
  );
};
