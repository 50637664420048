import React from 'react';
import Select from 'react-select';
import { SelectOption } from '../../types/SelectOption';
import { SelectOptionProps } from '../common/form-elements/SelectType/SelectTypeModel';
import {
  NumberOdColumnsInRow,
  useFormFieldColumnCount
} from '../../features/home/components/TaxBandsSummaryPage/hooks/useFormFieldColumnCount';
import { useTranslation } from 'react-i18next';
import { useThemeContext } from '../../features/platform/ThemeContext';

interface Props {
  receiver: (value: unknown) => {
    options: SelectOption[];
    selected: string | SelectOption;
    loading: boolean;
  };
  numberOfColumnsInRow?: NumberOdColumnsInRow;
  disabled?: boolean;
  clazzName?: string;
}

export const SelectTypeColumn: React.FC<SelectOptionProps<unknown> & Props> = ({
  label,
  value: selected,
  name,
  receiver,
  error,
  onChange,
  numberOfColumnsInRow = 1,
  disabled,
  optional
}) => {
  const { options, selected: value } = receiver(selected);
  const { t } = useTranslation();
  const numberOfColumnsClass = useFormFieldColumnCount({ numberOfColumnsInRow });
  const { selectDarkStyles } = useThemeContext();

  const finalOptions = options.map((option) => {
    if (option.lock) {
      return { ...option, label: option.label + ' - opcja dostępna w wyższym planie' };
    }
    return option;
  });

  return (
    <div className={'mb-3 ' + numberOfColumnsClass}>
      <label htmlFor={name}>
        {label}
        <span className="text-danger" style={{ display: optional === true ? 'none' : '' }}>
          {' '}
          *
        </span>
      </label>
      <Select
        name={name}
        options={finalOptions}
        onChange={(s) => onChange(name, s)}
        value={value}
        noOptionsMessage={() => t('select.noOptions.message')}
        placeholder={'Wybierz'}
        styles={selectDarkStyles(error?.hasError?.(name))}
        isDisabled={disabled}
        isOptionDisabled={(option) => {
          if (typeof option !== 'string') {
            return option.lock;
          }
          return false;
        }}
      />
      <span
        className="invalid-feedback"
        style={{ display: error?.hasError?.(name) ? 'block' : 'none' }}
      >
        {error?.getErrorMessage?.(name)}
      </span>
    </div>
  );
};
