import React from 'react';
import { TaxBandsReadModel } from '../../../../../types/TaxBands';

interface IProps {
  taxBands: TaxBandsReadModel;
  onClick: () => void;
  short: boolean;
  shorter: boolean;
  selectedTaxBands: TaxBandsReadModel;
}

export const TaxBandsRow: React.FC<IProps> = ({
  taxBands,
  onClick,
  short,
  shorter,
  selectedTaxBands
}) => {
  return (
    <tr
      className={
        'odd parent ' +
        (selectedTaxBands?.id === taxBands.id ? 'bg-gray-lighter bg-gray-lighter-dark' : '')
      }
      style={{ cursor: 'pointer', userSelect: 'none', WebkitUserSelect: 'none' }}
      onClick={onClick}
    >
      <td className="text-bold">{taxBands.label}</td>
      <td className={'text-center'}>{taxBands.initialQuantity}</td>
      <td
        className={'text-center ' + (taxBands.availableQuantity > 0 ? 'text-green' : 'text-danger')}
      >
        {taxBands.availableQuantity}
      </td>
      {!shorter ? <td className={'text-center'}>{taxBands.handingOverDate}</td> : null}
      {!short ? (
        <>
          <td className={'text-center'}>{taxBands.used}</td>
          <td className={'text-center'}>{taxBands.damaged}</td>
          <td className={'text-center'}>{taxBands.lost}</td>
          <td className={'text-center'}>{taxBands.destroyed}</td>
          <td className={'text-center'}>{taxBands.returned}</td>
        </>
      ) : null}
    </tr>
  );
};
