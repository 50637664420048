import React from 'react';
import { ReportType, useGetPdf } from '../../../../hooks/useGetPdf';
import { SubscriptionType } from '../../../../../../types/Subscription';
import { useTranslation } from 'react-i18next';
import { useConfirmation } from '../../../../../notifications/useConfirmation';
import { DropdownActionButton, DropdownButtonAction } from './DropdownActionButton';
import { EntityLiveStatus } from '../../../../../../components/common/enums/EntityLiveStatus';
import { Color } from '../../../../../../components/common/enums/Color';
import { Bottle } from '../../../../../winery/components/wine_storage/types/WineStorage';
import { SelectedBottleMode } from '../../hooks/useBottles';
import { Link, useParams } from 'react-router-dom';
import { RouterParams } from '../../../../../../types/RouterParams';
import { PdfButtonComponent } from '../../../../../reports/components/PdfButtonComponent';

export const BottleButtonsBar: React.FC<{
  reportAvailable: boolean;
  id?: number;
  removeBottle: (id: number) => void;
  archiveBottle: (id: number) => void;
  toggleForBottling: (id: number) => void;
  selectedBottleMode: SelectedBottleMode;
  toggleSelectedBottleMode: (newMode?: SelectedBottleMode) => void;
  selectedBottle: Bottle;
  showList: boolean;
  setShowList: (val: boolean) => void;
}> = ({
  reportAvailable,
  id,
  removeBottle,
  archiveBottle,
  selectedBottle,
  toggleForBottling,
  selectedBottleMode,
  toggleSelectedBottleMode,
  showList,
  setShowList
}) => {
  const { bottleId } = useParams<RouterParams>();

  const { getPdf, loading } = useGetPdf({
    subscriptionType: SubscriptionType.PREMIUM,
    reportType: ReportType.BOTTLE,
    id: selectedBottle.id
  });
  const { t } = useTranslation();
  const showConfirmation = useConfirmation();
  const removeTaxBandsHandler = (id: number) => {
    showConfirmation({
      message: t('alerts.confirmation.REMOVE_BOTTLE'),
      actionLabel: 'Usuń',
      actionCallback: () => removeBottle(id)
    });
  };
  const editButton: DropdownButtonAction = {
    label:
      selectedBottleMode === 'card'
        ? t('bottle.btn.action.edit.label')
        : t('bottle.btn.action.cancel.label'),
    onClick: () => toggleSelectedBottleMode(),
    color: selectedBottleMode === 'card' ? Color.LightBlue : Color.Red
  };

  const toggleForBottlingButton: DropdownButtonAction = {
    label: selectedBottle.forBottling
      ? t('bottle.button.EXCLUDE_FOR_BOTTLING')
      : t('bottle.button.SET_FOR_BOTTLING'),
    onClick: () => toggleForBottling(id),
    disabled: selectedBottle.liveStatus === EntityLiveStatus.ARCHIVED
  };

  const archiveButton: DropdownButtonAction = {
    label:
      selectedBottle.liveStatus === EntityLiveStatus.ARCHIVED
        ? t('button.REVERT_ARCHIVE')
        : t('button.ARCHIVE'),
    onClick: () => archiveBottle(id)
  };

  const removeButton: DropdownButtonAction = {
    label: t('bottle.btn.action.remove.label'),
    onClick: () => removeTaxBandsHandler(selectedBottle.id),
    color: Color.Red
  };
  return (
    <div className="mb-4">
      <div>
        <div className={'inline'}>
          <DropdownActionButton
            mainAction={editButton}
            dropdownActions={[archiveButton, toggleForBottlingButton]}
            bottomAction={removeButton}
          />
          {bottleId ? (
            <Link
              to={'/mv/board/bottles'}
              className="btn btn-sm btn-secondary ml-2"
              type="button"
              title={t('button.show.list')}
            >
              <span className={'pr-2'}>{t('button.show.list.bottles.show')}</span>
              <em className="fas fa-outdent" />
            </Link>
          ) : null}
          {!bottleId ? (
            <button
              className="btn btn-sm btn-secondary ml-2"
              type="button"
              onClick={() => setShowList(!showList)}
            >
              {
                <span className={'pr-2'}>
                  {showList
                    ? t('button.show.list.bottles.hide')
                    : t('button.show.list.bottles.show')}
                </span>
              }{' '}
              <em className="fas fa-outdent" />
            </button>
          ) : null}
        </div>
        <PdfButtonComponent
          reportAvailable={reportAvailable}
          getPdf={getPdf}
          loading={loading}
          subscriptionTypeNeeded={SubscriptionType.PREMIUM}
        />
      </div>
    </div>
  );
};
