import React, { ChangeEvent, FC } from 'react';
import PageWrapper from '../../../../../../layouts/PageWrapper';
import { Button, Card, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { ServiceError } from '../../../../../../types/Service';
import { Harvest } from '../../types/Harvest';
import { FormErrorMessage } from '../../../../../../components/notifications/FormErrorMessage';
import { EntityLiveStatus } from '../../../../../../components/common/enums/EntityLiveStatus';
import { Checkbox } from '../../../../../../components/common/form-elements/Checkbox';
import SelectGrapeColor from '../../../../common/select/SelectGrapeColor';
import { usePushHistory } from '../../../../../../hooks/usePushHistory';
import { SectionWrapper } from '../../../../../../layouts/SectionWrapper';
import { InputColumn } from '../../../../../../components/forms/InputColumn';
import { DatePickerColumn } from '../../../../../../components/forms/DatePickerColumn';
import { TextareaColumn } from '../../../../../../components/forms/TextareaColumn';

interface Props {
  onSubmit: (e: React.MouseEvent<HTMLButtonElement>) => void;
  error: ServiceError;
  harvest: Harvest;
  updateHarvest: (e: ChangeEvent<HTMLInputElement>) => void;
  updateDate: (date: Date | string) => void;
  loading: boolean;
  onClickBack: () => void;
  updateHarvestCheckbox: (name: string, value: boolean) => void;
  handleUpdateSelect: () => void;
}

const SimpleGrapesForm: FC<Props> = ({
  onSubmit,
  error,
  harvest,
  updateHarvest,
  updateDate,
  loading,
  onClickBack,
  updateHarvestCheckbox,
  handleUpdateSelect
}) => {
  const { t } = useTranslation();
  const { pushHistory } = usePushHistory();

  function pushToBoard() {
    pushHistory(`/mv/board/summary`);
  }

  return (
    <PageWrapper title={'harvest.TITLE'} onClick={pushToBoard} returnText={'home.summary.TITLE'}>
      <SectionWrapper loading={loading}>
        <Card>
          <CardBody>
            <div className={'pb-4'}>
              <h5 className="float-right">
                {harvest?.id
                  ? t('sidebar.nav.element.EDIT')
                  : t('sidebar.nav.element.ADD_GRAPES.TITLE')}
              </h5>
            </div>
            <InputColumn
              label={t('grapes.NAME')}
              name={'varietyName'}
              value={harvest?.varietyName}
              onChange={updateHarvest}
              error={error}
              disabled={harvest?.liveStatus === EntityLiveStatus.ARCHIVED}
            />
            <SelectGrapeColor
              value={harvest?.grapeColor}
              name={'grapeColor'}
              label={t('grapevine.GRAPE_COLOR')}
              onChange={handleUpdateSelect}
              error={error}
              disabled={harvest?.liveStatus === EntityLiveStatus.ARCHIVED}
            />
            <InputColumn
              label={t('harvest.WEIGHT_OF_GRAPES')}
              name={'weightOfGrapes'}
              type={'number'}
              value={harvest?.weightOfGrapes}
              onChange={updateHarvest}
              error={error}
              disabled={harvest?.liveStatus === EntityLiveStatus.ARCHIVED}
            />
            <DatePickerColumn
              label={t('grapes.DATE_OF_HARVEST')}
              name={'dateOfHarvest'}
              onChange={updateDate}
              value={harvest?.dateOfHarvest}
              error={error}
              disabled={harvest?.liveStatus === EntityLiveStatus.ARCHIVED}
            />
            <TextareaColumn
              label={t('grapes.INFO')}
              name={'info'}
              value={harvest?.info}
              onChange={updateHarvest}
              error={error}
              optional
              disabled={harvest?.liveStatus === EntityLiveStatus.ARCHIVED}
            />
            <div className="px-3">
              <Checkbox
                name={'fromOtherCountry'}
                label={t('grapes.FROM_OTHER_COUNTRY')}
                value={!!harvest.fromOtherCountry}
                onChange={() =>
                  updateHarvestCheckbox('fromOtherCountry', !harvest.fromOtherCountry)
                }
              />
              <Checkbox
                name={'fromUe'}
                label={t('grapes.FROM_UE')}
                value={!!harvest.fromUe}
                onChange={() => updateHarvestCheckbox('fromUe', !harvest.fromUe)}
              />
              {harvest?.id ? (
                <div className={'pb-2'}>
                  <small className="text-muted">{t('grapeForm.message.1')}</small>
                </div>
              ) : null}
              <FormErrorMessage error={error} messageType={'details'} />
            </div>
            <div className="text-center bg-secondary">
              {harvest?.liveStatus === EntityLiveStatus.ARCHIVED ? (
                <Button color="info" className="btn-square" onClick={onClickBack}>
                  {t('common.BACK')}
                </Button>
              ) : (
                <Button color="info" className="btn-square" onClick={onSubmit}>
                  {harvest?.id ? t('common.SAVE') : t('common.ADD')}
                </Button>
              )}
            </div>
          </CardBody>
        </Card>
      </SectionWrapper>
    </PageWrapper>
  );
};

export default SimpleGrapesForm;
