import useCrudeApi from '../../../lib/useCrudeApi';
import { CompanyInfoData } from '../types';
import { SubscriptionType } from '../../../types/Subscription';

const usePlansService = (subscriptionType?: SubscriptionType) => {
  const url = subscriptionType ? `/ajax/plans/${subscriptionType}` : `/ajax/plans`;
  const { getList, putBody } = useCrudeApi<CompanyInfoData>(url);

  return {
    getList,
    put: putBody
  };
};

export default usePlansService;
