import { useEffect, useRef } from 'react';
import { useParams } from 'react-router-dom';
import { useWineContext } from '../../context/WineContext';
import useWineService from '../../hooks/useWineService';
import { StatusType } from '../../../../../../types/Service';
import { ResponseError } from '../../../../../error/ResponseError';
import { EntityLiveStatus } from '../../../../../../components/common/enums/EntityLiveStatus';
import { usePushHistory } from '../../../../../../hooks/usePushHistory';
import { useTranslation } from 'react-i18next';
import { RouterParams } from '../../../../../../types/RouterParams';
import { useConfirmation } from '../../../../../notifications/useConfirmation';
import usePlanPicker from '../../../../../subscription/hooks/usePlanPicker';
import { SubscriptionType } from '../../../../../../types/Subscription';
import { useAuthContext } from '../../../../../platform/AuthContext';
import { AuthService } from '../../../../../platform/AuthService';
import { Tank } from '../../../tank/types/Tank';

export interface Button {
  action: () => void;
  label: string;
}

export interface WineInfoButtons {
  saveEditButton: Button;
  archiveButton?: Button;
  toProductionButton?: Button;
  removeButton?: Button;
  removeLastWineEntryAndWineProductionButton?: Button;
}

const WineInfoContainer = ({ render }) => {
  const { updateWine, setWineResult, wine, error, setError } = useWineContext();
  const serviceRef = useRef(useWineService());
  const { wineId } = useParams<RouterParams>();
  const { pushHistory, goBack } = usePushHistory();
  const { t } = useTranslation();
  const showConfirmation = useConfirmation();
  const { isValidSubscription } = usePlanPicker({
    subscriptionType: SubscriptionType.NORMAL,
    fromBlockingContent: true,
    itemIndexToHighlight: 1
  });

  const {
    setPrincipal,
    subscriptionInfo: { accessInfo }
  } = useAuthContext();

  const toProduction = () => {
    showConfirmation({
      message: t('alerts.confirmation.TO_PRODUCTION'),
      actionLabel: 'Tak',
      actionButtonColor: 'warning',
      actionCallback: () => handleToProduction()
    });
  };

  useEffect(() => {
    setWineResult({ status: StatusType.loading });
    wineId &&
      serviceRef.current
        .get(parseInt(wineId))
        .then((response) => {
          setWineResult({ status: StatusType.loaded, payload: response });
        })
        .catch((error) => setWineResult(new ResponseError(error)));

    return () => updateWine('reset', '');
  }, [wineId]); // eslint-disable-line react-hooks/exhaustive-deps

  const editWine = () => {
    pushHistory(`/mv/wine/e/${wineId}/`);
  };

  const archive = () => {
    if (!isValidSubscription()) return;
    const action =
      wine?.liveStatus === EntityLiveStatus.ARCHIVED
        ? serviceRef.current.revertArchive
        : serviceRef.current.archive;
    setWineResult({ status: StatusType.loading });
    action(wine?.id)
      .then((response) => {
        setWineResult({ status: StatusType.loaded, payload: response });
      })
      .catch((error) => {
        setError(error);
        setWineResult({ status: StatusType.error });
      });
  };

  const handleToProduction = () => {
    setWineResult({ status: StatusType.loading });
    serviceRef.current
      .toProduction(wine?.id)
      .then((wineProductionId) => {
        pushHistory(`/mv/wine_production/info/${wineProductionId}/`);
      })
      .catch((error) => {
        setError(error);
        setWineResult({ status: StatusType.error });
      });
  };

  const removeWine = () => {
    showConfirmation({
      message: t('alerts.confirmation.REMOVE_WINE'),
      actionLabel: 'Tak',
      actionButtonColor: 'danger',
      actionCallback: () => handleRemoveWine()
    });
  };

  function handleRemoveWine() {
    serviceRef.current
      .del(wine.id)
      .then(() => goBack(`/mv/board/production`))
      .then(() => {
        if (!accessInfo.hasNormalAccess) {
          AuthService.getUserInfo()
            .then((res) => setPrincipal(res))
            .catch(() => {
              pushHistory(`/mv/error`);
            });
        }
      })
      .catch((e: Error) => setError(new ResponseError(e)));
  }

  const updateTank = (selected: { value: number }) => {
    const tank: Partial<Tank> = { id: selected.value };
    setWineResult({ status: StatusType.loading });
    serviceRef.current
      .placeInTank(tank)
      .then((response) => {
        setWineResult({ status: StatusType.loaded, payload: response });
      })
      .catch((error) => {
        setError(error);
        setWineResult({ status: StatusType.error });
      });
  };

  const saveEditButton: Button = {
    action: editWine,
    label: wine?.liveStatus === EntityLiveStatus.ARCHIVED ? t('button.VIEW') : t('button.EDIT')
  };

  const toProductionButton: Button = {
    action: toProduction,
    label: t('button.toProduction')
  };

  const archiveButton: Button = {
    action: archive,
    label:
      wine?.liveStatus === EntityLiveStatus.ARCHIVED
        ? t('button.REVERT_ARCHIVE')
        : t('button.ARCHIVE')
  };

  const removeButton: Button = {
    action: removeWine,
    label: t('common.REMOVE')
  };

  const buttons: WineInfoButtons = {
    saveEditButton,
    archiveButton,
    toProductionButton,
    removeButton
  };

  return render(buttons, updateTank, error);
};

export default WineInfoContainer;
