import React from 'react';
import {
  PlacementResult,
  TaxBandsPlacementListPresentationData
} from '../../../../winery/components/wine_storage/types/WineStorage';
import { useTranslation } from 'react-i18next';
import { usePushHistory } from '../../../../../hooks/usePushHistory';
import '../assets/table.css';
import { Button } from '../../../../../components/common/buttons/Button';
import { useParams } from 'react-router-dom';
import { RouterParams } from '../../../../../types/RouterParams';

type IProps = {
  placements: TaxBandsPlacementListPresentationData[];
  short: boolean;
  shorter: boolean;
  date: string;
  withBackground: boolean;
} & RemovePlacementProps;

export type RemovePlacementProps = {
  removePlacement: ({
    placementId,
    wineStorageId
  }: {
    placementId: number;
    wineStorageId: number;
  }) => void;
  disabledRemove: boolean;
};

export const PlacementsRow: React.FC<IProps> = ({
  date,
  placements,
  short,
  shorter,
  withBackground,
  removePlacement,
  disabledRemove
}) => {
  const { t } = useTranslation();
  const { pushHistory } = usePushHistory();
  const { placementId } = useParams<RouterParams>();

  function navigateToWine(id: number) {
    if (!id) return;
    pushHistory(`/mv/wine_storage/info/${id}`);
  }

  function navigateToTaxBandPlacement(placementValue: TaxBandsPlacementListPresentationData) {
    pushHistory(
      `/mv/wine_storage/tax_band_placement/${placementValue.wineStorage.id}/${placementValue.id}/`,
      `/mv/board/tax_bands/${placementValue.taxBandsId}`
    );
  }

  const getStyle = (placement: TaxBandsPlacementListPresentationData) => {
    let style = '';
    if (placementId === placement.id.toString()) {
      style += 'bg-info-tax-bands ';
    }
    if (placement.needAttention) style += 'bg-info-tax-bands-warning';
    return style;
  };

  return (
    <>
      {placements.map((placement, index) => (
        <tr key={placement.id} className={withBackground ? 'bg-light' : ''}>
          {index === 0 ? (
            <td className="text-bold" rowSpan={placements.length}>
              {date}
            </td>
          ) : null}
          <td className={'text-center ' + getStyle(placement)}>{placement.quantity}</td>
          <td
            className={'text-center ' + getStyle(placement)}
            style={{ cursor: placement.wineStorage?.id ? 'pointer' : '' }}
            onClick={() => navigateToWine(placement.wineStorage?.id)}
          >
            <button className="btn btn-link p-0">{placement.wineStorage?.name}</button>
          </td>
          {!shorter ? (
            <td className={'text-center ' + getStyle(placement)}>
              {t(`placement.placementResult.${placement.placementResult}`)}
            </td>
          ) : null}
          {!short ? (
            <>
              <td className={'text-center ' + getStyle(placement)}>{placement.declarationDate}</td>
              {placement.placementResult === PlacementResult.USED && placement.wineStorage ? (
                <td
                  className={
                    'text-center ' +
                    (placement.payed ? 'text-success ' : 'text-danger ') +
                    getStyle(placement)
                  }
                >
                  {placement.payed ? t('answer.yes') : t('answer.no')}
                </td>
              ) : (
                <td className={placementId ? 'bg-info-tax-bands' : ''}></td>
              )}
            </>
          ) : null}
          <td className={'text-center text-nowrap ' + getStyle(placement)}>
            {placement.placementResult === PlacementResult.USED && placement.wineStorage ? (
              <Button.Edit onClick={() => navigateToTaxBandPlacement(placement)} />
            ) : null}
            <Button.Delete
              onClick={() =>
                removePlacement({
                  placementId: placement.id,
                  wineStorageId: placement.wineStorage?.id || 0
                })
              }
              disabled={disabledRemove}
            />
          </td>
        </tr>
      ))}
    </>
  );
};
