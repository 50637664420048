import React from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import {
  CommonListBox,
  ListBoxActions,
  ListBoxElementToShow
} from '../../../wine/components/WineInfo/list-box/shared/CommonListBox';
import { useWineProductionContext } from '../../context/WineProductionContext';
import CommonRow from '../../../../../../components/common/table/CommonRow';
import { EntityLiveStatus } from '../../../../../../components/common/enums/EntityLiveStatus';
import { useWineContext } from '../../../wine/context/WineContext';
import { SectionWrapper } from '../../../../../../layouts/SectionWrapper';
import { TankEntry } from '../../../wine_storage/types/WineStorage';
import { UsedWineStorage } from '../DisposedAsBottlesListBox/DisposedAsBottlesListBoxPresentation';

interface Props {
  tankEntries: TankEntry[];
  elementToShow: ListBoxElementToShow;
  actions: ListBoxActions;
}

const DisposedAsTanksListBoxPresentation: React.FC<Props> = ({
  tankEntries,
  elementToShow,
  actions
}) => {
  const { t } = useTranslation();
  const { loading: productionLoading, wineProduction } = useWineProductionContext();
  const { loading: wineLoading } = useWineContext();
  return (
    <SectionWrapper loading={productionLoading || wineLoading}>
      <Card>
        <CardBody>
          <h5 className="m-0 float-right">{t('wineProduction.info.DISPOSED_AS_TANK_ENTRIES')}</h5>
          <small className="text-muted">
            {t('common.SUMMARY')}:{' '}
            {tankEntries?.reduce((total, tankEntry) => total + tankEntry.liters, 0)} l.
          </small>
          {tankEntries?.length ? (
            <Table className={'table w-100 no-footer table-hover'}>
              <tbody>
                <CommonRow label={`${t('common.NAME')}:`} value={[`${t('common.LITERS')}:`]} />
                {(tankEntries || []).map((tankEntry, key) => {
                  return (
                    <CommonListBox
                      header={[
                        <UsedWineStorage
                          wineStorage={tankEntry?.wineStorageConsumer}
                          key={tankEntry.id}
                        />,
                        tankEntry?.liters?.toString()
                      ]}
                      actions={actions}
                      disableActions={wineProduction.liveStatus === EntityLiveStatus.ARCHIVED}
                      elementToSHow={elementToShow}
                      path={`wine_storage/info`}
                      elementId={tankEntry?.id}
                      infoElementId={tankEntry?.wineStorageConsumer?.id}
                      dropdownInfo={{
                        node: (
                          <>
                            <div>Przekazano {tankEntry.liters} l.</div>
                            <div>Aktualnie w zbiorniku: {tankEntry.actualLiters} l.</div>
                          </>
                        )
                      }}
                      elementToShowKey={key}
                      key={key}
                    />
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <CardBody>
              <div className={'text-center'}>{t('common.NO_DATA')}</div>
            </CardBody>
          )}
        </CardBody>
      </Card>
    </SectionWrapper>
  );
};

export default DisposedAsTanksListBoxPresentation;
