import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import usePagination from '../../../../hooks/usePagination';
import { usePushHistory } from '../../../../hooks/usePushHistory';
import { CompanyData, CompanyInfoList } from '../../types';
import { PaginationComponent } from '../../../../components/PaginationComponent';
import { CompanyRow } from './CompanyRow';
import { useToggle } from '../../../../hooks/useToggle';
import { Button, CardBody } from 'reactstrap';
import { SectionWrapper } from '../../../../layouts/SectionWrapper';
import { InputColumn } from '../../../../components/forms/InputColumn';
import { useSearch } from '../../../../hooks/useSearch';
import { SearchComponent } from '../../../../components/common/search/SearchComponent';
import { useResponsiveContext } from '../../../platform/ResponsiveContext';

export const CompaniesTable: React.FC<{
  companies: CompanyInfoList[];
  loading: boolean;
  actions: {
    addNewCompany: (company: CompanyData) => void;
  };
}> = ({ companies, loading, actions }) => {
  const { t } = useTranslation();
  const [showForm, toggleShowForm] = useToggle(false);
  const [newCompany, setNewCompany] = useState<CompanyData>({ name: '', description: '' });
  const [submitting, setSubmitting] = useState(false);
  const [result, search] = useSearch<CompanyInfoList>({
    entities: companies,
    field: [
      'id',
      'name',
      'subscriptionType',
      'subscriptionPeriod',
      'subscriptionStatus',
      'subscriptionValidToDate',
      'created',
      'hasActiveTrial'
    ]
  });
  const pagination = usePagination(result, 10);
  const { isMobile } = useResponsiveContext();

  const { pushHistory } = usePushHistory();

  function navigateToCompany(id: number) {
    pushHistory(`/admin_console/company/${id}`);
  }

  function handleUpdate(e: React.ChangeEvent<HTMLTextAreaElement | HTMLInputElement>) {
    const name = e.target.name;
    const newValue = e.target.value;
    setNewCompany((prev) => ({ ...prev, [name]: newValue }));
  }

  function submitCompany() {
    setSubmitting(true);
    actions.addNewCompany(newCompany);
    setSubmitting(false);
  }

  return (
    <SectionWrapper loading={loading}>
      <div className="mb-3">
        <div>
          <button
            className={'btn btn-sm ' + (showForm ? 'btn-danger' : 'btn-info')}
            type="button"
            onClick={() => toggleShowForm()}
          >
            <em className={'mr-2 fas fa-' + (showForm ? 'minus' : 'plus')} /> winnicę
          </button>
        </div>
      </div>
      {showForm ? (
        <SectionWrapper loading={submitting}>
          <div className="card">
            <div className="card-body pb-1">
              <small className="text-muted float-right">
                {t('companies.add.form.table.title')}
              </small>
              <div className="dataTables_wrapper dt-bootstrap4 no-footer">
                <CardBody>
                  <InputColumn
                    label={t('companies.add.form.table.name')}
                    name={'name'}
                    onChange={handleUpdate}
                    value={newCompany.name}
                  />

                  <InputColumn
                    label={t('ingredients.SHORT_INFO')}
                    name={'description'}
                    value={newCompany.description}
                    maxSize={200}
                    onChange={handleUpdate}
                    optional
                  />
                </CardBody>
                <div className="text-center mb-2">
                  <Button color="info" className="btn-square" onClick={submitCompany}>
                    {t('common.ADD')}
                  </Button>
                </div>
              </div>
            </div>
          </div>
        </SectionWrapper>
      ) : null}
      <div className="card">
        <div className="card-body pb-1">
          <small className="text-muted float-right">{t('companies.table.title')}</small>
          <SearchComponent search={search} />
          <div className="dataTables_wrapper dt-bootstrap4 no-footer">
            <div className="row">
              <div className="col-sm-12 table-responsive">
                <table className="table w-100 no-footer table-hover table-sm">
                  <thead>
                    <tr role="row">
                      <th style={{ width: '125px' }}>{t('companies.table.name')}</th>
                      <th>{t('companies.table.subscriptionType')}</th>
                      {isMobile ? null : <th>{t('companies.table.subscriptionPeriod')}</th>}
                      <th>{t('companies.table.subscriptionStatus')}</th>
                      <th style={{ width: '30px' }} className="text-center">
                        {t('companies.table.trial')}
                      </th>
                      {isMobile ? null : <th>{t('companies.table.created')}</th>}
                      {isMobile ? null : <th>{t('companies.table.subscriptionValidToDate')}</th>}
                    </tr>
                  </thead>
                  <tbody>
                    {pagination.getCurrentResults().map((company) => {
                      return (
                        <CompanyRow
                          company={company}
                          onClick={() => navigateToCompany(company.id)}
                          key={company.id}
                        />
                      );
                    })}
                    {pagination.getCurrentResults().length === 0 ? (
                      <tr>
                        <td colSpan={100} className="text-center">
                          Brak danych
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <PaginationComponent pagination={pagination} />
      </div>
    </SectionWrapper>
  );
};
