import React from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import CommonRow from '../../../../components/common/table/CommonRow';
import { useTranslation } from 'react-i18next';
import { PaymentInfo } from '../../types';
import { SectionWrapper } from '../../../../layouts/SectionWrapper';

interface IProps {
  paymentInfo: PaymentInfo;
  title: string;
  loading: boolean;
}

export const CompanyPaymentInfoCard: React.FC<IProps> = ({ paymentInfo, title, loading }) => {
  const { t } = useTranslation();

  return (
    <SectionWrapper loading={loading} hideSpinner>
      <Card>
        <CardBody>
          <span className="text-muted float-left">{title}</span>
          <Table>
            <tbody>
              <CommonRow
                label={t('company.paymentInfo.priceToPay')}
                value={`${paymentInfo.priceToPay} zł`}
                valueColumnClassName={'text-right'}
              />
              <CommonRow
                label={t('company.paymentInfo.paymentTitle')}
                value={paymentInfo.paymentTitle}
                valueColumnClassName={'text-right'}
              />
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </SectionWrapper>
  );
};
