import React from 'react';
import { useTranslation } from 'react-i18next';
import ContentWrapper from './ContentWrapper';
import 'loaders.css/loaders.css';
import 'spinkit/css/spinkit.css';

const PageWrapper: React.FC<{
  title?: string;
  returnText?: string;
  loading?: boolean;
  disabled?: boolean;
  onClick?: () => void;
  children?: React.ReactNode;
}> = ({ title, loading, disabled, onClick, children, returnText }) => {
  const { t } = useTranslation();

  return (
    <>
      {disabled ? (
        <div className={loading ? 'whirl duo area-disabled' : ''}>{children}</div>
      ) : (
        <ContentWrapper>
          <div
            className="content-heading border-0 rounded-bottom bg-secondary"
            onClick={onClick}
            style={{ cursor: onClick ? 'pointer' : '', marginLeft: '0px', marginRight: '0px' }}
          >
            <div>
              {t(title)}
              {/*<small>{t(subtitle)}</small>*/}
            </div>
            <div className={'position-absolute text-muted'} style={{ right: '35px' }}>
              {onClick ? <em className="fa-1x mr-2 fas fa-angle-double-left"></em> : null}
            </div>
            <div className={'position-absolute text-muted'} style={{ right: '75px' }}>
              {onClick ? <small>{t(returnText)}</small> : null}
            </div>
          </div>
          <div
            style={{ height: loading ? '80vh' : '' }}
            className={loading ? 'whirl area-disabled' : ''}
          >
            {children}
          </div>
        </ContentWrapper>
      )}
    </>
  );
};

export default PageWrapper;
