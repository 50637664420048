import React from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import CommonRow from '../../../../../../../../components/common/table/CommonRow';
import CardFooter from '../../../../../../../../components/common/cards/CardFooter';
import { FormErrorMessage } from '../../../../../../../../components/notifications/FormErrorMessage';
import { useIngredientContext } from '../../../../../ingredient/context/IngredientContext';
import { CommonListBox, ListBoxActions, ListBoxElementToShow } from './CommonListBox';
import { SectionWrapper } from '../../../../../../../../layouts/SectionWrapper';
import { TankEntry } from '../../../../../wine_storage/types/WineStorage';
import usePagination from '../../../../../../../../hooks/usePagination';
import { PaginationComponent } from '../../../../../../../../components/PaginationComponent';
import { UsedWineProduction } from './BottleEntriesListBox';

interface Props {
  tankEntries: TankEntry[];
  elementToShow: ListBoxElementToShow;
  actions: Pick<ListBoxActions, 'toggleShow' | 'editElement' | 'removeElement'>;
  entityName: string;
  disabledActions?: boolean;
  loading: boolean;
  title: string;
}

const TankEntriesListBox: React.FC<Props> = ({
  tankEntries,
  elementToShow,
  actions,
  entityName,
  disabledActions,
  loading,
  title
}) => {
  const { t } = useTranslation();
  const { error } = useIngredientContext();
  const pagination = usePagination(tankEntries, 10);

  return (
    <SectionWrapper loading={loading} hideSpinner>
      <Card>
        <CardBody>
          <h5 className="m-0 float-right">{title}</h5>
          <small className="text-muted">
            {t('common.SUMMARY')}:{' '}
            {tankEntries?.map((output) => output.liters).reduce((total, item) => total + item, 0)}
            {' l.'}
          </small>
          {tankEntries?.length ? (
            <Table className={'table w-100 no-footer table-hover'}>
              <tbody>
                <CommonRow
                  label={`${t('tankEntry.LITERS')}`}
                  value={[`${t('tankEntry.tank.NUMBER')}`, t('tankEntry.wineProduction')]}
                  className={'text-center'}
                />
                {(pagination.getCurrentResults() || []).map((entry, key) => {
                  return (
                    <CommonListBox
                      header={[
                        <>{`${entry?.actualLiters} / ${entry?.liters}`}</>,
                        entry?.tank.number,
                        <UsedWineProduction wineProduction={entry.wineProduction} key={entry.id} />
                      ]}
                      actions={actions}
                      disableActions={disabledActions}
                      elementToSHow={elementToShow}
                      path={`${entityName}/${entry.wineProduction?.id}`}
                      elementId={entry?.id}
                      dropdownInfo={{
                        paragraph: ''
                      }}
                      elementToShowKey={key}
                      key={key}
                      centerFirstColumn
                      dropdownClassName={'pl-2 max-w-80'}
                    />
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <CardBody>
              <div className={'text-center'}>{t('common.NO_DATA')}</div>
            </CardBody>
          )}
          {error?.error?.details && (
            <CardFooter>
              <FormErrorMessage error={error} messageType={'details'} />
            </CardFooter>
          )}
        </CardBody>
        <PaginationComponent pagination={pagination} />
      </Card>
    </SectionWrapper>
  );
};

export default TankEntriesListBox;
