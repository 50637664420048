import React, { FC } from 'react';
import {
  WineStorageTaxBandPlacementsListBoxContainer,
  WineStorageTaxBandsPlacementsListBoxProps
} from './WineStorageTankOutputsListBoxContainer';
import TaxBandPlacementsListBox from '../../../../wine/components/WineInfo/list-box/shared/TaxBandPlacementsListBox';

const WineStorageTaxBandsPlacementsListBox: FC<WineStorageTaxBandsPlacementsListBoxProps> = (
  props
) => {
  return (
    <WineStorageTaxBandPlacementsListBoxContainer
      {...props}
      render={(taxBandPlacements, elementToShow, actions, loading) => (
        <TaxBandPlacementsListBox
          taxBandPlacements={taxBandPlacements}
          elementToShow={elementToShow}
          actions={actions}
          entityName={'wine_storage'}
          loading={props.loading || loading}
          disabledActions={props?.disabledActions}
          entityId={props.entityId}
          title={props.title}
        />
      )}
    />
  );
};

export default WineStorageTaxBandsPlacementsListBox;
