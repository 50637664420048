import React, { FC } from 'react';
import {
  WineStorageBottleOutputsListBoxContainer,
  WineStorageBottleOutputsListBoxProps
} from './WineStorageBottleOutputsListBoxContainer';
import BottleOutputsListBox from '../../../../wine/components/WineInfo/list-box/shared/BottleOutputsListBox';

const WineStorageBottleOutputsListBox: FC<WineStorageBottleOutputsListBoxProps> = (props) => {
  return (
    <WineStorageBottleOutputsListBoxContainer
      {...props}
      render={(bottleOutputs, elementToShow, actions, loading) => (
        <BottleOutputsListBox
          bottleOutputs={bottleOutputs}
          elementToShow={elementToShow}
          actions={actions}
          entityName={'wine_production'}
          loading={props.loading || loading}
          disabledActions={props?.disabledActions}
          entityId={props.entityId}
          title={props.title}
        />
      )}
    />
  );
};

export default WineStorageBottleOutputsListBox;
