import React, { useEffect, useRef, useState } from 'react';
import PageWrapper from '../../../../layouts/PageWrapper';
import { useTranslation } from 'react-i18next';
import useIngredientService from '../../../winery/components/ingredient/hooks/useIngredientService';
import { Ingredient } from '../../../winery/components/ingredient/types/Ingredient';
import { IngredientsListButtonsBar } from './IngredientsListButtonsBar';
import { IngredientsListTable } from './IngredientsListTable';
import { ListItem } from '../../../reports/components/ListItem';
import { useIngredientGroups } from './hooks/useIngredientsGroups';
import { usePushHistory } from '../../../../hooks/usePushHistory';

export const IngredientsSummaryPage = () => {
  const { t } = useTranslation();
  const serviceRef = useRef(useIngredientService());
  const [loading, setLoading] = useState(true);
  const { pushHistory } = usePushHistory();

  const [ingredients, setIngredients] = useState<Ingredient[]>([]);
  const {
    ingredientsGroups,
    selectedType,
    selectedIngredients,
    setSelectedIngredients,
    handleAllGroups,
    handleChangeGroup
  } = useIngredientGroups(ingredients);

  useEffect(() => {
    if (serviceRef.current != null && loading) {
      serviceRef.current
        .getListPresentation()
        .then((response) => {
          setIngredients(response);
          setSelectedIngredients(response);
        })
        .finally(() => setLoading(false));
    }
  }, [loading, setSelectedIngredients]);

  return (
    <PageWrapper
      title={'home.INGREDIENTS'}
      onClick={() => pushHistory('/mv')}
      returnText={'sidebar.nav.element.DASHBOARD'}
    >
      <div className="row">
        <div className="col-lg-3">
          <div className="card">
            <div className="card-body">
              <strong className="text-muted">{t('file.manager.types')}</strong>
            </div>
            <div className="list-group">
              <ListItem
                label={'Wszystkie'}
                value={ingredients.length}
                active={'all' === selectedType}
                color={'grey'}
                onClick={() => handleAllGroups()}
              />
              {ingredientsGroups.map((item, index) => (
                <ListItem
                  label={item.label}
                  value={item.count}
                  active={item.type === selectedType}
                  color={item.color}
                  key={item.type}
                  onClick={() => handleChangeGroup(item.type)}
                  isLast={index === ingredientsGroups.length - 1}
                />
              ))}
            </div>
          </div>
        </div>
        <div className="col-lg-9">
          <IngredientsListButtonsBar reportAvailable={ingredients.length > 0} />
          <IngredientsListTable
            ingredients={selectedIngredients}
            loading={loading}
            key={selectedType}
          />
        </div>
      </div>
    </PageWrapper>
  );
};
