import { useEffect, useState } from 'react';
import { Service, ServiceError, ServiceWorking, StatusType } from '../../../../../types/Service';
import { ResponseError } from '../../../../error/ResponseError';
import useGrapevineService from './useGrapevineService';
import { Grapevine } from '../types/Grapevine';
import { SelectOption } from '../../../../../types/SelectOption';

const useGrapevinesReceiver = (value?) => {
  const service = useGrapevineService();
  const [result, setResult] = useState<Service<Grapevine[]>>({
    status: StatusType.loading
  });
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [selected, setSelected] = useState<SelectOption | string>('');

  useEffect(() => {
    if (value) {
      setSelected(
        value?.id ? { value: value.id, label: value.label ? value.label : value.name } : ''
      );
    }
    const workingResult = result as ServiceWorking<Grapevine[]>;
    if (!workingResult?.payload) {
      service
        ?.getList()
        .then((response) => {
          setResult({ status: StatusType.loaded, payload: response });
          setOptions(response?.map((p) => ({ value: p.id, label: p.name })));
        })
        .catch((error) => setResult(new ResponseError(error) as ServiceError));
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  return { result, options, selected, loading: result.status === StatusType.loading };
};

export default useGrapevinesReceiver;
