import React, { FC } from 'react';
import {
  WineStorageTankOutputsListBoxContainer,
  WineStorageTankOutputsListBoxProps
} from './WineStorageTankOutputsListBoxContainer';
import TankOutputsListBox from '../../../../wine/components/WineInfo/list-box/shared/TankOutputsListBox';

const WineStorageTankOutputsListBox: FC<WineStorageTankOutputsListBoxProps> = (props) => {
  return (
    <WineStorageTankOutputsListBoxContainer
      {...props}
      render={(tankOutputs, elementToShow, actions, loading) => (
        <TankOutputsListBox
          tankOutputs={tankOutputs}
          elementToShow={elementToShow}
          actions={actions}
          entityName={'wine_production'}
          loading={props.loading || loading}
          disabledActions={props?.disabledActions}
          entityId={props.entityId}
          title={props.title}
        />
      )}
    />
  );
};

export default WineStorageTankOutputsListBox;
