import { FC } from 'react';
import { WineProduction } from '../../../wine/types/WineProduction';
import { useCRUDListBoxContainer } from '../../../wine/components/WineInfo/list-box/shared/useCRUDListBoxContainer';
import { useWineProductionContext } from '../../context/WineProductionContext';
import { BottleEntry } from '../../../wine_storage/types/WineStorage';

export interface BottleEntryListBoxProps {
  bottleEntries: BottleEntry[];
}

type Props = BottleEntryListBoxProps & { render: (list, elementToShow, actions) => JSX.Element };

export const DisposedAsBottlesListBoxContainer: FC<Props> = ({ render, bottleEntries }) => {
  const { setWineProductionResult, setError } = useWineProductionContext();
  const { list, elementToShow, actions } = useCRUDListBoxContainer<WineProduction, BottleEntry>(
    bottleEntries,
    undefined,
    setWineProductionResult,
    setError
  );

  const selectedActions = {
    // infoElement: actions.infoElement,
    toggleShow: actions.toggleShow
  };

  return render(list, elementToShow, selectedActions);
};
