import React from 'react';
import { useTranslation } from 'react-i18next';
import { SectionWrapper } from '../../../../../layouts/SectionWrapper';
import { TaxBandsInfo } from '../../../../../types/TaxBands';
import { TaxBandsFormRow } from './TaxBandsFormRow';
import { ServiceError } from '../../../../../types/Service';
import { useUpdateTaxBands } from '../hooks/useUpdateTaxBands';
import { FormErrorMessage } from '../../../../../components/notifications/FormErrorMessage';

const Header = ({
  showAllFields,
  toggleAllFields
}: {
  showAllFields: boolean;
  toggleAllFields: () => void;
}) => {
  const { t } = useTranslation();
  return (
    <div className={'d-flex justify-content-between align-items-top'}>
      <div className="mb-2">
        <button
          title={
            showAllFields ? t('taxBands.board.show.initFields') : t('taxBands.board.show.allFields')
          }
          className={'mt-2 btn btn-sm btn-link pl-0'}
          onClick={() => toggleAllFields()}
        >
          {showAllFields ? (
            <em className="mr-1 fas fa-minus" />
          ) : (
            <em className="mr-1 fas fa-plus" />
          )}{' '}
          {t('taxBands.board.show.allFields.btn')}
        </button>
      </div>
      <small className="text-muted ">{t('taxBands.board.add')}</small>
    </div>
  );
};

export type TaxBandsUpdateFormFieldValue = { name: string; value: string | number | Date };

export const TaxBandsForm: React.FC<{
  addTaxBands: (taxBandsInfo: TaxBandsInfo) => void;
  loading: boolean;
  error?: ServiceError;
  showAllFields: boolean;
  toggleShowAllFields: () => void;
}> = ({ addTaxBands, loading, error, showAllFields, toggleShowAllFields }) => {
  const [taxBands, updateField] = useUpdateTaxBands();
  return (
    <SectionWrapper loading={loading} hideSpinner>
      <div className="card">
        <div className="card-body pb-1">
          <Header showAllFields={showAllFields} toggleAllFields={toggleShowAllFields} />
          <TaxBandsFormRow
            addTaxBands={addTaxBands}
            onChange={updateField}
            error={error}
            taxBands={taxBands}
            showAllFields={showAllFields}
          />
          <div className="pb-2">
            <FormErrorMessage error={error} messageType={'details'} />
          </div>
        </div>
      </div>
    </SectionWrapper>
  );
};
