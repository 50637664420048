import React from 'react';
import { useTranslation } from 'react-i18next';
import { SectionWrapper } from '../../../../../layouts/SectionWrapper';
import { ServiceError } from '../../../../../types/Service';
import {
  DiscountCardReadModel,
  DiscountPeriod,
  DiscountType
} from '../../../hooks/useDiscountService';
import { useUpdateDiscountCard } from '../hooks/useUpdateDiscountCard';
import { DiscountCardFormRow } from './DiscountCardFormRow';
import { FormErrorMessage } from '../../../../../components/notifications/FormErrorMessage';

const Header = () => {
  const { t } = useTranslation();
  return (
    <div className={'d-flex justify-content-between align-items-top'}>
      <div className="mb-2"></div>
      <small className="text-muted ">{t('discountCard.board.add')}</small>
    </div>
  );
};

export const DiscountCardForm: React.FC<{
  addDiscountCard: (
    card: DiscountCardReadModel,
    period: DiscountPeriod,
    type: DiscountType,
    customCode?: string
  ) => void;
  loading: boolean;
  error?: ServiceError;
}> = ({ addDiscountCard, loading, error }) => {
  const [discountCard, updateField] = useUpdateDiscountCard();

  return (
    <SectionWrapper loading={loading} hideSpinner>
      <div className="card">
        <div className="card-body pb-1">
          <Header />
          <DiscountCardFormRow
            addDiscountCard={addDiscountCard}
            onChange={updateField}
            error={error}
            discountCard={discountCard}
          />
          <div className="pb-2">
            <FormErrorMessage error={error} messageType={'details'} />
          </div>
        </div>
      </div>
    </SectionWrapper>
  );
};
