import { useEffect, useState } from 'react';
import { useTranslation } from 'react-i18next';
import { SelectOption } from '../../../types/SelectOption';
import { DiscountType } from './useDiscountService';

export const useDiscountTypeReceiver = (value?: DiscountType | SelectOption | null) => {
  const { t } = useTranslation();
  const options: SelectOption[] = Object.keys(DiscountType)
    .filter((i) => typeof DiscountType[i] === 'string')
    .map((i) => ({ value: i, label: t(`discountCard.type.${i}`) }));
  const [selected, setSelected] = useState<SelectOption | string>('');

  useEffect(() => {
    if (value) {
      setSelected(value ? { value: value, label: t(`discountCard.type.${value}`) } : '');
      return;
    }
  }, [value, t]);

  return { options, selected, loading: false };
};
