import React, { ChangeEvent, FC } from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import { ServiceError } from '../../../../../../types/Service';
import { useTranslation } from 'react-i18next';
import PageWrapper from '../../../../../../layouts/PageWrapper';
import { EntityLiveStatus } from '../../../../../../components/common/enums/EntityLiveStatus';
import { WineStorage } from '../../types/WineStorage';
import SelectWineColor from '../../../../common/select/SelectWineColor';
import SelectWineTaste from '../../../../common/select/SelectWineTaste';
import { Checkbox } from '../../../../../../components/common/form-elements/Checkbox';
import { usePushHistory } from '../../../../../../hooks/usePushHistory';
import { FormErrorMessage } from '../../../../../../components/notifications/FormErrorMessage';
import { SectionWrapper } from '../../../../../../layouts/SectionWrapper';
import { InputColumn } from '../../../../../../components/forms/InputColumn';

interface Props {
  onSubmit: (e: React.MouseEvent<HTMLButtonElement>) => void;
  wineStorage: WineStorage;
  updateWineStorage: (e: ChangeEvent<HTMLInputElement>) => void;
  handleUpdateSelect: () => void;
  loading: boolean;
  onClickBack: () => void;
  updateCheckbox: (name: string, value: boolean) => void;
  error: ServiceError;
}

export const WineStorageFormPresentation: FC<Props> = ({
  loading,
  onClickBack,
  onSubmit,
  wineStorage,
  updateWineStorage,
  error,
  handleUpdateSelect,
  updateCheckbox
}) => {
  const { t } = useTranslation();
  const { pushHistory } = usePushHistory();

  function pushToBoard() {
    pushHistory(`/mv/board/storage`);
  }

  return (
    <PageWrapper title={'wineStorage.TITLE'} onClick={pushToBoard} returnText={'home.STORAGE'}>
      <SectionWrapper loading={loading}>
        <Card>
          <CardBody>
            <div className={'pb-5'}>
              <h5 className="float-right">
                {wineStorage?.id
                  ? t('sidebar.nav.element.EDIT')
                  : t('sidebar.nav.element.ADD_NEW_WINE_STORAGE')}
              </h5>
            </div>
            <InputColumn
              label={t('wine.NAME')}
              name={'name'}
              onChange={updateWineStorage}
              value={wineStorage?.name}
              error={error}
              disabled={wineStorage?.liveStatus === EntityLiveStatus.ARCHIVED}
            />
            <SelectWineColor
              value={wineStorage?.wineColor}
              name={'wineColor'}
              label={t('wine.COLOR')}
              onChange={handleUpdateSelect}
              error={error}
              disabled={wineStorage?.liveStatus === EntityLiveStatus.ARCHIVED}
            />
            <SelectWineTaste
              value={wineStorage?.wineTaste}
              name={'wineTaste'}
              label={t('wine.TASTE')}
              onChange={handleUpdateSelect}
              error={error}
              disabled={wineStorage?.liveStatus === EntityLiveStatus.ARCHIVED}
            />
            {wineStorage?.id && wineStorage?.maxLiters > 0 ? (
              <>
                <div className="px-3">
                  <Checkbox
                    name={'varietalWine'}
                    label={t('wine.VARIETAL_WINE')}
                    value={!!wineStorage.varietalWine}
                    disabled={!!wineStorage.protectedNameOrGeoWine}
                    onChange={() => updateCheckbox('varietalWine', !wineStorage.varietalWine)}
                  />
                  <Checkbox
                    name={'protectedNameOrGeoWine'}
                    label={t('wine.PROTECTED_NAME_OR_GEO_WINE')}
                    value={!!wineStorage.protectedNameOrGeoWine}
                    disabled={!!wineStorage.varietalWine}
                    onChange={() =>
                      updateCheckbox('protectedNameOrGeoWine', !wineStorage.protectedNameOrGeoWine)
                    }
                  />
                  <Checkbox
                    name={'fromUE'}
                    label={t('wine.FROM_UE')}
                    value={wineStorage.fromUE}
                    onChange={() => updateCheckbox('fromUE', !wineStorage.fromUE)}
                  />
                  <Checkbox
                    name={'fromOtherCountry'}
                    label={t('wineStorage.FROM_OTHER_COUNTRY')}
                    value={!!wineStorage.fromOtherCountry}
                    onChange={() =>
                      updateCheckbox('fromOtherCountry', !wineStorage.fromOtherCountry)
                    }
                  />
                </div>
                <div className="px-3">
                  <small className="text-muted">{t('wineProduction.bottom.message1')}</small>
                </div>
                <div className="px-3">
                  <small className="text-muted">{t('wineProduction.bottom.message2')}</small>
                </div>
              </>
            ) : null}
            <div className="px-3">
              <Checkbox
                name={'favoriteWine'}
                label={
                  wineStorage.favoriteWine ? t('wine.FAVORITE_WINE.ADDED') : t('wine.FAVORITE_WINE')
                }
                value={wineStorage.favoriteWine}
                onChange={() => updateCheckbox('favoriteWine', !wineStorage.favoriteWine)}
                className={'mt-3'}
                favorite
              />
            </div>
            <FormErrorMessage error={error} messageType={'details'} />
            <div className="text-center bg-secondary">
              {wineStorage?.liveStatus === EntityLiveStatus.ARCHIVED ? (
                <Button color="info" className="btn-square" onClick={onClickBack}>
                  {t('common.BACK')}
                </Button>
              ) : (
                <Button color="info" className="btn-square" onClick={onSubmit}>
                  {wineStorage?.id ? t('common.SAVE') : t('common.ADD')}
                </Button>
              )}
            </div>
          </CardBody>
        </Card>
      </SectionWrapper>
    </PageWrapper>
  );
};
