import { useDebounce } from './useDebounce';
import { useCallback } from 'react';
import log from 'loglevel';

export enum SESSION_STORAGE_KEY {
  CREATE_PARCEL = 'CREATE_PARCEL',
  CREATE_GRAPEVINE = 'CREATE_GRAPEVINE',
  CREATE_HARVEST = 'CREATE_HARVEST',
  CREATE_TANK = 'CREATE_TANK',
  CREATE_INGREDIENT = 'CREATE_INGREDIENT',
  CREATE_WINE_FORM = 'CREATE_WINE_FORM',
  CREATE_WINE_PRODUCTION_FORM = 'CREATE_WINE_PRODUCTION_FORM',
  CREATE_WINE_STORAGE_FORM = 'CREATE_WINE_STORAGE_FORM',
  CREATE_EVENT_FOR_WINE = 'CREATE_EVENT_FOR_WINE',
  CREATE_EVENT_FOR_WINE_PRODUCTION = 'CREATE_EVENT_FOR_WINE_PRODUCTION',
  ADD_INGREDIENT_FOR_WINE = 'ADD_INGREDIENT_FOR_WINE',
  ADD_INGREDIENT_FOR_WINE_PRODUCTION = 'ADD_INGREDIENT_FOR_WINE_PRODUCTION',
  ADD_WINE_ENTRY = 'ADD_WINE_ENTRY',
  ADD_BOTTLE_ENTRY = 'ADD_BOTTLE_ENTRY',
  ADD_TANK_ENTRY = 'ADD_TANK_ENTRY',
  ENTRY_TYPE = 'ENTRY_TYPE',
  CREATE_TAX_BANDS_PLACEMENT = 'CREATE_TAX_BANDS_PLACEMENT',
  ADD_BOTTLE_OUTPUT = 'ADD_BOTTLE_OUTPUT',
  ADD_TANK_OUTPUT = 'ADD_TANK_OUTPUT',
  OUTPUT_TYPE = 'OUTPUT_TYPE'
}

type SessionStorageKeyWithId<T extends string, N extends string> = `${T}_${N}`;

export const useStorageSession = <T>({
  entity,
  key,
  shouldSave
}: {
  entity: T;
  key: SESSION_STORAGE_KEY | SessionStorageKeyWithId<SESSION_STORAGE_KEY, string> | string;
  shouldSave: boolean;
}) => {
  useDebounce(
    () => {
      log.debug('useStorageSession', key, shouldSave);
      shouldSave && sessionStorage.setItem(key, JSON.stringify(entity));
    },
    1000,
    [entity, shouldSave]
  );

  const getItemPayload = useCallback(() => {
    log.debug('getItemPayload', key);
    const item = sessionStorage.getItem(key);
    return item ? JSON.parse(item) : null;
  }, [key]);

  const getItem = useCallback(() => sessionStorage.getItem(key), [key]);
  const removeItem = useCallback(() => sessionStorage.removeItem(key), [key]);
  const setItem = useCallback(() => (value: string) => sessionStorage.setItem(key, value), [key]);

  return {
    getItem,
    getItemPayload,
    removeItem,
    setItem
  };
};
