import React, { useRef } from 'react';
import { TankEntry } from '../../../types/WineStorage';
import { useTankEntryService } from '../../../hooks/useTankEntryService';
import { useWineStorageContext } from '../../../context/WineStorageContext';
import { useTranslation } from 'react-i18next';
import WineStorageTanksEntriesListBox from '../WineStorageTanksEntriesListBox';

export const TankEntryList: React.FC<{ tankEntries: TankEntry[]; loading: boolean }> = ({
  tankEntries,
  loading
}) => {
  const { wineStorage } = useWineStorageContext();
  const serviceRef = useRef(useTankEntryService());

  const { t } = useTranslation();

  return (
    <>
      {tankEntries?.length || wineStorage?.id == undefined ? (
        <WineStorageTanksEntriesListBox
          tankEntries={tankEntries || []}
          delOutput={serviceRef.current.del}
          title={t('wineStorage.tank.entry.ADDED')}
          loading={loading}
        />
      ) : null}
    </>
  );
};
