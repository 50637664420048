import React, { useEffect, useRef } from 'react';
import PageWrapper from '../../../../../../layouts/PageWrapper';
import { useParams } from 'react-router-dom';
import { useWineStorageService } from '../../hooks/useWineStorageService';
import { useWineStorageContext } from '../../context/WineStorageContext';
import { StatusType } from '../../../../../../types/Service';
import { ResponseError } from '../../../../../error/ResponseError';
import { RouterParams } from '../../../../../../types/RouterParams';
import { Col, Row } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { EntityLiveStatus } from '../../../../../../components/common/enums/EntityLiveStatus';
import { Button, WineInfoButtons } from '../../../wine/components/WineInfo/WineInfoContainer';
import { usePushHistory } from '../../../../../../hooks/usePushHistory';
import WineStorageInfoBox from './WineStorageInfoBox';
import { AddStorageEntryForm } from './AddStorageEntryForm';
import { BottleEntryList } from './BottleEntryList';
import { TankEntryList } from './TankEntryList';
import { TaxBandsPlacementsList } from './TaxBandsPlacementsList';
import { AddTaxBandsPlacementForm } from './AddTaxBandsPlacementForm';
import { BottleOutputList } from './BottleOutputList';
import { AddStorageOutputForm } from './AddStorageOutputForm';
import { TankOutputList } from './TankOutputList';
import { useConfirmation } from '../../../../../notifications/useConfirmation';

export const WineStorageInfo = () => {
  const { pushHistory } = usePushHistory();

  function pushToBoard() {
    pushHistory(`/mv/board/storage`);
  }

  const { wineStorageId } = useParams<RouterParams>();
  const serviceRef = useRef(useWineStorageService());
  const { wineStorage, setResult, updateWineStorage, loading, setError, error } =
    useWineStorageContext();
  const { t } = useTranslation();
  const showConfirmation = useConfirmation();

  useEffect(() => {
    setResult({ status: StatusType.loading });
    wineStorageId &&
      serviceRef.current
        .get(parseInt(wineStorageId))
        .then((response) => {
          setResult({ status: StatusType.loaded, payload: response });
        })
        .catch((error) => setResult(new ResponseError(error)));

    return () => updateWineStorage('reset', '');
  }, [wineStorageId, updateWineStorage, setResult]); // eslint-disable-line react-hooks/exhaustive-deps

  const editWine = () => {
    pushHistory(`/mv/wine_storage/e/${wineStorageId}/`);
  };

  const archive = () => {
    const action =
      wineStorage?.liveStatus === EntityLiveStatus.ARCHIVED
        ? serviceRef.current.revertArchive
        : serviceRef.current.archive;
    setResult({ status: StatusType.loading });
    action(wineStorage?.id)
      .then((response) => {
        setResult({ status: StatusType.loaded, payload: response });
      })
      .catch((error) => {
        setError(error);
        setResult({ status: StatusType.error });
      });
  };

  const remove = () => {
    showConfirmation({
      message: t('alerts.confirmation.REMOVE_WINE_PRODUCTION'),
      actionLabel: 'Tak',
      actionButtonColor: 'danger',
      actionCallback: () => handleRemoveWine()
    });
  };

  const handleRemoveWine = () => {
    setResult({ status: StatusType.loading });
    serviceRef.current
      .del(wineStorage?.id)
      .then((response) => {
        setResult({ status: StatusType.loaded, payload: response });
        pushHistory(`/mv/board/storage`);
      })
      .catch((error) => {
        setError(error);
        setResult({ status: StatusType.error });
      });
  };

  const saveEditButton: Button = {
    action: editWine,
    label:
      wineStorage?.liveStatus === EntityLiveStatus.ARCHIVED ? t('button.VIEW') : t('button.EDIT')
  };

  const archiveButton: Button = {
    action: archive,
    label:
      wineStorage?.liveStatus === EntityLiveStatus.ARCHIVED
        ? t('button.REVERT_ARCHIVE')
        : t('button.ARCHIVE')
  };

  const removeButton: Button = {
    action: remove,
    label: 'Remove'
  };

  const buttons: WineInfoButtons = {
    saveEditButton,
    archiveButton,
    removeButton
  };
  // if (wineStorage.id === undefined || loading)
  //   return (
  //     <PageWrapper
  //       title={t('wineStorage.TITLE')}
  //       onClick={pushToBoard}
  //       loading={loading}
  //     />
  //   );
  // console.log('WineS', wineStorage);
  return (
    <PageWrapper title={t('wineStorage.TITLE')} onClick={pushToBoard} returnText={'home.STORAGE'}>
      <Row>
        <Col xl="4" lg="6">
          <WineStorageInfoBox
            buttons={buttons}
            wine={wineStorage}
            loading={loading}
            error={error}
          />
          {wineStorage.liveStatus === EntityLiveStatus.ARCHIVED || !wineStorage?.id ? null : (
            <AddStorageEntryForm />
          )}
          <BottleEntryList bottleEntries={wineStorage.bottleEntries} loading={loading} />
          <TankEntryList tankEntries={wineStorage.tankEntries} loading={loading} />
        </Col>
        <Col xl="4" lg="6">
          {wineStorage.liveStatus === EntityLiveStatus.ARCHIVED ? null : (
            <AddTaxBandsPlacementForm />
          )}
          <TaxBandsPlacementsList
            taxBandPlacements={wineStorage.taxBandsPlacements}
            loading={loading}
          />
        </Col>
        <Col xl="4" lg="6">
          {wineStorage.liveStatus === EntityLiveStatus.ARCHIVED ? null : <AddStorageOutputForm />}
          <BottleOutputList bottleOutputs={wineStorage.bottleOutputs} loading={loading} />
          <TankOutputList tankOutputs={wineStorage.tankOutputs} loading={loading} />
        </Col>
      </Row>
    </PageWrapper>
  );
};
