import { ChangeEvent, useEffect } from 'react';
import { useIngredientContext } from '../../context/IngredientContext';
import useIngredientService from '../../hooks/useIngredientService';
import { useParams } from 'react-router-dom';
import { ServiceError, StatusType } from '../../../../../../types/Service';
import { ResponseError } from '../../../../../error/ResponseError';
import log from 'loglevel';
import { SelectOption } from '../../../../../../types/SelectOption';
import { RouterParams } from '../../../../../../types/RouterParams';
import { SESSION_STORAGE_KEY, useStorageSession } from '../../../../../../hooks/useStorageSession';
import { Ingredient } from '../../types/Ingredient';
import { usePushHistory } from '../../../../../../hooks/usePushHistory';
import { HISTORY_FALLBACK_TO } from '../../../../../../services/Constants';

export const IngredientFormContainer = ({ render }) => {
  const { ingredient, updateIngredient, setIngredientResult, ingredientResult } =
    useIngredientContext();
  const service = useIngredientService();

  const { ingredientId } = useParams<RouterParams>();
  const { goBack } = usePushHistory();

  const storageSession = useStorageSession<Ingredient>({
    entity: ingredient,
    key: SESSION_STORAGE_KEY.CREATE_INGREDIENT,
    shouldSave: ingredientId?.toString() === '0'
  });

  useEffect(() => {
    setIngredientResult({ status: StatusType.loading });
    if (ingredientId?.toString() === '0') {
      const payload = storageSession.getItemPayload();
      setIngredientResult({ status: StatusType.loaded, payload });
      return;
    }
    ingredientId &&
      service
        .get(parseInt(ingredientId))
        .then((response) => {
          setIngredientResult({ status: StatusType.loaded, payload: response });
        })
        .catch((response) => setIngredientResult(new ResponseError(response) as ServiceError));

    return () => {
      updateIngredient('reset', '');
    };
  }, [ingredientId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleUpdateIngredient = (e: ChangeEvent<HTMLInputElement>) => {
    updateIngredient(e.target.name, e.target.value);
  };

  const handleUpdateIngredientType = (name: string, selected: SelectOption) => {
    updateIngredient(name, selected.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    log.debug('IngredientForm:onSubmit', e, ingredient);
    setIngredientResult({ status: StatusType.loading });

    const action = () =>
      ingredient?.id ? service.put(ingredient.id, ingredient) : service.post(ingredient);
    action()
      .then((response) => {
        storageSession.removeItem();
        setIngredientResult(response);
        goBack(HISTORY_FALLBACK_TO.INGREDIENTS_SUMMARY);
      })
      .catch((response) => setIngredientResult(new ResponseError(response) as ServiceError));
  };

  const onClickBack = () => {
    goBack(HISTORY_FALLBACK_TO.INGREDIENTS_ARCHIVED);
  };

  const error = ingredientResult as ServiceError;

  log.debug('IngredientForm::render', ingredient);
  return render(
    onSubmit,
    handleUpdateIngredientType,
    error,
    ingredient,
    handleUpdateIngredient,
    ingredientResult.status === StatusType.loading,
    onClickBack
  );
};
