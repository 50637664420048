import React, { useEffect } from 'react';
import { useHarvestContext } from '../../context/HarvestContext';
import useHarvestService from '../../hooks/useHarvestService';
import { useParams } from 'react-router-dom';
import { ServiceError, StatusType } from '../../../../../../types/Service';
import { ResponseError } from '../../../../../error/ResponseError';
import log from 'loglevel';
import { useEventHandlerActions } from '../../../../hooks/useEventHandlerActions';
import { RouterParams } from '../../../../../../types/RouterParams';
import { SESSION_STORAGE_KEY, useStorageSession } from '../../../../../../hooks/useStorageSession';
import { Harvest } from '../../types/Harvest';
import { HISTORY_FALLBACK_TO } from '../../../../../../services/Constants';
import { usePushHistory } from '../../../../../../hooks/usePushHistory';

const HarvestFormContainer = ({ render }) => {
  const { harvest, updateHarvest, setHarvestResult, harvestResult } = useHarvestContext();
  const { goBack, pushHistory } = usePushHistory();

  const service = useHarvestService();

  const { harvestId } = useParams<RouterParams>();

  const storageSession = useStorageSession<Harvest>({
    entity: harvest,
    key: SESSION_STORAGE_KEY.CREATE_HARVEST,
    shouldSave: harvestId?.toString() === '0'
  });

  const {
    updateDate,
    onChange: handleUpdateHarvest,
    updateEntitySelect: updateGrapevineInHarvest,
    updateSelect: handleUpdateSelect
  } = useEventHandlerActions(updateHarvest);

  useEffect(() => {
    setHarvestResult({ status: StatusType.loading });
    if (harvestId?.toString() === '0') {
      const payload = storageSession.getItemPayload();
      setHarvestResult({ status: StatusType.loaded, payload: { ...payload, fromUe: true } });
      return;
    }
    harvestId &&
      service
        .get(parseInt(harvestId))
        .then((response) => {
          setHarvestResult({ status: StatusType.loaded, payload: response });
        })
        .catch((response) => setHarvestResult(new ResponseError(response) as ServiceError));

    return () => {
      updateHarvest('reset', '');
    };
  }, [harvestId]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    log.debug('HarvestForm:onSubmit', harvest);
    setHarvestResult({ status: StatusType.loading });

    const action = () => (harvest?.id ? service.put(harvest.id, harvest) : service.post(harvest));
    action()
      .then((response) => {
        storageSession.removeItem();
        setHarvestResult(response);
        response?.id
          ? pushHistory(`/mv/harvest/info/${response.id}`)
          : goBack(HISTORY_FALLBACK_TO.VINEYARD_SUMMARY);
      })
      .catch((response) => setHarvestResult(new ResponseError(response) as ServiceError));
  };

  const onSubmitGrapes = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    log.debug('HarvestForm:onSubmitGrapes', harvest);
    setHarvestResult({ status: StatusType.loading });

    const action = () =>
      harvest?.id ? service.putGrapes(harvest.id, harvest) : service.postGrapes(harvest);
    action()
      .then((response) => {
        storageSession.removeItem();
        setHarvestResult(response);
        response?.id
          ? pushHistory(`/mv/harvest/info/${response.id}`)
          : goBack(HISTORY_FALLBACK_TO.VINEYARD_SUMMARY);
      })
      .catch((response) => setHarvestResult(new ResponseError(response) as ServiceError));
  };

  const onClickBack = () => {
    goBack(HISTORY_FALLBACK_TO.HARVEST_ARCHIVED);
  };

  const updateHarvestCheckbox = (name: string, value: boolean) => {
    updateHarvest(name, value);
  };

  const error = harvestResult as ServiceError;

  log.debug('HarvestForm::render', harvest);
  return render(
    updateGrapevineInHarvest,
    onSubmit,
    onSubmitGrapes,
    error,
    harvest,
    handleUpdateHarvest,
    updateDate,
    harvestResult.status === StatusType.loading,
    onClickBack,
    updateHarvestCheckbox,
    handleUpdateSelect
  );
};

export default HarvestFormContainer;
