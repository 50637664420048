import React, { useEffect, useState } from 'react';
import { Card, CardBody, Nav, NavItem, NavLink, TabContent, TabPane } from 'reactstrap';
import { useResponsiveContext } from '../../../features/platform/ResponsiveContext';
import { useToggleAndGoToRef } from '../../../hooks/useToggleAndGoToRef';
import { DivDropped } from '../dropdowns/button-and-div/DivDropped';
import { subscribe, unsubscribe } from '../../../events/events';

const PanelTab = ({ title, tab1, tab2, tab1Label, tab2Label }) => {
  const [activeTab, setActiveTab] = useState('add');

  const toggleTab = (tab) => {
    if (activeTab !== tab) {
      setActiveTab(tab);
    }
  };

  const { isMobile } = useResponsiveContext();
  const {
    value: isOpen,
    toggleValue: toggleOpen,
    currentRef
  } = useToggleAndGoToRef(false, isMobile);

  useEffect(() => {
    subscribe('onSubmitIngredient', () => toggleOpen());
    return () => unsubscribe('onSubmitIngredient', () => toggleOpen());
  }, [toggleOpen]);

  return (
    <Card
      innerRef={currentRef}
      className={isOpen ? 'border-info-dark form' : 'bg-info-dark form'}
      style={{ zIndex: '50' }}
    >
      <CardBody className={'pt-2 pb-1'}>
        <div
          onClick={() => toggleOpen()}
          style={{ cursor: 'pointer', height: '1.5em' }}
          className={'pt-1'}
        >
          <i className="float-left">
            <em
              className={
                isOpen ? 'fa fa-minus text-primary text-gradient-blue-purple' : 'fa fa-plus'
              }
            />
          </i>
          <h5 className={'m-0 float-right mb-2 ' + (isOpen ? 'text-gradient-blue-purple' : '')}>
            {title}
          </h5>
        </div>

        <DivDropped open={isOpen}>
          <div className={'mt-2'}>
            <Nav pills justified className={'bg-secondary'}>
              <NavItem style={{ cursor: 'pointer' }}>
                <NavLink
                  className={
                    activeTab === 'add' ? 'active bg-info' : 'active bg-secondary text-dark'
                  }
                  onClick={() => {
                    toggleTab('add');
                  }}
                >
                  <em className="far icon-magnifier-add fa-fw" /> {tab1Label}
                </NavLink>
              </NavItem>
              <NavItem style={{ cursor: 'pointer' }}>
                <NavLink
                  className={
                    activeTab === 'info'
                      ? 'active bg-info btn-info'
                      : 'active bg-secondary text-dark'
                  }
                  onClick={() => {
                    toggleTab('info');
                  }}
                >
                  <em className="far icon-info fa-fw" /> {tab2Label}
                </NavLink>
              </NavItem>
            </Nav>
            <TabContent activeTab={activeTab} className="bg-white p-0">
              <TabPane tabId="add">{isOpen ? tab1 : null}</TabPane>
              <TabPane tabId="info">{tab2}</TabPane>
            </TabContent>
          </div>
        </DivDropped>
      </CardBody>
    </Card>
  );
};
export default PanelTab;
