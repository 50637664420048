import React from 'react';
import { PaymentInfo } from './PaymentInfo';
import { useAuthContext } from '../../../platform/AuthContext';

export const PastDuePlanInfo = () => {
  const {
    subscriptionInfo: { paymentInfo }
  } = useAuthContext();

  return (
    <div className="px-2">
      <div className={'h4 pb-1'}>
        <span className="text-gradient-blue-orange">Twój plan stracił ważność!</span>
      </div>
      <div>
        <p>
          Aby kontynuować korzystanie z tego planu, dokonaj płatności zgodnie z informacjami
          przesłanymi w wiadomości e-mail.
        </p>
        <p>
          Możesz również wybrać opcję przelewu bankowego, korzystając z danych podanych poniżej.
        </p>
      </div>

      <div className={'pb-3'}>
        <PaymentInfo
          price={paymentInfo?.priceToPay?.toString() || '0'}
          title={paymentInfo?.paymentTitle}
        />
      </div>
    </div>
  );
};
