import React from 'react';
import { useTranslation } from 'react-i18next';
import { useResponsiveContext } from '../../platform/ResponsiveContext';
import { useAuthContext } from '../../platform/AuthContext';
import { SectionWrapper } from '../../../layouts/SectionWrapper';
import { LockIconInfo } from '../../../components/common/lock/LockIconInfo';
import { SubscriptionType } from '../../../types/Subscription';

export const PdfButtonComponent: React.FC<{
  reportAvailable: boolean;
  subscriptionTypeNeeded: SubscriptionType;
  getPdf: () => void;
  loading?: boolean;
}> = ({ reportAvailable, subscriptionTypeNeeded, getPdf, loading }) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsiveContext();
  const {
    subscriptionInfo: { accessInfo }
  } = useAuthContext();

  if (!reportAvailable) return null;

  return (
    <button
      className="btn btn-sm btn-secondary float-right"
      type="button"
      onClick={() => getPdf()}
      title={t('button.download.pdf')}
      disabled={loading}
    >
      <SectionWrapper loading={loading} hideSpinner type={'small-spinner'}>
        <em className="ml-auto fa fa-download fa-fw text-info"></em>
        {!isMobile ? <span className="ml-1">{t('button.download.report')}</span> : null}
        <LockIconInfo accessInfo={accessInfo} subscriptionTypeNeeded={subscriptionTypeNeeded} />
      </SectionWrapper>
    </button>
  );
};
