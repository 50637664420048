import React from 'react';
import { Link } from 'react-router-dom';
import { useTranslation } from 'react-i18next';
import { SubscriptionType } from '../../../../../types/Subscription';
import { PdfButtonComponent } from '../../../../reports/components/PdfButtonComponent';
import { ReportType, useGetPdf } from '../../../hooks/useGetPdf';

export const VarietiesListButtonsBar: React.FC<{ reportAvailable: boolean }> = ({
  reportAvailable
}) => {
  const { t } = useTranslation();

  const { getPdf, loading } = useGetPdf({
    subscriptionType: SubscriptionType.NORMAL,
    reportType: ReportType.VINEYARD_SUMMARY
  });

  return (
    <div className="mb-3">
      <div>
        <Link
          to={{
            pathname: '/mv/parcel/e/0',
            state: { from: '/mv/board/summary' }
          }}
          className="btn btn-sm btn-info"
          type="button"
        >
          <em className="mr-1 fas fa-plus" /> działkę
        </Link>
        <Link
          to={'/mv/parcel/all'}
          className="btn btn-sm btn-secondary"
          type="button"
          title={t('button.show.list')}
        >
          <em className="fas fa-outdent" />
        </Link>
        <Link
          to={{
            pathname: '/mv/grapevine/e/0',
            state: { from: '/mv/board/summary' }
          }}
          className="btn btn-sm btn-info ml-2"
          type="button"
        >
          <em className="mr-1 fas fa-plus" /> winorośl
        </Link>
        <Link
          to={'/mv/grapevine/all'}
          className="btn btn-sm btn-secondary"
          type="button"
          title={t('button.show.list')}
        >
          <em className="fas fa-outdent" />
        </Link>
        <PdfButtonComponent
          reportAvailable={reportAvailable}
          getPdf={getPdf}
          loading={loading}
          subscriptionTypeNeeded={SubscriptionType.NORMAL}
        />
      </div>
    </div>
  );
};
