import React, { useState } from 'react';
import PageWrapper from '../../../layouts/PageWrapper';
import { ProfileTab } from './ProfileTab';
import { AccountTab } from './AccountTab';
import { PlanTab } from './PlanTab';
import { AccountMenu } from './AccountMenu';
import { usePushHistory } from '../../../hooks/usePushHistory';

export type AccountActiveTab = '1' | '2' | '3';

export const AccountPage = () => {
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState<AccountActiveTab>('3');
  const { pushHistory } = usePushHistory();

  return (
    <PageWrapper
      title={'home.ACCOUNT'}
      onClick={() => pushHistory('/mv')}
      returnText={'sidebar.nav.element.DASHBOARD'}
    >
      <div className="container mx-0">
        <div className="row">
          <div className="col-lg-3">
            <AccountMenu setActiveTab={setActiveTab} activeTab={activeTab} />
          </div>
          <div className="col-lg-9">
            <div className="tab-content p-0 b0">
              <div className={'tab-pane ' + (activeTab === '1' ? 'active' : '')} id="tabSetting1">
                <ProfileTab setLoading={setLoading} loading={loading} />
              </div>
              <div className={'tab-pane ' + (activeTab === '2' ? 'active' : '')} id="tabSetting2">
                <AccountTab setLoading={setLoading} loading={loading} />
              </div>
              <div className={'tab-pane ' + (activeTab === '3' ? 'active' : '')} id="tabSetting3">
                <PlanTab setLoading={setLoading} loading={loading} />
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};
