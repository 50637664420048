import {
  Subscription,
  SubscriptionPeriod,
  SubscriptionStatus,
  SubscriptionType
} from '../../../../../types/Subscription';
import React from 'react';
import { PickerPaymentResultForBasic } from './PickerPaymentResultForBasic';
import { PickerPaymentResultForNormal } from './PickerPaymentResultForNormal';
import { PickerPlanStepTwoTrialPlansItems } from './PickerPlanStepTwoTrialPlansItems';
import { PickerPlanStepTwoPaidPlansItems } from './PickerPlanStepTwoPaidPlansItems';
import { SubscriptionPlan } from '../../../data/Plans';
import { CheckDiscountCodeData } from '../../../../admin/hooks/useDiscountService';
import { ServiceError } from '../../../../../types/Service';
import { InputColumn } from '../../../../../components/forms/InputColumn';
import { FormErrorMessage } from '../../../../../components/notifications/FormErrorMessage';

export const PickerPlanStepTwo: React.FC<{
  trialAllowed: boolean;
  handleBack: () => void;
  header: string;
  info: string;
  activeSubscription: Subscription;
  period: SubscriptionPeriod;
  setPeriod: (period: SubscriptionPeriod) => void;
  continueWithUpgradedPlan: boolean;
  plan: SubscriptionPlan;
  normalPlan: SubscriptionPlan;
  setDiscountCode: (code: string) => void;
  discountValue: number;
  checkDiscountCode: (data: CheckDiscountCodeData) => void;
  discountError: ServiceError;
  discountCode: string;
  showDiscountForm: boolean;
  setShowDiscountForm: (value: boolean) => void;
}> = ({
  trialAllowed,
  handleBack,
  header,
  info,
  activeSubscription,
  period,
  setPeriod,
  continueWithUpgradedPlan,
  plan,
  normalPlan,
  setDiscountCode,
  discountValue,
  checkDiscountCode,
  discountError,
  discountCode,
  showDiscountForm,
  setShowDiscountForm
}) => {
  function getPrice(selectedPeriod: SubscriptionPeriod) {
    if (
      activeSubscription?.type === SubscriptionType.NORMAL &&
      activeSubscription?.status === SubscriptionStatus.ACTIVATED
    ) {
      return activeSubscription?.period === SubscriptionPeriod.ANNUAL
        ? plan.annualPrice - normalPlan.annualPrice
        : plan.monthlyPrice - normalPlan.monthlyPrice;
    }
    return selectedPeriod === SubscriptionPeriod.ANNUAL ? plan.annualPrice : plan.monthlyPrice;
  }
  return (
    <>
      <div style={{ display: 'flex', alignItems: 'flex-start' }}>
        <button
          className={'btn btn-sm py-0'}
          style={{ marginTop: '-3px' }}
          onClick={() => handleBack()}
        >
          <em className={'icon-arrow-left pr-2'}></em>
        </button>
        <span className={'h5 text-bold text-gradient-orange-blue'}>{header}</span>
      </div>
      <div className={'h3 text-bold text-gradient-blue-purple'}>{info}</div>
      {trialAllowed ? <PickerPlanStepTwoTrialPlansItems /> : <PickerPlanStepTwoPaidPlansItems />}
      {/*{activeSubscription?.type === SubscriptionType.NORMAL &&*/}
      {/*activeSubscription?.status === SubscriptionStatus.ACTIVATED ? (*/}
      {/*  <div>*/}
      {/*    <Checkbox*/}
      {/*      name={'continueWithUpgradedPlan'}*/}
      {/*      label={'Aktywuj plan w bieżącym okresie'}*/}
      {/*      value={continueWithUpgradedPlan}*/}
      {/*      onChange={setContinueWithUpgradedPlanOnChange}*/}
      {/*    />*/}
      {/*  </div>*/}
      {/*) : null}*/}
      {!showDiscountForm ? (
        <>
          <div className="text-bold pt-1">Następnie:</div>
          <div>
            <div className="pt-2">
              <label className="c-radio text-bold">
                <input
                  id="monthly"
                  type="radio"
                  name="monthly"
                  checked={period === SubscriptionPeriod.MONTHLY || false}
                  onChange={() => {
                    setPeriod(SubscriptionPeriod.MONTHLY);
                    discountValue !== null &&
                      checkDiscountCode({
                        code: discountCode,
                        subscriptionType: plan.subscriptionType,
                        price: getPrice(SubscriptionPeriod.MONTHLY)
                      });
                  }}
                />
                <span className="fa fa-circle"></span> Miesięcznie
              </label>
              <div className="ml-4 pl-1">{plan.monthlyPrice} zł</div>
            </div>
            <div className="pt-2">
              <label className="c-radio text-bold">
                <input
                  id="yearly"
                  type="radio"
                  name="yearly"
                  checked={period === SubscriptionPeriod.ANNUAL || false}
                  onChange={() => {
                    setPeriod(SubscriptionPeriod.ANNUAL);
                    discountValue !== null &&
                      checkDiscountCode({
                        code: discountCode,
                        subscriptionType: plan.subscriptionType,
                        price: getPrice(SubscriptionPeriod.ANNUAL)
                      });
                  }}
                />
                <span className="fa fa-circle"></span> Rocznie{' '}
                <div className={'badge bg-green ml-2'}>
                  Najkorzystniej - Oszczędzasz {plan.monthlyPrice * 12 - plan.annualPrice} zł
                </div>
              </label>
              <div className="ml-4 pl-1">
                {plan.annualPrice} zł ( {plan.annualPricePerMonth} zł miesięcznie )
              </div>
            </div>
          </div>
          <button className={'btn btn-link p-0 my-2'} onClick={() => setShowDiscountForm(true)}>
            {discountValue === null ? 'Wprowadź kod zniżki' : 'Wprowadź inny kod'}
          </button>
          {discountError && !showDiscountForm ? (
            <FormErrorMessage error={discountError} fieldName={'code'} messageType={'fieldError'} />
          ) : null}
        </>
      ) : (
        <>
          <button
            className={'btn btn-link py-0 my-2 text-danger'}
            onClick={() => setShowDiscountForm(false)}
          >
            Anuluj
          </button>
          <InputColumn
            label={'Kod zniżki'}
            name={'code'}
            value={discountCode}
            maxSize={15}
            onChange={(e) => setDiscountCode(e.target.value)}
            optional
            error={discountError}
          />
          <div className={'flex w-100 text-center'}>
            <button
              className={'btn btn-link pt-0'}
              onClick={() =>
                checkDiscountCode({
                  code: discountCode,
                  subscriptionType: plan.subscriptionType,
                  price: getPrice(period)
                })
              }
            >
              Zastosuj kod
            </button>
          </div>
        </>
      )}
      <div>
        {!activeSubscription || activeSubscription?.type === SubscriptionType.BASIC ? (
          <PickerPaymentResultForBasic
            plan={plan}
            trialAllowed={trialAllowed}
            period={period}
            discountValue={discountValue}
          />
        ) : null}
        {activeSubscription?.type === SubscriptionType.NORMAL ? (
          <PickerPaymentResultForNormal
            plan={plan}
            normalPlan={normalPlan}
            period={period}
            continueWithUpgradedPlan={continueWithUpgradedPlan}
            activeSubscription={activeSubscription}
            discountValue={discountValue}
          />
        ) : null}
      </div>
    </>
  );
};
