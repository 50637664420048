import React, { useState } from 'react';
import { useTranslation } from 'react-i18next';
import { ServiceError } from '../../../../../types/Service';
import { DiscountCardUpdateFormFieldValue } from '../hooks/useUpdateDiscountCard';
import {
  DiscountCardReadModel,
  DiscountPeriod,
  DiscountType
} from '../../../hooks/useDiscountService';
import { InputColumn } from '../../../../../components/forms/InputColumn';
import SelectSubscriptionType from '../../companies/SelectSubscriptionType';
import SelectDiscountType from './SelectDiscountType';
import SelectDiscountPeriod from './SelectDiscountPeriod';
import SelectCompany from './SelectCompany';
import { CompanyInfoList } from '../../../types';
import { DatePickerColumn } from '../../../../../components/forms/DatePickerColumn';
import { ToApiConverter } from '../../../../../services/Converters';

interface IProps {
  error?: ServiceError;
  onChange: (value: DiscountCardUpdateFormFieldValue) => void;
  discountCard: DiscountCardReadModel;
  addDiscountCard: (
    card: DiscountCardReadModel,
    period: DiscountPeriod,
    type: DiscountType,
    customCode?: string
  ) => void;
}

export const DiscountCardFormRow: React.FC<IProps> = ({
  error,
  onChange,
  discountCard,
  addDiscountCard
}) => {
  const { t } = useTranslation();
  const [discountType, setDiscountType] = useState<DiscountType>(DiscountType.PRICE);
  const [period, setPeriod] = useState<DiscountPeriod>();
  const [company, setCompany] = useState<Partial<CompanyInfoList>>();
  const [customCode, setCustomCode] = useState('');

  return (
    <div className="form-group row">
      <div className="col-md-12">
        <div className="form-row">
          <InputColumn
            label={t('discountCards.form.name')}
            onChange={(e) => onChange({ name: e.target.name, value: e.target.value })}
            name={'name'}
            value={discountCard?.name}
            placeholder={t('discountCards.form.name')}
            error={error}
            numberOfColumnsInRow={3}
          />
          <InputColumn
            label={t('discountCards.form.customeCode')}
            onChange={(e) => setCustomCode(e.target.value)}
            name={'customCode'}
            value={customCode}
            placeholder={t('discountCards.form.customeCode')}
            error={error}
            maxSize={15}
            numberOfColumnsInRow={3}
            optional
          />
          <SelectDiscountType
            label={t('discountCards.form.discountType')}
            value={discountType}
            name={'discountType'}
            onChange={(s, v) => setDiscountType(v.value)}
            error={error}
            numberOfColumnsInRow={3}
          />
        </div>
        <div className="form-row">
          {discountType === DiscountType.PRICE ? (
            <InputColumn
              label={t('discountCards.form.discountPrice')}
              onChange={(e) => onChange({ name: e.target.name, value: e.target.value })}
              name={'discountPrice'}
              type={'number'}
              value={discountCard?.discountPrice}
              placeholder={t('discountCards.form.discountPrice')}
              error={error}
              numberOfColumnsInRow={3}
            />
          ) : null}
          {discountType === DiscountType.PERCENTAGE ? (
            <InputColumn
              label={t('discountCards.form.discountPercent')}
              onChange={(e) => onChange({ name: e.target.name, value: e.target.value })}
              name={'discountPercent'}
              type={'number'}
              value={discountCard?.discountPercent}
              placeholder={t('discountCards.form.discountPercent')}
              error={error}
              numberOfColumnsInRow={3}
            />
          ) : null}
          <DatePickerColumn
            label={t('discountCards.form.startDate')}
            name={'startDate'}
            value={discountCard?.startDate}
            onChange={(name, date) =>
              onChange({ name, value: ToApiConverter.convertDateToApi(date) })
            }
            error={error}
            numberOfColumnsInRow={3}
            optional
          />
          <SelectDiscountPeriod
            label={t('discountCards.form.period')}
            value={period}
            name={'period'}
            onChange={(s, v) => setPeriod(v.value)}
            error={error}
            numberOfColumnsInRow={3}
          />
        </div>
        <div className="form-row">
          <SelectSubscriptionType
            label={t('discountCards.form.subscriptionType')}
            value={discountCard?.subscriptionType}
            name={'subscriptionType'}
            onChange={(s, v) => onChange({ name: s, value: v.value })}
            error={error}
            numberOfColumnsInRow={3}
            selectType={'column'}
            optional
          />
          <InputColumn
            label={t('discountCards.form.maxUsageCount')}
            onChange={(e) => onChange({ name: e.target.name, value: e.target.value })}
            name={'maxUsageCount'}
            type={'number'}
            value={discountCard?.maxUsageCount}
            placeholder={t('discountCards.form.maxUsageCount')}
            error={error}
            numberOfColumnsInRow={3}
            optional
          />
          <SelectCompany
            value={company}
            name={'subscriberId'}
            label={t('discountCards.form.subscriberId')}
            onChange={(s, v) => {
              onChange({ name: s, value: v.value });
              setCompany({ id: v.value, name: v.label });
            }}
            error={error}
            numberOfColumnsInRow={3}
            optional
          />
        </div>
        <button
          className="btn btn-primary"
          type="button"
          disabled={
            !discountType ||
            !period ||
            !discountCard?.name ||
            (discountType === DiscountType.PRICE && !discountCard?.discountPrice) ||
            (discountType === DiscountType.PERCENTAGE && !discountCard?.discountPercent)
          }
          onClick={() => addDiscountCard(discountCard, period, discountType, customCode)}
        >
          {t('discountCard.form.submit')}
        </button>
      </div>
    </div>
  );
};
