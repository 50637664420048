import React, { useCallback, useContext, useEffect, useMemo, useReducer } from 'react';

interface ThemeContextInterface {
  darkMode: boolean;
  selectDarkStyles: (hasError: boolean) => object;
  logo: string;
}

const defaultState = {
  darkMode: undefined,
  selectDarkStyles: undefined,
  logo: 'img/logo-dark.png'
};

const reducer = (state: ThemeContextInterface, action: { type: string; value: boolean }) => {
  if (!action.value) return defaultState;
  return {
    darkMode: true,
    logo: 'img/logo-white.png'
  };
};

const ThemeContext = React.createContext<ThemeContextInterface>(defaultState);
export const useThemeContext = (): ThemeContextInterface => {
  return useContext(ThemeContext);
};

const ThemeProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultState);
  useEffect(() => {
    const updateDarkMode = () => {
      const hasDarkMode = document.body.classList.contains('dark-mode');
      dispatch({
        type: 'darkMode',
        value: hasDarkMode
      });
    };

    updateDarkMode();

    const observer = new MutationObserver(updateDarkMode);
    observer.observe(document.body, { attributes: true, attributeFilter: ['class'] });

    return () => observer.disconnect();
  }, []);

  const selectDarkStyles = useCallback(
    (hasError: boolean) => {
      const borderColor = hasError ? '#d92550' : state.darkMode ? '#212121' : '';
      return {
        control: (base, stateFromControl) => ({
          ...base,
          borderColor: borderColor,
          ':hover': {
            borderColor: '#444444'
          },
          backgroundColor: stateFromControl.isDisabled
            ? state.darkMode
              ? '#4f4f4f'
              : ''
            : state.darkMode
            ? '#3d3d3d'
            : '',
          color: state.darkMode ? '#919191' : '',
          cursor: stateFromControl.isDisabled ? 'not-allowed' : 'pointer'
        }),
        singleValue: (provided) => ({
          ...provided,
          color: '#919191'
        }),
        option: (provided, state) => ({
          ...provided,
          color: state.isSelected ? '#3d3d3d' : '#919191',
          backgroundColor: state.isSelected ? '#919191' : '#3d3d3d',
          ':hover': {
            backgroundColor: '#32333d',
            color: '#919191'
          }
        }),
        placeholder: (provided) => ({
          ...provided,
          color: '#888888'
        }),
        menu: (provided) => ({
          ...provided,
          backgroundColor: '#3d3d3d'
        }),
        menuList: (provided) => ({
          ...provided,
          backgroundColor: '#3d3d3d'
        })
      };
    },
    [state.darkMode]
  );

  const providerValue = useMemo(
    () => ({
      darkMode: state.darkMode,
      selectDarkStyles,
      logo: state.logo
    }),
    [state.darkMode, selectDarkStyles, state.logo]
  );

  return <ThemeContext.Provider value={providerValue}>{children}</ThemeContext.Provider>;
};

export default ThemeProvider;
