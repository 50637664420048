import React from 'react';
import { useHarvestContext } from '../../context/HarvestContext';
import PageWrapper from '../../../../../../layouts/PageWrapper';
import { ServiceError, StatusType } from '../../../../../../types/Service';
import { HarvestSummaryCard } from './HarvestSummaryCard';
import { WinesInHarvestListCard } from './wines/WinesInHarvestListCard';
import { BoxesInHarvestListCard } from './boxes/BoxesInHarvestListCard';
import HarvestHistory from './HarvestHistory';
import { useHarvestInfo } from './hooks/useHarvestInfo';
import { usePushHistory } from '../../../../../../hooks/usePushHistory';

export const HarvestInfoRedesigned = () => {
  const { harvest, harvestResult, updateBox } = useHarvestContext();
  const { pushHistory } = usePushHistory();

  function pushToBoard() {
    pushHistory(`/mv/board/summary`);
  }

  const { buttons, addBoxToHarvest, reloadHarvest } = useHarvestInfo();

  return (
    <PageWrapper title={'harvest.TITLE'} onClick={pushToBoard} returnText={'home.summary.TITLE'}>
      <div className="row">
        <div className="col-12 col-xl-4 col-lg-6">
          <HarvestSummaryCard
            harvest={harvest}
            buttons={buttons}
            addBoxToHarvest={addBoxToHarvest}
            updateBox={updateBox}
            error={harvestResult as ServiceError}
            loading={harvestResult.status === StatusType.loading}
          />
        </div>
        <div className="col-12 col-xl-8 col-lg-6">
          {harvest && !harvest.fromOtherVineyard ? (
            <BoxesInHarvestListCard
              disposed={harvest.allDisposedToWine}
              reloadHarvest={reloadHarvest}
            />
          ) : null}
        </div>
      </div>
      <div className="row">
        <div className="col-12">
          <WinesInHarvestListCard />
        </div>
      </div>
      <div className="bg-gray-lighter rounded-lg bg-gray-lighter-dark">
        <HarvestHistory
          history={harvest?.history}
          loading={harvestResult.status === StatusType.loading}
          grapeColor={harvest.grapeColor}
        />
      </div>
    </PageWrapper>
  );
};
