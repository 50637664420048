import { useTranslation } from 'react-i18next';
import usePlanPicker from '../../subscription/hooks/usePlanPicker';
import { SubscriptionType } from '../../../types/Subscription';
import { PdfPageLayout } from '../../../types/enums';
import log from 'loglevel';
import { useState } from 'react';

export enum ReportType {
  VINEYARD_SUMMARY = 'VINEYARD_SUMMARY',
  WINERY_SUMMARY = 'WINERY_SUMMARY',
  STORAGE_SUMMARY = 'STORAGE_SUMMARY',
  STORAGE_SUMMARY_BY_GROUP = 'STORAGE_SUMMARY_BY_GROUP',
  STORAGE_SUMMARY_2 = 'STORAGE_SUMMARY_2',
  STORAGE_SUMMARY_3 = 'STORAGE_SUMMARY_3',
  PARCELS_SUMMARY = 'PARCELS_SUMMARY',
  GRAPEVINE_SUMMARY = 'GRAPEVINE_SUMMARY',
  TANKS_SUMMARY = 'TANKS_SUMMARY',
  INGREDIENTS_SUMMARY = 'INGREDIENTS_SUMMARY',
  TANKS_WINERY = 'TANKS_WINERY',
  TANKS_STORAGE = 'TANKS_STORAGE',
  CURRENT_HARVESTS = 'CURRENT_HARVESTS',
  HARVEST_SUMMARY = 'HARVEST_SUMMARY',
  CURRENT_WINES = 'CURRENT_WINES',
  STORAGE_WINES = 'STORAGE_WINES',
  STORAGE_VARIETAL_WINES = 'STORAGE_VARIETAL_WINES',
  STORAGE_PROTECTED_WINES = 'STORAGE_PROTECTED_WINES',
  STORAGE_OTHER_WINES = 'STORAGE_OTHER_WINES',
  WINES_IN_HARVEST = 'WINES_IN_HARVEST',
  WINES_IN_SEASON = 'WINES_IN_SEASON',
  KOWR_JANUARY_15_1 = 'KOWR_JANUARY_15_1',
  KOWR_JANUARY_15_2 = 'KOWR_JANUARY_15_2',
  KOWR_SEPTEMBER_31 = 'KOWR_SEPTEMBER_31',
  WINE_IN_STORAGE = 'WINE_IN_STORAGE',
  WINE = 'WINE',
  DISPOSED_AS_WINE_ENTRY = 'DISPOSED_AS_WINE_ENTRY',
  WINE_ENTRIES = 'WINE_ENTRIES',
  WINE_PRODUCTION = 'WINE_PRODUCTION',
  APPLIED_INGREDIENTS = 'APPLIED_INGREDIENTS',
  PRODUCTION_EVENTS = 'PRODUCTION_EVENTS',
  HISTORY = 'HISTORY',
  TAX_BANDS_SUMMARY = 'TAX_BANDS_SUMMARY',
  TAX_BANDS = 'TAX_BANDS',
  TAX_BANDS_PLACEMENTS = 'TAX_BANDS_PLACEMENTS',
  BOTTLE_OUTPUTS = 'BOTTLE_OUTPUTS',
  HARVEST = 'HARVEST',
  BOTTLES_SUMMARY = 'BOTTLES_SUMMARY',
  BOTTLE = 'BOTTLE'
}

export function useGetPdf({
  subscriptionType,
  reportType,
  id
}: {
  subscriptionType: SubscriptionType;
  reportType: ReportType | string;
  id?: number;
}): { getPdf: (layout?: PdfPageLayout) => void; loading: boolean } {
  const { t } = useTranslation();
  const [loading, setLoading] = useState(false);

  const { isValidSubscription } = usePlanPicker({
    subscriptionType: subscriptionType,
    fromBlockingContent: true,
    blockingMessage: t('subscriptions.pdf.warning'),
    itemIndexToHighlight: 6
  });

  async function getPdf(layout = PdfPageLayout.LANDSCAPE) {
    if (!isValidSubscription()) {
      return;
    }
    log.debug('GET_PDF', reportType, layout);
    const baseUrl = `/ajax/pdf/${reportType}?layout=${layout}`;
    const url = id ? baseUrl + `&id=${id}` : baseUrl;

    try {
      setLoading(true);
      const response = await fetch(url, { method: 'GET' });

      if (!response.ok) {
        throw new Error('Failed to generate PDF');
      }

      const blob = await response.blob();

      const link = document.createElement('a');
      link.href = window.URL.createObjectURL(blob);
      link.download = `report_${reportType}.pdf`;
      document.body.appendChild(link);
      link.click();
      document.body.removeChild(link);
    } catch (error) {
      console.error('Error generating PDF:', error);
    } finally {
      setLoading(false);
    }
  }

  return { getPdf, loading };
}
