import React, { ChangeEvent, FC } from 'react';
import PageWrapper from '../../../../../../layouts/PageWrapper';
import { Button, Card, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import Grapevines from './SelectGrapevines';
import { ServiceError } from '../../../../../../types/Service';
import { Harvest } from '../../types/Harvest';
import { FormErrorMessage } from '../../../../../../components/notifications/FormErrorMessage';
import { EntityLiveStatus } from '../../../../../../components/common/enums/EntityLiveStatus';
import { usePushHistory } from '../../../../../../hooks/usePushHistory';
import { SectionWrapper } from '../../../../../../layouts/SectionWrapper';
import { DatePickerColumn } from '../../../../../../components/forms/DatePickerColumn';
import { InputColumn } from '../../../../../../components/forms/InputColumn';

interface Props {
  onSubmit: (e: React.MouseEvent<HTMLButtonElement>) => void;
  updateGrapevineInHarvest: () => void;
  error: ServiceError;
  harvest: Harvest;
  updateHarvest: (e: ChangeEvent<HTMLInputElement>) => void;
  updateDate: (date: Date | string) => void;
  loading: boolean;
  onClickBack: () => void;
}

const SimpleHarvestForm: FC<Props> = ({
  updateGrapevineInHarvest,
  onSubmit,
  error,
  harvest,
  updateHarvest,
  updateDate,
  loading,
  onClickBack
}) => {
  const { t } = useTranslation();
  const { pushHistory } = usePushHistory();

  function pushToBoard() {
    pushHistory(`/mv/board/summary`);
  }

  return (
    <PageWrapper title={'harvest.TITLE'} onClick={pushToBoard} returnText={'home.summary.TITLE'}>
      <SectionWrapper loading={loading}>
        <Card>
          <CardBody>
            <div className={'pb-4'}>
              <h5 className="float-right">
                {harvest?.id
                  ? t('sidebar.nav.element.EDIT')
                  : t('sidebar.nav.element.ADD_NEW_HARVEST')}
              </h5>
            </div>
            <DatePickerColumn
              label={t('harvest.DATE_OF_HARVEST')}
              name={'dateOfHarvest'}
              onChange={updateDate}
              value={harvest?.dateOfHarvest}
              error={error}
              disabled={harvest?.liveStatus === EntityLiveStatus.ARCHIVED}
            />
            <InputColumn
              label={t('harvest.WEIGHT_OF_EVERY_EMPTY_BOX')}
              type={'number'}
              name={'weightOfEveryEmptyBox'}
              maxSize={'100'}
              onChange={updateHarvest}
              value={harvest?.weightOfEveryEmptyBox}
              error={error}
              optional
              disabled={harvest?.liveStatus === EntityLiveStatus.ARCHIVED}
            />
            <Grapevines
              value={harvest?.grapevine || {}}
              name={'grapevine'}
              label={t('harvest.GRAPEVINE')}
              onChange={updateGrapevineInHarvest}
              error={error}
              disabled={harvest?.liveStatus === EntityLiveStatus.ARCHIVED}
              hideLink={harvest?.liveStatus === EntityLiveStatus.ARCHIVED}
            />
            <FormErrorMessage error={error} messageType={'details'} />
            <div className="text-center bg-secondary">
              {harvest?.liveStatus === EntityLiveStatus.ARCHIVED ? (
                <Button color="info" className="btn-square" onClick={onClickBack}>
                  {t('common.BACK')}
                </Button>
              ) : (
                <Button color="info" className="btn-square" onClick={onSubmit}>
                  {harvest?.id ? t('common.SAVE') : t('common.ADD')}
                </Button>
              )}
            </div>
          </CardBody>
        </Card>
      </SectionWrapper>
    </PageWrapper>
  );
};

export default SimpleHarvestForm;
