import { useEffect, useMemo, useState } from 'react';
import { Tank, TankType } from '../../../../winery/components/tank/types/Tank';
import { FilesListItemColor } from '../../../../../types/FileType';

export type SelectedTankType = TankType | 'all';

export function useTankGroups(
  filteredTanks: Tank[],
  setSelectedType: (type: SelectedTankType) => void,
  filteredTanksOnlyByUsage: Tank[]
) {
  const [selectedTanks, setSelectedTanks] = useState<Tank[]>([]);
  useEffect(() => {
    setSelectedTanks(filteredTanks);
  }, [filteredTanks]);

  const tanksGroups: { label: string; type: TankType; color: FilesListItemColor; count: number }[] =
    useMemo(
      () => [
        {
          label: 'Zbiornik',
          type: TankType.TANK,
          color: 'green',
          count: filteredTanksOnlyByUsage.filter((tank) => tank.type === TankType.TANK).length
        },
        {
          label: 'Beczka',
          type: TankType.BARREL,
          color: 'info',
          count: filteredTanksOnlyByUsage.filter((tank) => tank.type === TankType.BARREL).length
        },
        {
          label: 'Kadź',
          type: TankType.VAT,
          color: 'warning',
          count: filteredTanksOnlyByUsage.filter((tank) => tank.type === TankType.VAT).length
        },
        {
          label: 'Balon',
          type: TankType.BALLOON,
          color: 'purple',
          count: filteredTanksOnlyByUsage.filter((tank) => tank.type === TankType.BALLOON).length
        }
      ],
      [filteredTanksOnlyByUsage]
    );

  function handleChangeGroup(type: TankType) {
    setSelectedType(type);
    setSelectedTanks(filteredTanks.filter((tank) => tank.type === type));
  }

  function handleAllGroups() {
    setSelectedType('all');
    setSelectedTanks(filteredTanks);
  }

  const totalItemsCount = useMemo(
    () => tanksGroups.reduce((acc, item) => acc + item.count, 0),
    [tanksGroups]
  );

  return {
    tanksGroups,
    selectedTanks,
    handleAllGroups,
    handleChangeGroup,
    totalItemsCount
  };
}
