import React, { FC } from 'react';
import {
  WineStorageBottleEntriesListBoxContainer,
  WineStorageBottleEntriesListBoxProps
} from './WineStorageBottleOutputsListBoxContainer';
import BottleEntriesListBox from '../../../../wine/components/WineInfo/list-box/shared/BottleEntriesListBox';

const WineStorageBottleOutputsListBox: FC<WineStorageBottleEntriesListBoxProps> = (props) => {
  return (
    <WineStorageBottleEntriesListBoxContainer
      {...props}
      render={(bottleEntries, elementToShow, actions, loading) => (
        <BottleEntriesListBox
          bottleEntries={bottleEntries}
          elementToShow={elementToShow}
          actions={actions}
          entityName={'wine_production'}
          loading={props.loading || loading}
          disabledActions={props?.disabledActions}
          title={props.title}
        />
      )}
    />
  );
};

export default WineStorageBottleOutputsListBox;
