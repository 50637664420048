import React, { useEffect, useRef, useState } from 'react';

import { GrapevineSummaryPresentationData } from '../../../../../types/dto';
import { usePushHistory } from '../../../../../hooks/usePushHistory';
import { useLongTouch } from '../../../../../hooks/useLongTouch';
import { FilesListItemColor } from '../../../../../types/FileType';
import useClickOutside from '../../../../../hooks/useClickOutside';
import { GrapeColor } from '../../../../winery/components/grapevine/types/GrapeColor';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { useTimeout } from '../../../../../hooks/useTimeout';
import { useThemeContext } from '../../../../platform/ThemeContext';

interface IProps {
  grapevine: GrapevineSummaryPresentationData;
  totalPlants: number;
  vineyardArea: number;
}

export const GrapevineItem: React.FC<IProps> = ({ grapevine, totalPlants, vineyardArea }) => {
  const { pushHistory } = usePushHistory();
  const [show, setShow] = useState(false);
  const { wrapperRef: clickOutsideRef } = useClickOutside(() => {
    setShow(false);
  });
  const { darkMode } = useThemeContext();
  const inside: [number, string][] = [
    [
      Math.round((grapevine.numberOfPlants / totalPlants) * 100),
      `${grapevine.numberOfPlants} szt.`
    ],
    [Math.round((grapevine.area / vineyardArea) * 100), `${grapevine.area} ha`]
  ];

  const array = useRef(inside);
  const [currentIndex, setCurrentIndex] = useState(0);
  const { clear } = useTimeout(() => handleSetPercent(0), 100);

  // eslint-disable-next-line react-hooks/exhaustive-deps
  function handleSetPercent(index: number) {
    setPercentage(Math.round(inside[index][0] / 5) * 5);
    clear();
  }

  const [percentage, setPercentage] = useState(0);

  useEffect(() => {
    const intervalId = setInterval(() => {
      // Update the currentIndex every 10 seconds
      setCurrentIndex(currentIndex === 0 ? 1 : 0);
      handleSetPercent(currentIndex === 0 ? 1 : 0);
    }, 10000);

    // Cleanup the interval when the component unmounts
    return () => clearInterval(intervalId);
  }, [array.current.length, handleSetPercent, currentIndex]);

  function handleClick(e: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>) {
    e.preventDefault();
    pushHistory(`/mv/grapevine/e/${grapevine.id}`);
  }

  function getColor(): FilesListItemColor | string {
    if (grapevine.grapeColor === GrapeColor.WHITE) return '';
    if (grapevine.grapeColor === GrapeColor.RED) return 'pink';
    return 'gray';
  }

  function getBackgroundColor(): FilesListItemColor | string {
    if (grapevine.grapeColor === GrapeColor.WHITE) return 'rgba(255,207,81,0.49)';
    if (grapevine.grapeColor === GrapeColor.RED) return 'rgba(255,1,1,0.53)';
    return '';
  }

  function handleNavigateToEdit(
    e: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>
  ) {
    e.preventDefault();
    pushHistory(`/mv/grapevine/e/${grapevine.id}`);
  }

  function handleContextMenu(
    e: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>
  ) {
    e.preventDefault();
    setShow(true);
  }

  const { handleTouchStart, handleTouchEnd, handleTouchMove } = useLongTouch(handleContextMenu);

  return (
    <>
      <div
        className="col-auto pt-2 pb-1 pl-3 pr-3 d-flex flex-column justify-content-between"
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTouchMove={handleTouchMove}
        onContextMenu={handleContextMenu}
        onClick={handleClick}
        style={{
          cursor: 'pointer',
          width: '125px',
          userSelect: 'none',
          WebkitUserSelect: 'none',
          height: '100%',
          minHeight: '220px'
        }}
      >
        <div>
          <div
            className={`flex circle circle-lg bg-${getColor()}`}
            style={{ backgroundColor: getBackgroundColor() }}
          />
          {grapevine.numberOfPlants ? (
            <p className={'m-0 text-muted'}>
              <small>{`${grapevine.numberOfPlants} szt. (${grapevine.area} ha)`}</small>
            </p>
          ) : null}
          <p className={'m-0'}>{grapevine.name}</p>
        </div>
        <div className="pb-2 text center" style={{ maxWidth: '80px' }}>
          <CircularProgressbar
            value={percentage}
            text={
              inside[currentIndex][1].toString().substring(0, 8) ||
              `${Math.round((grapevine.numberOfPlants / totalPlants) * 100)}%`
            }
            styles={buildStyles({
              strokeLinecap: 'round',
              textSize: '16px',
              pathTransitionDuration: 1,
              pathColor: `rgba(55, 188, 155, ${percentage / 10})`,
              textColor: 'gray',
              trailColor: darkMode ? '#414141' : '#f5f5f5'
            })}
          />
        </div>
        <div
          className={'dropdown-menu animated pulse ' + (show ? 'show' : '')}
          role="menu"
          style={{
            position: 'absolute',
            transform: 'translate3d(0px, 34px, 0px)',
            top: 100,
            left: 0,
            willChange: 'transform'
          }}
          ref={clickOutsideRef}
        >
          <div
            className="dropdown-item"
            onClick={handleNavigateToEdit}
            style={{ cursor: 'pointer' }}
          >
            Edytuj
          </div>
        </div>
      </div>
    </>
  );
};
