import React, { useState } from 'react';
import { usePushHistory } from '../../../../hooks/usePushHistory';
import { WineStoragePresentationData } from '../../../../types/dto';
import { WineColor } from '../../../winery/components/wine/types/WineProduction';
import { useLongTouch } from '../../../../hooks/useLongTouch';
import { FilesListItemColor } from '../../../../types/FileType';
import { useTranslation } from 'react-i18next';
import useClickOutside from '../../../../hooks/useClickOutside';
import { buildStyles, CircularProgressbar } from 'react-circular-progressbar';
import { useTimeout } from '../../../../hooks/useTimeout';
import { useThemeContext } from '../../../platform/ThemeContext';

interface IProps {
  wine: WineStoragePresentationData;
}

export const WineInStorageItem: React.FC<IProps> = ({ wine }) => {
  const { pushHistory } = usePushHistory();
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const { wrapperRef: clickOutsideRef } = useClickOutside(() => {
    setShow(false);
  });
  const { darkMode } = useThemeContext();

  const { clear } = useTimeout(handleSetPercent, 100);
  const [percentage, setPercentage] = useState(0);

  function handleSetPercent() {
    setPercentage(
      Math.round(
        wine.maxLiters
          ? Math.round(Math.round((wine.totalLiters / wine.maxLiters) * 100) / 5) * 5
          : 0
      )
    );
    clear();
  }

  function getColor(): FilesListItemColor | string {
    if (wine.wineColor === WineColor.WHITE) return '';
    if (wine.wineColor === WineColor.ORANGE) return '';
    if (wine.wineColor === WineColor.RED) return 'pink';
    if (wine.wineColor === WineColor.ROSE) return 'pink';
    return 'gray';
  }

  function getBackgroundColor(): FilesListItemColor | string {
    if (wine.wineColor === WineColor.WHITE) return 'rgba(255,207,81,0.49)';
    if (wine.wineColor === WineColor.ORANGE) return 'rgba(255,142,0,0.44)';
    if (wine.wineColor === WineColor.RED) return 'rgba(255,1,1,0.53)';
    if (wine.wineColor === WineColor.ROSE) return 'rgba(255,44,44,0.39)';
    return '';
  }

  function handleNavigateToEdit(
    e: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>
  ) {
    e.preventDefault();
    pushHistory(`/mv/wine_storage/e/${wine.id}`);
  }

  function handleContextMenu(
    e: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>
  ) {
    e.preventDefault();
    setShow(true);
  }

  function handleNavigateToInfo(
    e: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>
  ) {
    e.preventDefault();
    pushHistory(`/mv/wine_storage/info/${wine.id}`);
  }

  const { handleTouchStart, handleTouchEnd, handleTouchMove } = useLongTouch(handleContextMenu);

  return (
    <>
      <div
        className="col-auto pt-2 pb-1 pl-3 pr-3 d-flex flex-column justify-content-between"
        onTouchStart={handleTouchStart}
        onTouchEnd={handleTouchEnd}
        onTouchMove={handleTouchMove}
        onContextMenu={handleContextMenu}
        onClick={handleNavigateToInfo}
        style={{
          cursor: 'pointer',
          width: '125px',
          userSelect: 'none',
          WebkitUserSelect: 'none',
          height: '100%',
          minHeight: '220px'
        }}
      >
        <div>
          <div
            className={`flex circle circle-lg bg-${getColor()}`}
            style={{ backgroundColor: getBackgroundColor() }}
          />
          {wine.wineTaste ? (
            <p className={'m-0 text-muted'}>
              <small>{wine.wineTaste ? t(`wine.TASTE.${wine.wineTaste}`) : null}</small>
            </p>
          ) : null}
          <p className={'m-0 pb-1'} style={{ minWidth: '93px' }}>
            {wine.name}
          </p>
        </div>
        <div className="pb-2 text center" style={{ maxWidth: '80px' }}>
          <CircularProgressbar
            value={percentage}
            text={`${wine.totalLiters} l`}
            styles={buildStyles({
              strokeLinecap: 'round',
              textSize: '16px',
              pathTransitionDuration: 1,
              pathColor: `rgba(255, 144, 43, ${percentage / 10})`,
              textColor: 'gray',
              trailColor: darkMode ? '#414141' : '#f5f5f5'
            })}
          />
        </div>
      </div>
      <div
        className={'dropdown-menu animated pulse ' + (show ? 'show' : '')}
        role="menu"
        style={{
          position: 'absolute',
          transform: 'translate3d(0px, 34px, 0px)',
          top: 100,
          left: 0,
          willChange: 'transform'
        }}
        ref={clickOutsideRef}
      >
        <div className="dropdown-item" onClick={handleNavigateToEdit} style={{ cursor: 'pointer' }}>
          Edytuj
        </div>
      </div>
    </>
  );
};
