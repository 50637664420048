import React from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyInfoList } from '../../../types';
import { SubscriptionStatus } from '../../../../../types/Subscription';

interface IProps {
  company: CompanyInfoList;
  onClick: () => void;
}

export const CompanyWithDiscountRow: React.FC<IProps> = ({ company, onClick }) => {
  const { t } = useTranslation();

  function getColorByStatus(status: SubscriptionStatus) {
    switch (status) {
      case SubscriptionStatus.ACTIVATED:
        return 'text-green text-center';
      case SubscriptionStatus.PAST_DUE:
        return 'text-danger text-center';
      case SubscriptionStatus.DISABLED:
        return 'text-info text-center';
      case SubscriptionStatus.UPGRADED:
        return 'text-muted text-center';
      case SubscriptionStatus.TRIAL:
        return 'text-warning text-center';
    }
  }

  return (
    <tr className="odd parent" onClick={onClick} style={{ cursor: 'pointer' }}>
      <td>{company.name}</td>
      <td className="text-center">
        {company.subscriptionType ? t(`plans.subscription.type.${company.subscriptionType}`) : '-'}
      </td>
      <td className={getColorByStatus(company.subscriptionStatus)}>
        {company.subscriptionStatus
          ? t(`plans.subscription.status.${company.subscriptionStatus}`)
          : '-'}
      </td>
    </tr>
  );
};
