import React from 'react';
import Select from 'react-select';
import { SelectOption } from '../../../../../../../types/SelectOption';
import { Ingredient } from '../../../types/Ingredient';
import useIngredientsReceiver from '../../../hooks/useIngredientsReceiver';
import { IngredientType } from '../../../types/IngredientType';
import { ServiceError } from '../../../../../../../types/Service';
import {
  NumberOdColumnsInRow,
  useFormFieldColumnCount
} from '../../../../../../home/components/TaxBandsSummaryPage/hooks/useFormFieldColumnCount';
import { Link } from 'react-router-dom';
import { SectionWrapper } from '../../../../../../../layouts/SectionWrapper';
import { useTranslation } from 'react-i18next';
import { useThemeContext } from '../../../../../../platform/ThemeContext';

interface Props {
  value?: Ingredient | SelectOption | null;
  onChange: (e: unknown) => void;
  type?: IngredientType | null;
  name: string;
  label?: string;
  optional?: boolean;
  error?: ServiceError;
  disabled?: boolean;
  numberOfColumnsInRow?: NumberOdColumnsInRow;
  hideLink?: boolean;
}

const SelectIngredient: React.FC<Props> = ({
  value: selected,
  onChange,
  type,
  name,
  label,
  optional,
  error,
  disabled,
  hideLink,
  numberOfColumnsInRow = 1
}) => {
  const { t } = useTranslation();
  const { selected: value, options: ingredients, loading } = useIngredientsReceiver(selected, type);
  const numberOfColumnsClass = useFormFieldColumnCount({ numberOfColumnsInRow });
  const { selectDarkStyles } = useThemeContext();

  return (
    <div className={'mb-3 ' + numberOfColumnsClass}>
      <label htmlFor={name}>
        {label}
        <span className="text-danger" style={{ display: optional === true ? 'none' : '' }}>
          {' '}
          *
        </span>
      </label>
      <SectionWrapper loading={loading} hideSpinner>
        <Select
          name={name}
          options={ingredients}
          onChange={onChange}
          value={value}
          noOptionsMessage={() => t('select.noOptions.message')}
          placeholder={'Wybierz'}
          styles={selectDarkStyles(error?.hasError?.(name))}
          isDisabled={disabled}
          isOptionDisabled={(option) => {
            if (typeof option !== 'string') {
              return option.lock;
            }
            return false;
          }}
        />
      </SectionWrapper>
      <span
        className="invalid-feedback"
        style={{ display: error?.hasError?.(name) ? 'block' : 'none' }}
      >
        {error?.getErrorMessage?.(name)}
      </span>
      {hideLink ? null : (
        <small className={'pl-1'}>
          <Link
            to={{
              pathname: '/mv/ingredient/e/0',
              state: { from: window.location.hash?.split('#')?.[1] }
            }}
          >
            Stwórz nowy
          </Link>
        </small>
      )}
    </div>
  );
};

export default SelectIngredient;
