import { useEventHandlerActions } from '../../../../hooks/useEventHandlerActions';
import React, { useEffect } from 'react';
import { ServiceError, StatusType } from '../../../../../../types/Service';
import { useParams } from 'react-router-dom';
import log from 'loglevel';
import { ResponseError } from '../../../../../error/ResponseError';
import { SelectOption } from '../../../../../../types/SelectOption';
import { WineStorage } from '../../types/WineStorage';
import { useWineStorageService } from '../../hooks/useWineStorageService';
import { useWineStorageContext } from '../../context/WineStorageContext';
import { RouterParams } from '../../../../../../types/RouterParams';
import { SESSION_STORAGE_KEY, useStorageSession } from '../../../../../../hooks/useStorageSession';
import { usePushHistory } from '../../../../../../hooks/usePushHistory';
import { HISTORY_FALLBACK_TO } from '../../../../../../services/Constants';

export const withWineStorageFromServiceHOC = <Props,>(
  WrappedComponent: React.ComponentType<Props>
) => {
  const WithWineStorageFromServiceHOC = (props) => {
    const { wineStorage, updateWineStorage, setResult, result } = useWineStorageContext();

    const { onChange: handleUpdateWineProduction } = useEventHandlerActions(updateWineStorage);

    const { wineStorageId } = useParams<RouterParams>();
    const { goBack } = usePushHistory();

    const storageSession = useStorageSession<WineStorage | Partial<WineStorage>>({
      entity: wineStorage,
      key: SESSION_STORAGE_KEY.CREATE_WINE_STORAGE_FORM,
      shouldSave: wineStorageId?.toString() === '0'
    });

    const service = useWineStorageService();

    useEffect(() => {
      if (wineStorageId?.toString() === '0') {
        const payload = storageSession.getItemPayload();
        setResult({ status: StatusType.loaded, payload });
        return;
      }
      wineStorageId &&
        service
          .get(parseInt(wineStorageId))
          .then((response) => {
            setResult({ status: StatusType.loaded, payload: response });
          })
          .catch((response) => setResult(new ResponseError(response) as ServiceError));

      return () => {
        updateWineStorage('reset', '');
      };
    }, [wineStorageId, updateWineStorage]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleUpdateSelect = (name: string, selected: SelectOption) => {
      updateWineStorage(name, selected.value);
    };

    const onSubmit = (e) => {
      e.preventDefault();
      log.debug('withWineProductionFromServiceHOC:onSubmit', e, wineStorage);
      setResult({ status: StatusType.loading });

      const action = () =>
        wineStorage?.id ? service.put(wineStorage.id, wineStorage) : service.post(wineStorage);
      action()
        .then((response) => {
          setResult(response);
          storageSession.removeItem();
          goBack(HISTORY_FALLBACK_TO.STORAGE_SUMMARY);
        })
        .catch((response) => setResult(new ResponseError(response) as ServiceError));
    };

    const onClickBack = () => {
      goBack(HISTORY_FALLBACK_TO.WINE_STORAGE_ARCHIVED);
    };

    const updateCheckbox = (name: string, value: boolean) => {
      updateWineStorage(name, value);
    };

    const error = result as ServiceError;

    const newProps = {
      ...props,
      updateWineStorage: handleUpdateWineProduction,
      loading: result.status === StatusType.loading,
      onClickBack,
      onSubmit,
      wineStorage,
      error,
      handleUpdateSelect,
      updateCheckbox
    };

    return <WrappedComponent {...newProps} />;
  };

  WithWineStorageFromServiceHOC.displayName = `withWineStorageFromServiceHOC(${
    WrappedComponent.displayName || WrappedComponent.name || 'Component'
  })`;

  return WithWineStorageFromServiceHOC;
};
