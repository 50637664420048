import { useWineProductionContext } from '../../context/WineProductionContext';
import { useEventHandlerActions } from '../../../../hooks/useEventHandlerActions';
import React, { useEffect } from 'react';
import { ServiceError, StatusType } from '../../../../../../types/Service';
import { useParams } from 'react-router-dom';
import log from 'loglevel';
import { ResponseError } from '../../../../../error/ResponseError';
import { SelectOption } from '../../../../../../types/SelectOption';
import { WineProduction } from '../../../wine/types/WineProduction';
import useWineProductionService from '../../hooks/useWineProductionService';
import { RouterParams } from '../../../../../../types/RouterParams';
import { SESSION_STORAGE_KEY, useStorageSession } from '../../../../../../hooks/useStorageSession';
import { HISTORY_FALLBACK_TO } from '../../../../../../services/Constants';
import { usePushHistory } from '../../../../../../hooks/usePushHistory';

export const withWineProductionFromServiceHOC = <Props,>(
  WrappedComponent: React.ComponentType<Props>
) => {
  const WithWineProductionFromServiceHOC = (props) => {
    const { wineProduction, updateWineProduction, setWineProductionResult, wineProductionResult } =
      useWineProductionContext();

    const { onChange: handleUpdateWineProduction, updateDate } =
      useEventHandlerActions(updateWineProduction);

    const { goBack } = usePushHistory();

    const updateCheckbox = (name: string, value: boolean) => {
      updateWineProduction(name, value);
    };

    const { wineProductionId } = useParams<RouterParams>();

    const storageSession = useStorageSession<WineProduction | Partial<WineProduction>>({
      entity: wineProduction,
      key: SESSION_STORAGE_KEY.CREATE_WINE_PRODUCTION_FORM,
      shouldSave: wineProductionId?.toString() === '0'
    });

    const service = useWineProductionService();

    useEffect(() => {
      if (wineProductionId?.toString() === '0') {
        const payload = storageSession.getItemPayload();
        setWineProductionResult({ status: StatusType.loaded, payload });
        return;
      }
      wineProductionId &&
        service
          .get(parseInt(wineProductionId))
          .then((response) => {
            setWineProductionResult({ status: StatusType.loaded, payload: response });
          })
          .catch((response) =>
            setWineProductionResult(new ResponseError(response) as ServiceError)
          );

      return () => {
        updateWineProduction('reset', '');
      };
    }, [wineProductionId, updateWineProduction]); // eslint-disable-line react-hooks/exhaustive-deps

    const handleUpdateSelect = (name: string, selected: SelectOption) => {
      updateWineProduction(name, selected.value);
    };

    const onSubmit = (e) => {
      e.preventDefault();
      log.debug('withWineProductionFromServiceHOC:onSubmit', e, wineProduction);
      setWineProductionResult({ status: StatusType.loading });

      const action = () =>
        wineProduction?.id
          ? service.put(wineProduction.id, wineProduction)
          : service.post(wineProduction);
      action()
        .then((response) => {
          setWineProductionResult(response);
          storageSession.removeItem();
          goBack(HISTORY_FALLBACK_TO.VINEYARD_SUMMARY);
        })
        .catch((response) => setWineProductionResult(new ResponseError(response) as ServiceError));
    };

    const onClickBack = () => {
      goBack(HISTORY_FALLBACK_TO.WINE_PRODUCTION_ARCHIVED);
    };

    const error = wineProductionResult as ServiceError;

    const newProps = {
      ...props,
      updateWineProduction: handleUpdateWineProduction,
      loading: wineProductionResult.status === StatusType.loading,
      onClickBack,
      onSubmit,
      wineProduction,
      error,
      handleUpdateSelect,
      updateDate,
      updateCheckbox
    };

    return <WrappedComponent {...newProps} />;
  };

  WithWineProductionFromServiceHOC.displayName = `withWineProductionFromServiceHOC(${
    WrappedComponent.displayName || WrappedComponent.name || 'Component'
  })`;

  return WithWineProductionFromServiceHOC;
};
