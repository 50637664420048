export const HISTORY_FALLBACK_TO = {
  VINEYARD_SUMMARY: '/mv/board/summary',
  WINERY_SUMMARY: '/mv/board/production',
  STORAGE_SUMMARY: '/mv/board/storage',
  TANKS_SUMMARY: '/mv/board/tanks',
  INGREDIENTS_SUMMARY: '/mv/board/ingredients',
  PARCEL_ARCHIVED: '/mv/parcel/archived',
  GRAPEVINE_ARCHIVED: '/mv/grapevine/archived',
  HARVEST_ARCHIVED: '/mv/harvest/archived',
  WINE_ARCHIVED: '/mv/wine/archived',
  WINE_PRODUCTION_ARCHIVED: '/mv/wine_production/archived',
  WINE_STORAGE_ARCHIVED: '/mv/wine_storage/archived',
  INGREDIENTS_ARCHIVED: '/mv/ingredient/archived',
  ERROR: '/mv/error'
};
