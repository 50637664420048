import React from 'react';
import { usePushHistory } from '../hooks/usePushHistory';

const NotAuthorized = () => {
  const { pushHistory } = usePushHistory();
  return (
    <div className="container wd-xl" style={{ marginTop: '25vh' }}>
      <div className="text-center mb-4">
        <div className="text-lg mb-3">401</div>
        <p className="lead m-0">Ups, nie można załadować treści</p>
        <p>Nie masz uprawnień do tej strony, lub zostałeś wylogowany</p>
        <button
          className="btn btn-sm btn-info text-center justify-content-center"
          onClick={() => pushHistory('/welcome/login')}
        >
          Zaloguj się ponownie
        </button>
      </div>
      <div className="p-3 text-center">
        <img
          className="img-fluid "
          src={`img/logo-${
            JSON.parse(localStorage.getItem('angle-store-key'))?.['settings']?.darkMode
              ? 'white'
              : 'dark'
          }.png`}
          style={{ height: '3rem' }}
          alt="Mistrz wina"
        />
        <br />
        <span>Osiągnij poziom mistrzowski w produkcji wina!</span>
      </div>
    </div>
  );
};

export default NotAuthorized;
