import { useMemo, useState } from 'react';
import { useUpdateEffect } from './useUpdateEffect';
import { Pagination } from '../types/Pagination';

function usePagination<T>(data: T[] = [], itemsPerPage: number): Pagination<T[]> {
  const [currentPage, setCurrentPage] = useState(1);
  const [currentItemsPerPage, setCurrentItemsPerPage] = useState(itemsPerPage);
  const maxPage = currentItemsPerPage < 0 ? 1 : Math.ceil(data.length / currentItemsPerPage);
  const total = useMemo(() => data.length, [data]);

  function getCurrentResults(): T[] {
    if (currentItemsPerPage < 0) {
      return data;
    }
    const begin = (currentPage - 1) * currentItemsPerPage;
    const end = begin + currentItemsPerPage;
    return data.slice(begin, end);
  }

  useUpdateEffect(() => {
    if (currentPage > maxPage) {
      jump(maxPage);
    }
    if (currentPage === 0) {
      jump(1);
    }
  }, [currentItemsPerPage, currentPage, maxPage]);

  function next() {
    setCurrentPage((currentPage) => Math.min(currentPage + 1, maxPage));
  }

  function appendNext() {
    setCurrentItemsPerPage((prevState) => prevState + itemsPerPage);
  }

  function prev() {
    setCurrentPage((currentPage) => Math.max(currentPage - 1, 1));
  }

  function jump(page: number) {
    const pageNumber = Math.max(1, page);
    setCurrentPage(() => Math.min(pageNumber, maxPage));
  }

  return { next, appendNext, prev, jump, getCurrentResults, currentPage, maxPage, total };
}

export default usePagination;
