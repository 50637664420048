import React, { ChangeEvent, FC } from 'react';
import { Button, Card, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import PageWrapper from '../../../../../../layouts/PageWrapper';
import { ServiceError } from '../../../../../../types/Service';
import { Parcel } from '../../types/Parcel';
import { FormErrorMessage } from '../../../../../../components/notifications/FormErrorMessage';
import { EntityLiveStatus } from '../../../../../../components/common/enums/EntityLiveStatus';
import { usePushHistory } from '../../../../../../hooks/usePushHistory';
import { SectionWrapper } from '../../../../../../layouts/SectionWrapper';
import { InputColumn } from '../../../../../../components/forms/InputColumn';

interface Props {
  onSubmit: (e: React.MouseEvent<HTMLButtonElement>) => void;
  error: ServiceError;
  parcel: Parcel;
  updateParcel: (e: ChangeEvent<HTMLInputElement>) => void;
  loading: boolean;
  onClickBack: () => void;
}

const SimpleParcelForm: FC<Props> = ({
  onSubmit,
  error,
  parcel,
  updateParcel,
  onClickBack,
  loading
}) => {
  const { t } = useTranslation();
  const { pushHistory } = usePushHistory();

  function pushToBoard() {
    pushHistory(`/mv/board/summary`);
  }

  return (
    <PageWrapper
      title={'parcel.TITLE'}
      onClick={pushToBoard}
      disabled={false}
      returnText={'home.summary.TITLE'}
    >
      <SectionWrapper loading={loading}>
        <Card>
          <CardBody>
            <div className={'pb-4'}>
              <h5 className="float-right">
                {parcel?.id ? t('sidebar.nav.element.EDIT') : t('sidebar.nav.element.ADD_NEW')}
              </h5>
            </div>
            <InputColumn
              label={t('parcel.REGISTRATION_NUMBER')}
              name={'registrationNumber'}
              maxSize={'100'}
              onChange={updateParcel}
              value={parcel?.registrationNumber}
              error={error}
              disabled={parcel?.liveStatus === EntityLiveStatus.ARCHIVED}
            />
            <InputColumn
              label={t('parcel.AREA')}
              type={'number'}
              name={'area'}
              maxSize={'100'}
              onChange={updateParcel}
              value={parcel?.area}
              error={error}
              disabled={parcel?.liveStatus === EntityLiveStatus.ARCHIVED}
            />
            <InputColumn
              label={t('parcel.DISTRICT')}
              name={'district'}
              maxSize={'100'}
              onChange={updateParcel}
              value={parcel?.district}
              error={error}
              disabled={parcel?.liveStatus === EntityLiveStatus.ARCHIVED}
            />
            <InputColumn
              label={t('parcel.COUNTRY')}
              name={'country'}
              maxSize={'100'}
              onChange={updateParcel}
              value={parcel?.country}
              error={error}
              optional
              disabled={parcel?.liveStatus === EntityLiveStatus.ARCHIVED}
            />
            <InputColumn
              label={t('parcel.CITY')}
              name={'city'}
              maxSize={'100'}
              onChange={updateParcel}
              value={parcel?.city}
              error={error}
              optional
              disabled={parcel?.liveStatus === EntityLiveStatus.ARCHIVED}
            />
            <InputColumn
              label={t('parcel.ZIP_CODE')}
              name={'zipCode'}
              maxSize={'100'}
              onChange={updateParcel}
              value={parcel?.zipCode}
              error={error}
              optional
              disabled={parcel?.liveStatus === EntityLiveStatus.ARCHIVED}
            />
            <FormErrorMessage error={error} messageType={'details'} />
            <div className="text-center bg-secondary">
              {parcel?.liveStatus === EntityLiveStatus.ARCHIVED ? (
                <Button color="info" className="btn-square" onClick={onClickBack}>
                  {t('common.BACK')}
                </Button>
              ) : (
                <Button color="info" className="btn-square" onClick={onSubmit}>
                  {parcel?.id ? t('common.SAVE') : t('common.ADD')}
                </Button>
              )}
            </div>
          </CardBody>
        </Card>
      </SectionWrapper>
    </PageWrapper>
  );
};

export default SimpleParcelForm;
