import { useEffect, useState } from 'react';
import { Service, ServiceWorking, StatusType } from '../../../../../types/Service';
import { ResponseError } from '../../../../error/ResponseError';
import { SelectOptionType } from '../../../../../types/SelectOption';
import { Bottle, BottleSimpleData } from '../types/WineStorage';
import { useBottleService } from '../../../../home/components/BottlesSummaryPages/hooks/useBottleService';

const useBottlesInWineStorageReceiver = (value?) => {
  const service = useBottleService();
  const [result, setResult] = useState<Service<BottleSimpleData[]>>({
    status: StatusType.loading
  });
  const [options, setOptions] = useState<SelectOptionType<number>[]>([]);
  const [selected, setSelected] = useState<SelectOptionType<number> | string>('');

  useEffect(() => {
    if (value && options.length) {
      setSelected(options.find((option) => option.value === value.id));
    }
    const workingResult = result as ServiceWorking<Bottle[]>;
    if (!workingResult?.payload) {
      service
        ?.getListForWineStorage()
        .then((response: BottleSimpleData[]) => {
          setResult({ status: StatusType.loaded, payload: response });
          const bottlesOptions =
            response?.map((bottle) => ({
              value: bottle.id,
              label: `${bottle.name} - ${bottle.capacity} l.`
            })) || [];
          setOptions(bottlesOptions);
        })
        .catch((error) => setResult(new ResponseError(error)));
    }
  }, [value, options]); // eslint-disable-line react-hooks/exhaustive-deps

  return { result, options, selected, loading: result.status === StatusType.loading };
};

export default useBottlesInWineStorageReceiver;
