import React from 'react';
import { SelectOption } from '../../../../types/SelectOption';
import { ServiceError } from '../../../../types/Service';
import { TankType } from '../../../winery/components/tank/types/Tank';
import { useTankTypeReceiver } from './hooks/useTankTypeReceiver';
import { SelectTypeColumn } from '../../../../components/forms/SelectTypeColumn';

interface Props {
  value?: TankType | SelectOption | null;
  onChange: (name: string, s: Record<string, string>) => void;
  name: string;
  label?: string;
  optional?: boolean;
  error?: ServiceError;
  disabled?: boolean;
}

const SelectTankType: React.FC<Props> = ({
  value,
  onChange,
  name,
  label,
  optional,
  error,
  disabled
}) => {
  return (
    <SelectTypeColumn
      onChange={onChange}
      name={name}
      receiver={useTankTypeReceiver}
      error={error}
      optional={optional}
      label={label}
      value={value}
      disabled={disabled}
    />
  );
};

export default SelectTankType;
