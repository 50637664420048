import { useCallback, useEffect, useState } from 'react';
import { Bottle } from '../../../../winery/components/wine_storage/types/WineStorage';
import { useParams } from 'react-router-dom';
import { RouterParams } from '../../../../../types/RouterParams';
import { useBottleService } from './useBottleService';
import log from 'loglevel';

export const defaultBottle: Bottle = {
  id: undefined,
  name: undefined,
  capacity: undefined,
  info: undefined,
  liveStatus: undefined,
  forBottling: undefined,
  approximateQuantity: undefined
};

export function useSelectedBottle({
  bottles
}: {
  bottles: Bottle[];
}): [
  selectedBottle: Bottle,
  selectBottle: ({ id, bottle }: { id: number; bottle?: Bottle }) => void
] {
  const [selectedBottle, setSelectedBottle] = useState<Bottle>(defaultBottle);
  const { bottleId } = useParams<RouterParams>();
  const bottleService = useBottleService();

  const findBottle = useCallback((id: number) => {
    id &&
      bottleService
        .get(id)
        .then((bottle) => setSelectedBottle(bottle))
        .catch((error) => log.error(error));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const selectBottle = useCallback(
    ({ id, bottle }: { id: number; bottle?: Bottle }) => {
      if (id === null) {
        setSelectedBottle(defaultBottle);
        return;
      }
      if (bottleId) {
        findBottle(id);
      } else if (bottle) {
        setSelectedBottle(bottle);
      } else {
        const bottleFromList = bottles.find((bottle) => bottle.id === id);
        bottleFromList && setSelectedBottle(bottleFromList);
        !bottleFromList && findBottle(id);
      }
    },
    [bottles, findBottle, bottleId]
  );

  useEffect(() => {
    if (bottleId) {
      selectBottle({ id: Number(bottleId) });
    }
  }, [bottleId, selectBottle]);

  return [selectedBottle, selectBottle];
}
