import { Checkbox } from '../../../../../../../components/common/form-elements/Checkbox';
import { SelectStorageEntryType } from '../SelectStorageEntryType';
import { Bottle, BottleEntry, TankEntry, WineStorageEntryType } from '../../../types/WineStorage';
import SelectTank from '../../../../wine/components/WineForm/SelectTank';
import { useWineProductionsReceiver } from '../../../../wine_production/hooks/useWineProductionsReceiver';
import { FormErrorMessage } from '../../../../../../../components/notifications/FormErrorMessage';
import React, { FC } from 'react';
import { Card, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Tank } from '../../../../tank/types/Tank';
import { WineProduction } from '../../../../wine/types/WineProduction';
import { ServiceError } from '../../../../../../../types/Service';
import { useResponsiveContext } from '../../../../../../platform/ResponsiveContext';
import { useToggleAndGoToRef } from '../../../../../../../hooks/useToggleAndGoToRef';
import { useAuthContext } from '../../../../../../platform/AuthContext';
import usePlanPicker from '../../../../../../subscription/hooks/usePlanPicker';
import { SubscriptionType } from '../../../../../../../types/Subscription';
import { SectionWrapper } from '../../../../../../../layouts/SectionWrapper';
import { DivDropped } from '../../../../../../../components/common/dropdowns/button-and-div/DivDropped';
import SelectBottlesForBottling from '../../../../wine/components/WineForm/SelectBottlesForBottling';
import { SelectTypeColumn } from '../../../../../../../components/forms/SelectTypeColumn';
import { InputColumn } from '../../../../../../../components/forms/InputColumn';

interface Props {
  hasWineProduction: boolean;
  updateWineProductionCheckbox: () => void;
  selectDifferentTank: boolean;
  updateTankCheckbox: () => void;
  entryType: WineStorageEntryType;
  updateEntryType: () => void;
  tank: Tank;
  bottle: Bottle;
  updateTank: () => void;
  updateBottle: () => void;
  updateQuantity: () => void;
  updateLiters: () => void;
  wineProduction: WineProduction;
  updateWineProduction: () => void;
  onSubmit: () => void;
  error: ServiceError;
  loading: boolean;
  disableTankAndLiters: boolean;
  bottleEntry: BottleEntry;
  tankEntry: TankEntry;
}

export const AddStorageEntryFormPresentation: FC<Props> = ({
  hasWineProduction,
  updateWineProductionCheckbox,
  selectDifferentTank,
  updateTankCheckbox,
  entryType,
  updateEntryType,
  tank,
  bottle,
  updateTank,
  updateBottle,
  updateQuantity,
  updateLiters,
  wineProduction,
  updateWineProduction,
  onSubmit,
  error,
  loading,
  disableTankAndLiters,
  bottleEntry,
  tankEntry
}) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsiveContext();
  const {
    value: isOpen,
    toggleValue: toggleOpen,
    currentRef
  } = useToggleAndGoToRef(false, isMobile);

  const {
    subscriptionInfo: { accessInfo }
  } = useAuthContext();

  const { isValidSubscription } = usePlanPicker({
    subscriptionType: SubscriptionType.PREMIUM,
    fromBlockingContent: true,
    blockingMessage: 'Przejdź na wyższy plan, aby umieścić wino w piwnicy',
    itemIndexToHighlight: 3
  });

  function handleOpen() {
    if (!isValidSubscription()) {
      return;
    }
    toggleOpen();
  }

  return (
    <SectionWrapper loading={loading}>
      <Card
        innerRef={currentRef}
        className={isOpen ? 'border-info-dark form' : 'bg-info-dark form'}
        style={{ zIndex: 1 }}
      >
        <CardBody className={'pt-2 pb-1'}>
          <div
            onClick={() => handleOpen()}
            style={{ cursor: 'pointer', height: '1.5em' }}
            className={'pt-1'}
          >
            <i className="float-left">
              {!accessInfo.hasPremiumAccess ? (
                <em className={'icon-lock'} />
              ) : (
                <em
                  className={
                    isOpen ? 'fa fa-minus text-primary text-gradient-blue-purple' : 'fa fa-plus'
                  }
                />
              )}
              <sup className="text-yellow ml-1"> premium</sup>
            </i>
            <h5 className={'m-0 mb-2 float-right ' + (isOpen ? 'text-gradient-blue-purple' : '')}>
              {t('wineStorage.addEntry')}
            </h5>
          </div>
          <DivDropped open={isOpen}>
            <div className={'mt-2'}>
              <div className="row pl-3 pb-2 pt-1">
                <Checkbox
                  name={'hasWineProduction'}
                  label={t('wineStorage.entry.checkbox.WINE_ENTRY')}
                  value={hasWineProduction}
                  onChange={updateWineProductionCheckbox}
                />
                {entryType === WineStorageEntryType.TANK && wineProduction?.tank && (
                  <Checkbox
                    name={'hasWineProduction'}
                    label={t('wineStorage.entry.checkbox.TANK')}
                    value={selectDifferentTank}
                    onChange={updateTankCheckbox}
                  />
                )}
              </div>
              <div>
                <SelectStorageEntryType
                  name={'entryType'}
                  value={entryType}
                  onChange={updateEntryType}
                  optional
                  label={t('wineStorage.entry.TYPE')}
                  key={entryType}
                />
                {hasWineProduction && entryType && (
                  <SelectTypeColumn
                    onChange={updateWineProduction}
                    name={'wineProduction'}
                    receiver={useWineProductionsReceiver}
                    error={error}
                    label={t('wineStorage.entry.WINE_PRODUCTION')}
                    optional
                    value={wineProduction}
                    errorName={'wineProduction'}
                    disabled={!entryType}
                    clazzName={!entryType ? 'pb-0 mb-0' : ''}
                  />
                )}
                {entryType === WineStorageEntryType.TANK && (
                  <SelectTank
                    value={tank}
                    name={'tank'}
                    onChange={updateTank}
                    label={t('wineStorage.entry.TANK')}
                    error={error}
                    key={(wineProduction?.id ? 2 : 3) + (selectDifferentTank ? 1 : 0)}
                    disabled={disableTankAndLiters}
                  />
                )}
                {entryType === WineStorageEntryType.BOTTLE && (
                  <>
                    <SelectBottlesForBottling
                      value={bottle}
                      name={'bottle'}
                      label={t('wineStorage.entry.BOTTLE')}
                      onChange={updateBottle}
                      error={error}
                    />
                    <InputColumn
                      name={'quantity'}
                      onChange={updateQuantity}
                      label={t('wineStorage.entry.QUANTITY')}
                      placeholder={
                        wineProduction?.liters
                          ? 'max ' + Math.floor(wineProduction?.liters / 0.75)
                          : ''
                      }
                      type={'number'}
                      error={error}
                      value={bottleEntry.quantity}
                    />
                  </>
                )}
                {entryType === WineStorageEntryType.TANK && (
                  <InputColumn
                    name={'liters'}
                    onChange={updateLiters}
                    value={tankEntry.liters}
                    placeholder={
                      wineProduction?.liters ? 'max ' + wineProduction?.liters + ' l.' : ''
                    }
                    type={'number'}
                    label={t('wineStorage.entry.LITERS')}
                    error={error}
                    disabled={disableTankAndLiters}
                    key={tankEntry.liters + tankEntry?.tank?.id}
                  />
                )}
              </div>
              <FormErrorMessage error={error} messageType={'details'} clazzName={'p-1'} />
              <div className={'text-center bg-secondary'}>
                <button
                  className="btn btn-info"
                  type="button"
                  onClick={onSubmit}
                  disabled={!entryType}
                >
                  {' '}
                  {t('common.ADD')}
                </button>
              </div>
            </div>
          </DivDropped>
        </CardBody>
      </Card>
    </SectionWrapper>
  );
};
