import React, { useEffect, useRef, useState } from 'react';
import PageWrapper from '../../../../layouts/PageWrapper';
import useTankService from '../../../winery/components/tank/hooks/useTankService';
import { Tank } from '../../../winery/components/tank/types/Tank';
import { ListItem } from '../../../reports/components/ListItem';
import { TankItem } from './TankItem';
import { useTranslation } from 'react-i18next';
import { TankListButtonsBar } from './TankListButtonsBar';
import { SelectedTankType, useTankGroups } from './hooks/useTankGroups';
import { usePushHistory } from '../../../../hooks/usePushHistory';
import { SectionWrapper } from '../../../../layouts/SectionWrapper';
import { useTankFilters } from './hooks/useTankFilters';

export const TanksSummaryPage = () => {
  const { t } = useTranslation();
  const serviceRef = useRef(useTankService());
  const [loading, setLoading] = useState(true);
  const { pushHistory } = usePushHistory();
  const [tanks, setTanks] = useState<Tank[]>([]);
  const [selectedType, setSelectedType] = useState<SelectedTankType>('all');

  const {
    tanksFilters,
    filteredTanks,
    filteredTanksOnlyByUsage,
    selectedFilter,
    setFilteredTanks,
    handleNoFilter,
    handleChangeFilter,
    totalItemsCount: totalFilteredItems
  } = useTankFilters(tanks, selectedType);

  const {
    tanksGroups,
    selectedTanks,
    handleAllGroups,
    handleChangeGroup,
    totalItemsCount: totalGroupedItems
  } = useTankGroups(filteredTanks, setSelectedType, filteredTanksOnlyByUsage);

  useEffect(() => {
    if (serviceRef.current != null && loading) {
      serviceRef.current
        .getListPresentation()
        .then((response) => {
          setTanks(response);
          setFilteredTanks(response);
        })
        .finally(() => setLoading(false));
    }
  }, [loading, setFilteredTanks]);

  return (
    <PageWrapper
      title={'home.TANKS'}
      onClick={() => pushHistory('/mv')}
      returnText={'sidebar.nav.element.DASHBOARD'}
    >
      <div className="row">
        <div className="col-lg-3">
          <SectionWrapper loading={loading} hideSpinner>
            <div className="card">
              <div className="card-body">
                <strong className="text-muted">{t('tanks.panel.filters')}</strong>
              </div>
              <div className="list-group">
                <ListItem
                  label={t('tanks.panel.types.all')}
                  value={totalFilteredItems}
                  active={'noFilter' === selectedFilter}
                  color={'grey'}
                  onClick={() => handleNoFilter()}
                />
                {tanksFilters.map((item, index) => (
                  <ListItem
                    label={item.label}
                    value={item.count}
                    active={item.filterType === selectedFilter}
                    color={item.color}
                    key={item.filterType}
                    onClick={() => handleChangeFilter(item.filterType)}
                    isLast={index === tanksGroups.length - 1}
                  />
                ))}
              </div>
            </div>
          </SectionWrapper>
          <SectionWrapper loading={loading} hideSpinner>
            <div className="card">
              <div className="card-body">
                <strong className="text-muted">{t('tanks.panel.types')}</strong>
              </div>
              <div className="list-group">
                <ListItem
                  label={t('tanks.panel.types.all')}
                  value={totalGroupedItems}
                  active={'all' === selectedType}
                  color={'grey'}
                  onClick={() => handleAllGroups()}
                />
                {tanksGroups
                  // .filter((item) => item.count > 0)
                  .map((item, index) => (
                    <ListItem
                      label={item.label}
                      value={item.count}
                      active={item.type === selectedType}
                      color={item.color}
                      key={item.type}
                      onClick={() => handleChangeGroup(item.type)}
                      isLast={index === tanksGroups.length - 1}
                    />
                  ))}
              </div>
            </div>
          </SectionWrapper>
        </div>
        <div className="col-lg-9">
          <TankListButtonsBar reportAvailable={tanks.length > 0} />
          {loading ? (
            <SectionWrapper loading={loading}>
              <div style={{ height: '50vh' }}></div>
            </SectionWrapper>
          ) : (
            <div className="row">
              {selectedTanks.map((tank) => (
                <TankItem tank={tank} key={tank.id} />
              ))}
            </div>
          )}
        </div>
      </div>
    </PageWrapper>
  );
};
