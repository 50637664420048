import React, { useRef } from 'react';
import { TaxBandsPlacement } from '../../../types/WineStorage';
import { useWineStorageContext } from '../../../context/WineStorageContext';
import { useTaxBandsPlacementService } from '../../../hooks/useTaxBandsPlacementService';
import { useTranslation } from 'react-i18next';
import WineStorageTaxBandsPlacementsListBox from '../WineStorageTaxBandsPlacementsListBox';

export const TaxBandsPlacementsList: React.FC<{
  taxBandPlacements: TaxBandsPlacement[];
  loading: boolean;
}> = ({ taxBandPlacements, loading }) => {
  const { wineStorage } = useWineStorageContext();
  const serviceRef = useRef(useTaxBandsPlacementService());

  const { t } = useTranslation();

  return (
    <>
      {taxBandPlacements?.length || wineStorage?.id == undefined ? (
        <WineStorageTaxBandsPlacementsListBox
          taxBandsPlacements={taxBandPlacements || []}
          delOutput={serviceRef.current.del}
          title={t('wineStorage.taxBandsPlacement.ADDED')}
          loading={loading}
        />
      ) : null}
    </>
  );
};
