import React, { useCallback, useState } from 'react';
import { Service, ServiceError, StatusType } from '../../../../../types/Service';
import log from 'loglevel';
import { ResponseError } from '../../../../error/ResponseError';
import { useParams } from 'react-router-dom';
import { useEventHandlerActions } from '../../../hooks/useEventHandlerActions';
import { WineEntry } from '../../wine/types/WineProduction';
import { useWineEntryContext } from '../components/AddWineEntryForm/WineEntryContext';
import { RouterParams } from '../../../../../types/RouterParams';
import { ToApiConverter } from '../../../../../services/Converters';
import { SESSION_STORAGE_KEY, useStorageSession } from '../../../../../hooks/useStorageSession';
import { usePushHistory } from '../../../../../hooks/usePushHistory';
import { HISTORY_FALLBACK_TO } from '../../../../../services/Constants';

export const useWineEntryOnClickService = <T>(
  addWineEntry: (wineEntry: WineEntry) => Promise<T>,
  editWineEntry: (wineEntryId: number, wineEntry: WineEntry) => Promise<T>,
  setResult: (value: Service<T>) => void
) => {
  const {
    wineEntry,
    setResult: setWineEntryResult,
    updateWineEntry: update
  } = useWineEntryContext();

  const [loading, setLoading] = useState(false);

  const { wineEntryId } = useParams<RouterParams>();

  const [key, setKey] = useState(new Date());

  const { goBack } = usePushHistory();

  const { onChange, updateDateTime: updateDate } = useEventHandlerActions(update);

  const storageSession = useStorageSession<WineEntry>({
    entity: wineEntry,
    key: SESSION_STORAGE_KEY.ADD_WINE_ENTRY,
    shouldSave: false
  });

  const updateSelectWineEntry = (
    entityName: string,
    selected: { value: string; label: string; liters: number }
  ) => {
    const entity = { id: selected.value, label: selected.label, liters: selected.liters };
    if (entityName === 'wine') {
      update('wine', entity);
      update('wineProduction', null);
    }
    if (entityName === 'wineProduction') {
      update('wineProduction', entity);
      update('wine', null);
    }
    update('entryId', selected?.value);
  };

  const updateSelect = (selectName: string, selected) => {
    update(selectName, selected.value);
    update('wine', null);
    update('wineProduction', null);
  };

  const saveOnSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    addWineEntry(ToApiConverter.convertWineEntry(wineEntry))
      .then((response) => {
        storageSession.removeItem();
        update('reset', '');
        setResult({ status: StatusType.loaded, payload: response });
        setKey(new Date());
      })
      .then(() => {
        window.scrollTo({ behavior: 'smooth', top: 0 });
      })
      .catch((response) => {
        log.debug(response);
        setWineEntryResult(new ResponseError(response) as ServiceError);
      })
      .finally(() => setLoading(false));
  };

  const updateOnSubmit = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      if (wineEntryId) {
        setLoading(true);
        editWineEntry(parseInt(wineEntryId), ToApiConverter.convertWineEntry(wineEntry))
          .then(() => goBack(HISTORY_FALLBACK_TO.WINERY_SUMMARY))
          .catch((response) => {
            log.debug(response);
            setResult({ status: StatusType.loaded });
            setWineEntryResult(new ResponseError(response) as ServiceError);
          })
          .finally(() => setLoading(false));
      }
    },
    [editWineEntry, setResult, goBack, setWineEntryResult, wineEntry, wineEntryId]
  );

  return {
    onChange,
    updateSelect,
    updateSelectWineEntry,
    onSubmit: { update: updateOnSubmit, save: saveOnSubmit },
    updateDate,
    key,
    loading
  };
};
