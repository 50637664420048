import React from 'react';
import Select from 'react-select';
import { SelectOption } from '../../../../../../types/SelectOption';
import { Harvest } from '../../../harvest/types/Harvest';
import useHarvestsReceiver from '../../../harvest/hooks/useHarvestsReceiver';
import { Link, useHistory } from 'react-router-dom';
import { ServiceError } from '../../../../../../types/Service';
import {
  NumberOdColumnsInRow,
  useFormFieldColumnCount
} from '../../../../../home/components/TaxBandsSummaryPage/hooks/useFormFieldColumnCount';
import { SectionWrapper } from '../../../../../../layouts/SectionWrapper';
import { useTranslation } from 'react-i18next';
import { useThemeContext } from '../../../../../platform/ThemeContext';

interface Props {
  value: Harvest | SelectOption;
  onChange: () => void;
  name: string;
  label?: string;
  optional?: boolean;
  disabled?: boolean;
  error?: ServiceError;
  showExtraMessage?: boolean;
  numberOfColumnsInRow?: NumberOdColumnsInRow;
  hideLink?: boolean;
}

const SelectHarvest: React.FC<Props> = ({
  value: selected,
  onChange,
  name,
  label,
  optional,
  disabled,
  error,
  showExtraMessage,
  hideLink,
  numberOfColumnsInRow = 1
}) => {
  const { selected: value, options: harvests, loading } = useHarvestsReceiver(selected);
  const history = useHistory();
  const numberOfColumnsClass = useFormFieldColumnCount({ numberOfColumnsInRow });
  const { t } = useTranslation();
  const { selectDarkStyles } = useThemeContext();

  const goToHarvest = (id) => {
    history.push(`/mv/harvest/${id}/info`, { from: window.location.pathname });
  };

  return (
    <div className={'mb-3 ' + numberOfColumnsClass}>
      <label htmlFor={name}>
        {label}
        <span className="text-danger" style={{ display: optional === true ? 'none' : '' }}>
          {' '}
          *
        </span>
      </label>
      <SectionWrapper loading={loading} hideSpinner>
        <Select
          name={name}
          options={harvests}
          onChange={onChange}
          value={value}
          isDisabled={disabled}
          noOptionsMessage={() => t('select.noOptions.message')}
          placeholder={'Wybierz'}
          styles={selectDarkStyles(error?.hasError?.(name))}
        />
      </SectionWrapper>
      <span
        className="invalid-feedback"
        style={{ display: error?.hasError?.(name) ? 'block' : 'none' }}
      >
        {error?.getErrorMessage?.(name)}
      </span>
      {hideLink ? null : (
        <small className={'pl-1'}>
          <Link
            to={{
              pathname: '/mv/harvest/e/0',
              state: { from: window.location.hash?.split('#')?.[1] }
            }}
          >
            Stwórz nowy
          </Link>
        </small>
      )}
      {showExtraMessage && (
        <span className="text-muted small">
          Tego pola nie możesz edytować, ponieważ zbiór został zakmnięty i rozdysponowany.
          <span
            className="text-info btn-link"
            onClick={() => goToHarvest('id' in selected ? selected.id : '')}
            style={{
              cursor: 'pointer',
              display: 'id' in selected && selected.id ? '' : 'none'
            }}
          >
            Przejdź do Zarządzania zbiorem...
          </span>
        </span>
      )}
    </div>
  );
};

export default SelectHarvest;
