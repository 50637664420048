import React, { FC } from 'react';
import { Button, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { AddIngredientFormFields } from '../../applied/shared/AddIngredientFormFields';
import { IngredientFormProps } from '../../applied/shared/withIngredientServiceHOC';
import { SectionWrapper } from '../../../../../../../layouts/SectionWrapper';

const AddIngredientForm: FC<IngredientFormProps> = ({
  ingredient,
  actions: { onSubmit, updateIngredientSelect, updateTypeSelect, onChange, updateDate },
  error,
  loading
}) => {
  const { t } = useTranslation();

  return (
    <SectionWrapper loading={loading}>
      <CardBody className={'pb-0 px-0 bg-secondary-dark'}>
        <AddIngredientFormFields
          ingredient={ingredient}
          actions={{ updateIngredientSelect, updateTypeSelect, onChange, updateDate }}
          error={error}
        />
        <div>
          <div className={'bg-secondary text-center'}>
            <Button color="info" className="btn-square" onClick={onSubmit.save}>
              {t('common.ADD')}
            </Button>
          </div>
        </div>
      </CardBody>
    </SectionWrapper>
  );
};

export default AddIngredientForm;
