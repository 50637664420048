import { EntityLiveStatus } from '../../../../../components/common/enums/EntityLiveStatus';
import { WineColor, WineProduction, WineTaste } from '../../wine/types/WineProduction';
import { Tank } from '../../tank/types/Tank';

export type WineStorage = {
  id: number;
  bottleEntries: BottleEntry[];
  tankEntries: TankEntry[];
  bottleOutputs: BottleOutput[];
  tankOutputs: TankOutput[];
  liveStatus: EntityLiveStatus;
  totalLiters: number;
  totalBottles: number;
  litersInBottles: number;
  totalTanks: number;
  litersInTanks: number;
  maxLiters: number;
  maxBottles: number;
  maxLitersInBottles: number;
  maxTanks: number;
  maxLitersInTanks: number;
  taxBandsPlacements: TaxBandsPlacement[];
  taxBandsTotalQuantity: number;
  taxBandsActualQuantity: number;
  varietalWine: boolean;
  favoriteWine: boolean;
  protectedNameOrGeoWine: boolean;
  fromUE: boolean;
  fromOwnHarvests: boolean;
  fromOtherCountry: boolean;
  totalNotFinishedPlacementsOfTaxBands: number;
  taxBandsPlacementNeedAttention: boolean;
} & SimplyWineStorageData;

export type WineStorageInBottleData = Pick<
  WineStorage,
  'id' | 'name' | 'wineColor' | 'wineTaste' | 'totalBottles' | 'totalLiters'
>;

export interface TaxBandsPlacement {
  id: number;
  quantity: number;
  placementDate: string;
  declarationDate: string;
  payed: boolean;
  needAttention: boolean;
  notProcessed: boolean;
  wineStorageId: number;
  placementResult: PlacementResult;
  taxBandsId: number;
}

export type TaxBandsPlacementListPresentationData = {
  id: number;
  quantity: number;
  placementDate: string;
  declarationDate: string;
  payed: boolean;
  wineStorage: WineStorageForTaxBandsPresentationData;
  placementResult: PlacementResult;
  taxBandsId: number;
  needAttention: boolean;
};

type WineStorageForTaxBandsPresentationData = {
  id: number;
  name: string;
  wineColor: WineColor;
  wineTaste: WineTaste;
  taxBandsTotalQuantity: number;
  taxBandsActualQuantity: number;
};

export enum PlacementResult {
  USED = 'USED',
  DAMAGED = 'DAMAGED',
  LOST = 'LOST',
  DESTROYED = 'DESTROYED',
  RETURNED = 'RETURNED'
}

export enum PlacementResultForSelect {
  DAMAGED = 'DAMAGED',
  LOST = 'LOST',
  DESTROYED = 'DESTROYED',
  RETURNED = 'RETURNED'
}

export type TaxBandsPlacementForm = Pick<
  TaxBandsPlacement,
  'quantity' | 'placementDate' | 'placementResult' | 'declarationDate' | 'payed' | 'taxBandsId'
>;

export type PlacementForm = Pick<
  TaxBandsPlacement,
  'quantity' | 'placementDate' | 'placementResult' | 'taxBandsId'
>;

export interface SimplyWineStorageData {
  name: string;
  wineColor: WineColor;
  wineTaste: WineTaste;
}

export interface BottleEntry {
  id: number;
  bottle: Bottle;
  quantity: number;
  wineProduction: Partial<WineProduction>;
  wineStorageConsumer: Partial<WineStorage>;
}

export type BottleOutput = {
  id: number;
  bottle: Bottle | BottleSimpleData;
  quantity: number;
  hasTaxBands: boolean;
  outputDate: Date;
  outputType: BottleOutputType;
  outputDescription: string;
};

export type TankOutput = {
  id: number;
  liters: number;
  outputDate: Date;
  outputType: TankOutputType;
  bottleEntry: BottleEntry;
  tank: Tank;
  outputDescription: string;
};

export enum BottleOutputType {
  SALE = 'SALE',
  TASTING = 'TASTING',
  WASTE = 'WASTE',
  OWN_CONSUMPTION = 'OWN_CONSUMPTION',
  OTHER = 'OTHER'
}

export enum TankOutputType {
  SALE = 'SALE',
  TASTING = 'TASTING',
  WASTE = 'WASTE',
  OWN_CONSUMPTION = 'OWN_CONSUMPTION',
  BOTTLING = 'BOTTLING',
  OTHER = 'OTHER'
}

export interface TankEntry {
  id: number;
  tank: Tank;
  liters: number;
  actualLiters: number;
  wineProduction: Partial<WineProduction>;
  wineStorageConsumer: Partial<WineStorage>;
}

export interface Bottle extends BottleSimpleData {
  info: string;
  liveStatus: EntityLiveStatus;
  forBottling: boolean;
  approximateQuantity: number;
}

export interface BottleSimpleData {
  id: number;
  name: string;
  capacity: number;
}

export enum WineStorageEntryType {
  BOTTLE = 'BOTTLE',
  TANK = 'TANK'
}
