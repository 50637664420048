import useTankService from './useTankService';
import { useEffect, useState } from 'react';
import { Service, ServiceWorking, StatusType } from '../../../../../types/Service';
import { Tank } from '../types/Tank';
import { ResponseError } from '../../../../error/ResponseError';
import { SelectOption } from '../../../../../types/SelectOption';
import { useTranslation } from 'react-i18next';

const useTanksReceiver = (value?) => {
  const { t } = useTranslation();
  const service = useTankService();
  const [result, setResult] = useState<Service<Tank[]>>({
    status: StatusType.loading
  });
  const [options, setOptions] = useState<SelectOption[]>([]);
  const [selected, setSelected] = useState<SelectOption | string>('');

  useEffect(() => {
    if (value) {
      setSelected(
        value?.id
          ? {
              value: value.id,
              label: value.label ? value.label : value.number + ' - ' + value.capacity + ' l.'
            }
          : ''
      );
    }
    const workingResult = result as ServiceWorking<Tank[]>;
    if (!workingResult?.payload) {
      service
        ?.getAvailableList()
        .then((response) => {
          setResult({ status: StatusType.loaded, payload: response });
          const tankOptions =
            response?.map((tank) => ({
              value: tank.id,
              label: t(`tank.TYPE.${tank.type}`) + ` (${tank.number}) - ${tank.capacity} l.`
            })) || [];
          setOptions([{ value: null, label: t(`tank.TYPE.NONE`) }, ...tankOptions]);
        })
        .catch((error) => setResult(new ResponseError(error)));
    }
  }, [value]); // eslint-disable-line react-hooks/exhaustive-deps

  return { result, options, selected, loading: result.status === StatusType.loading };
};

export default useTanksReceiver;
