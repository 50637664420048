import React, { useState } from 'react';
import { Tank, TankType } from '../../../winery/components/tank/types/Tank';
import { WineColor } from '../../../winery/components/wine/types/WineProduction';
import { usePushHistory } from '../../../../hooks/usePushHistory';
import { Progress } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { useLongTouch } from '../../../../hooks/useLongTouch';
import { useResponsiveContext } from '../../../platform/ResponsiveContext';
import useClickOutside from '../../../../hooks/useClickOutside';

interface IProps {
  tank: Tank;
}

export const TankItem: React.FC<IProps> = ({ tank }) => {
  const { pushHistory } = usePushHistory();
  const { isMobile } = useResponsiveContext();
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const { wrapperRef: clickOutsideRef } = useClickOutside(() => {
    setShow(false);
  });

  function getIcon() {
    if (tank.type === TankType.TANK) return 'fa-mobile';
    if (tank.type === TankType.BARREL) return 'fa-circle-notch';
    if (tank.type === TankType.VAT) return 'fa-trash';
    if (tank.type === TankType.BALLOON) return 'fa-tint';
    return 'fa-mobile';
  }

  function getColor() {
    if (tank.liters === 0) return 'grey';
    if (tank.colorInside === WineColor.WHITE) return 'rgba(255,196,30,0.6)';
    if (tank.colorInside === WineColor.ROSE) return 'rgba(255,44,44,0.39)';
    if (tank.colorInside === WineColor.RED) return 'rgba(255,44,44,0.73)';
    if (tank.colorInside === WineColor.ORANGE) return 'rgba(255,156,67,0.73)';
    if (tank.colorInside === WineColor.OTHER) return '#28baae';
    return '#28baae';
  }

  function getProgressbarColor() {
    if (tank.colorInside === WineColor.WHITE) return 'yellow';
    if (tank.colorInside === WineColor.ROSE) return 'danger';
    if (tank.colorInside === WineColor.RED) return 'danger';
    if (tank.colorInside === WineColor.ORANGE) return 'warning';
    if (tank.colorInside === WineColor.OTHER) return 'green';
    return '#28baae';
  }

  function handleNavigateToEdit(
    e: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>
  ) {
    e.preventDefault();
    pushHistory(`/mv/tank/e/${tank.id}`);
  }

  const { handleTouchStart, handleTouchEnd, handleTouchMove } = useLongTouch(handleContextMenu);

  function handleNavigateToWine() {
    if (tank.liters > 0 && tank.entryType && tank.entryId) {
      pushHistory(`/mv/${tank?.entryType?.toLowerCase()}/info/${tank.entryId}`);
    } else {
      pushHistory(`/mv/tank/e/${tank.id}`);
    }
  }

  function handleContextMenu(
    e: React.TouchEvent<HTMLDivElement> | React.MouseEvent<HTMLDivElement>
  ) {
    e.preventDefault();
    setShow(true);
  }

  return (
    <div className="col-6 col-sm-4 col-md-4 col-lg-4 col-xl-3">
      <div className="card card-hovered">
        <div
          className="card-body text-center pb-1"
          style={{
            minHeight: '200px',
            userSelect: 'none',
            WebkitUserSelect: 'none',
            cursor: 'pointer'
          }}
          onTouchStart={handleTouchStart}
          onTouchEnd={handleTouchEnd}
          onTouchMove={handleTouchMove}
          onContextMenu={handleContextMenu}
          onClick={handleNavigateToWine}
        >
          <div className="d-flex">
            <div className="ml-auto mb-2">
              <small className="mr-2 text-muted">{`${tank.number} - ${tank.capacity} l`}</small>
            </div>
          </div>
          <div className="row">
            <div className={`float-left`}>
              <span className="px-4">
                <em className={`fa-3x fas ${getIcon()} mb-1`} style={{ color: getColor() }} />
              </span>
            </div>
          </div>
          <br />
          {!isMobile ? <br /> : null}
          <p>
            <small className="text-dark">{tank.liters} l</small>
          </p>
          <Progress
            className="progress-xs m-2 mr-4 ml-4"
            value={tank.percentOfUsage}
            color={getProgressbarColor()}
          />
          <div className="d-flex m-0 text-muted">
            <small className="ml-auto">
              {tank.aboutInside ||
                (tank.liters > 0 ? t('tank.aboutInside.UNKNOWN') : t('tank.aboutInside.UNUSED'))}
            </small>
          </div>
        </div>
      </div>
      <div
        className={'dropdown-menu animated pulse ' + (show ? 'show' : '')}
        role="menu"
        style={{
          position: 'absolute',
          transform: 'translate3d(0px, 34px, 0px)',
          top: 50,
          left: 50,
          willChange: 'transform'
        }}
        ref={clickOutsideRef}
      >
        <div className="dropdown-item" onClick={handleNavigateToEdit} style={{ cursor: 'pointer' }}>
          Edytuj
        </div>
      </div>
    </div>
  );
};
