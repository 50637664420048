import { ChangeEvent, useEffect } from 'react';
import { useParams } from 'react-router-dom';
import { ServiceError, StatusType } from '../../../../../../types/Service';
import useTankService from '../../hooks/useTankService';
import { ResponseError } from '../../../../../error/ResponseError';
import log from 'loglevel';
import { useTankContext } from '../../context/TankContext';
import { RouterParams } from '../../../../../../types/RouterParams';
import { SelectOption } from '../../../../../../types/SelectOption';
import { SESSION_STORAGE_KEY, useStorageSession } from '../../../../../../hooks/useStorageSession';
import { Tank } from '../../types/Tank';
import { usePushHistory } from '../../../../../../hooks/usePushHistory';
import { HISTORY_FALLBACK_TO } from '../../../../../../services/Constants';

const TankFormContainer = ({ render }) => {
  const { tank, updateTank, setTankResult, tankResult } = useTankContext();

  const service = useTankService();

  const { tankId } = useParams<RouterParams>();
  const { goBack } = usePushHistory();

  const storageSession = useStorageSession<Tank>({
    entity: tank,
    key: SESSION_STORAGE_KEY.CREATE_TANK,
    shouldSave: tankId?.toString() === '0'
  });

  useEffect(() => {
    setTankResult({ status: StatusType.loading });
    if (tankId?.toString() === '0') {
      const payload = storageSession.getItemPayload();
      setTankResult({ status: StatusType.loaded, payload });
      return;
    }
    tankId &&
      service
        .get(parseInt(tankId))
        .then((response) => {
          setTankResult({ status: StatusType.loaded, payload: response });
        })
        .catch((response) => setTankResult(new ResponseError(response) as ServiceError));

    return () => {
      updateTank('reset', '');
    };
  }, [tankId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleUpdateTank = (e: ChangeEvent<HTMLInputElement>) => {
    updateTank(e.target.name, e.target.value);
  };

  const handleUpdateSelect = (name: string, selected: SelectOption) => {
    updateTank(name, selected.value);
  };

  const onSubmit = (e) => {
    e.preventDefault();
    log.debug('TankForm:onSubmit', e, tank);
    setTankResult({ status: StatusType.loading });

    const action = () => (tank?.id ? service.put(tank.id, tank) : service.post(tank));
    action()
      .then((response) => {
        storageSession.removeItem();
        setTankResult(response);
        goBack(HISTORY_FALLBACK_TO.TANKS_SUMMARY);
      })
      .catch((response) => setTankResult(new ResponseError(response) as ServiceError));
  };

  const error = tankResult as ServiceError;

  log.debug('TankForm::render', tank);
  return render(
    onSubmit,
    tank,
    handleUpdateTank,
    error,
    tankResult.status === StatusType.loading,
    handleUpdateSelect
  );
};

export default TankFormContainer;
