import React from 'react';
import { useTranslation } from 'react-i18next';
import { SubscriptionType } from '../../../../types/Subscription';

export const AdminPlansHeader: React.FC<{ subscriptionType: SubscriptionType; title: string }> = ({
  subscriptionType,
  title
}) => {
  const { t } = useTranslation();

  return (
    <div>
      <div className="text-bold float-left card-header">{title}</div>
      <div className={'float-right'}>
        <div>
          <div className="mt-3 mr-3">
            <strong className="float-right h4 text-gradient-blue-purple">
              {t(`plans.subscription.type.${subscriptionType}`)}
            </strong>
          </div>
        </div>
      </div>
    </div>
  );
};
