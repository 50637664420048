import React from 'react';
import WineInfoContainer, { WineInfoButtons } from './WineInfoContainer';
import WineInfoPanel from './WineInfoPanel';

const WineInfo = () => {
  return (
    <WineInfoContainer
      render={(buttons: WineInfoButtons, updateTank, error) => (
        <WineInfoPanel buttons={buttons} updateTank={updateTank} error={error} />
      )}
    />
  );
};

export default WineInfo;
