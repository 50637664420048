import React from 'react';
import { FromApiConverter } from '../../../../../../services/Converters';
import SimpleIngredientList from './SimpleIngredientList';
import useIngredientService from '../../hooks/useIngredientService';
import { useEntityPageReceiver } from '../../../../hooks/pagination/useEntityPageReceiver';
import { BoxWithGrapes } from '../../../harvest/components/HarvestInfoRedesigned/types/BoxWithGrapes';
import { useIngredientContext } from '../../context/IngredientContext';
import { FormErrorMessage } from '../../../../../../components/notifications/FormErrorMessage';
import { Harvest } from '../../../harvest/types/Harvest';

const IngredientList = () => {
  const service = useIngredientService();
  const { setError, error } = useIngredientContext();
  const { entities, currentPage, pagination, limit, loading, paginationActions, entityActions } =
    useEntityPageReceiver<BoxWithGrapes, Harvest>(
      service,
      FromApiConverter.convertIngredientList,
      'ingredient',
      setError
    );

  return (
    <SimpleIngredientList
      ingredients={entities}
      page={currentPage}
      pagination={pagination}
      limit={limit}
      loading={loading}
      paginationActions={paginationActions}
      entityActions={entityActions}
    >
      <FormErrorMessage error={error} messageType={'details'} />
    </SimpleIngredientList>
  );
};

export default IngredientList;
