import React from 'react';
import { useThemeContext } from '../features/platform/ThemeContext';

const Footer = () => {
  const year = new Date().getFullYear();
  const { logo } = useThemeContext();
  return (
    <footer className="footer-container">
      <span>
        {' '}
        <img src={logo} style={{ height: '2rem' }} alt="Mistrz wina" />
        &copy; {year}
      </span>
    </footer>
  );
};

export default Footer;
