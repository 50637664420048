import React, { useRef, useState } from 'react';
import { useTranslation } from 'react-i18next';
import usePagination from '../../../../hooks/usePagination';
import { PaginationComponent } from '../../../../components/PaginationComponent';
import { Subscription } from '../../../../types/Subscription';
import { SubscriptionRow } from './SubscriptionRow';
import { FormErrorMessage } from '../../../../components/notifications/FormErrorMessage';
import useSubscriptionService from '../../../subscription/hooks/useSubscriptionService';
import { ServiceError } from '../../../../types/Service';
import { useConfirmation } from '../../../notifications/useConfirmation';
import { ResponseError } from '../../../error/ResponseError';
import { SectionWrapper } from '../../../../layouts/SectionWrapper';
import { useResponsiveContext } from '../../../platform/ResponsiveContext';

interface IProps {
  subscriptions: Subscription[];
  reload: () => void;
  loading: boolean;
}

export const SubscriptionsTable: React.FC<IProps> = ({ subscriptions, reload, loading }) => {
  const { t } = useTranslation();
  const pagination = usePagination(subscriptions, 10);
  const serviceRef = useRef(useSubscriptionService());
  const [loadingCurrent, setLoadingCurrent] = useState(false);
  const [error, setError] = useState<ServiceError>();
  const { isMobile } = useResponsiveContext();

  const showConfirmation = useConfirmation();

  function removeSubscription(id: number) {
    setLoadingCurrent(true);
    serviceRef.current
      .removeSubscription(id)
      .then(() => {
        reload();
      })
      .catch((err) => {
        console.error(err);
        setError(new ResponseError(err));
      })
      .finally(() => setLoadingCurrent(false));
  }

  const handleRemoveSubscription = (id: number) => {
    showConfirmation({
      message: t('alerts.confirmation.confirmRemoveSubscription'),
      actionLabel: 'Tak',
      actionButtonColor: 'danger',
      actionCallback: () => removeSubscription(id)
    });
  };

  return (
    <SectionWrapper loading={loading || loadingCurrent} hideSpinner>
      <div className="card">
        <div className="card-body pb-1">
          <small className="text-muted float-right">{t('subscriptions.table.title')}</small>
          <div className="dataTables_wrapper dt-bootstrap4 no-footer">
            <div className="row">
              <div className="col-sm-12 table-responsive">
                <table className="table w-100 no-footer table-hover table-sm">
                  <thead>
                    <tr role="row">
                      <th className={'text-center'} style={{ width: 50 }}>
                        {t('companies.table.id')}
                      </th>
                      <th>{t('subscriptions.table.type')}</th>
                      <th>{t('subscriptions.table.status')}</th>
                      <th className={'text-center'}>{t('subscriptions.table.period')}</th>
                      {isMobile ? null : (
                        <>
                          <th className={'text-center'}>{t('subscriptions.table.created')}</th>
                          <th className={'text-center'}>{t('subscriptions.table.validToDate')}</th>
                          <th className={'text-center'}>{t('subscriptions.table.trialStarted')}</th>
                          <th className={'text-center'}>{t('subscriptions.table.trialEnded')}</th>
                          <th className={'text-center'}>{t('common.ACTIONS')}</th>
                        </>
                      )}
                    </tr>
                  </thead>
                  <tbody>
                    {pagination.getCurrentResults().map((subscription) => {
                      return (
                        <SubscriptionRow
                          subscription={subscription}
                          key={subscription.id}
                          remove={handleRemoveSubscription}
                        />
                      );
                    })}
                    {pagination.getCurrentResults().length === 0 ? (
                      <tr>
                        <td colSpan={100} className="text-center">
                          Brak danych
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
          <FormErrorMessage error={error} messageType={'details'} />
        </div>
        <PaginationComponent pagination={pagination} />
      </div>
    </SectionWrapper>
  );
};
