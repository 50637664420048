import React from 'react';
import { CompanyInfoList } from '../../types';
import { useTranslation } from 'react-i18next';
import { useResponsiveContext } from '../../../platform/ResponsiveContext';
import { SubscriptionStatus } from '../../../../types/Subscription';

interface IProps {
  company: CompanyInfoList;
  onClick: () => void;
}

export const CompanyRow: React.FC<IProps> = ({ company, onClick }) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsiveContext();

  function getColorByStatus(status: SubscriptionStatus) {
    switch (status) {
      case SubscriptionStatus.ACTIVATED:
        return 'text-green';
      case SubscriptionStatus.PAST_DUE:
        return 'text-danger';
      case SubscriptionStatus.DISABLED:
        return 'text-info';
      case SubscriptionStatus.UPGRADED:
        return 'text-muted';
      case SubscriptionStatus.TRIAL:
        return 'text-warning';
    }
  }

  return (
    <tr className="odd parent" style={{ cursor: 'pointer' }} onClick={onClick}>
      <td>{company.name}</td>
      <td>
        {company.subscriptionType ? t(`plans.subscription.type.${company.subscriptionType}`) : '-'}
      </td>
      {isMobile ? null : (
        <td>
          {company.subscriptionPeriod
            ? t(`plans.subscription.period.${company.subscriptionPeriod}`)
            : '-'}
        </td>
      )}
      <td className={getColorByStatus(company.subscriptionStatus)}>
        {company.subscriptionStatus
          ? t(`plans.subscription.status.${company.subscriptionStatus}`)
          : '-'}
      </td>
      <td className="text-center text-warning">{company.hasActiveTrial ? 'T' : ''}</td>
      {isMobile ? null : <td>{company.created.split('T').join(' ')}</td>}
      {isMobile ? null : <td>{company.subscriptionValidToDate?.toString()}</td>}
    </tr>
  );
};
