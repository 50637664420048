import React from 'react';
import { BoxWithGrapes } from '../types/BoxWithGrapes';
import { Button } from '../../../../../../../components/common/buttons/Button';

interface IProps {
  box: BoxWithGrapes;
  disposed: boolean;
  remove: (box: BoxWithGrapes) => void;
  reloadHarvest: () => void;
}

export const BoxInHarvestRow: React.FC<IProps> = ({ box, disposed, remove, reloadHarvest }) => {
  return (
    <tr className="odd parent" style={{ cursor: 'pointer' }}>
      <td className="text-center">
        {Math.round((box?.weightOfFullBox - box?.weightOfEmptyBox) * 100) / 100}
      </td>
      <td className="text-center">{box.weightOfEmptyBox || 0}</td>
      <td className="text-center">{box.weightOfFullBox || 0}</td>
      <td className="text-center" style={{ padding: '5px', textAlign: 'center' }} key={5}>
        {!disposed ? (
          <Button.Delete
            onClick={() => {
              remove(box);
              reloadHarvest();
            }}
          />
        ) : null}
      </td>
    </tr>
  );
};
