import { useElementToShow } from '../../../../../../hooks/useElementToShow';
import { useGenericCRUDEntityService } from '../../../../../../hooks/useGenericCRUDEntityService';
import { Service, ServiceError } from '../../../../../../../../types/Service';

export const useCRUDListBoxContainer = <ENTITY, LIST>(
  list: LIST[],
  removeItemCallback,
  setResult: (value: Service<ENTITY>) => void,
  setError?: (value: ServiceError) => void
): {
  list: LIST[];
  elementToShow;
  actions: { toggleShow; removeElement; editElement; infoElement };
  loading;
} => {
  const [elementToShow, toggleShow] = useElementToShow();
  const { removeElement, editElement, infoElement, loading } = useGenericCRUDEntityService<ENTITY>(
    removeItemCallback,
    setResult,
    setError
  );

  const actions = { toggleShow, removeElement, editElement, infoElement };

  return { list, elementToShow, actions, loading };
};
