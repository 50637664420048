import React from 'react';
import Select from 'react-select';
import { Tank } from '../../../../tank/types/Tank';
import { SelectOption } from '../../../../../../../types/SelectOption';
import { ServiceError } from '../../../../../../../types/Service';
import useStorageTanksReceiver from '../../../hooks/useStorageTanksReceiver';
import {
  NumberOdColumnsInRow,
  useFormFieldColumnCount
} from '../../../../../../home/components/TaxBandsSummaryPage/hooks/useFormFieldColumnCount';
import { useTranslation } from 'react-i18next';
import { useThemeContext } from '../../../../../../platform/ThemeContext';

interface Props {
  value: Tank | SelectOption;
  onChange: (selected: { value: number }) => void;
  name: string;
  label?: string;
  optional?: boolean;
  disabled?: boolean;
  error?: ServiceError;
  tanks: Tank[];
  numberOfColumnsInRow?: NumberOdColumnsInRow;
}

const SelectTankInStorage: React.FC<Props> = ({
  value: selected,
  onChange,
  name,
  label,
  optional,
  disabled,
  error,
  tanks,
  numberOfColumnsInRow = 1
}) => {
  const { selected: value, options } = useStorageTanksReceiver(tanks, selected);
  const numberOfColumnsClass = useFormFieldColumnCount({ numberOfColumnsInRow });
  const { t } = useTranslation();
  const { selectDarkStyles } = useThemeContext();

  if (label) {
    return (
      <div className={'mb-3 ' + numberOfColumnsClass}>
        <label htmlFor={name}>
          {label}
          <span className="text-danger" style={{ display: optional === true ? 'none' : '' }}>
            {' '}
            *
          </span>
        </label>
        <Select
          name={name}
          options={options}
          onChange={onChange}
          value={value}
          placeholder={'Wybierz'}
          noOptionsMessage={() => t('select.noOptions.message')}
          styles={selectDarkStyles(error?.hasError?.(name))}
          isDisabled={disabled}
          isOptionDisabled={(option) => {
            if (typeof option !== 'string') {
              return option.lock;
            }
            return false;
          }}
        />
        <span
          className="invalid-feedback"
          style={{ display: error?.hasError?.(name) ? 'block' : 'none' }}
        >
          {error?.getErrorMessage?.(name)}
        </span>
      </div>
    );
  }
  return (
    <>
      <Select
        name={name}
        options={options}
        onChange={onChange}
        value={value}
        isDisabled={disabled}
        noOptionsMessage={() => t('select.noOptions.message')}
        placeholder={'Wybierz'}
        styles={selectDarkStyles(error?.hasError?.(name))}
      />
      <span
        className="invalid-feedback"
        style={{ display: error?.hasError?.(name) ? 'block' : 'none' }}
      >
        {error?.getErrorMessage?.(name)}
      </span>
    </>
  );
};

export default SelectTankInStorage;
