import { Service, ServiceError, StatusType } from '../../../types/Service';
import { ResponseError } from '../../error/ResponseError';
import { useTranslation } from 'react-i18next';
import { usePushHistory } from '../../../hooks/usePushHistory';
import { useConfirmation } from '../../notifications/useConfirmation';
import { useState } from 'react';

export const useGenericCRUDEntityService = <T>(
  serviceCallback: (id: number, entity?: T) => Promise<T>,
  setResult: (value: Service<T>) => void,
  setError?: (value: ServiceError) => void
) => {
  const { pushHistory } = usePushHistory();
  const { t } = useTranslation();
  const showConfirmation = useConfirmation();
  const [loading, setLoading] = useState(false);

  const removeElement = (elementId: number) => {
    showConfirmation({
      message: t('alerts.confirmation.REMOVE_ELEMENT'),
      actionLabel: 'Usuń',
      actionCallback: () => handleRemove(elementId)
    });
  };

  function handleRemove(elementId: number) {
    setResult({ status: StatusType.loading });
    serviceCallback(elementId)
      .then((response) => {
        setResult({ status: StatusType.loaded, payload: response });
      })
      .catch((error) => {
        setResult(new ResponseError(error));
        setError?.(new ResponseError(error));
      })
      .finally(() => setLoading(false));
  }

  const editElement = (entityName, elementId) => {
    pushHistory(`/mv/${entityName}/${elementId}/`);
  };
  const infoElement = (entityName, elementId) => {
    pushHistory(`/mv/${entityName}/${elementId}/`);
  };

  return { removeElement, editElement, infoElement, loading };
};
