import useCrudeApi from '../../../../../lib/useCrudeApi';
import { Harvest } from '../types/Harvest';
import { useParams } from 'react-router-dom';
import { BoxWithGrapes } from '../components/HarvestInfoRedesigned/types/BoxWithGrapes';

const useHarvestService = () => {
  const { harvestId } = useParams<{ harvestId?: string }>();

  const crudeApi = useCrudeApi<Harvest>('/ajax/harvests');
  const { get, getAll, getList, post, put, del } = crudeApi;
  const { putId: dispose } = useCrudeApi<Harvest>('/ajax/harvests/dispose');
  const { put: putGrapes, post: postGrapes } = useCrudeApi<Harvest>('/ajax/harvests/grapes');
  const { putId: revertDispose } = useCrudeApi<Harvest>('/ajax/harvests/revert-dispose');
  const { putBody: addBox } = useCrudeApi<BoxWithGrapes>(`/ajax/harvests/${harvestId}/box`);
  const { putId: archive } = useCrudeApi<Harvest>('/ajax/harvests/archive');
  const { putId: revertArchive } = useCrudeApi<Harvest>('/ajax/harvests/revert-archive');

  const getAvailableList = () => {
    return crudeApi.getAllWithParams(new Map<string, boolean>([['available', true]]));
  };

  return {
    get,
    getAll,
    getList,
    getAvailableList,
    post,
    put,
    del,
    addBox,
    dispose,
    revertDispose,
    archive,
    revertArchive,
    putGrapes,
    postGrapes
  };
};

export default useHarvestService;
