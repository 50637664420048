import React, { useEffect } from 'react';
import { useGrapevineContext } from '../../context/GrapevineContext';
import useGrapevineService from '../../hooks/useGrapevineService';
import { useParams } from 'react-router-dom';
import { ServiceError, StatusType } from '../../../../../../types/Service';
import { ResponseError } from '../../../../../error/ResponseError';
import log from 'loglevel';
import { useEventHandlerActions } from '../../../../hooks/useEventHandlerActions';
import { RouterParams } from '../../../../../../types/RouterParams';
import { SESSION_STORAGE_KEY, useStorageSession } from '../../../../../../hooks/useStorageSession';
import { Grapevine } from '../../types/Grapevine';
import { usePushHistory } from '../../../../../../hooks/usePushHistory';
import { HISTORY_FALLBACK_TO } from '../../../../../../services/Constants';

const GrapevineFormContainer = ({ render }) => {
  const { grapevine, updateGrapevine, setGrapevineResult, grapevineResult } = useGrapevineContext();

  const service = useGrapevineService();

  const { grapevineId } = useParams<RouterParams>();
  const { goBack } = usePushHistory();

  const storageSession = useStorageSession<Grapevine>({
    entity: grapevine,
    key: SESSION_STORAGE_KEY.CREATE_GRAPEVINE,
    shouldSave: grapevineId?.toString() === '0'
  });

  const {
    updateDate,
    onChange: handleUpdateGrapevine,
    updateSelect: handleUpdateSelect
  } = useEventHandlerActions(updateGrapevine);

  const updateParcelsInGrapevine = (value) => {
    const parcels = (value || []).map((v) => ({ id: v.value, label: v.label }));
    updateGrapevine('parcels', parcels);
  };

  useEffect(() => {
    setGrapevineResult({ status: StatusType.loading });
    if (grapevineId?.toString() === '0') {
      const payload = storageSession.getItemPayload();
      setGrapevineResult({ status: StatusType.loaded, payload });
      return;
    }
    grapevineId &&
      service
        .get(parseInt(grapevineId))
        .then((response) => {
          setGrapevineResult({ status: StatusType.loaded, payload: response });
        })
        .catch((response) => setGrapevineResult(new ResponseError(response) as ServiceError));

    return () => {
      updateGrapevine('reset', '');
    };
  }, [grapevineId]); // eslint-disable-line react-hooks/exhaustive-deps

  const onSubmit = (e: React.MouseEvent<HTMLButtonElement>) => {
    e.preventDefault();
    log.debug('GrapevineFormContainer:onSubmit', e, grapevine);
    setGrapevineResult({ status: StatusType.loading });

    const action = () =>
      grapevine?.id ? service.put(grapevine.id, grapevine) : service.post(grapevine);
    action()
      .then((response) => {
        storageSession.removeItem();
        setGrapevineResult(response);
        goBack(HISTORY_FALLBACK_TO.VINEYARD_SUMMARY);
      })
      .catch((response) => setGrapevineResult(new ResponseError(response) as ServiceError));
  };

  const onClickBack = () => {
    goBack(HISTORY_FALLBACK_TO.GRAPEVINE_ARCHIVED);
  };

  const error = grapevineResult as ServiceError;

  log.debug('GrapevineFormContainer::render', grapevine);
  return render(
    updateParcelsInGrapevine,
    onSubmit,
    error,
    grapevine,
    handleUpdateGrapevine,
    handleUpdateSelect,
    grapevineResult.status === StatusType.loading,
    onClickBack,
    updateDate
  );
};

export default GrapevineFormContainer;
