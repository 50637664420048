import { FC } from 'react';
import { useCRUDListBoxContainer } from '../../../../wine/components/WineInfo/list-box/shared/useCRUDListBoxContainer';

import { TaxBandsPlacement, WineStorage } from '../../../types/WineStorage';
import { useWineStorageContext } from '../../../context/WineStorageContext';

export interface WineStorageTaxBandsPlacementsListBoxProps {
  taxBandsPlacements: TaxBandsPlacement[];
  delOutput: (outputId: number, entity: TaxBandsPlacement) => void;
  disabledActions?: boolean;
  entityId?: number;
  title: string;
  loading: boolean;
}

type Props = WineStorageTaxBandsPlacementsListBoxProps & {
  render: (list, elementToShow, actions, loading) => JSX.Element;
};

export const WineStorageTaxBandPlacementsListBoxContainer: FC<Props> = ({
  render,
  taxBandsPlacements,
  delOutput
}) => {
  const { setResult, setError } = useWineStorageContext();
  const { list, elementToShow, actions, loading } = useCRUDListBoxContainer<
    WineStorage,
    TaxBandsPlacement
  >(taxBandsPlacements, delOutput, setResult, setError);

  const selectedActions = {
    editElement: actions.editElement,
    removeElement: actions.removeElement,
    toggleShow: actions.toggleShow
  };

  return render(list, elementToShow, selectedActions, loading);
};
