import React from 'react';
import { SelectOption } from '../../../../types/SelectOption';
import { ServiceError } from '../../../../types/Service';
import { SubscriptionType } from '../../../../types/Subscription';
import { useSubscriptionTypeReceiver } from '../../hooks/useSubscriptionTypeReceiver';
import { SectionWrapper } from '../../../../layouts/SectionWrapper';
import { SelectTypeColumn } from '../../../../components/forms/SelectTypeColumn';
import { NumberOdColumnsInRow } from '../../../home/components/TaxBandsSummaryPage/hooks/useFormFieldColumnCount';
import { SelectType } from '../../../../components/common/form-elements/SelectType';

interface Props {
  value?: SubscriptionType | SelectOption | null;
  onChange: (name: string, s: Record<string, string>) => void;
  name: string;
  label?: string;
  optional?: boolean;
  error?: ServiceError;
  disabled?: boolean;
  loading?: boolean;
  selectType?: 'normal' | 'column';
  numberOfColumnsInRow?: NumberOdColumnsInRow;
}

const SelectSubscriptionType: React.FC<Props> = ({
  value,
  onChange,
  name,
  label,
  optional,
  error,
  disabled,
  loading,
  selectType = 'normal',
  numberOfColumnsInRow
}) => {
  return (
    <>
      {selectType === 'normal' ? (
        <SectionWrapper loading={loading}>
          <SelectType
            onChange={onChange}
            name={name}
            receiver={useSubscriptionTypeReceiver}
            error={error}
            optional={optional}
            label={label}
            value={value}
            disabled={disabled}
            clazzName={'pb-0 mb-0'}
          />
        </SectionWrapper>
      ) : (
        <SelectTypeColumn
          onChange={onChange}
          name={name}
          receiver={useSubscriptionTypeReceiver}
          error={error}
          optional={optional}
          label={label}
          value={value}
          disabled={disabled}
          clazzName={'pb-0 mb-0'}
          numberOfColumnsInRow={numberOfColumnsInRow}
        />
      )}
    </>
  );
};

export default SelectSubscriptionType;
