import React from 'react';
import { Subscription, SubscriptionStatus } from '../../../../types/Subscription';
import { useTranslation } from 'react-i18next';
import { useResponsiveContext } from '../../../platform/ResponsiveContext';

interface IProps {
  subscription: Subscription;
  remove: (id: number) => void;
}

export const SubscriptionRow: React.FC<IProps> = ({ subscription, remove }) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsiveContext();

  function getColorByStatus() {
    switch (subscription.status) {
      case SubscriptionStatus.ACTIVATED:
        return 'text-green';
      case SubscriptionStatus.PAST_DUE:
        return 'text-danger';
      case SubscriptionStatus.DISABLED:
        return 'text-info';
      case SubscriptionStatus.UPGRADED:
        return 'text-muted';
      case SubscriptionStatus.TRIAL:
        return 'text-warning';
    }
  }

  return (
    <tr className="odd parent">
      <td className={'text-center'}>{subscription.id}</td>
      <td>{t(`plans.subscription.type.${subscription.type}`)}</td>
      <td className={getColorByStatus()}>
        {t(`plans.subscription.status.${subscription.status}`)}
      </td>
      <td className={'text-center'}>{t(`plans.subscription.period.${subscription.period}`)}</td>
      {isMobile ? null : (
        <>
          <td className={'text-center'}>{subscription.created?.split('T').join(' ')}</td>
          <td className={'text-center'}>{subscription.validToDate?.split('T').join(' ')}</td>
          <td className={'text-center'}>{subscription.trialStarted?.split('T').join(' ')}</td>
          <td className={'text-center'}>{subscription.trialEnded?.split('T').join(' ')}</td>
          <td className={'text-center'}>
            <em
              style={{ cursor: 'pointer' }}
              className="fa fa-trash fa-fw text-danger"
              onClick={() => remove(subscription.id)}
            />
          </td>
        </>
      )}
    </tr>
  );
};
