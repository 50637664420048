import React, { ChangeEvent } from 'react';
import { ServiceError } from '../../types/Service';
import { useFormFieldColumnCount } from '../../features/home/components/TaxBandsSummaryPage/hooks/useFormFieldColumnCount';

interface Props {
  label?: string;
  name: string;
  type?: 'text' | 'number';
  placeholder?: string;
  value: string | number;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  error?: ServiceError;
  numberOfColumnsInRow?: 1 | 2 | 3 | 4 | 6;
  disabled?: boolean;
  optional?: boolean;
  maxSize?: string | number;
  maxLength?: number;
}

export const InputColumn: React.FC<Props> = ({
  label,
  name,
  type = 'text',
  placeholder,
  value,
  error,
  onChange,
  numberOfColumnsInRow = 1,
  disabled,
  optional,
  maxSize,
  maxLength
}) => {
  const numberOfColumnsClass = useFormFieldColumnCount({ numberOfColumnsInRow });

  return (
    <div className={'mb-3 ' + numberOfColumnsClass}>
      {label ? (
        <label htmlFor={name}>
          {label}
          <span className="text-danger" style={{ display: optional === true ? 'none' : '' }}>
            {' '}
            *
          </span>
        </label>
      ) : null}
      <input
        className={'form-control ' + (error?.hasError?.(name) ? 'is-invalid' : '')}
        id={name}
        name={name}
        type={type}
        placeholder={placeholder}
        defaultValue={value}
        onChange={onChange}
        disabled={disabled}
        max={maxSize}
        maxLength={maxLength || maxSize ? Number(maxSize) : undefined}
      />
      <span
        className="invalid-feedback"
        style={{ display: error?.hasError?.(name) ? 'block' : 'none' }}
      >
        {error?.getErrorMessage?.(name)}
      </span>
    </div>
  );
};
