import React from 'react';
import { Button, Card, CardBody, CardFooter, CardHeader, Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Harvest } from '../../types/Harvest';
import CommonRow from '../../../../../../components/common/table/CommonRow';
import { ServiceError } from '../../../../../../types/Service';
import { FormErrorMessage } from '../../../../../../components/notifications/FormErrorMessage';
import SimpleInputElement from '../../../../../../components/common/SimpleInputElement';
import { SectionWrapper } from '../../../../../../layouts/SectionWrapper';
import { HarvestInfoButtons } from './hooks/useHarvestInfo';
import { ListActionsButtons } from '../../../../../../components/common/ListActionButtons';
import { SubscriptionType } from '../../../../../../types/Subscription';
import { ReportType, useGetPdf } from '../../../../../home/hooks/useGetPdf';
import { PdfButtonComponent } from '../../../../../reports/components/PdfButtonComponent';

interface IProps {
  harvest: Harvest;
  buttons: HarvestInfoButtons;
  updateBox: (name: string, value: string) => void;
  addBoxToHarvest: (e: React.MouseEvent<HTMLInputElement>) => void;
  error: ServiceError;
  loading: boolean;
}

export const HarvestSummaryCard: React.FC<IProps> = ({
  harvest,
  buttons: { editButton, removeButton, disposeButton, archiveButton },
  updateBox,
  addBoxToHarvest,
  error,
  loading
}) => {
  const { t } = useTranslation();

  const { getPdf, loading: loadingPdf } = useGetPdf({
    subscriptionType: SubscriptionType.NORMAL,
    reportType: ReportType.HARVEST_SUMMARY,
    id: harvest.id
  });

  const buttons = {
    proceed: editButton,
    dispose: disposeButton,
    remove: removeButton,
    archive: archiveButton,
    revertArchive: archiveButton
  };

  return (
    <SectionWrapper loading={loading}>
      <Card>
        <CardHeader className={'card-header ' + (harvest.allDisposedToWine ? '' : 'pb-0')}>
          {harvest?.grapevine?.name || harvest.varietyName ? (
            <div className="card-title">
              {(harvest?.grapevine?.name || harvest.varietyName) + ' '}{' '}
              <span className="text-muted">{harvest?.dateOfHarvest?.toString()}</span>
            </div>
          ) : (
            <div className="card-title">Zbiór</div>
          )}
        </CardHeader>
        <CardBody>
          {harvest?.id && !harvest.fromOtherVineyard && !harvest?.allDisposedToWine ? (
            <>
              <div className={'row mb-1'} style={{ paddingBottom: '17.5px' }}>
                <div className="col-6 float-left">
                  <SimpleInputElement
                    type="number"
                    name="weightOfFullBox"
                    maxSize="100"
                    onChange={(e) => updateBox(e.target.name, e.target.value)}
                    disabled={harvest?.allDisposedToWine}
                    autoFocus={true}
                    onKeyPress={(e) => e.key === 'Enter' && addBoxToHarvest?.(e)}
                  />
                </div>
                <div className="col-6 float-right">
                  <Button
                    color="info"
                    disabled={harvest?.allDisposedToWine}
                    className="btn-square"
                    style={{ width: '100%' }}
                    onClick={addBoxToHarvest}
                  >
                    Dodaj kg
                  </Button>
                </div>
              </div>
              <FormErrorMessage
                error={error}
                messageType={'fieldError'}
                fieldName={'weightOfFullBox'}
              />
            </>
          ) : null}
          <Table>
            <tbody>
              <CommonRow
                label={t('harvest.WEIGHT_OF_GRAPES')}
                value={`${harvest.weightOfGrapes || 0}`}
              />
              <CommonRow
                label={t('harvest.AMOUNT_OF_MUST')}
                value={`${harvest.amountOfMust || 0}`}
              />
              {harvest.amountOfWaste ? (
                <CommonRow
                  label={t('harvest.AMOUNT_OF_WASTE')}
                  value={`${harvest.amountOfWaste || 0}`}
                />
              ) : null}
            </tbody>
          </Table>
          <CardFooter className="text-center bg-secondary">
            <div className="mr-1 float-right">
              <ListActionsButtons entity={harvest} buttons={buttons} />
            </div>
            <div className="float-left">
              <PdfButtonComponent
                reportAvailable={true}
                getPdf={getPdf}
                loading={loadingPdf}
                subscriptionTypeNeeded={SubscriptionType.NORMAL}
              />
            </div>
          </CardFooter>
        </CardBody>
        <div className="px-4 text-center bg-secondary">
          {error && <FormErrorMessage error={error} messageType={'details'} />}
        </div>
      </Card>
    </SectionWrapper>
  );
};
