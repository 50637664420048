import { Color } from '../../../../components/common/enums/Color';
import TimelineSeparator from '../../../../components/common/Timeline/TimelineSeparator';
import React from 'react';

export const SubscriptionTimeLineItem: React.FC<{
  header: string;
  subheader: string;
  icon?: string;
  iconColor?: Color;
}> = ({ header, subheader, icon = 'icon-present', iconColor }) => {
  return (
    <>
      <li className={'timeline-inverted mt-0'} style={{ width: '95%' }}>
        <div
          className={'timeline-badge border ' + (iconColor || 'bg-secondary')}
          style={{ left: '0%' }}
        >
          <em className={icon + (iconColor ? '' : ' text-gradient-blue-purple')} />
        </div>
        <div className="timeline-card">
          <div className="popover right bg-light">
            <h5 className="popover-header bg-secondary text-gradient-blue-purple">{header}</h5>
            {/*<div className="arrow" />*/}
            <div className="popover-body bg-light">
              <p className={'text-sm'} style={{ whiteSpace: 'pre-wrap' }}>
                {subheader}
              </p>
            </div>
          </div>
        </div>
      </li>
      <TimelineSeparator className={'p-0'} style={{ height: '0px' }} />
    </>
  );
};
