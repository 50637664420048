import React, { useCallback, useEffect, useState } from 'react';
import PageWrapper from '../../../../layouts/PageWrapper';
import { CompanyInfoData, UserData } from '../../types';
import useAdminService from '../../hooks/useAdminService';
import { UsersTable } from '../users/UsersTable';
import { useParams } from 'react-router-dom';
import { RouterParams } from '../../../../types/RouterParams';
import { CompanySummary } from './CompanySummary';
import { CompanySubscription } from './CompanySubscription';
import { CompanySubscriptionCard } from './CompanySubscriptionCard';
import { CompanyAccessInfoCard } from './CompanyAccessInfoCard';
import { CompanyPaymentInfoCard } from './CompanyPaymentInfoCard';
import { PaymentsTable } from '../payments/PaymentsTable';
import { SubscriptionsTable } from '../subscriptions/SubscriptionsTable';
import log from 'loglevel';
import { usePushHistory } from '../../../../hooks/usePushHistory';

export const CompanyPage = () => {
  const [company, setCompany] = useState<CompanyInfoData>(null);
  const service = useAdminService();
  const [loading, setLoading] = useState(false);
  const { companyId } = useParams<RouterParams>();
  const { pushHistory } = usePushHistory();

  const getCompany = useCallback(() => {
    setLoading(true);
    service
      .get(companyId)
      .then((response) => {
        setCompany(response);
      })
      .finally(() => setLoading(false));
  }, [companyId, setCompany, setLoading]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    getCompany();
  }, [getCompany]);

  function deactivateCompany(id: number) {
    setLoading(true);
    service
      .deactivateCompany(id)
      .then(() => {
        getCompany();
      })
      .catch((error) => log.error(error))
      .finally(() => setLoading(false));
  }

  const activateCompany = useCallback(
    (id: number) => {
      setLoading(true);
      service
        .activateCompany(id)
        .then(() => {
          getCompany();
        })
        .finally(() => setLoading(false));
    },
    [getCompany, service]
  );

  const deactivateUser = useCallback(
    (id: number) => {
      setLoading(true);
      service
        .deactivateUser(id)
        .then(() => {
          getCompany();
        })
        .catch((error) => log.error(error))
        .finally(() => setLoading(false));
    },
    [getCompany, service]
  );

  const activateUser = useCallback(
    (id: number) => {
      setLoading(true);
      service
        .activateUser(id)
        .then((response) => setCompany(response))
        .catch((error) => log.error(error))
        .finally(() => setLoading(false));
    },
    [service]
  );

  const reloadPage = useCallback(() => {
    getCompany();
  }, [getCompany]);

  function addNewUser(user: UserData) {
    return service
      .addNewUser(user)
      .then((response) => setCompany(response))
      .catch((error) => log.error(error));
  }

  if (company === null || company === undefined)
    return <PageWrapper title={'admin.PAGE'} loading={true} />;
  const { subscriptionInfo } = company;

  return (
    <PageWrapper
      title={'sidebar.nav.element.COMPANY'}
      onClick={() => pushHistory('/admin_console/companies/active')}
      returnText={'sidebar.nav.element.COMPANIES'}
    >
      <div className={'row'}>
        <div className="col-lg-4 col-md-6">
          <CompanySummary
            company={company}
            actions={{ activateCompany, deactivateCompany }}
            loading={loading}
          />
          {subscriptionInfo?.accessInfo ? (
            <CompanyAccessInfoCard
              accessInfo={subscriptionInfo?.accessInfo}
              title={'Informacje o dostępach'}
              loading={loading}
            />
          ) : null}
          {subscriptionInfo?.paymentInfo ? (
            <CompanyPaymentInfoCard
              paymentInfo={subscriptionInfo?.paymentInfo}
              title={'Aktualna płatność'}
              loading={loading}
            />
          ) : null}
          <CompanySubscription company={company} reload={reloadPage} />
          {subscriptionInfo?.activeSubscription ? (
            <CompanySubscriptionCard
              subscription={subscriptionInfo?.activeSubscription}
              title={'Aktywna'}
              loading={loading}
            />
          ) : null}
          {subscriptionInfo?.inProgressSubscription ? (
            <CompanySubscriptionCard
              subscription={subscriptionInfo?.inProgressSubscription}
              title={'W trakcie'}
              loading={loading}
            />
          ) : null}
        </div>
        <div className="col-lg-8 col-md-6">
          <UsersTable
            users={company.users || []}
            loading={loading}
            actions={{ deactivateUser, activateUser, addNewUser }}
            key={company?.users?.length + (company.active + '')}
          />
          <PaymentsTable payments={company.payments} loading={loading} />
          <SubscriptionsTable
            subscriptions={company.subscriptions}
            reload={reloadPage}
            loading={loading}
          />
        </div>
      </div>
    </PageWrapper>
  );
};
