import React from 'react';
import { PlanSection } from './PlanSection';
import { PlanItem } from './PlanItem';
import { useAuthContext } from '../../../platform/AuthContext';
import PageWrapper from '../../../../layouts/PageWrapper';
import { useGetPlans } from '../../hooks/useGetPlans';
import { useResponsiveContext } from '../../../platform/ResponsiveContext';
import { SectionWrapper } from '../../../../layouts/SectionWrapper';

export const PlansPage = () => {
  const {
    subscriptionInfo: { activeSubscription }
  } = useAuthContext();
  const { plans } = useGetPlans();

  const { isMobile } = useResponsiveContext();

  return (
    <PageWrapper disabled>
      <div className="px-4 center">
        <div className="text-center mb-3 py-5 bg-secondary rounded-bottom">
          <div className="h2 text-bold">
            Dobrze dopasowany plan pomoże Ci w stworzeniu{' '}
            <span className={'text-gradient-blue-purple'}>idealnego wina</span>
          </div>
        </div>
        <div className={'row pt-4 justify-content-center'}>
          {plans?.[0]?.description ? (
            plans
              ?.filter((plan) => {
                if (activeSubscription) {
                  return plan.id !== 0;
                }
                return true;
              })
              .map((plan) => {
                return (
                  <div
                    className={'col-lg-4'}
                    style={{ maxWidth: isMobile ? '400px' : '305px' }}
                    key={plan.id}
                  >
                    <PlanSection
                      title={plan.name}
                      type={plan.subscriptionType}
                      description={plan.description}
                      extraInfo={plan.extraInfo}
                      buttonLabel={plan.buttonLabel}
                      color={plan.color}
                      key={plan.id}
                      annualPricePerMonth={plan.annualPricePerMonth}
                    >
                      <div className={'text-bold mb-3'}>{plan.containsItems[0]}</div>
                      {plan.containsItems.map(
                        (item, index) =>
                          index !== 0 && <PlanItem title={item} include={true} key={index} />
                      )}
                    </PlanSection>
                  </div>
                );
              })
          ) : (
            <SectionWrapper loading={true}>
              <div style={{ height: '60vh' }}></div>
            </SectionWrapper>
          )}
        </div>
        <p className="text-center text-muted">
          <small></small>
        </p>
      </div>
    </PageWrapper>
  );
};
