import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RouterParams } from '../../../../../types/RouterParams';
import log from 'loglevel';
import { DiscountCardReadModel, useDiscountService } from '../../../hooks/useDiscountService';

export const defaultDiscountCard: DiscountCardReadModel = {
  discountCardId: undefined,
  code: undefined,
  name: undefined,
  discountPrice: undefined,
  discountPercent: undefined,
  usageCount: undefined,
  maxUsageCount: undefined,
  subscriptionType: undefined,
  createdDate: undefined,
  startDate: undefined,
  validDate: undefined,
  usedDate: undefined,
  subscriberId: undefined,
  subscriberName: undefined,
  valid: undefined
};

export function useSelectedDiscountCard({
  discountCards
}: {
  discountCards: DiscountCardReadModel[];
}): [
  selectedDiscountCard: DiscountCardReadModel,
  selectDiscountCard: ({ code, card }: { code: string; card?: DiscountCardReadModel }) => void
] {
  const [selectedDiscountCard, setSelectedDiscountCard] =
    useState<DiscountCardReadModel>(defaultDiscountCard);
  const { discountCardCode } = useParams<RouterParams>();
  const service = useDiscountService();

  const findDisountCard = useCallback((code: string) => {
    code &&
      service
        .get(code)
        .then((card) => setSelectedDiscountCard(card))
        .catch((error) => log.error(error));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const selectDiscountCard = useCallback(
    ({ code, card }: { code: string; card?: DiscountCardReadModel }) => {
      if (code === null) {
        setSelectedDiscountCard(defaultDiscountCard);
        return;
      }
      if (discountCardCode) {
        findDisountCard(code);
      } else if (card) {
        setSelectedDiscountCard(card);
      } else {
        const bottleFromList = discountCards.find((card) => card.code === code);
        bottleFromList && setSelectedDiscountCard(bottleFromList);
        !bottleFromList && findDisountCard(code);
      }
    },
    [discountCards, findDisountCard, discountCardCode]
  );

  useEffect(() => {
    if (discountCardCode) {
      selectDiscountCard({ code: discountCardCode });
    }
  }, [discountCardCode, selectDiscountCard]);

  return [selectedDiscountCard, selectDiscountCard];
}
