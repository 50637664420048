import React from 'react';

import DatePicker from 'react-datepicker';
import { ServiceError } from '../../types/Service';
import { useTranslation } from 'react-i18next';
import pl from 'date-fns/locale/pl';
import { useFormFieldColumnCount } from '../../features/home/components/TaxBandsSummaryPage/hooks/useFormFieldColumnCount';
import 'react-datepicker/dist/react-datepicker.css';

interface Props {
  label: string;
  name: string;
  value: string | Date;
  error?: ServiceError;
  onChange: (name: string, date: string) => void;
  disabled?: boolean;
  optional?: boolean;
  showTimeInput?: boolean;
  showTimeSelect?: boolean;
  maxDate?: Date;
  minDate?: Date;
  numberOfColumnsInRow?: 1 | 2 | 3 | 4 | 6;
  extraInfo?: string;
}

export const DatePickerColumn: React.FC<Props> = ({
  label,
  name,
  value,
  error,
  onChange,
  disabled,
  optional,
  showTimeInput,
  showTimeSelect,
  maxDate,
  minDate,
  numberOfColumnsInRow,
  extraInfo
}) => {
  const { t } = useTranslation();

  const numberOfColumnsClass = useFormFieldColumnCount({ numberOfColumnsInRow });

  return (
    <div className={'mb-3 ' + numberOfColumnsClass}>
      <label htmlFor={name}>
        {label}
        <span className="text-danger" style={{ display: optional === true ? 'none' : '' }}>
          {' '}
          *
        </span>
      </label>
      <DatePicker
        selected={value ? new Date(value) : ''}
        onChange={(date) => onChange(name, date)}
        timeInputLabel={`${t('common.TIME')}:`}
        dateFormat={'dd.MM.yyyy' + (showTimeSelect ? ' HH:mm' : '')}
        showTimeSelect={showTimeSelect}
        timeFormat="HH:mm"
        timeCaption={t('common.TIME')}
        showTimeInput={showTimeInput}
        timeIntervals={15}
        disabled={disabled}
        minDate={minDate}
        maxDate={maxDate}
        locale={pl}
        className={'form-control ' + (error?.hasError?.(name) ? 'border-danger' : '')}
      />
      <span
        className="invalid-feedback"
        style={{ display: error?.hasError?.(name) ? 'block' : 'none' }}
      >
        {error?.getErrorMessage?.(name)}
      </span>
      {extraInfo ? <small className={'text-muted'}>{extraInfo}</small> : null}
    </div>
  );
};
