import React, { useRef } from 'react';
import { TankOutput } from '../../../types/WineStorage';
import { useTranslation } from 'react-i18next';
import { useWineStorageContext } from '../../../context/WineStorageContext';
import { useTankOutputService } from '../../../hooks/useTankOutputService';
import WineStorageTankOutputsListBox from '../WineStorageTanksOutputsListBox';

export const TankOutputList: React.FC<{ tankOutputs: TankOutput[]; loading: boolean }> = ({
  tankOutputs = [],
  loading
}) => {
  const { wineStorage } = useWineStorageContext();
  const { t } = useTranslation();
  const serviceRef = useRef(useTankOutputService());

  return (
    <>
      {tankOutputs?.length || wineStorage?.id == undefined ? (
        <WineStorageTankOutputsListBox
          tankOutputs={tankOutputs || []}
          delOutput={serviceRef.current.del}
          title={t('wineStorage.tank.output')}
          loading={loading}
        />
      ) : null}
    </>
  );
};
