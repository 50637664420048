import { useCallback, useState } from 'react';
import { DiscountCardReadModel } from '../../../hooks/useDiscountService';

export type DiscountCardUpdateFormFieldValue = { name: string; value: string | number | Date };

export function useUpdateDiscountCard(
  selectedDiscountCard?: DiscountCardReadModel
): [
  bottle: DiscountCardReadModel,
  updateField: ({ name, value }: DiscountCardUpdateFormFieldValue) => void
] {
  const [card, setCard] = useState<DiscountCardReadModel>(selectedDiscountCard || undefined);

  const updateField = useCallback(({ name, value }: DiscountCardUpdateFormFieldValue) => {
    setCard((prev) => ({
      ...prev,
      [name]: value
    }));
  }, []);

  return [card, updateField];
}
