import React, { useCallback, useEffect, useState } from 'react';
import PageWrapper from '../../../../layouts/PageWrapper';
import { PlanData, SubscriptionType } from '../../../../types/Subscription';
import { AdminPlansMenu } from './AdminPlansMenu';
import { AdminPlanTab } from './AdminPlanTab';
import usePlansService from '../../hooks/usePlansService';
import { usePushHistory } from '../../../../hooks/usePushHistory';

export type PlansActiveTab =
  | SubscriptionType.BASIC
  | SubscriptionType.NORMAL
  | SubscriptionType.PREMIUM;

export const AdminPlansPage = () => {
  const [loading, setLoading] = useState(false);
  const [activeTab, setActiveTab] = useState<PlansActiveTab>(SubscriptionType.BASIC);
  const [plans, setPlans] = useState<PlanData[]>([]);
  const { getList } = usePlansService();
  const { pushHistory } = usePushHistory();

  const fetchPlans = useCallback(() => {
    setLoading(true);
    getList()
      .then((res) => setPlans(res))
      .finally(() => setLoading(false));
  }, [getList]);

  useEffect(() => {
    if (!plans.length) {
      fetchPlans();
    }
  }, [plans, fetchPlans]);

  return (
    <PageWrapper
      title={'sidebar.nav.element.PLANS'}
      onClick={() => pushHistory('/admin_console/companies/active')}
      returnText={'sidebar.nav.element.COMPANIES'}
    >
      <div className="row">
        <div className="col-lg-12">
          <div className="container mx-0">
            <div className="row">
              <div className="col-lg-3">
                <AdminPlansMenu setActiveTab={setActiveTab} activeTab={activeTab} />
              </div>
              <div className="col-lg-9">
                <div className="tab-content p-0 b0">
                  <div className={'tab-pane active'}>
                    <AdminPlanTab
                      loading={loading}
                      subscriptionType={activeTab}
                      plan={plans.find((p) => p.subscriptionType === activeTab)}
                      fetchPlans={fetchPlans}
                    />
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </PageWrapper>
  );
};
