import React, { FC } from 'react';
import CommonRow from '../../../../../../../../components/common/table/CommonRow';
import { BoxListActionsButtons } from '../../../../../../../../components/common/buttons/BoxListActionButtons';

interface Props {
  header: (string | React.ReactNode)[];
  actions: ListBoxActions;
  elementToSHow: ListBoxElementToShow;
  path: string;
  elementId?: number | null;
  infoElementId?: number | null;
  dropdownInfo: {
    paragraph?: string;
    footer?: { label: string; value?: string | React.ReactNode };
    node?: React.ReactNode;
  };
  disableActions?: boolean;
  elementToShowKey: number;
  centerFirstColumn?: boolean;
  dropdownClassName?: string;
}

export interface ListBoxActions {
  toggleShow?: (id) => void;
  removeElement?: (id) => void;
  editElement?: (entityName, id) => void;
  infoElement?: (entityName, id) => void;
}

export interface ListBoxElementToShow {
  id?: number;
  isOpen?: boolean;
}

export const CommonListBox: FC<Props> = ({
  header,
  actions: { toggleShow, editElement, removeElement, infoElement },
  disableActions,
  elementToSHow,
  path,
  elementId,
  infoElementId,
  dropdownInfo,
  elementToShowKey,
  centerFirstColumn,
  dropdownClassName = ''
}) => {
  const getClassName = (element: ListBoxElementToShow, key: number, center: boolean) => {
    const isActive = element.id === key && element.isOpen;
    return `${isActive ? 'bg-light ' : ''}${center ? 'text-center' : ''}`.trim();
  };

  return (
    <>
      <CommonRow
        label={header?.[0] as string}
        value={header?.slice(1)}
        onClick={() => toggleShow?.(elementToShowKey)}
        style={{ cursor: 'pointer' }}
        className={getClassName(elementToSHow, elementToShowKey, centerFirstColumn)}
      />
      {elementToSHow.id === elementToShowKey && elementToSHow.isOpen && (
        <tr>
          <td className="bg-light" colSpan={header.length}>
            <div className={'float-left ' + dropdownClassName} style={{ whiteSpace: 'pre-wrap' }}>
              <p>{dropdownInfo?.paragraph || dropdownInfo.node}</p>
              {dropdownInfo?.footer?.value && (
                <p className="m-0">
                  {dropdownInfo?.footer?.label ? `${dropdownInfo?.footer?.label}: ` : ''}{' '}
                  {dropdownInfo?.footer?.value}
                </p>
              )}
            </div>
            <BoxListActionsButtons
              actions={{
                editElement:
                  editElement && !disableActions ? () => editElement?.(path, elementId) : undefined,
                infoElement: infoElement ? () => infoElement?.(path, infoElementId) : undefined,
                removeElement:
                  removeElement && !disableActions ? () => removeElement?.(elementId) : undefined
              }}
            />
          </td>
        </tr>
      )}
    </>
  );
};
