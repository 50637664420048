import React from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import CommonRow from '../../../../../../../../components/common/table/CommonRow';
import CardFooter from '../../../../../../../../components/common/cards/CardFooter';
import { FormErrorMessage } from '../../../../../../../../components/notifications/FormErrorMessage';
import { useIngredientContext } from '../../../../../ingredient/context/IngredientContext';
import { CommonListBox, ListBoxActions, ListBoxElementToShow } from './CommonListBox';
import { SectionWrapper } from '../../../../../../../../layouts/SectionWrapper';
import { BottleEntry } from '../../../../../wine_storage/types/WineStorage';
import usePagination from '../../../../../../../../hooks/usePagination';
import { PaginationComponent } from '../../../../../../../../components/PaginationComponent';
import { BottleInfo } from './TankOutputsListBox';
import { BottleCapacity } from './BottleOutputsListBox';
import { usePushHistory } from '../../../../../../../../hooks/usePushHistory';
import { WineProduction } from '../../../../types/WineProduction';

export const UsedWineProduction = ({
  wineProduction
}: {
  wineProduction?: Partial<WineProduction>;
}) => {
  const { pushHistory } = usePushHistory();
  if (!wineProduction) return <>-</>;
  return (
    <button
      className={'btn btn-link p-0'}
      onClick={() => pushHistory(`/mv/wine_production/info/${wineProduction.id}`)}
    >
      {wineProduction.name}
    </button>
  );
};

interface Props {
  bottleEntries: BottleEntry[];
  elementToShow: ListBoxElementToShow;
  actions: Pick<ListBoxActions, 'toggleShow' | 'infoElement' | 'removeElement'>;
  entityName: string;
  disabledActions?: boolean;
  loading: boolean;
  title: string;
}

const BottleEntriesListBox: React.FC<Props> = ({
  bottleEntries,
  elementToShow,
  actions,
  entityName,
  disabledActions,
  loading,
  title
}) => {
  const { t } = useTranslation();
  const { error } = useIngredientContext();
  const pagination = usePagination(bottleEntries, 10);

  return (
    <SectionWrapper loading={loading} hideSpinner>
      <Card>
        <CardBody>
          <h5 className="m-0 float-right">{title}</h5>
          <small className="text-muted">
            {t('common.SUMMARY')}:{' '}
            {bottleEntries
              ?.map((output) => output.quantity)
              .reduce((total, item) => total + item, 0)}{' '}
            szt.
          </small>
          {bottleEntries?.length ? (
            <Table className={'table w-100 no-footer table-hover'}>
              <tbody>
                <CommonRow
                  label={`${t('bottleEntry.QUANTITY')}`}
                  value={[t('bottleOutput.bottle'), `${t('bottleEntry.wineProduction')}`]}
                  className={'text-center'}
                />
                {(pagination.getCurrentResults() || []).map((entry, key) => {
                  return (
                    <CommonListBox
                      header={[
                        entry.quantity || '',
                        <BottleCapacity bottle={entry.bottle} key={entry.id} />,
                        <UsedWineProduction wineProduction={entry.wineProduction} key={entry.id} />
                      ]}
                      actions={actions}
                      disableActions={disabledActions}
                      elementToSHow={elementToShow}
                      path={`${entityName}/${entry.wineProduction?.id}`}
                      elementId={entry?.id}
                      dropdownInfo={{
                        paragraph: '',
                        footer: {
                          label: '',
                          value: <BottleInfo bottle={entry.bottle} />
                        }
                      }}
                      elementToShowKey={key}
                      key={key}
                      centerFirstColumn
                      dropdownClassName={'pl-2 max-w-80'}
                    />
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <CardBody>
              <div className={'text-center'}>{t('common.NO_DATA')}</div>
            </CardBody>
          )}
          {error?.error?.details && (
            <CardFooter>
              <FormErrorMessage error={error} messageType={'details'} />
            </CardFooter>
          )}
        </CardBody>
        <PaginationComponent pagination={pagination} />
      </Card>
    </SectionWrapper>
  );
};

export default BottleEntriesListBox;
