import { useEffect } from 'react';
import { ServiceError, StatusType } from '../../../../../types/Service';
import { ResponseError } from '../../../../error/ResponseError';
import { useParams } from 'react-router-dom';
import { ProductionEvent } from '../../wine/types/Wine';
import { useProductionEventContext } from '../context/ProductionEventContext';
import { useProductionEventService } from './useProductionEventService';
import { RouterParams } from '../../../../../types/RouterParams';
import { SESSION_STORAGE_KEY, useStorageSession } from '../../../../../hooks/useStorageSession';
import { WineEntity } from '../components/common/withProductionEventToWineServiceHOC';

interface ReturnProps {
  event: ProductionEvent;
  error: ServiceError | undefined;
  loading: boolean;
}

export const useGetProductionEvent = (entity: WineEntity): ReturnProps => {
  const {
    productionEvent: event,
    productionEventResult: result,
    setProductionEventResult: setResult,
    loading
  } = useProductionEventContext();

  const { productionEventId, wineProductionId, wineId } = useParams<RouterParams>();
  const eventService = useProductionEventService();

  const storageSession = useStorageSession<ProductionEvent>({
    entity: event,
    key:
      entity === 'wine'
        ? SESSION_STORAGE_KEY.CREATE_EVENT_FOR_WINE + '_' + wineId
        : SESSION_STORAGE_KEY.CREATE_EVENT_FOR_WINE_PRODUCTION + '_' + wineProductionId,
    shouldSave: !productionEventId
  });

  useEffect(() => {
    if (result.status === StatusType.loading && productionEventId && !event?.type) {
      eventService
        .get(parseInt(productionEventId))
        .then((response) => setResult({ status: StatusType.loaded, payload: response }))
        .catch((response) => setResult(new ResponseError(response)));
    } else if (!productionEventId && result.status === StatusType.loading) {
      const payload = storageSession.getItemPayload();
      setResult({ status: StatusType.loaded, payload });
    }
  });

  const error = result?.status === StatusType.error ? (result as ServiceError) : undefined;

  return { event, error, loading };
};
