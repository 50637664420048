import { SectionWrapper } from '../../../../layouts/SectionWrapper';
import React, { useState } from 'react';
import { AdminPlansHeader } from './AdminPlansHeader';
import { PlanData, PlanPrices, SubscriptionType } from '../../../../types/Subscription';
import { InputColumn } from '../../../../components/forms/InputColumn';
import usePlansService from '../../hooks/usePlansService';

const initPrices = {
  annualPrice: undefined,
  monthlyPrice: undefined
};

export const AdminPlanTab: React.FC<{
  loading: boolean;
  subscriptionType: SubscriptionType;
  plan: PlanData;
  fetchPlans: () => void;
}> = ({ loading, subscriptionType, plan, fetchPlans }) => {
  const [prices, setPrices] = useState<PlanPrices>(initPrices);

  const [showPrices, setShowPrices] = useState(false);

  const changePrice = (name: string, value: number | string) => {
    setPrices((prevState) => ({ ...prevState, [name]: value }));
  };

  const service = usePlansService(subscriptionType);

  const updatePrices = () => {
    service.put(prices).then(() => {
      setPrices(initPrices);
      fetchPlans();
      setShowPrices(false);
    });
  };

  return (
    <SectionWrapper loading={loading}>
      <div className="card">
        <AdminPlansHeader subscriptionType={subscriptionType} title={'Plan'} />
        <div className="card-body">
          <p className={'text-gradient-blue-green px-3'}>{plan?.description}</p>
          <p className={'px-3'}>{plan?.extraInfo}</p>
          {subscriptionType !== SubscriptionType.BASIC ? (
            <>
              <p>
                <span className={'text-bold px-3'}>Miesięcznie: </span>
                <span className={'text-gradient-blue-green'}>{plan?.monthlyPrice}</span>
              </p>
              <p>
                <span className={'text-bold px-3'}>Średnio miesiecznie, gdy na rok: </span>
                <span className={'text-gradient-blue-green'}>{plan?.annualPricePerMonth}</span>
              </p>
              <p>
                <span className={'text-bold px-3'}>Rocznie: </span>
                <span className={'text-gradient-blue-green'}>{plan?.annualPrice}</span>
              </p>
              <button
                className={'btn btn-link ' + (showPrices ? 'text-danger' : '')}
                onClick={() => setShowPrices((prevState) => !prevState)}
              >
                {showPrices ? 'Anuluj' : 'Zmień ceny'}
              </button>
              {showPrices ? (
                <>
                  <InputColumn
                    label={'Nowa cena miesięcznie'}
                    name={'monthlyPrice'}
                    type={'number'}
                    value={prices.monthlyPrice}
                    onChange={(e) => changePrice(e.target.name, e.target.value)}
                    optional
                  />
                  <InputColumn
                    label={'Nowa cena rocznie'}
                    name={'annualPrice'}
                    type={'number'}
                    value={prices.annualPrice}
                    onChange={(e) => changePrice(e.target.name, e.target.value)}
                    optional
                  />

                  <button
                    className="btn btn-info mx-3"
                    onClick={updatePrices}
                    disabled={!prices.monthlyPrice && !prices.annualPrice}
                  >
                    Zapisz zmiany
                  </button>
                </>
              ) : null}
            </>
          ) : null}
        </div>
      </div>
    </SectionWrapper>
  );
};
