import React, { useCallback, useContext, useEffect, useMemo, useReducer } from 'react';

const SMALL_MOBILE_WIDTH_EDGE_VALUE = 375;
const MOBILE_WIDTH_EDGE_VALUE = 768;
const MEDIUM_WIDTH_EDGE_VALUE = 1430;

interface ResponsiveContextInterface {
  isMobile: boolean;
  isSmallMobile: boolean;
  isMedium: boolean;
  isMenuOpen: boolean;
  pageWidth: number;
  pageHeight: number;
  closeMenu: () => void;
  toggleOpenMenu: () => void;
  setIsMobile: (value: boolean) => void;
  setState: (name: string, value: unknown) => void;
}

const defaultState: ResponsiveContextInterface = {
  pageWidth: window.innerWidth,
  pageHeight: window.innerHeight,
  isMobile: window.innerWidth < 960,
  isSmallMobile: window.innerWidth < SMALL_MOBILE_WIDTH_EDGE_VALUE,
  isMedium: window.innerWidth >= 960 && window.innerWidth < 1430,
  isMenuOpen: false,
  closeMenu: () => {
    /* intentionally empty */
  },
  toggleOpenMenu: () => {
    /* intentionally empty */
  },
  setIsMobile: () => {
    /* intentionally empty */
  },
  setState: () => {
    /* intentionally empty */
  }
};

const reducer = (state: ResponsiveContextInterface, action: { type: string; value?: unknown }) => {
  switch (action.type) {
    case 'toggleOpenMenu':
      return { ...state, isMenuOpen: !state.isMenuOpen };
    case 'setIsMobile':
      return { ...state, isMobile: Boolean(action.value) };
    case 'setIsSmallMobile':
      return { ...state, isSmallMobile: Boolean(action.value) };
    case 'setIsMedium':
      return { ...state, isMedium: Boolean(action.value) };
    case 'closeMenu':
      return { ...state, isMenuOpen: false };
    default:
      return { ...state, [action.type]: action.value };
  }
};

const ResponsiveContext = React.createContext<ResponsiveContextInterface>(defaultState);
export const useResponsiveContext = (): ResponsiveContextInterface => {
  return useContext(ResponsiveContext);
};

const ResponsiveProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultState);

  const toggleOpenMenu = useCallback(() => {
    dispatch({ type: 'toggleOpenMenu' });
  }, []);

  const closeMenu = useCallback(() => {
    dispatch({ type: 'closeMenu' });
  }, []);

  const setIsMobile = useCallback((isMobile: boolean) => {
    dispatch({ type: 'setIsMobile', value: isMobile });
  }, []);

  const setIsSmallMobile = useCallback((isSmallMobile: boolean) => {
    dispatch({ type: 'setIsSmallMobile', value: isSmallMobile });
  }, []);

  const setIsMedium = useCallback((isMedium: boolean) => {
    dispatch({ type: 'setIsMedium', value: isMedium });
  }, []);

  const setState = useCallback((name: string, value: boolean | string | number) => {
    dispatch({ type: name, value });
  }, []);

  useEffect(() => {
    const updateDimensions = () => {
      setState('pageWidth', window.innerWidth);
      setState('pageHeight', window.innerHeight);
      setIsMobile(window.innerWidth < MOBILE_WIDTH_EDGE_VALUE);
      setIsSmallMobile(window.innerWidth < SMALL_MOBILE_WIDTH_EDGE_VALUE);
      setIsMedium(
        window.innerWidth >= MOBILE_WIDTH_EDGE_VALUE && window.innerWidth < MEDIUM_WIDTH_EDGE_VALUE
      );
    };

    window.addEventListener('resize', () => updateDimensions());
    return window.removeEventListener('resize', () => updateDimensions());
  }, [setIsMobile, setState, setIsMedium, setIsSmallMobile]);

  const providerValue = useMemo(
    () => ({
      isMobile: state.isMobile || /Mobi|Android|iPhone|iPad|iPod/i.test(navigator.userAgent),
      isSmallMobile: state.isSmallMobile,
      isMedium: state.isMedium,
      isMenuOpen: state.isMenuOpen,
      pageWidth: state.pageWidth,
      pageHeight: state.pageHeight,
      closeMenu,
      toggleOpenMenu,
      setIsMobile,
      setState
    }),
    [
      state.isMobile,
      state.isSmallMobile,
      state.isMedium,
      state.isMenuOpen,
      state.pageWidth,
      state.pageHeight,
      closeMenu,
      toggleOpenMenu,
      setIsMobile,
      setState
    ]
  );

  return <ResponsiveContext.Provider value={providerValue}>{children}</ResponsiveContext.Provider>;
};

export default ResponsiveProvider;
