import React, { FC, useState } from 'react';
import { Card, CardBody, CardFooter, Table } from 'reactstrap';
import ProgressBar from '../../../components/common/charts/ProgressBar';
import SmallTextBadge from '../../../components/common/badges/SmallTextBadge';
import CommonRow from '../../../components/common/table/CommonRow';
import { useTranslation } from 'react-i18next';
import { WineInfoButtons } from '../components/wine/components/WineInfo/WineInfoContainer';
import { ProductionEventForWineType, Wine } from '../components/wine/types/Wine';
import { WineProduction } from '../components/wine/types/WineProduction';
import { EntityLiveStatus } from '../../../components/common/enums/EntityLiveStatus';
import SelectTank from '../components/wine/components/WineForm/SelectTank';
import { ServiceError } from '../../../types/Service';
import { FormErrorMessage } from '../../../components/notifications/FormErrorMessage';
import { useAuthContext } from '../../platform/AuthContext';
import ListActions from '../../../components/common/ListActions';
import { SubscriptionType } from '../../../types/Subscription';
import { SectionWrapper } from '../../../layouts/SectionWrapper';
import useClickOutside from '../../../hooks/useClickOutside';
import { PdfButtonComponent } from '../../reports/components/PdfButtonComponent';
import { useGetPdf } from '../../home/hooks/useGetPdf';

export type WineInfo = Pick<
  Wine,
  | 'id'
  | 'name'
  | 'status'
  | 'tank'
  | 'liters'
  | 'startDate'
  | 'endLiters'
  | 'lastIngredient'
  | 'lastEvent'
  | 'liveStatus'
  | 'harvest'
> & {
  entity: 'wine' | 'wine_production';
  addedToProduction?: Date;
  tankPercentOfUsage?: number;
  tankNumber?: number;
} & Pick<WineProduction, 'wineColor' | 'wineTaste'>;

const WineInfoBox: FC<{
  buttons: WineInfoButtons;
  wine: Partial<WineInfo>;
  loading: boolean;
  updateTank?: (selected: { value: number }) => void;
  error?: ServiceError;
  isBoardPage?: boolean;
  hasWineEntries?: boolean;
}> = ({
  buttons: {
    saveEditButton,
    archiveButton,
    toProductionButton,
    removeButton,
    removeLastWineEntryAndWineProductionButton
  },
  wine,
  loading,
  updateTank,
  error,
  isBoardPage = false,
  hasWineEntries
}) => {
  const { t } = useTranslation();
  const [show, setShow] = useState(false);
  const { wrapperRef: clickOutsideRef } = useClickOutside(() => {
    setShow(false);
  });

  const {
    subscriptionInfo: { accessInfo }
  } = useAuthContext();

  const { getPdf, loading: loadingPdf } = useGetPdf({
    subscriptionType: SubscriptionType.NORMAL,
    reportType: wine.entity.toUpperCase(),
    id: wine.id
  });

  const actions = {
    proceed: saveEditButton.action,
    remove:
      hasWineEntries && !accessInfo.hasNormalAccess && wine?.entity === 'wine_production'
        ? removeLastWineEntryAndWineProductionButton.action
        : removeButton.action,
    archive: archiveButton.action,
    revertArchive: archiveButton.action,
    toProduction:
      toProductionButton && wine.liveStatus !== EntityLiveStatus.ARCHIVED && wine.liters > 0
        ? toProductionButton.action
        : undefined
  };

  const lastEventName =
    wine?.lastEvent?.type === ProductionEventForWineType.OTHER && wine?.lastEvent?.name
      ? wine?.lastEvent?.name
      : t(`event.type.${wine?.lastEvent?.type}`) + '';

  function getCategoryOfWine() {
    const wineProduction = wine as WineProduction;

    if (wineProduction.varietalWine) return 'Wino odmianowe';
    if (wineProduction.protectedNameOrGeoWine) return 'Wino z ChNP lub ChOG';
    return 'Pozostałe';
  }

  return (
    <SectionWrapper loading={loading}>
      <Card className={'super-form'}>
        <CardBody>
          {wine?.wineTaste && (
            <SmallTextBadge
              title={t(`wine.TASTE.${wine.wineTaste}`)}
              color={t(`wine_info_box.badge.color.${wine.wineTaste}`)}
            />
          )}
          {wine?.wineColor && (
            <SmallTextBadge
              title={t(`wine.COLOR.${wine.wineColor}`)}
              color={t(`wine_info_box.badge.color.${wine.wineColor}`)}
            />
          )}
          <SmallTextBadge
            title={t(`wine_info_box.badge.text.${wine.entity}`)}
            color={t(`wine_info_box.badge.color.${wine.entity}`)}
          />
          <h4 className="m-0">{wine?.name}</h4>
          {wine?.tank?.number || wine?.tankNumber ? (
            <small className="text-muted">
              {wine?.tank?.type ? t(`tank.TYPE.${wine.tank.type}`) : 'Zbiornik '} nr{' '}
              {wine?.tank?.number || wine.tankNumber}
            </small>
          ) : wine.liveStatus !== EntityLiveStatus.ARCHIVED && wine?.liters > 0 ? (
            <small className="text-danger">Umieść wino w zbiorniku</small>
          ) : null}
          <CardBody>
            <ProgressBar
              percent={wine?.tank?.percentOfUsage || wine?.tankPercentOfUsage}
              color={'info'}
              title={`Aktualnie w produkcji ${wine?.liters ? wine.liters : '0'} l.`}
            />
          </CardBody>
          <Table>
            <tbody>
              {wine?.entity === 'wine_production' ? (
                <CommonRow label={'Kategoria'} value={getCategoryOfWine()} />
              ) : null}
              {wine?.startDate ? (
                <CommonRow
                  label={wine.entity === 'wine' ? 'Data rozpoczęscia' : 'Data początkowa'}
                  value={wine.startDate.toString()}
                />
              ) : null}
              {updateTank ? (
                <CommonRow
                  label={wine?.tank?.type ? t(`tank.TYPE.${wine.tank.type}`) : 'Zbiornik '}
                  value={
                    <div ref={clickOutsideRef}>
                      {' '}
                      {show ? (
                        <SelectTank
                          value={wine?.tank || {}}
                          name={'tank'}
                          disabled={wine?.liveStatus === EntityLiveStatus.ARCHIVED}
                          onChange={updateTank}
                          error={error}
                          key={wine?.tank?.number}
                        />
                      ) : (
                        <div>
                          {wine?.tank?.number ? (
                            <span className={'pr-2'}>
                              {wine.tank.number.length > 15
                                ? wine.tank.number.toString().substring(0, 15) + '...'
                                : wine.tank.number}
                            </span>
                          ) : null}
                          <button
                            className={'btn btn-link text-sm px-0 pt-1'}
                            style={{ cursor: 'pointer' }}
                            onClick={() => setShow((prev) => !prev)}
                          >
                            {wine?.tank?.number ? 'Zmień' : 'Umieść'}
                          </button>
                        </div>
                      )}
                    </div>
                  }
                />
              ) : null}
              {/*<CommonRow label={'Aktualnie w produkcji'} value={wine?.liters} />*/}
              <CommonRow label={'Stworzono litrów wina'} value={wine?.endLiters} />

              {!isBoardPage && (
                <>
                  <CommonRow
                    label={'Ostatni dodany składnik'}
                    value={wine?.lastIngredient ? wine?.lastIngredient?.name : 'Brak'}
                  />
                  <CommonRow
                    label={'Ostatni proces'}
                    value={wine?.lastEvent?.type ? lastEventName : 'Brak'}
                  />
                  {wine?.harvest ? (
                    <CommonRow
                      label={'Ze zbioru'}
                      value={`${wine?.harvest?.grapevine?.name || wine?.harvest?.varietyName} (${
                        wine?.harvest?.dateOfHarvest
                      })`}
                      path={`/mv/harvest/info/${wine?.harvest?.id}`}
                    />
                  ) : null}
                </>
              )}
            </tbody>
          </Table>
          <CardFooter className="text-center bg-secondary">
            <div>
              <div className="float-left">
                <PdfButtonComponent
                  reportAvailable={true}
                  getPdf={getPdf}
                  loading={loadingPdf}
                  subscriptionTypeNeeded={SubscriptionType.NORMAL}
                />
              </div>
              <div className="mr-1 float-right">
                <ListActions entity={wine} actions={actions} />
              </div>
            </div>
          </CardFooter>
        </CardBody>
        <div className="px-4 text-center bg-secondary">
          {error && <FormErrorMessage error={error} messageType={'details'} />}
        </div>
      </Card>
    </SectionWrapper>
  );
};

export default WineInfoBox;
