import useCrudeApi from '../../../../../lib/useCrudeApi';
import { TaxBands, TaxBandsInfo } from '../../../../../types/TaxBands';
import { EntityLiveStatus } from '../../../../../components/common/enums/EntityLiveStatus';

export const useTaxBandsService = () => {
  const { getList, post, getAllWithParams, del, get } = useCrudeApi<TaxBands>(`/ajax/tax_bands`);
  const { get: getPlacementsForTaxBands } = useCrudeApi<TaxBands>(`/ajax/tax_bands/placements`);
  const { putId: archive } = useCrudeApi<TaxBands>('/ajax/tax_bands/archive');
  const { putId: revertArchive } = useCrudeApi<TaxBands>('/ajax/tax_bands/revert-archive');
  const getAvailableListByStatus = (showAvailable = true) => {
    const status = showAvailable ? EntityLiveStatus.CREATED : EntityLiveStatus.ARCHIVED;

    return getAllWithParams(new Map<string, EntityLiveStatus>([['s', status]]));
  };

  const getAvailableList = (showAvailable = true) => {
    return getAllWithParams(new Map<string, boolean>([['available', showAvailable]]));
  };

  const { post: addTaxBandsPlacement, delByTwoIds: removePlacement } = useCrudeApi(
    'ajax/tax_bands/placement'
  );

  const postWithInitialValues = (body: TaxBandsInfo) => {
    return post(body, new Map<string, boolean>([['withInitValues', true]]));
  };

  return {
    getList,
    getAvailableList,
    getAvailableListByStatus,
    post,
    postWithInitialValues,
    getPlacementsForTaxBands,
    del,
    removePlacement,
    addTaxBandsPlacement,
    archive,
    revertArchive,
    get
  };
};
