import { Plans, SubscriptionPlan } from '../data/Plans';
import { PlanData, SubscriptionType } from '../../../types/Subscription';
import { useCallback, useEffect, useState } from 'react';
import useSubscriptionService from './useSubscriptionService';

export function useGetPlans(): {
  plans: SubscriptionPlan[];
  get: (type: SubscriptionType) => SubscriptionPlan;
  loading: boolean;
} {
  const service = useSubscriptionService();
  const [loading, setLoading] = useState(false);

  const [subscriptionPlans, setSubscriptionPlans] = useState<SubscriptionPlan[]>([]);

  const mapPlans = useCallback((planData: PlanData[]) => {
    return Plans.map((plan) => ({
      ...(planData.find((planData) => planData.subscriptionType === plan.subscriptionType) || {}),
      ...plan
    })) as SubscriptionPlan[];
  }, []);

  useEffect(() => {
    if (subscriptionPlans.length) return;
    setLoading(true);
    service
      .getPlans()
      .then((res) => {
        setSubscriptionPlans(mapPlans(res));
        setLoading(false);
      })
      .catch((err) => console.log(err))
      .finally(() => setLoading(false));
  }, [subscriptionPlans.length, service, mapPlans]);

  const get = useCallback(
    (type: SubscriptionType): SubscriptionPlan | undefined => {
      return subscriptionPlans?.find((plan) => plan.subscriptionType === type);
    },
    [subscriptionPlans]
  );

  return { plans: subscriptionPlans, get, loading };
}
