import React, { useContext } from 'react';
import { AuthContext } from '../../../../platform/AuthContext';
import { SubscriptionType } from '../../../../../types/Subscription';
import { LockIconInfo } from '../../../../../components/common/lock/LockIconInfo';
import { ReportType, useGetPdf } from '../../../hooks/useGetPdf';
import { TaxBandsTab } from '../hooks/useTaxBands';
import usePlanPicker from '../../../../subscription/hooks/usePlanPicker';
import { PdfButtonComponent } from '../../../../reports/components/PdfButtonComponent';

const TabButton: React.FC<{
  tab: TaxBandsTab;
  label: string;
  activeTab: TaxBandsTab;
  onClick: ({ newTab }: { newTab: TaxBandsTab }) => void;
}> = ({ tab, label, activeTab, onClick }) => {
  const {
    subscriptionInfo: { accessInfo }
  } = useContext(AuthContext);

  const { isValidSubscription } = usePlanPicker({
    subscriptionType: SubscriptionType.PREMIUM,
    fromBlockingContent: true,
    blockingMessage: 'Przejdź na wyższy plan, aby zarządzać banderolami',
    itemIndexToHighlight: 4
  });

  function handleClickButton() {
    if (!isValidSubscription()) {
      return;
    }
    onClick({ newTab: tab });
  }

  return (
    <button
      className={`mr-2 btn btn-sm ${activeTab !== tab ? 'btn-info' : 'btn-danger'}`}
      type="button"
      onClick={handleClickButton}
    >
      {activeTab === tab ? (
        <>
          <em className="mr-1 fas fa-minus" /> {label}
        </>
      ) : (
        <>
          <em className="mr-1 fas fa-plus" /> {label}
        </>
      )}
      <LockIconInfo
        accessInfo={accessInfo}
        subscriptionTypeNeeded={SubscriptionType.PREMIUM}
        hideLabel
      />
    </button>
  );
};

export const TaxBandsListButtonsBar: React.FC<{
  reportAvailable: boolean;
  onClick: ({ newTab }: { newTab: TaxBandsTab }) => void;
  activeTab: TaxBandsTab;
}> = ({ reportAvailable, onClick, activeTab }) => {
  const { getPdf, loading } = useGetPdf({
    subscriptionType: SubscriptionType.PREMIUM,
    reportType: ReportType.TAX_BANDS_SUMMARY
  });

  return (
    <div className="mb-3">
      <div>
        <TabButton tab="form" label="banderole" activeTab={activeTab} onClick={onClick} />
        <TabButton tab="addPlacement" label="strata" activeTab={activeTab} onClick={onClick} />
        <PdfButtonComponent
          reportAvailable={reportAvailable}
          getPdf={getPdf}
          loading={loading}
          subscriptionTypeNeeded={SubscriptionType.PREMIUM}
        />
      </div>
    </div>
  );
};
