import React from 'react';
import 'loaders.css/loaders.css';
import 'spinkit/css/spinkit.css';

export const SectionWrapper: React.FC<{
  loading?: boolean;
  hideSpinner?: boolean;
  children?: React.ReactNode;
  type?: 'ringed' | 'line' | 'line back-and-forth' | 'small-spinner';
}> = ({ loading, children, hideSpinner, type = 'ringed' }) => {
  if (hideSpinner) {
    return <div className={loading ? `whirl none area-disabled ${type}` : ''}>{children}</div>;
  }
  return <div className={loading ? `whirl area-disabled ${type}` : ''}>{children}</div>;
};
