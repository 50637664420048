import React, { useCallback, useContext, useMemo, useReducer } from 'react';
import { WineEntry } from '../../../wine/types/WineProduction';
import { Service, ServiceError, StatusType } from '../../../../../../types/Service';
import { defaultError } from '../../../parcel/context/ParcelContext';
import { ResponseError } from '../../../../../error/ResponseError';

interface WineEntryContextInterface {
  wineEntry: WineEntry;
  setWineEntry: (value: WineEntry) => void;
  updateWineEntry: (name, value) => void;
  wineEntries: WineEntry[];
  setWineEntries: (value: WineEntry[]) => void;
  result: Service<WineEntry>;
  setResult: (value: Service<WineEntry>) => void;
  setError: (value: ServiceError) => void;
  error: ServiceError;
  loading: boolean;
}

const defaultWineEntry = {
  id: 0,
  name: '',
  wine: undefined,
  wineProduction: undefined,
  wineProductionConsumer: undefined,
  tank: undefined,
  liters: 0,
  created: undefined,
  historyEdgeDate: undefined,
  addedToProduction: undefined,
  entity: undefined,
  entryType: undefined,
  entryId: 0
};

const defaultState = {
  wineEntry: defaultWineEntry,
  setWineEntry: () => {
    /* intentionally empty */
  },
  updateWineEntry: () => {
    /* intentionally empty */
  },
  wineEntries: [],
  setWineEntries: () => {
    /* intentionally empty */
  },
  result: { status: StatusType.loading },
  setResult: () => {
    /* intentionally empty */
  },
  setError: () => {
    /* intentionally empty */
  },
  error: defaultError,
  loading: false
};

const reducer = (state, action) => {
  switch (action.type) {
    case 'reset':
      return defaultState;
    case 'resetWineEntry':
      return { ...state, wineEntry: defaultWineEntry };
    case 'resetWineEntries':
      return { ...state, wineEntries: [] };
    case 'wineEntry':
      return { ...state, wineEntry: { ...action.value } };
    case 'wineEntries':
      return { ...state, wineEntries: [...action.value] };
    case 'result':
      return { ...state, result: { ...action.value } };
    case 'error':
      return { ...state, error: action.value };

    default:
      return { ...state, wineEntry: { ...state.wineEntry, [action.type]: action.value } };
  }
};

const WineEntryContext = React.createContext({} as WineEntryContextInterface);
export const useWineEntryContext = (): WineEntryContextInterface => {
  return useContext(WineEntryContext);
};

const WineEntryProvider: React.FC<{ children: React.ReactNode }> = ({ children }) => {
  const [state, dispatch] = useReducer(reducer, defaultState);

  const resetWineEntry = useCallback(() => {
    dispatch({ type: 'resetWineEntry', value: '' });
  }, []);

  const resetWineEntries = useCallback(() => {
    dispatch({ type: 'resetWineEntries', value: '' });
  }, []);

  const setWineEntry = useCallback((wineEntry) => {
    dispatch({ type: 'wineEntry', value: wineEntry });
  }, []);

  const setWineEntries = useCallback((wineEntries) => {
    dispatch({ type: 'wineEntries', value: wineEntries });
  }, []);

  const updateWineEntry = useCallback((name, value) => {
    dispatch({ type: name, value });
  }, []);

  const setResult = useCallback((result) => {
    dispatch({ type: 'result', value: result });
    if (result?.payload) {
      dispatch({ type: 'wineEntry', value: result.payload });
    }
  }, []);

  const setError = useCallback((error) => {
    dispatch({ type: 'error', value: new ResponseError(error) });
  }, []);

  const providerValue = useMemo(
    () => ({
      wineEntry: state.wineEntry,
      updateWineEntry,
      wineEntries: state.wineEntries,
      setWineEntry,
      setWineEntries,
      resetWineEntry,
      resetWineEntries,
      result: state.result,
      setResult,
      setError,
      error: state.error,
      loading: state.result.status === StatusType.loading
    }),
    [
      state.wineEntry,
      updateWineEntry,
      state.wineEntries,
      setWineEntry,
      setWineEntries,
      resetWineEntry,
      resetWineEntries,
      state.result,
      setResult,
      setError,
      state.error
    ]
  );

  return <WineEntryContext.Provider value={providerValue}>{children}</WineEntryContext.Provider>;
};

export default WineEntryProvider;
