import React, { FC } from 'react';
import EventsListBox from '../../../../wine/components/WineInfo/list-box/shared/EventsListBox';
import {
  WineProductionEventsListBoxContainer,
  WineProductionEventsListBoxProps
} from './WineProductionEventsListBoxContainer';

export const WineProductionEventsListBox: FC<WineProductionEventsListBoxProps> = (props) => {
  return (
    <WineProductionEventsListBoxContainer
      {...props}
      render={(events, eventToShow, actions) => (
        <EventsListBox
          events={events}
          eventToShow={eventToShow}
          actions={actions}
          entityName={'wine_production'}
          loading={props.loading}
          disabledActions={props?.disabledActions}
          entityId={props.entityId}
        />
      )}
    />
  );
};
