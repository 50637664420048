import 'core-js/es6/string';
import 'core-js/es6/array';
import 'core-js/es6/map';
import 'core-js/es6/set';
import 'core-js/es6/object';
import 'core-js/es6/promise';
import 'core-js/es7/object';
import 'core-js/es7/array';
import 'raf/polyfill';

import React from 'react';
import ReactDOM from 'react-dom';
import { Provider } from 'react-redux';
import 'react-circular-progressbar/dist/styles.css';

import App from './App';

import './i18n';
import './config.ts';

import log from 'loglevel';

import configureStore from './store/store';
const store = configureStore();
import './assets/App.css';
import ThemeProvider from './features/platform/ThemeContext';

log.debug('env: ', process.env);

const rootElement = document.getElementById('app');
if (!rootElement) {
  throw new Error('Could not find root element');
}

ReactDOM.render(
  <React.StrictMode>
    <Provider store={store}>
      <ThemeProvider>
        <App />
      </ThemeProvider>
    </Provider>
  </React.StrictMode>,
  rootElement
);
