import React from 'react';
import { PlansActiveTab } from './AdminPlansPage';
import { SubscriptionType } from '../../../../types/Subscription';

export const AdminPlansMenu: React.FC<{
  setActiveTab: (tab: PlansActiveTab) => void;
  activeTab: PlansActiveTab;
}> = ({ setActiveTab, activeTab }) => {
  return (
    <div className="card">
      <div className="card-header text-muted">PLANY</div>
      <div className="list-group">
        <div
          onClick={() => setActiveTab(SubscriptionType.BASIC)}
          className={
            'list-group-item list-group-item-action ' +
            (activeTab === SubscriptionType.BASIC ? 'active' : '')
          }
          style={{ cursor: 'pointer' }}
        >
          Podstawowy
        </div>
        <div
          onClick={() => setActiveTab(SubscriptionType.NORMAL)}
          className={
            'list-group-item list-group-item-action ' +
            (activeTab === SubscriptionType.NORMAL ? 'active' : '')
          }
          style={{ cursor: 'pointer' }}
        >
          Pro dla winiarza
        </div>
        <div
          onClick={() => setActiveTab(SubscriptionType.PREMIUM)}
          className={
            'list-group-item list-group-item-action ' +
            (activeTab === SubscriptionType.PREMIUM ? 'active' : '')
          }
          style={{
            borderBottomLeftRadius: '10px',
            borderBottomRightRadius: '10px',
            cursor: 'pointer'
          }}
        >
          Premium dla winnic
        </div>
      </div>
    </div>
  );
};
