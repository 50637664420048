import React, { useRef } from 'react';
import { BottleOutput } from '../../../types/WineStorage';
import { useBottleOutputService } from '../../../hooks/useBottleOutputService';
import { useWineStorageContext } from '../../../context/WineStorageContext';
import WineStorageBottleOutputsListBox from '../WineStorageBottleOutputsListBox';
import { useTranslation } from 'react-i18next';

export const BottleOutputList: React.FC<{ bottleOutputs: BottleOutput[]; loading: boolean }> = ({
  bottleOutputs = [],
  loading
}) => {
  const { wineStorage } = useWineStorageContext();
  const serviceRef = useRef(useBottleOutputService());
  const { t } = useTranslation();

  const withTaxBands = bottleOutputs
    ?.filter((output) => output.hasTaxBands)
    ?.sort((a, b) => new Date(b.outputDate).getTime() - new Date(a.outputDate).getTime());
  const withoutTaxBands = bottleOutputs
    ?.filter((output) => !output.hasTaxBands)
    ?.sort((a, b) => new Date(b.outputDate).getTime() - new Date(a.outputDate).getTime());

  return (
    <>
      {withTaxBands?.length || wineStorage?.id == undefined ? (
        <WineStorageBottleOutputsListBox
          bottleOutputs={withTaxBands || []}
          delOutput={serviceRef.current.del}
          title={t('wineStorage.bottle.output.withTaxBands')}
          loading={loading}
        />
      ) : null}
      {withoutTaxBands?.length || wineStorage?.id == undefined ? (
        <WineStorageBottleOutputsListBox
          bottleOutputs={withoutTaxBands || []}
          delOutput={serviceRef.current.del}
          title={t('wineStorage.bottle.output.withoutTaxBands')}
          loading={loading}
        />
      ) : null}
    </>
  );
};
