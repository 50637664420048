import React from 'react';
import { ReportType, useGetPdf } from '../../../hooks/useGetPdf';
import { SubscriptionType } from '../../../../../types/Subscription';
import { useTranslation } from 'react-i18next';
import { useConfirmation } from '../../../../notifications/useConfirmation';
import { EntityLiveStatus } from '../../../../../components/common/enums/EntityLiveStatus';
import { Color } from '../../../../../components/common/enums/Color';
import { Link, useParams } from 'react-router-dom';
import { RouterParams } from '../../../../../types/RouterParams';
import { TaxBandsReadModel } from '../../../../../types/TaxBands';
import {
  DropdownActionButton,
  DropdownButtonAction
} from '../../BottlesSummaryPages/components/bottle/DropdownActionButton';
import { PdfButtonComponent } from '../../../../reports/components/PdfButtonComponent';

export const PlacementButtonsBar: React.FC<{
  reportAvailable: boolean;
  id?: number;
  removeTaxBands: (id: number) => void;
  archiveTaxBands: (id: number) => void;
  selectedTaxBands: TaxBandsReadModel;
  showList: boolean;
  setShowList: (val: boolean) => void;
  disableRemove: boolean;
}> = ({
  reportAvailable,
  id,
  removeTaxBands,
  archiveTaxBands,
  selectedTaxBands,
  showList,
  setShowList,
  disableRemove
}) => {
  const { taxBandsId } = useParams<RouterParams>();

  const { getPdf, loading } = useGetPdf({
    subscriptionType: SubscriptionType.PREMIUM,
    reportType: ReportType.TAX_BANDS,
    id: selectedTaxBands.id
  });
  const { t } = useTranslation();
  const showConfirmation = useConfirmation();
  const removeTaxBandsHandler = (id: number) => {
    showConfirmation({
      message: t('alerts.confirmation.REMOVE_TAX_BANDS'),
      actionLabel: 'Usuń',
      actionCallback: () => removeTaxBands(id)
    });
  };

  const archiveButton: DropdownButtonAction = {
    label:
      selectedTaxBands.liveStatus === EntityLiveStatus.ARCHIVED
        ? t('button.REVERT_ARCHIVE')
        : t('button.ARCHIVE'),
    onClick: () => archiveTaxBands(id),
    color: Color.LightBlue
  };

  const removeButton: DropdownButtonAction = {
    label: t('bottle.btn.action.remove.label'),
    onClick: () => removeTaxBandsHandler(selectedTaxBands.id),
    color: Color.Red,
    disabled: disableRemove
  };
  return (
    <div className="mb-4">
      <div>
        <div className={'inline'}>
          <DropdownActionButton mainAction={archiveButton} dropdownActions={[removeButton]} />
          {taxBandsId ? (
            <Link
              to={'/mv/board/tax_bands'}
              className="btn btn-sm btn-secondary ml-2"
              type="button"
              title={t('button.show.list')}
            >
              <span className={'pr-2'}>{t('button.show.list.taxBands.show')}</span>
              <em className="fas fa-outdent" />
            </Link>
          ) : null}
          {!taxBandsId ? (
            <button
              className="btn btn-sm btn-secondary ml-2"
              type="button"
              onClick={() => setShowList(!showList)}
            >
              {
                <span className={'pr-2'}>
                  {showList
                    ? t('button.show.list.taxBands.hide')
                    : t('button.show.list.taxBands.show')}
                </span>
              }{' '}
              <em className="fas fa-outdent" />
            </button>
          ) : null}
        </div>
        <PdfButtonComponent
          reportAvailable={reportAvailable}
          getPdf={getPdf}
          loading={loading}
          subscriptionTypeNeeded={SubscriptionType.PREMIUM}
        />
      </div>
    </div>
  );
};
