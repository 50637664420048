import { FC } from 'react';
import { useCRUDListBoxContainer } from '../../../../wine/components/WineInfo/list-box/shared/useCRUDListBoxContainer';

import { TankEntry, WineStorage } from '../../../types/WineStorage';
import { useWineStorageContext } from '../../../context/WineStorageContext';

export interface WineStorageTankEntriesListBoxProps {
  tankEntries: TankEntry[];
  delOutput: (outputId: number) => void;
  disabledActions?: boolean;
  entityId?: number;
  title: string;
  loading: boolean;
}

type Props = WineStorageTankEntriesListBoxProps & {
  render: (list, elementToShow, actions, loading) => JSX.Element;
};

export const WineStorageTankEntriesListBoxContainer: FC<Props> = ({
  render,
  tankEntries,
  delOutput
}) => {
  const { setResult, setError } = useWineStorageContext();
  const { list, elementToShow, actions, loading } = useCRUDListBoxContainer<WineStorage, TankEntry>(
    tankEntries,
    delOutput,
    setResult,
    setError
  );

  const selectedActions = {
    removeElement: actions.removeElement,
    toggleShow: actions.toggleShow
  };

  return render(list, elementToShow, selectedActions, loading);
};
