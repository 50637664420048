import React, { useRef } from 'react';
import { useBottleEntryService } from '../../../hooks/useBottleEntryService';
import { BottleEntry } from '../../../types/WineStorage';
import { useWineStorageContext } from '../../../context/WineStorageContext';
import { useTranslation } from 'react-i18next';
import WineStorageBottleEntriesListBox from '../WineStorageBottleEntriesListBox';

export const BottleEntryList: React.FC<{ bottleEntries: BottleEntry[]; loading: boolean }> = ({
  bottleEntries = [],
  loading
}) => {
  const { wineStorage } = useWineStorageContext();
  const serviceRef = useRef(useBottleEntryService());

  const { t } = useTranslation();

  return (
    <>
      {bottleEntries?.length || wineStorage?.id == undefined ? (
        <WineStorageBottleEntriesListBox
          bottleEntries={bottleEntries || []}
          delOutput={serviceRef.current.del}
          title={t('wineStorage.bottle.entry.ADDED')}
          loading={loading}
        />
      ) : null}
    </>
  );
};
