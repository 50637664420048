import { ChangeEvent, useEffect } from 'react';
import { useParcelContext } from '../../context/ParcelContext';
import { useParams } from 'react-router-dom';
import { ServiceError, StatusType } from '../../../../../../types/Service';
import useParcelService from '../../hooks/useParcelService';
import { ResponseError } from '../../../../../error/ResponseError';
import { Parcel } from '../../types/Parcel';
import log from 'loglevel';
import { RouterParams } from '../../../../../../types/RouterParams';
import { SESSION_STORAGE_KEY, useStorageSession } from '../../../../../../hooks/useStorageSession';
import { usePushHistory } from '../../../../../../hooks/usePushHistory';
import { HISTORY_FALLBACK_TO } from '../../../../../../services/Constants';

const ParcelFormContainer = ({ render }) => {
  const { parcel, updateParcel, setParcelResult, parcelResult } = useParcelContext();
  const { goBack } = usePushHistory();

  const service = useParcelService();

  const { parcelId } = useParams<RouterParams>();

  const storageSession = useStorageSession<Parcel>({
    entity: parcel,
    key: SESSION_STORAGE_KEY.CREATE_PARCEL,
    shouldSave: parcelId?.toString() === '0'
  });

  useEffect(() => {
    setParcelResult({ status: StatusType.loading });
    if (parcelId?.toString() === '0') {
      const payload = storageSession.getItemPayload();
      setParcelResult({ status: StatusType.loaded, payload });
      return;
    }
    parcelId &&
      service
        .get(parseInt(parcelId))
        .then((response) => {
          setParcelResult({ status: StatusType.loaded, payload: response });
        })
        .catch((response) => setParcelResult(new ResponseError(response) as ServiceError));

    return () => {
      updateParcel('reset', '');
    };
  }, [parcelId]); // eslint-disable-line react-hooks/exhaustive-deps

  const handleUpdateParcel = (e: ChangeEvent<HTMLInputElement>) => {
    updateParcel(e.target.name, e.target.value);
  };

  const onSubmit = (e: ChangeEvent<HTMLInputElement>) => {
    e.preventDefault();
    log.debug('ParcelForm:onSubmit', e, parcel);
    setParcelResult({ status: StatusType.loading });

    const action = () => (parcel?.id ? service.put(parcel.id, parcel) : service.post(parcel));
    action()
      .then((response) => {
        storageSession.removeItem();
        setParcelResult(response);
        goBack(HISTORY_FALLBACK_TO.VINEYARD_SUMMARY);
      })
      .catch((response) => setParcelResult(new ResponseError(response) as ServiceError));
  };

  const onClickBack = () => {
    goBack(HISTORY_FALLBACK_TO.PARCEL_ARCHIVED);
  };

  const error = parcelResult as ServiceError;

  log.debug('ParcelForm::render', parcel);
  return render(
    onSubmit,
    error,
    parcel,
    handleUpdateParcel,
    onClickBack,
    parcelResult.status === StatusType.loading
  );
};

export default ParcelFormContainer;
