import { SubscriptionType } from '../../../types/Subscription';
import useCrudeApi from '../../../lib/useCrudeApi';
import { CompanyInfoList } from '../types';

export type DiscountCardReadModel = {
  discountCardId: number;
  code: string;
  name: string;
  discountPrice: number;
  discountPercent: number;
  usageCount: number;
  maxUsageCount: number;
  subscriptionType: SubscriptionType;
  createdDate: string;
  startDate: string;
  validDate: string;
  usedDate: string;
  subscriberId: number;
  subscriberName: string;
  valid: boolean;
};

export enum DiscountType {
  PRICE = 'PRICE',
  PERCENTAGE = 'PERCENTAGE'
}

export type DiscountValue = {
  discount: number;
};

export type DiscountCode = {
  code: string;
};

export type CheckDiscountCodeData = {
  code: string;
  subscriptionType: SubscriptionType;
  price: number;
};

export enum DiscountPeriod {
  DAY = 'DAY',
  WEEK = 'WEEK',
  MONTH = 'MONTH',
  YEAR = 'YEAR'
}

export type DiscountCardPriceInitSetup = {
  discountPrice: number;
} & DiscountCardSharedSetup;

export type DiscountCardPercentageInitSetup = {
  discountPercentage: number;
} & DiscountCardSharedSetup;

export type DiscountCardSharedSetup = Pick<
  Partial<DiscountCardReadModel>,
  'maxUsageCount' | 'subscriberId' | 'name' | 'startDate'
> & { customCode?: string };

export function useDiscountService() {
  const { putBody } = useCrudeApi<CheckDiscountCodeData>('/ajax/discount_card/check_code');
  const { post: postEmail } = useCrudeApi<CheckDiscountCodeData>('/ajax/discount_card/send_email');
  const {
    getAllWithParams,
    get,
    del,
    put: updateDiscountCard
  } = useCrudeApi<CheckDiscountCodeData>('/ajax/discount_card');
  const { getAllWithParams: getCompanies } = useCrudeApi<CompanyInfoList>(
    '/ajax/discount_card/companies'
  );
  const { putId: invalidate } = useCrudeApi<CheckDiscountCodeData>(
    '/ajax/discount_card/invalidate'
  );
  const { post: postPrice } = useCrudeApi<CheckDiscountCodeData>('/ajax/discount_card/price');
  const { post: postPercentage } = useCrudeApi<CheckDiscountCodeData>(
    '/ajax/discount_card/percentage'
  );

  function getCompaniesWithDiscount({ discountCardId }: { discountCardId: number }) {
    return getCompanies(new Map<string, number>([['id', discountCardId]]));
  }

  const getAllByValid = (valid: boolean) => {
    return getAllWithParams(new Map<string, boolean>([['valid', valid]]));
  };

  const notifyByEmail = (code: string, subscriberId: number) => {
    return postEmail({ code, subscriberId });
  };

  const createNewPriceCard = (
    setup: DiscountCardPriceInitSetup,
    subscriptionType: SubscriptionType,
    period: DiscountPeriod
  ) => {
    return postPrice(
      setup,
      new Map<string, string>([
        ['subscriptionType', subscriptionType],
        ['period', period]
      ])
    );
  };

  const createNewPercentageCard = (
    setup: DiscountCardPercentageInitSetup,
    subscriptionType: SubscriptionType,
    period: DiscountPeriod
  ) => {
    return postPercentage(
      setup,
      new Map<string, string>([
        ['subscriptionType', subscriptionType],
        ['period', period]
      ])
    );
  };

  const checkDiscountByCode = (data: CheckDiscountCodeData) => {
    return putBody(data);
  };

  return {
    checkDiscountByCode,
    getAllByValid,
    createNewPriceCard,
    createNewPercentageCard,
    get,
    del,
    getCompaniesWithDiscount,
    updateDiscountCard,
    invalidate,
    notifyByEmail
  };
}
