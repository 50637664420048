import { useCallback, useEffect, useMemo, useState } from 'react';
import { Tank } from '../../../../winery/components/tank/types/Tank';
import { FilesListItemColor } from '../../../../../types/FileType';
import { useTranslation } from 'react-i18next';
import { WineEntryType } from '../../../../winery/components/wine/types/WineProduction';
import { SelectedTankType } from './useTankGroups';

type TankFilterType = WineEntryType | 'noFilter' | 'noUsage';

const TANK_FILTER_KEY = 'tankFilter';

export function useTankFilters(tanks: Tank[], selectedTankType: SelectedTankType) {
  const [selectedFilter, setSelectedFilter] = useState<TankFilterType>();
  const [filteredTanks, setFilteredTanks] = useState<Tank[]>([]);
  const [filteredTanksOnlyByUsage, setFilteredTanksOnlyByUsage] = useState<Tank[]>([]);
  const { t } = useTranslation();
  const resolveTanks = useCallback((tanks: Tank[], selectedTankType: SelectedTankType) => {
    return [...tanks].filter((tank) => {
      if (selectedTankType && selectedTankType !== 'all') {
        return tank.type === selectedTankType;
      }
      return true;
    });
  }, []);

  function getLastFilter(): TankFilterType {
    const tankFilter = localStorage.getItem(TANK_FILTER_KEY);
    return (tankFilter as TankFilterType) || 'noFilter';
  }

  const handleChangeFilter = useCallback(
    (filterType: TankFilterType) => {
      setSelectedFilter(filterType);
      localStorage.setItem(TANK_FILTER_KEY, filterType);
      const filteredTanks = tanks.filter((tank) => {
        if (filterType === WineEntryType.WINE_PRODUCTION) {
          return (
            tank.entryType === WineEntryType.WINE_PRODUCTION ||
            tank.entryType === WineEntryType.WINE
          );
        }
        if (filterType === 'noUsage') {
          return !tank.entryType;
        }
        return filterType === tank.entryType;
      });
      setFilteredTanksOnlyByUsage(filteredTanks);
      setFilteredTanks(resolveTanks(filteredTanks, selectedTankType));
    },
    [tanks, selectedTankType, resolveTanks]
  );

  useEffect(() => {
    const lastFilter = getLastFilter();
    const tanksToFilter = tanks.filter((tank) => !tank.entryType);

    if (lastFilter === 'noUsage') {
      setFilteredTanksOnlyByUsage(tanksToFilter);
      setSelectedFilter('noUsage');
      setFilteredTanks(resolveTanks(tanksToFilter, selectedTankType));
    } else if (!lastFilter || lastFilter === 'noFilter') {
      setFilteredTanksOnlyByUsage(tanks);
      setFilteredTanks(resolveTanks(tanks, selectedTankType));
      setSelectedFilter('noFilter');
    } else {
      handleChangeFilter(lastFilter);
    }
  }, [tanks, handleChangeFilter, selectedTankType, resolveTanks]);

  const tanksFilters: {
    label: string;
    filterType: TankFilterType;
    color: FilesListItemColor;
    count: number;
  }[] = useMemo(
    () => [
      {
        label: t('tanks.panel.filters.winery'),
        filterType: WineEntryType.WINE_PRODUCTION,
        color: 'info',
        count: resolveTanks(tanks, selectedTankType).filter(
          (tank) =>
            tank.entryType === WineEntryType.WINE ||
            tank.entryType === WineEntryType.WINE_PRODUCTION
        ).length
      },
      {
        label: t('tanks.panel.filters.storage'),
        filterType: WineEntryType.WINE_STORAGE,
        color: 'warning',
        count: resolveTanks(tanks, selectedTankType).filter(
          (tank) => tank.entryType === WineEntryType.WINE_STORAGE
        ).length
      },
      {
        label: t('tanks.panel.filters.notUsed'),
        filterType: 'noUsage',
        color: 'grey',
        count: resolveTanks(tanks, selectedTankType).filter((tank) => !tank.entryType).length
      }
    ],
    [tanks, t, resolveTanks, selectedTankType]
  );

  const totalItemsCount = useMemo(
    () => tanksFilters.reduce((acc, item) => acc + item.count, 0),
    [tanksFilters]
  );

  function handleNoFilter() {
    setSelectedFilter('noFilter');
    localStorage.setItem(TANK_FILTER_KEY, 'noFilter');
    setFilteredTanks(resolveTanks(tanks, selectedTankType));
    setFilteredTanksOnlyByUsage(tanks);
  }

  return {
    tanksFilters,
    filteredTanks,
    filteredTanksOnlyByUsage,
    selectedFilter,
    setFilteredTanks,
    handleNoFilter,
    handleChangeFilter,
    totalItemsCount
  };
}
