import { useEffect } from 'react';
import { ServiceError, StatusType } from '../../../../../types/Service';
import { Ingredient } from '../types/Ingredient';
import { ResponseError } from '../../../../error/ResponseError';
import useIngredientService from './useIngredientService';
import { useParams } from 'react-router-dom';
import { useIngredientContext } from '../context/IngredientContext';
import { RouterParams } from '../../../../../types/RouterParams';
import { SESSION_STORAGE_KEY, useStorageSession } from '../../../../../hooks/useStorageSession';
import log from 'loglevel';
import { WineEntity } from '../../production_event/components/common/withProductionEventToWineServiceHOC';

interface ReturnProps {
  ingredient: Ingredient;
  error: ServiceError | undefined;
  loading: boolean;
}

export const useGetAppliedIngredient = (entity: WineEntity): ReturnProps => {
  const service = useIngredientService();
  const { appliedIngredientId, wineProductionId, wineId } = useParams<RouterParams>();
  const { ingredient, setIngredientResult, ingredientResult, loading } = useIngredientContext();

  const storageSession = useStorageSession<Ingredient>({
    entity: ingredient,
    key:
      entity === 'wine'
        ? SESSION_STORAGE_KEY.ADD_INGREDIENT_FOR_WINE + '_' + wineId
        : SESSION_STORAGE_KEY.ADD_INGREDIENT_FOR_WINE_PRODUCTION + '_' + wineProductionId,
    shouldSave: !appliedIngredientId
  });

  useEffect(() => {
    log.debug('useGetAppliedIngredient', appliedIngredientId);
    if (loading && appliedIngredientId && !ingredient.type) {
      const resolveAppliedIngredient = (appliedIngredient: Ingredient) => {
        return {
          notes: appliedIngredient.notes,
          name: appliedIngredient.name,
          type: appliedIngredient.type,
          amount: appliedIngredient.amount,
          ingredient: appliedIngredient.ingredient,
          appliedDate: appliedIngredient.appliedDate
        };
      };
      service
        .getApplied(parseInt(appliedIngredientId))
        .then((response) =>
          setIngredientResult({
            status: StatusType.loaded,
            payload: resolveAppliedIngredient(response)
          })
        )
        .catch((response) => new ResponseError(response));
    } else if (!appliedIngredientId) {
      const payload = storageSession.getItemPayload();
      setIngredientResult({ status: StatusType.loaded, payload });
    }
    // eslint-disable-next-line react-hooks/exhaustive-deps
  }, [appliedIngredientId]);

  const error =
    ingredientResult?.status === StatusType.error ? (ingredientResult as ServiceError) : undefined;

  return { ingredient, error, loading };
};
