import React, { useState } from 'react';
import PageWrapper from '../../../../layouts/PageWrapper';
import { DiscountCardListButtonsBar } from './list/DiscountCardListButtonsBar';
import { DiscountCardListTable } from './list/DiscountCardListTable';
import { DiscountCardForm } from './form/DiscountCardForm';
import { useDiscountCards } from './hooks/useDiscountCards';
import { DiscountCardButtonsBar } from './card/DiscountCardButtonsBar';
import { useParams } from 'react-router-dom';
import { RouterParams } from '../../../../types/RouterParams';
import { CardSelected } from './card/CardSelected';
import { CompaniesWithDiscountListTable } from './companies/CompaniesWithDiscountListTable';
import { usePushHistory } from '../../../../hooks/usePushHistory';

export type DiscountTab = 'list' | 'form';

export const AdminDiscountPage = () => {
  const { discountCardCode } = useParams<RouterParams>();
  const [showList, setShowList] = useState(!discountCardCode);
  const { pushHistory } = usePushHistory();
  const {
    state: {
      discountCards,
      showUsedCards,
      tab,
      loading,
      error,
      selectedDiscountCard,
      magicNumber,
      selectedDiscountCardMode,
      companies,
      loadingCompanies
    },
    actions: {
      addDiscountCard,
      toggleShowUsedCards,
      handleChangeTab,
      selectDiscountCard,
      removeDiscountCard,
      invalidateCard,
      notifyUsers,
      updateDiscountCard,
      toggleSelectedDiscountCardMode
    }
  } = useDiscountCards();

  return (
    <PageWrapper
      title={'sidebar.nav.element.DISCOUNT_CARDS'}
      onClick={() => pushHistory('/admin_console/companies/active')}
      returnText={'sidebar.nav.element.COMPANIES'}
    >
      <div className="row">
        <div className="col-lg-12">
          {discountCardCode || !showList ? null : (
            <>
              <DiscountCardListButtonsBar onClick={handleChangeTab} activeTab={tab} />
              {tab === 'list' ? (
                <DiscountCardListTable
                  loading={loading}
                  discountCards={discountCards}
                  showUsedCards={showUsedCards}
                  toggleShowUsedCards={toggleShowUsedCards}
                  selectDiscountCard={selectDiscountCard}
                  selectedDiscountCard={selectedDiscountCard}
                  key={
                    discountCards.length +
                    (showUsedCards + '') +
                    discountCards?.[0]?.discountCardId +
                    magicNumber
                  }
                />
              ) : null}
              {tab === 'form' ? (
                <DiscountCardForm
                  loading={loading}
                  addDiscountCard={addDiscountCard}
                  error={error}
                />
              ) : null}
            </>
          )}
          {selectedDiscountCard?.discountCardId ? (
            <>
              <DiscountCardButtonsBar
                removeDiscountCard={removeDiscountCard}
                invalidateCard={invalidateCard}
                notifyUsers={notifyUsers}
                selectedDiscountCardMode={selectedDiscountCardMode}
                toggleSelectedDiscountCardMode={toggleSelectedDiscountCardMode}
                selectedDiscountCard={selectedDiscountCard}
                showList={showList}
                setShowList={setShowList}
              />
              <div className={'row'}>
                <div className={'col-md-4'}>
                  <CardSelected
                    selectedDiscountCard={selectedDiscountCard}
                    selectedDiscountCardMode={selectedDiscountCardMode}
                    updateDiscountCard={updateDiscountCard}
                    loading={loading}
                    error={error}
                    key={
                      selectedDiscountCard.discountCardId +
                      (selectedDiscountCard.valid ? '1' : '2') +
                      magicNumber
                    }
                  />
                </div>
                <div className={'col-md-8'}>
                  <CompaniesWithDiscountListTable
                    companies={companies}
                    loading={loadingCompanies}
                    key={
                      selectedDiscountCard.discountCardId +
                      (selectedDiscountCard.valid + '') +
                      magicNumber
                    }
                  />
                </div>
              </div>
            </>
          ) : null}
        </div>
      </div>
    </PageWrapper>
  );
};
