import React from 'react';
import { useTranslation } from 'react-i18next';
import { useResponsiveContext } from '../../../../platform/ResponsiveContext';
import { useSearch } from '../../../../../hooks/useSearch';
import { DiscountCardReadModel } from '../../../hooks/useDiscountService';
import usePagination from '../../../../../hooks/usePagination';
import { SectionWrapper } from '../../../../../layouts/SectionWrapper';
import { PaginationComponent } from '../../../../../components/PaginationComponent';
import { Pagination } from '../../../../../types/Pagination';
import { DiscountCardRow } from './DiscountCardRow';
import { SearchComponent } from '../../../../../components/common/search/SearchComponent';

const Header = ({
  search,
  showUsedCards,
  toggleShowUsedCards
}: {
  search: (value: string) => void;
  showUsedCards: boolean;
  toggleShowUsedCards: () => void;
}) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsiveContext();

  function getMessage() {
    if (isMobile) return '';
    return showUsedCards
      ? t('discountCards.board.show.valid.btn')
      : t('discountCards.board.show.notValid.btn');
  }

  function getTitle() {
    return showUsedCards ? t('discountCards.board.list.notValid') : t('discountCards.board.list');
  }

  return (
    <>
      <small className={'text-muted float-right ' + (showUsedCards ? 'text-warning' : '')}>
        {getTitle()}
      </small>
      <div className="float-left">
        <SearchComponent search={search} />
        <button
          title={
            showUsedCards
              ? t('taxBands.board.show.available')
              : t('taxBands.board.show.inAvailable')
          }
          className={'ml-2 btn btn-sm ' + (showUsedCards ? 'btn-info' : 'btn-outline-info')}
          onClick={toggleShowUsedCards}
        >
          <em className="fa-1x fas fa-archive"></em>{' '}
          {isMobile ? null : <span className="ml-1">{getMessage()}</span>}
        </button>
      </div>
    </>
  );
};

const TableSection = ({ children }: { children: React.ReactNode }) => (
  <div className="dataTables_wrapper dt-bootstrap4 no-footer">
    <div className="row">
      <div className="col-sm-12 table-responsive">
        <table className="table w-100 no-footer table-hover table-sm">{children}</table>
      </div>
    </div>
  </div>
);

const THead = ({ short }: { short: boolean }) => {
  const { t } = useTranslation();
  return (
    <thead>
      <tr role="row">
        <th style={{ minWidth: '100px' }}>{t('discountCards.table.name')}</th>
        {!short ? (
          <>
            <th>{t('discountCards.table.code')}</th>
            <th className={'text-center'}>{t('discountCards.table.discountPrice')}</th>
            <th className={'text-center'}>{t('discountCards.table.discountPercent')}</th>
          </>
        ) : null}
        <th className={'text-center'}>{t('discountCards.table.usageCount')}</th>
        <th className={'text-center'}>{t('discountCards.table.subscriptionType')}</th>
        {!short ? (
          <>
            <th className={'text-center'}>{t('discountCards.table.createdDate')}</th>
            <th className={'text-center'}>{t('discountCards.table.startDate')}</th>
            <th className={'text-center'}>{t('discountCards.table.validDate')}</th>
            <th className={'text-center'}>{t('discountCards.table.usedDate')}</th>
            <th className={'text-center'}>{t('discountCards.table.subscriberId')}</th>
          </>
        ) : null}
      </tr>
    </thead>
  );
};

const TBody = ({
  short,
  pagination,
  selectDiscountCard,
  selectedDiscountCard
}: {
  short: boolean;
  pagination: Pagination<DiscountCardReadModel[]>;
  selectDiscountCard: ({ code }: { code: string }) => void;
  selectedDiscountCard: DiscountCardReadModel;
}) => (
  <tbody>
    {pagination.getCurrentResults().map((card) => {
      return (
        <DiscountCardRow
          discountCard={card}
          onClick={() => selectDiscountCard({ code: card.code })}
          selectedDiscountCard={selectedDiscountCard}
          key={card.discountCardId}
          short={short}
        />
      );
    })}
    {pagination.getCurrentResults().length === 0 ? (
      <tr>
        <td colSpan={100} className="text-center">
          Brak danych
        </td>
      </tr>
    ) : null}
  </tbody>
);

export const DiscountCardListTable: React.FC<{
  discountCards: DiscountCardReadModel[];
  loading: boolean;
  showUsedCards: boolean;
  toggleShowUsedCards: () => void;
  selectDiscountCard: ({ code }: { code: string }) => void;
  selectedDiscountCard: DiscountCardReadModel;
}> = ({
  discountCards,
  loading,
  showUsedCards,
  toggleShowUsedCards,
  selectDiscountCard,
  selectedDiscountCard
}) => {
  const { isMobile } = useResponsiveContext();

  const [result, search] = useSearch<DiscountCardReadModel>({
    entities: discountCards,
    field: [
      'name',
      'code',
      'discountPrice',
      'discountCardId',
      'usageCount',
      'discountPercent',
      'maxUsageCount',
      'subscriptionType',
      'subscriberId',
      'subscriberName'
    ]
  });
  const pagination = usePagination(result, 10);
  return (
    <SectionWrapper loading={loading} hideSpinner>
      <div className="card">
        <div className="card-body pb-1">
          <Header
            search={search}
            showUsedCards={showUsedCards}
            toggleShowUsedCards={toggleShowUsedCards}
          />
          <TableSection>
            <THead short={isMobile} />
            <TBody
              short={isMobile}
              pagination={pagination}
              selectDiscountCard={selectDiscountCard}
              selectedDiscountCard={selectedDiscountCard}
            />
          </TableSection>
        </div>
        <PaginationComponent pagination={pagination} />
      </div>
    </SectionWrapper>
  );
};
