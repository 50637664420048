import React from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';

import {
  CommonListBox,
  ListBoxActions,
  ListBoxElementToShow
} from '../../../wine/components/WineInfo/list-box/shared/CommonListBox';
import { useWineProductionContext } from '../../context/WineProductionContext';
import CommonRow from '../../../../../../components/common/table/CommonRow';
import { EntityLiveStatus } from '../../../../../../components/common/enums/EntityLiveStatus';
import { useWineContext } from '../../../wine/context/WineContext';
import { SectionWrapper } from '../../../../../../layouts/SectionWrapper';
import { BottleEntry, WineStorage } from '../../../wine_storage/types/WineStorage';
import { BottleInfo } from '../../../wine/components/WineInfo/list-box/shared/TankOutputsListBox';
import { BottleCapacity } from '../../../wine/components/WineInfo/list-box/shared/BottleOutputsListBox';
import { usePushHistory } from '../../../../../../hooks/usePushHistory';

interface Props {
  bottleEntries: BottleEntry[];
  elementToShow: ListBoxElementToShow;
  actions: ListBoxActions;
}

export const UsedWineStorage = ({ wineStorage }: { wineStorage?: Partial<WineStorage> }) => {
  const { pushHistory } = usePushHistory();
  if (!wineStorage) return <>-</>;
  return (
    <button
      className={'btn btn-link p-0'}
      onClick={() => pushHistory(`/mv/wine_storage/info/${wineStorage.id}`)}
    >
      {wineStorage.name}
    </button>
  );
};

const DisposedAsBottlesListBoxPresentation: React.FC<Props> = ({
  bottleEntries,
  elementToShow,
  actions
}) => {
  const { t } = useTranslation();
  const { loading: productionLoading, wineProduction } = useWineProductionContext();
  const { loading: wineLoading } = useWineContext();
  return (
    <SectionWrapper loading={productionLoading || wineLoading}>
      <Card>
        <CardBody>
          <h5 className="m-0 float-right">{t('wineProduction.info.DISPOSED_AS_BOTTLE_ENTRIES')}</h5>
          <small className="text-muted">
            {t('common.SUMMARY')}:{' '}
            {bottleEntries?.reduce((total, bottleEntry) => total + bottleEntry.quantity, 0)} szt. /{' '}
            {bottleEntries?.reduce(
              (total, bottleEntry) => total + bottleEntry.quantity * bottleEntry.bottle.capacity,
              0
            )}{' '}
            l.
          </small>
          {bottleEntries?.length ? (
            <Table className={'table w-100 no-footer table-hover'}>
              <tbody>
                <CommonRow
                  label={`${t('common.NAME')}:`}
                  value={[`${t('disposed.as.bottle.label')}:`, `${t('common.QUANTITY')}:`]}
                />
                {(bottleEntries || []).map((bottleEntry, key) => {
                  return (
                    <CommonListBox
                      header={[
                        <UsedWineStorage
                          wineStorage={bottleEntry?.wineStorageConsumer}
                          key={bottleEntry.id}
                        />,
                        <BottleCapacity bottle={bottleEntry.bottle} key={bottleEntry.id} />,
                        bottleEntry?.quantity?.toString()
                      ]}
                      actions={actions}
                      disableActions={wineProduction.liveStatus === EntityLiveStatus.ARCHIVED}
                      elementToSHow={elementToShow}
                      path={`wine_storage/info`}
                      elementId={bottleEntry?.id}
                      infoElementId={bottleEntry?.wineStorageConsumer?.id}
                      dropdownInfo={{
                        node: (
                          <>
                            <div>
                              Przekazano {bottleEntry.quantity * bottleEntry.bottle.capacity} l.
                            </div>
                            <BottleInfo bottle={bottleEntry.bottle} />
                          </>
                        )
                      }}
                      elementToShowKey={key}
                      key={key}
                    />
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <CardBody>
              <div className={'text-center'}>{t('common.NO_DATA')}</div>
            </CardBody>
          )}
        </CardBody>
      </Card>
    </SectionWrapper>
  );
};

export default DisposedAsBottlesListBoxPresentation;
