import React from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import CommonRow from '../../../../../../../../components/common/table/CommonRow';
import CardFooter from '../../../../../../../../components/common/cards/CardFooter';
import { FormErrorMessage } from '../../../../../../../../components/notifications/FormErrorMessage';
import { useIngredientContext } from '../../../../../ingredient/context/IngredientContext';
import { CommonListBox, ListBoxActions, ListBoxElementToShow } from './CommonListBox';
import { SectionWrapper } from '../../../../../../../../layouts/SectionWrapper';
import {
  Bottle,
  BottleSimpleData,
  TankOutput,
  TankOutputType
} from '../../../../../wine_storage/types/WineStorage';
import usePagination from '../../../../../../../../hooks/usePagination';
import { PaginationComponent } from '../../../../../../../../components/PaginationComponent';
import { usePushHistory } from '../../../../../../../../hooks/usePushHistory';

export const BottleInfo = ({ bottle }: { bottle: Bottle | BottleSimpleData }) => {
  const { pushHistory } = usePushHistory();
  return (
    <button
      className={'btn btn-link p-0'}
      onClick={() => pushHistory(`/mv/board/bottles/${bottle.id}`)}
    >
      {`${bottle.name} - ${bottle.capacity} l.`}
    </button>
  );
};

interface Props {
  tankOutputs: TankOutput[];
  elementToShow: ListBoxElementToShow;
  actions: Pick<ListBoxActions, 'toggleShow' | 'editElement' | 'removeElement'>;
  entityName: string;
  entityId?: number;
  disabledActions?: boolean;
  loading: boolean;
  title: string;
}

const TankOutputsListBox: React.FC<Props> = ({
  tankOutputs,
  elementToShow,
  actions,
  entityName,
  entityId,
  disabledActions,
  loading,
  title
}) => {
  const { t } = useTranslation();
  const { error } = useIngredientContext();
  const pagination = usePagination(tankOutputs, 10);

  return (
    <SectionWrapper loading={loading} hideSpinner>
      <Card>
        <CardBody>
          <h5 className="m-0 float-right">{title}</h5>
          <small className="text-muted">
            {t('common.SUMMARY')}:{' '}
            {tankOutputs?.map((output) => output.liters).reduce((total, item) => total + item, 0)}
            {' l.'}
          </small>
          {tankOutputs?.length ? (
            <Table className={'table w-100 no-footer table-hover'}>
              <tbody>
                <CommonRow
                  label={`${t('tankOutput.liters')}`}
                  value={[
                    `${t('tankOutput.tankNumber')}`,
                    `${t('tankOutput.type')}`,
                    t('tankOutput.date')
                  ]}
                  className={'text-center'}
                />
                {(pagination.getCurrentResults() || []).map((output, key) => {
                  return (
                    <CommonListBox
                      header={[
                        output.liters,
                        output?.tank.number,
                        <>
                          {t(`wine.OUTPUT.TYPE.${output?.outputType}`)}
                          {output.outputType === TankOutputType.BOTTLING ? (
                            <div style={{ marginTop: '-10px' }}>
                              <small
                                className={'text-muted'}
                              >{` (${output?.bottleEntry?.quantity} szt.)`}</small>
                            </div>
                          ) : null}
                        </>,
                        output.outputDate.toString()
                      ]}
                      actions={actions}
                      disableActions={disabledActions}
                      elementToSHow={elementToShow}
                      path={`${entityName}/tankOutput/${entityId}`}
                      elementId={output?.id}
                      dropdownInfo={{
                        paragraph: output.outputDescription,
                        footer:
                          output.outputType === TankOutputType.BOTTLING
                            ? {
                                label: '',
                                value: <BottleInfo bottle={output.bottleEntry.bottle} />
                              }
                            : undefined
                      }}
                      elementToShowKey={key}
                      key={key}
                      centerFirstColumn
                      dropdownClassName={'pl-2 max-w-80'}
                    />
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <CardBody>
              <div className={'text-center'}>{t('common.NO_DATA')}</div>
            </CardBody>
          )}
          {error?.error?.details && (
            <CardFooter>
              <FormErrorMessage error={error} messageType={'details'} />
            </CardFooter>
          )}
        </CardBody>
        <PaginationComponent pagination={pagination} />
      </Card>
    </SectionWrapper>
  );
};

export default TankOutputsListBox;
