import React, { useCallback, useState } from 'react';
import { useProductionEventContext } from '../context/ProductionEventContext';
import { Service, ServiceError, StatusType } from '../../../../../types/Service';
import log from 'loglevel';
import { ResponseError } from '../../../../error/ResponseError';
import { ProductionEvent } from '../../wine/types/Wine';
import { useParams } from 'react-router-dom';
import { ToApiConverter } from '../../../../../services/Converters';
import { useEventHandlerActions } from '../../../hooks/useEventHandlerActions';
import { RouterParams } from '../../../../../types/RouterParams';
import { SESSION_STORAGE_KEY, useStorageSession } from '../../../../../hooks/useStorageSession';
import { usePushHistory } from '../../../../../hooks/usePushHistory';
import { HISTORY_FALLBACK_TO } from '../../../../../services/Constants';

export const useProductionEventOnClickService = <T>(
  addEvent: (event: ProductionEvent) => Promise<T>,
  editEvent: (eventId: number, event: ProductionEvent) => Promise<T>,
  fieldName: 'wine' | 'wine_production',
  setResult: (value: Service<T>) => void
) => {
  const {
    productionEvent: event,
    setProductionEventResult,
    updateProductionEvent: update
  } = useProductionEventContext();

  const { wineId, wineProductionId } = useParams<RouterParams>();

  const storageSession = useStorageSession<ProductionEvent>({
    entity: event,
    key:
      fieldName === 'wine'
        ? SESSION_STORAGE_KEY.CREATE_EVENT_FOR_WINE + '_' + wineId
        : SESSION_STORAGE_KEY.CREATE_EVENT_FOR_WINE_PRODUCTION + '_' + wineProductionId,
    shouldSave: false
  });

  const [loading, setLoading] = useState(false);

  const { productionEventId } = useParams<RouterParams>();

  const [key, setKey] = useState(new Date());

  const { goBack } = usePushHistory();

  const {
    onChange,
    updateSelect: updateTypeSelect,
    updateDateTime: updateDate
  } = useEventHandlerActions(update);

  const saveOnSubmit = (e) => {
    e.preventDefault();
    setLoading(true);
    addEvent(ToApiConverter.convertEvent(event))
      .then((response) => {
        storageSession.removeItem();
        update('reset', '');
        setResult({ status: StatusType.loaded, payload: response });
        setKey(new Date());
      })
      .then(() => {
        window.scrollTo({ behavior: 'smooth', top: 0 });
      })
      .catch((response) => {
        log.debug(response);
        setProductionEventResult(new ResponseError(response) as ServiceError);
      })
      .finally(() => setLoading(false));
  };

  const updateOnSubmit = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      if (productionEventId) {
        setLoading(true);
        editEvent(parseInt(productionEventId), ToApiConverter.convertEvent(event))
          .then(() => goBack(HISTORY_FALLBACK_TO.WINERY_SUMMARY))
          .catch((response) => {
            log.debug(response);
            setProductionEventResult(new ResponseError(response) as ServiceError);
          })
          .finally(() => setLoading(false));
      }
    },
    [editEvent, event, goBack, productionEventId, setProductionEventResult]
  );

  return {
    onChange,
    updateTypeSelect,
    onSubmit: { update: updateOnSubmit, save: saveOnSubmit },
    updateDate,
    key,
    loading
  };
};
