import React from 'react';
import { useTranslation } from 'react-i18next';
import { Link, useParams } from 'react-router-dom';
import { SelectedDiscountCardMode } from '../hooks/useDiscountCards';
import { DiscountCardReadModel } from '../../../hooks/useDiscountService';
import { RouterParams } from '../../../../../types/RouterParams';
import { useConfirmation } from '../../../../notifications/useConfirmation';
import {
  DropdownActionButton,
  DropdownButtonAction
} from '../../../../home/components/BottlesSummaryPages/components/bottle/DropdownActionButton';
import { Color } from '../../../../../components/common/enums/Color';

export const DiscountCardButtonsBar: React.FC<{
  removeDiscountCard: (code: string) => void;
  invalidateCard: ({ code }: { code: string }) => void;
  notifyUsers: ({ code, subscriberId }: { code: string; subscriberId: number }) => void;
  selectedDiscountCardMode: SelectedDiscountCardMode;
  toggleSelectedDiscountCardMode: (newMode?: SelectedDiscountCardMode) => void;
  selectedDiscountCard: DiscountCardReadModel;
  showList: boolean;
  setShowList: (val: boolean) => void;
}> = ({
  removeDiscountCard,
  invalidateCard,
  selectedDiscountCard,
  selectedDiscountCardMode,
  toggleSelectedDiscountCardMode,
  showList,
  setShowList,
  notifyUsers
}) => {
  const { discountCardCode } = useParams<RouterParams>();

  const { t } = useTranslation();
  const showConfirmation = useConfirmation();
  const removeDiscountCardHandler = (code: string) => {
    showConfirmation({
      message: t('alerts.confirmation.REMOVE_discountCard'),
      actionLabel: 'Usuń',
      actionCallback: () => removeDiscountCard(code)
    });
  };
  const editButton: DropdownButtonAction = {
    label:
      selectedDiscountCardMode === 'card'
        ? t('discountCard.btn.action.edit.label')
        : t('discountCard.btn.action.cancel.label'),
    onClick: () => toggleSelectedDiscountCardMode(),
    color: selectedDiscountCardMode === 'card' ? Color.LightBlue : Color.Red
  };

  const notifyUsersButton: DropdownButtonAction = {
    label: t('button.NOTIFY_USERS'),
    onClick: () =>
      notifyUsers({
        code: selectedDiscountCard.code,
        subscriberId: selectedDiscountCard.subscriberId
      }),
    disabled: !selectedDiscountCard.subscriberId
  };

  const invalidateButton: DropdownButtonAction = {
    label: t('button.INVALIDATE'),
    onClick: () => invalidateCard({ code: selectedDiscountCard.code }),
    disabled: !selectedDiscountCard.valid
  };

  const removeButton: DropdownButtonAction = {
    label: t('discountCard.btn.action.remove.label'),
    onClick: () => removeDiscountCardHandler(selectedDiscountCard.code),
    color: Color.Red
  };
  return (
    <div className="mb-4">
      <div>
        <div className={'inline'}>
          <DropdownActionButton
            mainAction={editButton}
            dropdownActions={[invalidateButton, notifyUsersButton]}
            bottomAction={removeButton}
          />
          {discountCardCode ? (
            <Link
              to={'/admin_console/discount_cards'}
              className="btn btn-sm btn-secondary ml-2"
              type="button"
              title={t('button.show.list')}
              onClick={() => setShowList(true)}
            >
              <span className={'pr-2'}>{t('button.show.list.discountCards.show')}</span>
              <em className="fas fa-outdent" />
            </Link>
          ) : null}
          {!discountCardCode ? (
            <button
              className="btn btn-sm btn-secondary ml-2"
              type="button"
              onClick={() => setShowList(!showList)}
            >
              {
                <span className={'pr-2'}>
                  {showList
                    ? t('button.show.list.discountCards.hide')
                    : t('button.show.list.discountCards.show')}
                </span>
              }{' '}
              <em className="fas fa-outdent" />
            </button>
          ) : null}
        </div>
      </div>
    </div>
  );
};
