import React from 'react';
import PageWrapper from '../../../../../../../layouts/PageWrapper';
import { useTranslation } from 'react-i18next';
import { FormErrorMessage } from '../../../../../../../components/notifications/FormErrorMessage';
import { Card, CardBody } from 'reactstrap';
import { useParams } from 'react-router-dom';
import { RouterParams } from '../../../../../../../types/RouterParams';
import { useResponsiveContext } from '../../../../../../platform/ResponsiveContext';
import { useToggleAndGoToRef } from '../../../../../../../hooks/useToggleAndGoToRef';
import { Checkbox } from '../../../../../../../components/common/form-elements/Checkbox';
import { useAuthContext } from '../../../../../../platform/AuthContext';
import usePlanPicker from '../../../../../../subscription/hooks/usePlanPicker';
import { SubscriptionType } from '../../../../../../../types/Subscription';
import { DivDropped } from '../../../../../../../components/common/dropdowns/button-and-div/DivDropped';
import { InputColumn } from '../../../../../../../components/forms/InputColumn';
import { DatePickerColumn } from '../../../../../../../components/forms/DatePickerColumn';
import { TaxBandsPlacementForm } from '../../../types/WineStorage';
import { ServiceError } from '../../../../../../../types/Service';
import { SelectOption } from '../../../../../../../types/SelectOption';
import SelectTaxBands from '../../../../wine/components/WineForm/SelectTaxBands';
import { SectionWrapper } from '../../../../../../../layouts/SectionWrapper';

interface Props {
  loading: boolean;
  taxBandsPlacement: TaxBandsPlacementForm;
  updateDate: (name: string, date: string) => void;
  updateQuantity: (event: React.ChangeEvent<HTMLInputElement>) => void;
  error: ServiceError;
  onSubmit: () => void;
  onUpdate: () => void;
  updateCheckbox: (name: string, value: boolean) => void;
  updateSelectedTaxBands: (name: string, selected: SelectOption) => void;
  selectedTaxBands: SelectOption;
}

export const AddTaxBandsPlacementFormPresentation: React.FC<Props> = ({
  loading,
  taxBandsPlacement,
  updateDate,
  updateQuantity,
  error,
  onSubmit,
  onUpdate,
  updateCheckbox,
  updateSelectedTaxBands,
  selectedTaxBands
}) => {
  const { t } = useTranslation();
  const { taxBandsPlacementId } = useParams<RouterParams>();

  const { isMobile } = useResponsiveContext();

  const {
    subscriptionInfo: { accessInfo }
  } = useAuthContext();

  const {
    value: isOpen,
    toggleValue: toggleOpen,
    currentRef
  } = useToggleAndGoToRef(!!taxBandsPlacementId && accessInfo.hasPremiumAccess, isMobile);

  const { isValidSubscription } = usePlanPicker({
    subscriptionType: SubscriptionType.PREMIUM,
    fromBlockingContent: true,
    blockingMessage: 'Przejdź na wyższy plan, aby zarządzać banderolami',
    itemIndexToHighlight: 4
  });

  function handleOpen() {
    if (!isValidSubscription()) {
      return;
    }
    toggleOpen();
  }

  return (
    <PageWrapper loading={false} title={t('wine.info.EVENTS')} disabled={!taxBandsPlacementId}>
      <SectionWrapper loading={loading}>
        <Card
          innerRef={currentRef}
          className={isOpen ? 'border-info-dark form' : 'bg-info-dark form'}
        >
          <CardBody className={'pt-2 pb-1'}>
            <div
              onClick={() => handleOpen()}
              style={{ cursor: 'pointer', height: '1.5em' }}
              className={'pt-1'}
            >
              <i className="float-left">
                {!accessInfo.hasPremiumAccess ? (
                  <em className={'icon-lock'} />
                ) : (
                  <em
                    className={
                      isOpen ? 'fa fa-minus text-primary text-gradient-blue-purple' : 'fa fa-plus'
                    }
                  />
                )}
                <sup className="text-yellow ml-1"> premium</sup>
              </i>
              <h5 className={'m-0 mb-2 float-right ' + (isOpen ? 'text-gradient-blue-purple' : '')}>
                {taxBandsPlacementId ? t('taxBands.EDIT') : t('taxBands.ADD')}
              </h5>
            </div>
            <DivDropped open={isOpen}>
              <div className={'mt-2'}>
                <div className="">
                  {isOpen ? (
                    <SelectTaxBands
                      value={selectedTaxBands}
                      name={'taxBandsId'}
                      label={t('wineStorage.taxBandsPlacement.TAX_BANDS_ID')}
                      onChange={updateSelectedTaxBands}
                      disabled={!!taxBandsPlacementId}
                      error={error}
                    />
                  ) : null}
                  <InputColumn
                    name={'quantity'}
                    onChange={updateQuantity}
                    type={'number'}
                    error={error}
                    label={t('wineStorage.taxBandsPlacement.QUANTITY')}
                    placeholder={t('wineStorage.taxBandsPlacement.QUANTITY')}
                    disabled={!!taxBandsPlacementId}
                    value={taxBandsPlacement?.quantity}
                  />
                  <DatePickerColumn
                    name={'placementDate'}
                    value={taxBandsPlacement?.placementDate}
                    onChange={updateDate}
                    error={error}
                    label={t('wineStorage.taxBandsPlacement.PLACEMENT_DATE')}
                    disabled={!!taxBandsPlacementId}
                  />
                  <DatePickerColumn
                    name={'declarationDate'}
                    value={taxBandsPlacement?.declarationDate}
                    onChange={updateDate}
                    error={error}
                    label={t('wineStorage.taxBandsPlacement.DECLARATION_DATE')}
                    optional
                  />
                  <Checkbox
                    name={'payed'}
                    label={t('wineStorage.taxBandsPlacement.PAYED')}
                    value={!!taxBandsPlacement.payed}
                    onChange={() => updateCheckbox('payed', !taxBandsPlacement.payed)}
                    className={'pl-3'}
                  />
                </div>
                <FormErrorMessage error={error} messageType={'details'} clazzName={'p-1'} />
                <div className={'text-center bg-secondary'}>
                  <button
                    className="btn btn-info"
                    type="button"
                    onClick={taxBandsPlacementId ? onUpdate : onSubmit}
                    disabled={
                      // !taxBandsPlacement?.taxBandsId ||
                      !taxBandsPlacement?.placementDate || !taxBandsPlacement?.quantity
                    }
                  >
                    {' '}
                    {taxBandsPlacementId ? t('common.SAVE') : t('common.ADD')}
                  </button>
                </div>
              </div>
            </DivDropped>
          </CardBody>
        </Card>
      </SectionWrapper>
    </PageWrapper>
  );
};
