import React, { useCallback, useEffect, useRef, useState } from 'react';
import { ServiceError, StatusType } from '../../../../../../../types/Service';
import { ResponseError } from '../../../../../../error/ResponseError';
import { useWineStorageService } from '../../../hooks/useWineStorageService';
import { useWineStorageContext } from '../../../context/WineStorageContext';
import {
  Bottle,
  BottleEntry,
  BottleOutput,
  BottleOutputType,
  TankEntry,
  TankOutput,
  TankOutputType,
  WineStorage,
  WineStorageEntryType
} from '../../../types/WineStorage';
import { SelectOption, SelectOptionType } from '../../../../../../../types/SelectOption';
import { ToApiConverter } from '../../../../../../../services/Converters';
import {
  SESSION_STORAGE_KEY,
  useStorageSession
} from '../../../../../../../hooks/useStorageSession';
import { useParams } from 'react-router-dom';
import { RouterParams } from '../../../../../../../types/RouterParams';

export type UpdateBottleType = 'bottle' | 'tank';

export const withAddWineStorageOutputService =
  <Props,>(WrappedComponent: React.ComponentType<Props>) =>
  (props) => {
    const service = useWineStorageService();
    const { setResult } = useWineStorageContext();
    const { wineStorageId } = useParams<RouterParams>();
    const [loading, setLoading] = useState(false);
    const [entryType, setEntryType] = useState<WineStorageEntryType>();
    const [tankEntry, setTankEntry] = useState<Partial<TankEntry>>({});
    const [bottleOutput, setBottleOutput] = useState<BottleOutput>({
      hasTaxBands: true,
      outputDate: undefined,
      outputType: BottleOutputType.SALE,
      id: undefined,
      bottle: undefined,
      quantity: undefined,
      outputDescription: undefined
    });
    const [tankOutput, setTankOutput] = useState<TankOutput>({
      outputDate: new Date(),
      outputType: TankOutputType.SALE,
      id: undefined,
      liters: undefined,
      bottleEntry: undefined,
      tank: undefined,
      outputDescription: undefined
    });
    const [error, setError] = useState<ServiceError>();

    const triggerBottleOutput = useRef(false);
    const triggerTankOutput = useRef(false);

    const storageSessionForBottleOutput = useStorageSession<BottleEntry | Partial<BottleOutput>>({
      entity: bottleOutput,
      key: SESSION_STORAGE_KEY.ADD_BOTTLE_OUTPUT + '_' + wineStorageId,
      shouldSave: true
    });

    const storageSessionForTankOutput = useStorageSession<TankOutput | Partial<TankOutput>>({
      entity: tankOutput,
      key: SESSION_STORAGE_KEY.ADD_TANK_OUTPUT + '_' + wineStorageId,
      shouldSave: true
    });

    const storageSessionForEntryType = useStorageSession<WineStorageEntryType>({
      entity: entryType,
      key: SESSION_STORAGE_KEY.OUTPUT_TYPE + '_' + wineStorageId,
      shouldSave: true
    });

    useEffect(() => {
      if (!triggerBottleOutput.current) {
        const payload = storageSessionForBottleOutput.getItemPayload();
        payload && setBottleOutput(payload);
        triggerBottleOutput.current = true;
      }
    }, [entryType]);

    useEffect(() => {
      if (!triggerTankOutput.current) {
        const payload = storageSessionForTankOutput.getItemPayload();
        payload && setTankOutput(payload);
        triggerTankOutput.current = true;
      }
    }, [entryType]);

    useEffect(() => {
      if (!entryType) {
        const entryTypePayload = storageSessionForEntryType.getItemPayload();
        entryTypePayload && setEntryType(entryTypePayload);
      }
    }, [entryType]);

    const updateEntryType = (name, selected) => {
      setEntryType(selected.value);
      setBottleOutput((output) => ({ ...output, outputType: null }));
      setTankOutput((output) => ({ ...output, outputType: null }));
      setTankEntry({});
    };

    const resetForm = () => {
      setEntryType(undefined);
      setTankEntry({});
    };

    const updateLiters = (event: React.ChangeEvent<HTMLInputElement>) => {
      const liters = event.target.value;
      setTankOutput((output) => ({ ...output, liters: parseInt?.(liters) }));
    };

    const updateTankOutput = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
      const name = event.target.name;
      const value = event.target.value;
      setTankOutput((prevState) => ({ ...prevState, [name]: value }));
    }, []);

    const updateBottleOutput = useCallback((event: React.ChangeEvent<HTMLInputElement>) => {
      const name = event.target.name;
      const value = event.target.value;
      setBottleOutput((prevState) => ({ ...prevState, [name]: value }));
    }, []);

    const updateCheckbox = (name: string, value: boolean) => {
      setBottleOutput((output) => ({ ...output, [name]: value }));
    };

    const handleUpdateSelect = (name: string, selected: SelectOption) => {
      setBottleOutput((output) => ({ ...output, [name]: selected.value }));
      setTankOutput((output) => ({ ...output, [name]: selected.value }));
    };

    const handleUpdateTankSelect = (selected: SelectOptionType<number>) => {
      const selectedTank = { id: selected.value, label: selected.label };
      setTankOutput((output) => ({ ...output, tank: { ...output.tank, ...selectedTank } }));
    };

    const updateDate = (dateName: string, date: Date) => {
      setBottleOutput((output) => ({
        ...output,
        [dateName]: date ? ToApiConverter.convertDate(date) : ''
      }));
      setTankOutput((output) => ({
        ...output,
        [dateName]: date ? ToApiConverter.convertDate(date) : ''
      }));
    };

    const updateQuantity = (event: React.ChangeEvent<HTMLInputElement>) => {
      const quantity = event.target.value;
      quantity && setBottleOutput((output) => ({ ...output, quantity: parseInt?.(quantity) }));
    };

    const updateBottleEntryInTankOutput = (event: React.ChangeEvent<HTMLInputElement>) => {
      const quantity = event.target.value;
      quantity &&
        setTankOutput((output) => ({
          ...output,
          bottleEntry: { ...output.bottleEntry, quantity: parseInt?.(quantity) }
        }));
    };

    const updateBottle = (
      output: UpdateBottleType,
      selected: SelectOptionType<number> & Pick<Bottle, 'capacity'>
    ) => {
      const selectedBottle = {
        id: selected.value,
        label: selected.label,
        capacity: selected.capacity
      };
      if (output === 'bottle') {
        setBottleOutput((output) => ({
          ...output,
          bottle: { ...output.bottle, ...selectedBottle }
        }));
      } else if (output === 'tank') {
        setTankOutput((output) => ({
          ...output,
          bottleEntry: {
            ...output.bottleEntry,
            bottle: { ...output?.bottleEntry?.bottle, ...selectedBottle }
          }
        }));
      }
    };

    const onSubmit = () => {
      setLoading(true);
      const handleResponse = (response: WineStorage) => {
        storageSessionForBottleOutput.removeItem();
        storageSessionForTankOutput.removeItem();
        storageSessionForEntryType.removeItem();
        setResult({ status: StatusType.loaded, payload: response });
        setLoading(false);
        resetForm();
      };

      const handleError = (response: { message: string; errors: NonNullable<unknown> }) => {
        setError(new ResponseError(response));
        setLoading(false);
      };

      if (entryType === WineStorageEntryType.BOTTLE) {
        service
          .addBottleOutput(bottleOutput)
          .then((response) => {
            handleResponse(response);
          })
          .then(() => {
            window.scrollTo({ behavior: 'smooth', top: 0 });
          })
          .catch(handleError);
      }
      if (entryType === WineStorageEntryType.TANK) {
        service
          .addTankOutput(tankOutput)
          .then((response) => {
            handleResponse(response);
          })
          .then(() => {
            window.scrollTo({ behavior: 'smooth', top: 0 });
          })
          .catch(handleError);
      }
    };

    const newProps = {
      ...props,
      entryType,
      updateEntryType,
      tank: tankEntry.tank,
      updateQuantity,
      onSubmit,
      error,
      updateCheckbox,
      hasTaxBands: !!bottleOutput.hasTaxBands,
      handleUpdateSelect,
      handleUpdateTankSelect,
      outputType: bottleOutput.outputType,
      updateDate,
      outputDate: bottleOutput.outputDate,
      loading,
      tankOutput,
      updateLiters,
      updateBottleEntryInTankOutput,
      updateBottle,
      updateTankOutput,
      updateBottleOutput,
      bottle: bottleOutput.bottle,
      bottleOutput
    };

    return <WrappedComponent {...newProps} />;
  };
