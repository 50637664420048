import React from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import CommonRow from '../../../../components/common/table/CommonRow';
import { CompanyInfoData } from '../../types';
import { useTranslation } from 'react-i18next';
import { SectionWrapper } from '../../../../layouts/SectionWrapper';

interface IProps {
  company: CompanyInfoData;
  actions: { deactivateCompany: (id: number) => void; activateCompany: (id: number) => void };
  loading: boolean;
}

export const CompanySummary: React.FC<IProps> = ({
  company,
  actions: { deactivateCompany, activateCompany },
  loading
}) => {
  const { t } = useTranslation();

  return (
    <SectionWrapper loading={loading} hideSpinner>
      <Card>
        <CardBody>
          <span className="text-muted float-left">{company.name}</span>
          <Table>
            <tbody>
              <CommonRow
                label={t('company.summary.status')}
                value={
                  <div>
                    <span className="pr-2">
                      {t(
                        company.active
                          ? 'company.summary.status.active'
                          : 'company.summary.status.inactive'
                      )}
                    </span>
                    <button
                      className={'btn btn-xs ' + (company.active ? 'btn-green' : 'btn-danger')}
                      type="button"
                      title={t(
                        company.active
                          ? 'company.summary.run.active'
                          : 'company.summary.run.inactive'
                      )}
                      onClick={() =>
                        company.active ? deactivateCompany(company.id) : activateCompany(company.id)
                      }
                    >
                      <span data-toggle="tooltip" title="">
                        <span className={'fa fa-' + (company.active ? 'unlock' : 'lock')} />
                      </span>
                    </button>
                  </div>
                }
                valueColumnClassName={
                  'text-right ' + (company.active ? 'text-green' : 'text-danger')
                }
              />
              <CommonRow
                label={t('company.summary.tin')}
                value={company?.tin || 'Brak'}
                valueColumnClassName={'text-right'}
              />
              <CommonRow
                label={t('company.summary.created')}
                value={`${company.created?.split('T').join(' ')}`}
                valueColumnClassName={'text-right'}
              />
              <CommonRow
                label={t('company.summary.users')}
                value={`${company.users?.filter((u) => u.active).length} / ${
                  company.users?.length || 0
                }`}
                valueColumnClassName={'text-right'}
              />
            </tbody>
          </Table>
          {company.description ? (
            <>
              {' '}
              <span className="text-muted float-right">
                <small>{t('company.summary.description')}</small>
              </span>
              <Table>
                <tbody>
                  <tr>
                    <td colSpan={2}>
                      <div>{company.description}</div>
                    </td>
                  </tr>
                </tbody>
              </Table>
            </>
          ) : null}
        </CardBody>
      </Card>
    </SectionWrapper>
  );
};
