import React from 'react';
import { useTranslation } from 'react-i18next';
import { CompanyInfoList } from '../../../types';
import { useSearch } from '../../../../../hooks/useSearch';
import usePagination from '../../../../../hooks/usePagination';
import { usePushHistory } from '../../../../../hooks/usePushHistory';
import { SectionWrapper } from '../../../../../layouts/SectionWrapper';
import { SearchComponent } from '../../../../../components/common/search/SearchComponent';
import { CompanyWithDiscountRow } from './CompanyWithDiscountRow';
import { PaginationComponent } from '../../../../../components/PaginationComponent';

export const CompaniesWithDiscountListTable: React.FC<{
  companies: CompanyInfoList[];
  loading: boolean;
}> = ({ companies, loading }) => {
  const { t } = useTranslation();
  const [result, search] = useSearch<CompanyInfoList>({
    entities: companies,
    field: [
      'id',
      'name',
      'subscriptionType',
      'subscriptionPeriod',
      'subscriptionStatus',
      'subscriptionValidToDate',
      'created',
      'hasActiveTrial'
    ]
  });
  const pagination = usePagination(result, 10);

  const { pushHistory } = usePushHistory();

  function navigateToCompany(id: number) {
    pushHistory(`/admin_console/company/${id}`);
  }

  return (
    <SectionWrapper loading={loading} hideSpinner>
      <div className="card">
        <div className="card-body pb-1">
          <small className="text-muted float-right">
            {t('discountCard.companies.table.title')}
          </small>
          <div className="float-left">
            <SearchComponent search={search} />
          </div>
          <div className="dataTables_wrapper dt-bootstrap4 no-footer">
            <div className="row">
              <div className="col-sm-12 table-responsive">
                <table className="table w-100 no-footer table-hover" style={{ width: 1068 }}>
                  <thead>
                    <tr role="row">
                      <th>{t('companies.table.name')}</th>
                      <th className="text-center">{t('companies.table.subscriptionType')}</th>
                      <th className="text-center">{t('companies.table.subscriptionStatus')}</th>
                    </tr>
                  </thead>
                  <tbody>
                    {pagination.getCurrentResults().map((company) => {
                      return (
                        <CompanyWithDiscountRow
                          company={company}
                          onClick={() => navigateToCompany(company.id)}
                          key={company.id}
                        />
                      );
                    })}
                    {pagination.getCurrentResults().length === 0 ? (
                      <tr>
                        <td colSpan={100} className="text-center">
                          Brak danych
                        </td>
                      </tr>
                    ) : null}
                  </tbody>
                </table>
              </div>
            </div>
          </div>
        </div>
        <PaginationComponent pagination={pagination} />
      </div>
    </SectionWrapper>
  );
};
