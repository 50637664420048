import { TaxBandsReadModel } from '../../../../../types/TaxBands';
import { useCallback, useEffect, useState } from 'react';
import { useParams } from 'react-router-dom';
import { RouterParams } from '../../../../../types/RouterParams';
import log from 'loglevel';
import { useTaxBandsService } from './useTaxBandsService';

const defaultTaxBands: TaxBandsReadModel = {
  id: undefined,
  label: '',
  initialQuantity: undefined,
  handingOverDate: undefined,
  usedDate: undefined,
  used: undefined,
  damaged: undefined,
  damagedDate: undefined,
  destroyed: undefined,
  destroyedDate: undefined,
  lost: undefined,
  lostDate: undefined,
  returned: undefined,
  returnedDate: undefined,
  available: false,
  availableQuantity: undefined,
  liveStatus: undefined
};

export function useSelectedTaxBands({
  taxBands
}: {
  taxBands: TaxBandsReadModel[];
}): [
  selectedTaxBands: TaxBandsReadModel,
  selectTaxBands: ({ id }: { id: number; singleTaxBands?: TaxBandsReadModel }) => void
] {
  const [selectedTaxBands, setSelectedTaxBands] = useState<TaxBandsReadModel>(defaultTaxBands);
  const { taxBandsId } = useParams<RouterParams>();
  const service = useTaxBandsService();

  const findTaxBands = useCallback((id: number) => {
    id &&
      service
        .get(id)
        .then((taxBands) => setSelectedTaxBands(taxBands))
        .catch((error) => log.error(error));
  }, []); // eslint-disable-line react-hooks/exhaustive-deps

  const selectTaxBands = useCallback(
    ({ id, singleTaxBands }: { id: number; singleTaxBands?: TaxBandsReadModel }) => {
      if (id === null) {
        setSelectedTaxBands(defaultTaxBands);
        return;
      }
      if (taxBandsId) {
        findTaxBands(id);
      } else if (singleTaxBands) {
        setSelectedTaxBands(singleTaxBands);
      } else {
        const taxBandsFromList = taxBands.find((bands) => bands.id === id);
        taxBandsFromList && setSelectedTaxBands(taxBandsFromList);
        !taxBandsFromList && findTaxBands(id);
      }
    },
    [taxBands, findTaxBands, taxBandsId]
  );

  useEffect(() => {
    if (taxBandsId) {
      selectTaxBands({ id: Number(taxBandsId) });
    }
  }, [taxBandsId, selectTaxBands]);

  return [selectedTaxBands, selectTaxBands];
}
