import { Checkbox } from '../../../../../../../components/common/form-elements/Checkbox';
import { SelectStorageEntryType } from '../SelectStorageEntryType';
import {
  BottleOutput,
  BottleOutputType,
  BottleSimpleData,
  TankOutput,
  TankOutputType,
  WineStorageEntryType
} from '../../../types/WineStorage';
import { FormErrorMessage } from '../../../../../../../components/notifications/FormErrorMessage';
import React, { FC, useCallback } from 'react';
import { Card, CardBody } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import { Tank } from '../../../../tank/types/Tank';
import { ServiceError } from '../../../../../../../types/Service';
import SelectBottleOutputType from './SelectBottleOutputType';
import SelectTankOutputType from './SelectTankOutputType';
import SelectTankInStorage from './SelectTankInStorage';
import { useWineStorageContext } from '../../../context/WineStorageContext';
import { useResponsiveContext } from '../../../../../../platform/ResponsiveContext';
import { useToggleAndGoToRef } from '../../../../../../../hooks/useToggleAndGoToRef';
import { useAuthContext } from '../../../../../../platform/AuthContext';
import usePlanPicker from '../../../../../../subscription/hooks/usePlanPicker';
import { SubscriptionType } from '../../../../../../../types/Subscription';
import { SectionWrapper } from '../../../../../../../layouts/SectionWrapper';
import { DivDropped } from '../../../../../../../components/common/dropdowns/button-and-div/DivDropped';
import { DatePickerColumn } from '../../../../../../../components/forms/DatePickerColumn';
import { InputColumn } from '../../../../../../../components/forms/InputColumn';
import SelectBottlesForOutput from '../../../../wine/components/WineForm/SelectBottlesForOutput';
import { UpdateBottleType } from './withAddWineStorageOutputService';
import { SelectOptionType } from '../../../../../../../types/SelectOption';
import SelectBottlesForBottling from '../../../../wine/components/WineForm/SelectBottlesForBottling';
import { TextareaColumn } from '../../../../../../../components/forms/TextareaColumn';

interface Props {
  updateCheckbox: (name: string, value: boolean) => void;
  entryType: WineStorageEntryType;
  updateEntryType: () => void;
  tank: Tank;
  updateQuantity: () => void;
  updateBottleEntryInTankOutput: () => void;
  liters: number;
  updateLiters: () => void;
  onSubmit: () => void;
  error: ServiceError;
  loading: boolean;
  hasTaxBands: boolean;
  outputType: BottleOutputType;
  handleUpdateSelect: () => void;
  handleUpdateTankSelect: () => void;
  updateDate: () => void;
  outputDate: string;
  tankOutput: TankOutput;
  bottle: BottleSimpleData;
  updateBottle: (output: UpdateBottleType, selected: SelectOptionType<number>) => void;
  updateTankOutput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  updateBottleOutput: (event: React.ChangeEvent<HTMLInputElement>) => void;
  bottleOutput: BottleOutput;
}

export const AddStorageOutputFormPresentation: FC<Props> = ({
  entryType,
  updateEntryType,
  updateQuantity,
  onSubmit,
  error,
  loading,
  outputType,
  hasTaxBands,
  updateCheckbox,
  handleUpdateSelect,
  handleUpdateTankSelect,
  updateDate,
  outputDate,
  updateBottleEntryInTankOutput,
  updateLiters,
  tankOutput,
  bottle,
  updateBottle,
  updateTankOutput,
  updateBottleOutput,
  bottleOutput
}) => {
  const { t } = useTranslation();
  const { isMobile } = useResponsiveContext();
  const {
    value: isOpen,
    toggleValue: toggleOpen,
    currentRef
  } = useToggleAndGoToRef(false, isMobile);
  const { wineStorage } = useWineStorageContext();
  const getBottleQuantityPlaceholder = useCallback(() => {
    return tankOutput.liters && tankOutput.bottleEntry?.bottle?.capacity
      ? 'max ' + Math.floor(tankOutput.liters / tankOutput.bottleEntry.bottle.capacity) + ' l.'
      : '';
  }, [tankOutput.liters, tankOutput.bottleEntry?.bottle?.capacity]);

  const {
    subscriptionInfo: { accessInfo }
  } = useAuthContext();

  const { isValidSubscription } = usePlanPicker({
    subscriptionType: SubscriptionType.PREMIUM,
    fromBlockingContent: true,
    blockingMessage: 'Przejdź na wyższy plan, aby rozdysponować wino',
    itemIndexToHighlight: 5
  });

  function handleOpen() {
    if (!isValidSubscription()) {
      return;
    }
    toggleOpen();
  }

  return (
    <SectionWrapper loading={loading}>
      <Card
        innerRef={currentRef}
        className={isOpen ? 'border-info-dark form' : 'bg-info-dark form'}
      >
        <CardBody className={'pt-2 pb-1'}>
          <div
            onClick={() => handleOpen()}
            style={{ cursor: 'pointer', height: '1.5em' }}
            className={'pt-1'}
          >
            <i className="float-left">
              {!accessInfo.hasPremiumAccess ? (
                <em className={'icon-lock'} />
              ) : (
                <em
                  className={
                    isOpen ? 'fa fa-minus text-primary text-gradient-blue-purple' : 'fa fa-plus'
                  }
                />
              )}
              <sup className="text-yellow ml-1"> premium</sup>
            </i>
            <h5 className={'m-0 mb-2 float-right ' + (isOpen ? 'text-gradient-blue-purple' : '')}>
              {t('wineStorage.addOutput')}
            </h5>
          </div>
          <DivDropped open={isOpen}>
            <div className={'mt-2'}>
              <SelectStorageEntryType
                name={'entryType'}
                value={entryType}
                onChange={updateEntryType}
                optional
                label={t('wineStorage.entry.TYPE')}
                key={entryType}
              />
              {entryType === WineStorageEntryType.BOTTLE ? (
                <>
                  <SelectBottlesForOutput
                    value={bottle}
                    name={'bottle'}
                    label={t('wineStorage.entry.BOTTLE')}
                    onChange={(selected) => updateBottle('bottle', selected)}
                    error={error}
                  />
                  <SelectBottleOutputType
                    value={outputType}
                    name={'outputType'}
                    label={t('wineStorage.outputType')}
                    onChange={handleUpdateSelect}
                    error={error}
                  />
                </>
              ) : null}
              {entryType === WineStorageEntryType.TANK ? (
                <SelectTankOutputType
                  value={outputType}
                  name={'outputType'}
                  label={t('wineStorage.outputType')}
                  onChange={handleUpdateSelect}
                  error={error}
                />
              ) : null}
              <DatePickerColumn
                label={t('bottleOutput.date')}
                name={'outputDate'}
                onChange={updateDate}
                value={outputDate}
                error={error}
              />
              {entryType === WineStorageEntryType.TANK && (
                <SelectTankInStorage
                  value={tankOutput.tank}
                  name={'tank'}
                  onChange={handleUpdateTankSelect}
                  label={t('wineStorage.entry.TANK')}
                  error={error}
                  tanks={wineStorage.tankEntries?.map((entry) => entry.tank)}
                />
              )}
              {entryType === WineStorageEntryType.BOTTLE && (
                <>
                  <InputColumn
                    name={'quantity'}
                    onChange={updateQuantity}
                    label={t('wineStorage.entry.QUANTITY')}
                    placeholder={''}
                    type={'number'}
                    error={error}
                    value={bottleOutput.quantity}
                  />
                  <TextareaColumn
                    name={'outputDescription'}
                    onChange={updateBottleOutput}
                    label={t('wineStorage.entry.DESCRIPTION')}
                    placeholder={''}
                    error={error}
                    value={bottleOutput.outputDescription}
                    maxLength={100}
                    optional
                  />
                  <div className="row pl-2 ml-2 pb-2">
                    <Checkbox
                      name={'withTaxBands'}
                      label={t('wineStorage.entry.checkbox.withTaxBands')}
                      value={hasTaxBands}
                      onChange={() => updateCheckbox('hasTaxBands', !hasTaxBands)}
                    />
                  </div>
                </>
              )}
              {entryType === WineStorageEntryType.TANK ? (
                <>
                  <InputColumn
                    name={'liters'}
                    onChange={updateLiters}
                    value={tankOutput.liters}
                    type={'number'}
                    label={t('wineStorage.tank.bottleEntry.LITERS')}
                    error={error}
                  />
                  <TextareaColumn
                    name={'outputDescription'}
                    onChange={updateTankOutput}
                    label={t('wineStorage.entry.DESCRIPTION')}
                    placeholder={''}
                    error={error}
                    value={tankOutput.outputDescription}
                    maxLength={100}
                    optional
                  />
                </>
              ) : null}
              {entryType === WineStorageEntryType.TANK &&
              tankOutput.outputType === TankOutputType.BOTTLING ? (
                <>
                  <SelectBottlesForBottling
                    value={tankOutput.bottleEntry?.bottle}
                    name={'bottle'}
                    label={t('wineStorage.entry.BOTTLE')}
                    onChange={(selected) => updateBottle('tank', selected)}
                    error={error}
                  />
                  <InputColumn
                    name={'quantity'}
                    onChange={updateBottleEntryInTankOutput}
                    value={tankOutput.bottleEntry?.quantity}
                    placeholder={getBottleQuantityPlaceholder()}
                    type={'number'}
                    label={t('wineStorage.tank.bottleEntry.QUANTITY')}
                    error={error}
                  />
                </>
              ) : null}
              <FormErrorMessage error={error} messageType={'details'} clazzName={'p-1'} />
              <div className={'text-center bg-secondary'}>
                <button
                  className="btn btn-info"
                  type="button"
                  onClick={onSubmit}
                  disabled={!entryType}
                >
                  {' '}
                  {t('common.ADD')}
                </button>
              </div>
            </div>
          </DivDropped>
        </CardBody>
      </Card>
    </SectionWrapper>
  );
};
