import React from 'react';
import { useTranslation } from 'react-i18next';
import { DiscountCardReadModel } from '../../../hooks/useDiscountService';
import { usePushHistory } from '../../../../../hooks/usePushHistory';

interface IProps {
  discountCard: DiscountCardReadModel;
  short: boolean;
  onClick: () => void;
  selectedDiscountCard: DiscountCardReadModel;
}

export const DiscountCardRow: React.FC<IProps> = ({
  discountCard,
  short,
  selectedDiscountCard,
  onClick
}) => {
  const { pushHistory } = usePushHistory();
  const { t } = useTranslation();
  return (
    <tr
      className={
        'odd parent ' +
        (selectedDiscountCard?.discountCardId === discountCard.discountCardId
          ? 'bg-gray-lighter bg-gray-lighter-dark'
          : '')
      }
      style={{ cursor: 'pointer', userSelect: 'none', WebkitUserSelect: 'none' }}
      onClick={onClick}
    >
      <td className="text-bold">{discountCard.name}</td>
      {!short ? (
        <>
          <td>{discountCard.code}</td>
          <td className={'text-center'}>{discountCard.discountPrice ?? '-'}</td>
          <td className={'text-center'}>{discountCard.discountPercent ?? '-'}</td>
        </>
      ) : null}
      <td className={'text-center'}>
        {discountCard.maxUsageCount
          ? `${discountCard.usageCount} / ${discountCard.maxUsageCount}`
          : discountCard.usageCount}
      </td>
      <td className={'text-center'}>
        {t(`plans.subscription.type.${discountCard.subscriptionType || 'all'}`)}
      </td>
      {!short ? (
        <>
          <td className={'text-center'}>{discountCard.createdDate}</td>
          <td className={'text-center'}>{discountCard.startDate}</td>
          <td className={'text-center'}>{discountCard.validDate}</td>
          <td className={'text-center'}>{discountCard.usedDate ?? '-'}</td>
          <td className={'text-center'}>
            {discountCard.subscriberId ? (
              <button
                className={'btn btn-link py-0'}
                onClick={() => pushHistory(`/admin_console/company/${discountCard.subscriberId}`)}
              >
                {discountCard.subscriberName}
              </button>
            ) : (
              '-'
            )}
          </td>
        </>
      ) : null}
    </tr>
  );
};
