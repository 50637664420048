import React, { useCallback, useState } from 'react';
import { useIngredientContext } from '../context/IngredientContext';
import { Service, StatusType } from '../../../../../types/Service';
import log from 'loglevel';
import { ResponseError } from '../../../../error/ResponseError';
import { Ingredient } from '../types/Ingredient';
import { useParams } from 'react-router-dom';
import { useEventHandlerActions } from '../../../hooks/useEventHandlerActions';
import { ToApiConverter } from '../../../../../services/Converters';
import { RouterParams } from '../../../../../types/RouterParams';
import { publish } from '../../../../../events/events';
import { SESSION_STORAGE_KEY, useStorageSession } from '../../../../../hooks/useStorageSession';
import { usePushHistory } from '../../../../../hooks/usePushHistory';
import { HISTORY_FALLBACK_TO } from '../../../../../services/Constants';

export const useIngredientOnClickService = <T>(
  addIngredient: (ingredient: Ingredient) => Promise<T>,
  putIngredient: (ingredientId: number, ingredient: Ingredient) => Promise<T>,
  fieldName: 'wine' | 'wine_production',
  setResult: (value: Service<T>) => void
) => {
  const { updateIngredient, ingredient, setIngredientResult } = useIngredientContext();
  const { goBack } = usePushHistory();
  const { appliedIngredientId, wineId, wineProductionId } = useParams<RouterParams>();
  const [key, setKey] = useState(new Date());
  const [loading, setLoading] = useState(false);

  const { onChange, updateDateTime: updateDate } = useEventHandlerActions(updateIngredient);

  const storageSession = useStorageSession<Ingredient>({
    entity: ingredient,
    key:
      fieldName === 'wine'
        ? SESSION_STORAGE_KEY.ADD_INGREDIENT_FOR_WINE + '_' + wineId
        : SESSION_STORAGE_KEY.ADD_INGREDIENT_FOR_WINE_PRODUCTION + '_' + wineProductionId,
    shouldSave: false
  });

  const updateIngredientSelect = (selected) => {
    updateIngredient('ingredient', { ...selected, id: selected.value, label: selected.label });
    updateIngredient('type', selected.type);
  };

  const updateTypeSelect = (name, selected) => {
    updateIngredient(name, selected.value);
    updateIngredient('ingredient', {});
  };

  const updateOnSubmit = useCallback(
    (e: React.MouseEvent) => {
      e.preventDefault();
      if (appliedIngredientId) {
        setLoading(true);
        putIngredient(parseInt(appliedIngredientId), ToApiConverter.convertIngredient(ingredient))
          .then(() => goBack(HISTORY_FALLBACK_TO.WINERY_SUMMARY))
          .catch((response) => {
            log.debug(response);
            setIngredientResult(new ResponseError(response));
          })
          .finally(() => setLoading(false));
      }
    },
    [ingredient, appliedIngredientId, setIngredientResult, goBack, putIngredient]
  );

  const saveOnSubmit = useCallback(
    (e) => {
      e.preventDefault();
      setLoading(true);
      addIngredient(ToApiConverter.convertIngredient(ingredient))
        .then((response) => {
          storageSession.removeItem();
          updateIngredient('reset', '');
          setResult({ status: StatusType.loaded, payload: response });
          setKey(new Date());
        })
        .then(() => {
          window.scrollTo({ behavior: 'smooth', top: 0 });
          publish('onSubmitIngredient');
        })
        .catch((response) => {
          log.debug(response);
          setIngredientResult(new ResponseError(response));
        })
        .finally(() => setLoading(false));
    },
    [ingredient, setResult, updateIngredient, setIngredientResult, addIngredient, storageSession]
  );

  return {
    updateIngredientSelect,
    updateTypeSelect,
    onChange,
    updateDate,
    onSubmit: { update: updateOnSubmit, save: saveOnSubmit },
    key,
    loading
  };
};
