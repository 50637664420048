import React, { useContext, useMemo, useState } from 'react';
import * as PropTypes from 'prop-types';
import { Principal, SubscriptionInfo } from '../admin/types';
import { SubscriptionStatus, SubscriptionType } from '../../types/Subscription';

interface IAuthContext {
  principal: Principal;
  setPrincipal: (u: unknown) => void;
  lock: boolean;
  subscriptionInfo: SubscriptionInfo;
  setLock: (v: boolean) => void;
  actions: {
    mainRole: string;
    isAdmin: boolean;
    isUser: boolean;
    isTrial: boolean;
    hasActiveSubscription: boolean;
    hasValidPremium: boolean;
  };
}

const defaultState = {
  principal: undefined,
  setPrincipal: () => {},
  lock: false,
  subscriptionInfo: {
    activeSubscription: undefined,
    inProgressSubscription: undefined,
    accessInfo: {
      hasNormalAccess: false,
      hasPremiumAccess: false,
      trialAllowed: false,
      winesAllowed: false,
      wineProductionsAllowed: false,
      wineStorageAllowed: false,
      subscriptionType: undefined
    },
    paymentInfo: undefined
  },
  setLock: () => {},
  actions: {
    mainRole: '',
    isAdmin: false,
    isUser: false,
    isTrial: false,
    hasActiveSubscription: false,
    hasValidPremium: false
  }
};

export const AuthContext = React.createContext<IAuthContext>(defaultState);
export const useAuthContext = (): IAuthContext => useContext(AuthContext);

const AuthProvider = ({ children }) => {
  const [principal, setPrincipal] = useState<Principal>(null);
  const [lock, setLock] = useState(false);

  const actions = {
    mainRole: principal?.realms?.[0],
    isAdmin: principal?.hasAccess?.('/admin_console'),
    isUser: principal?.hasAccess?.('/mv'),
    hasActiveSubscription:
      principal?.subscriptionInfo?.activeSubscription?.status === SubscriptionStatus.ACTIVATED,
    isTrial: principal?.subscriptionInfo?.activeSubscription?.status === SubscriptionStatus.TRIAL,
    hasValidPremium:
      (principal?.subscriptionInfo?.activeSubscription?.status === SubscriptionStatus.ACTIVATED ||
        principal?.subscriptionInfo?.activeSubscription?.status === SubscriptionStatus.TRIAL) &&
      principal?.subscriptionInfo?.activeSubscription?.type === SubscriptionType.PREMIUM
  };

  const providerValue = useMemo(
    () => ({
      principal,
      subscriptionInfo: principal?.subscriptionInfo,
      setPrincipal,
      actions,
      lock,
      setLock
    }),
    [principal, setPrincipal, actions, lock, setLock]
  );

  return <AuthContext.Provider value={providerValue}>{children}</AuthContext.Provider>;
};

AuthProvider.propTypes = {
  children: PropTypes.any
};

export default AuthProvider;
