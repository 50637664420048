import React from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import { useTranslation } from 'react-i18next';
import CommonRow from '../../../../../../../../components/common/table/CommonRow';
import { ProductionEvent, ProductionEventForWineType } from '../../../../types/Wine';
import { CommonListBox, ListBoxActions, ListBoxElementToShow } from './CommonListBox';
import { FromApiConverter } from '../../../../../../../../services/Converters';
import { FormErrorMessage } from '../../../../../../../../components/notifications/FormErrorMessage';
import CardFooter from '../../../../../../../../components/common/cards/CardFooter';
import { useProductionEventContext } from '../../../../../production_event/context/ProductionEventContext';
import { useWineProductionContext } from '../../../../../wine_production/context/WineProductionContext';
import { useWineContext } from '../../../../context/WineContext';
import { SectionWrapper } from '../../../../../../../../layouts/SectionWrapper';
import { PlusMinusComponent } from './PlusMinusComponent';
import usePagination from '../../../../../../../../hooks/usePagination';
import { PaginationComponent } from '../../../../../../../../components/PaginationComponent';

interface Props {
  events: ProductionEvent[];
  eventToShow: ListBoxElementToShow;
  actions: Pick<ListBoxActions, 'toggleShow' | 'editElement' | 'removeElement'>;
  entityName: string;
  entityId?: number;
  disabledActions?: boolean;
  loading: boolean;
}

const EventsListBox: React.FC<Props> = ({
  events,
  eventToShow,
  actions,
  entityName,
  entityId,
  disabledActions,
  loading
}) => {
  const { t } = useTranslation();
  const { error } = useProductionEventContext();
  const { error: productionError } = useWineProductionContext();
  const { error: wineError } = useWineContext();
  const pagination = usePagination(events, 10);

  const finalError = error || productionError || wineError;

  function getWasteOrInput(event: ProductionEvent) {
    if (event?.waste && event?.input) {
      return (
        <div className="text-center">
          <span className="pr-2">
            <PlusMinusComponent value={event.waste} minus />
          </span>
          <PlusMinusComponent value={event.input} plus />
        </div>
      );
    }
    if (event?.waste) {
      return (
        <div className="text-center">
          <PlusMinusComponent value={event.waste} minus />
        </div>
      );
    }
    if (event?.input) {
      return (
        <div className="text-center">
          <PlusMinusComponent value={event.input} plus />
        </div>
      );
    }
    return <div className="text-center">-</div>;
  }

  return (
    <SectionWrapper loading={loading} hideSpinner>
      <Card>
        <CardBody>
          <h5 className="m-0 float-right">{t('wine.info.ADDED_EVENTS')}</h5>
          <small className="text-muted">
            {t('common.SUMMARY')}: {events?.length}
          </small>
          {events?.length ? (
            <Table className={'table w-100 no-footer table-hover'}>
              <tbody>
                <CommonRow
                  label={`${t('common.TYPE')}:`}
                  value={[`${t('common.START')}:`, `${t('event.WASTE')} / ${t('event.INPUT')}:`]}
                />
                {(pagination.getCurrentResults() || []).map((i, key) => {
                  return (
                    <CommonListBox
                      header={[
                        i.type === ProductionEventForWineType.OTHER && i.name
                          ? i.name
                          : t(`event.type.${i.type}`),
                        FromApiConverter.convertDateTime(i.startingDate),
                        getWasteOrInput(i)
                      ]}
                      actions={actions}
                      disableActions={disabledActions}
                      elementToSHow={eventToShow}
                      path={`${entityName}/event/${entityId}`}
                      elementId={i?.id}
                      dropdownInfo={{
                        paragraph: i.info || t('common.NO_INFO'),
                        footer: {
                          label: t('event.PROCESS_ENDING_DATE'),
                          value: FromApiConverter.convertDateTime?.(i.endingDate)
                        }
                      }}
                      key={key}
                      elementToShowKey={key}
                    />
                  );
                })}
              </tbody>
            </Table>
          ) : (
            <CardBody>
              <div className={'text-center'}>{t('common.NO_DATA')}</div>
            </CardBody>
          )}
          {finalError?.error?.details && (
            <CardFooter>
              <FormErrorMessage error={finalError} messageType={'details'} />
            </CardFooter>
          )}
        </CardBody>
        <PaginationComponent pagination={pagination} />
      </Card>
    </SectionWrapper>
  );
};

export default EventsListBox;
