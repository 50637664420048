import React from 'react';
import Select from 'react-select';

import { useTranslation } from 'react-i18next';
import { CompanyInfoList } from '../../../types';
import { SelectOptionType } from '../../../../../types/SelectOption';
import { ServiceError } from '../../../../../types/Service';
import {
  NumberOdColumnsInRow,
  useFormFieldColumnCount
} from '../../../../home/components/TaxBandsSummaryPage/hooks/useFormFieldColumnCount';
import { useThemeContext } from '../../../../platform/ThemeContext';
import useCompaniesReceiver from '../hooks/useCompaniesReceiver';
import { SectionWrapper } from '../../../../../layouts/SectionWrapper';

interface Props {
  value: Partial<CompanyInfoList> | SelectOptionType<number> | number;
  onChange: (name: string, selected: SelectOptionType<number>) => void;
  name: string;
  label?: string;
  optional?: boolean;
  error?: ServiceError;
  disabled?: boolean;
  numberOfColumnsInRow?: NumberOdColumnsInRow;
}

const SelectCompany: React.FC<Props> = ({
  value: selected,
  onChange,
  name,
  label,
  optional,
  error,
  disabled,
  numberOfColumnsInRow = 1
}) => {
  const { selected: value, options: companies, loading } = useCompaniesReceiver(selected);
  const { t } = useTranslation();
  const numberOfColumnsClass = useFormFieldColumnCount({ numberOfColumnsInRow });
  const { selectDarkStyles } = useThemeContext();

  console.log(companies);
  return (
    <div className={'mb-3 ' + numberOfColumnsClass}>
      <label htmlFor={name}>
        {label}
        <span className="text-danger" style={{ display: optional === true ? 'none' : '' }}>
          {' '}
          *
        </span>
      </label>
      <SectionWrapper loading={loading} hideSpinner>
        <Select
          name={name}
          options={companies}
          onChange={(s) => onChange(name, s)}
          value={value}
          noOptionsMessage={() => t('select.noOptions.message')}
          placeholder={'Wybierz'}
          styles={selectDarkStyles(error?.hasError?.(name))}
          isDisabled={disabled}
        />
      </SectionWrapper>
      <span
        className="invalid-feedback"
        style={{ display: error?.hasError?.(name) ? 'block' : 'none' }}
      >
        {error?.getErrorMessage?.(name)}
      </span>
    </div>
  );
};

export default SelectCompany;
