import React from 'react';
import Select from 'react-select';
import { SelectOption } from '../../../../../../types/SelectOption';
import { Tank } from '../../../tank/types/Tank';
import useTanksReceiver from '../../../tank/hooks/useTanksReceiver';
import { ServiceError } from '../../../../../../types/Service';
import { Link } from 'react-router-dom';
import {
  NumberOdColumnsInRow,
  useFormFieldColumnCount
} from '../../../../../home/components/TaxBandsSummaryPage/hooks/useFormFieldColumnCount';
import { SectionWrapper } from '../../../../../../layouts/SectionWrapper';
import { useTranslation } from 'react-i18next';
import { useThemeContext } from '../../../../../platform/ThemeContext';

interface Props {
  value: Tank | SelectOption;
  onChange: (selected: { value: number }) => void;
  name: string;
  label?: string;
  optional?: boolean;
  disabled?: boolean;
  error?: ServiceError;
  numberOfColumnsInRow?: NumberOdColumnsInRow;
  hideLink?: boolean;
}

const SelectTank: React.FC<Props> = ({
  value: selected,
  onChange,
  name,
  label,
  optional,
  disabled,
  error,
  hideLink,
  numberOfColumnsInRow = 1
}) => {
  const { selected: value, options: tanks, loading } = useTanksReceiver(selected);
  const numberOfColumnsClass = useFormFieldColumnCount({ numberOfColumnsInRow });
  const { t } = useTranslation();
  const { selectDarkStyles } = useThemeContext();

  if (label) {
    return (
      <div className={'mb-3 ' + numberOfColumnsClass}>
        <label htmlFor={name}>
          {label}
          <span className="text-danger" style={{ display: optional === true ? 'none' : '' }}>
            {' '}
            *
          </span>
        </label>
        <SectionWrapper loading={loading} hideSpinner>
          <Select
            name={name}
            options={tanks}
            onChange={onChange}
            value={value}
            placeholder={'Wybierz'}
            noOptionsMessage={() => t('select.noOptions.message')}
            styles={selectDarkStyles(error?.hasError?.(name))}
            isDisabled={disabled}
            isOptionDisabled={(option) => {
              if (typeof option !== 'string') {
                return option.lock;
              }
              return false;
            }}
          />
        </SectionWrapper>
        <span
          className="invalid-feedback"
          style={{ display: error?.hasError?.(name) ? 'block' : 'none' }}
        >
          {error?.getErrorMessage?.(name)}
        </span>
        {hideLink ? null : (
          <small className={'pl-1'}>
            <Link
              to={{
                pathname: '/mv/tank/e/0',
                state: { from: window.location.hash?.split('#')?.[1] }
              }}
            >
              Stwórz nowy
            </Link>
          </small>
        )}
      </div>
    );
  }
  return (
    <>
      <Select
        name={name}
        options={tanks}
        onChange={onChange}
        value={value}
        isDisabled={disabled}
        placeholder={'Wybierz'}
        noOptionsMessage={() => t('select.noOptions.message')}
        styles={selectDarkStyles(error?.hasError?.(name))}
      />
      <span
        className="invalid-feedback"
        style={{ display: error?.hasError?.(name) ? 'block' : 'none' }}
      >
        {error?.getErrorMessage?.(name)}
      </span>
    </>
  );
};

export default SelectTank;
