import React, { useMemo, useState } from 'react';
import PageWrapper from '../../../../layouts/PageWrapper';
import { usePushHistory } from '../../../../hooks/usePushHistory';
import { TaxBandsListTable } from './list/TaxBandsListTable';
import { TaxBandsListButtonsBar } from './list/TaxBandsListButtonsBar';
import { TaxBandsForm } from './form/TaxBandsForm';
import { useTaxBands } from './hooks/useTaxBands';
import { TaxBandsSelected } from './placements/TaxBandsSelected';
import { usePlacementForTaxBands } from './hooks/usePlacementsForTaxBands';
import { PlacementFormComponent } from './placement/PlacementFormComponent';
import { PlacementButtonsBar } from './placements/PlacementButtonsBar';
import { useParams } from 'react-router-dom';
import { RouterParams } from '../../../../types/RouterParams';

export const TaxBandsSummaryPage = () => {
  const { pushHistory } = usePushHistory();
  const { taxBandsId } = useParams<RouterParams>();
  const [showList, setShowList] = useState(!taxBandsId);
  const {
    state: {
      taxBands,
      tab,
      showAllTaxBands,
      showAllFields,
      loading,
      error,
      selectedTaxBands,
      magicNumber
    },
    actions: {
      addTaxBands,
      toggleShowAllTaxBands,
      handleChangeTab,
      toggleShowAllFields,
      removeTaxBands,
      archiveTaxBands,
      selectTaxBands,
      getList: fetchTaxBandsList
    }
  } = useTaxBands();

  const {
    placements: placementToMap,
    loading: loadingPlacements,
    error: placementsError,
    getPlacementsForTaxBands,
    removePlacement
  } = usePlacementForTaxBands({ taxBandsId: selectedTaxBands?.id, fetchTaxBandsList });

  const placements = useMemo(() => new Map(Object.entries(placementToMap || {})), [placementToMap]);

  return (
    <PageWrapper
      title={'home.TAX_BANDS'}
      onClick={() => pushHistory('/mv')}
      returnText={'sidebar.nav.element.DASHBOARD'}
    >
      <div className="row">
        <div className="col-lg-12">
          {showList ? (
            <>
              <TaxBandsListButtonsBar
                reportAvailable={taxBands.length > 0}
                onClick={handleChangeTab}
                activeTab={tab}
              />
              {tab === 'list' ? (
                <TaxBandsListTable
                  loading={loading}
                  taxBands={taxBands}
                  showAllTaxBands={showAllTaxBands}
                  toggleShowAllTaxBands={toggleShowAllTaxBands}
                  selectTaxBands={selectTaxBands}
                  selectedTaxBands={selectedTaxBands}
                  key={taxBands.length + (showAllTaxBands + '') + taxBands?.[0]?.id + magicNumber}
                />
              ) : null}
              {tab === 'form' ? (
                <TaxBandsForm
                  loading={loading}
                  addTaxBands={addTaxBands}
                  showAllFields={showAllFields}
                  toggleShowAllFields={toggleShowAllFields}
                  error={error}
                />
              ) : null}

              {tab === 'addPlacement' ? (
                <PlacementFormComponent
                  fetchTaxBandsList={fetchTaxBandsList}
                  getPlacementsForTaxBands={getPlacementsForTaxBands}
                  selectTaxBands={selectTaxBands}
                />
              ) : null}
            </>
          ) : null}
          {selectedTaxBands?.id ? (
            <>
              <PlacementButtonsBar
                reportAvailable={true}
                removeTaxBands={removeTaxBands}
                archiveTaxBands={archiveTaxBands}
                selectedTaxBands={selectedTaxBands}
                showList={showList}
                setShowList={setShowList}
                id={selectedTaxBands.id}
                disableRemove={loadingPlacements || placements?.size > 0}
                key={selectedTaxBands.id + selectedTaxBands.liveStatus + magicNumber}
              />
              <TaxBandsSelected
                selectedTaxBands={selectedTaxBands}
                placements={placements}
                loading={loadingPlacements}
                error={placementsError || error}
                removePlacement={removePlacement}
                disabledRemove={loadingPlacements}
              />
            </>
          ) : null}
        </div>
      </div>
    </PageWrapper>
  );
};
