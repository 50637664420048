import React from 'react';

const TimelineSection = ({ children, hasMoreElements, showMore }) => {
  return (
    <ul className="timeline container">
      {children}
      {hasMoreElements ? (
        <li className="timeline-end">
          <button
            className="timeline-badge btn-flat border-0 p-0"
            onClick={showMore}
            aria-label="Show more"
          >
            <em className="fa fa-plus"></em>
          </button>
        </li>
      ) : null}
    </ul>
  );
};

export default TimelineSection;
