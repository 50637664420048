import React from 'react';
import { PaymentData } from '../../types';
import { useResponsiveContext } from '../../../platform/ResponsiveContext';
import { usePushHistory } from '../../../../hooks/usePushHistory';

interface IProps {
  payment: PaymentData;
}

const DiscountCell = ({ payment }) => {
  const { pushHistory } = usePushHistory();
  if (!payment.discountCard) return <></>;
  return (
    <button
      onClick={() => pushHistory(`/admin_console/discount_cards/${payment.discountCard.code}`)}
      className={'btn btn-link pt-0 px-0 m-0'}
    >
      {payment.discount}{' '}
    </button>
  );
};

export const PaymentRow: React.FC<IProps> = ({ payment }) => {
  const { isMobile } = useResponsiveContext();
  return (
    <tr className="odd parent">
      {isMobile ? null : <td className={'text-center'}>{payment.id}</td>}
      <td className={'text-center'}>{payment.subscriptionId}</td>
      <td className={'text-center'}>
        {payment.payed ? (
          <div className={'badge bg-green'}>Tak</div>
        ) : (
          <div className={'badge bg-danger'}>Nie</div>
        )}
      </td>
      <td className={'text-center'}>{payment.paymentTitle}</td>
      <td className={'text-center'}>{payment.price}</td>
      <td className={'text-center'}>
        <DiscountCell payment={payment} />
      </td>
      <td className={'text-center text-green'}>{payment.priceToPay}</td>
      {isMobile ? null : (
        <>
          <td className={'text-center'}>{payment.createdDate?.split('T').join(' ')}</td>
          <td className={'text-center'}>{payment.paymentDate?.split('T').join(' ')}</td>
          <td className={'text-center'}>{payment.lastModifiedDate?.split('T').join(' ')}</td>
        </>
      )}
    </tr>
  );
};
