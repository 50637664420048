import React from 'react';
import { DiscountPeriod } from '../../../hooks/useDiscountService';
import { ServiceError } from '../../../../../types/Service';
import { NumberOdColumnsInRow } from '../../../../home/components/TaxBandsSummaryPage/hooks/useFormFieldColumnCount';
import { SelectOption } from '../../../../../types/SelectOption';
import { SelectTypeColumn } from '../../../../../components/forms/SelectTypeColumn';
import { useDiscountPeriodReceiver } from '../../../hooks/useDiscountPeriodReceiver';

interface Props {
  value?: DiscountPeriod | SelectOption | null;
  onChange: (name: string, s: Record<string, DiscountPeriod>) => void;
  name: string;
  label?: string;
  optional?: boolean;
  error?: ServiceError;
  disabled?: boolean;
  numberOfColumnsInRow?: NumberOdColumnsInRow;
}

const SelectDiscountPeriod: React.FC<Props> = ({
  value,
  onChange,
  name,
  label,
  optional,
  error,
  disabled,
  numberOfColumnsInRow
}) => {
  return (
    <SelectTypeColumn
      onChange={onChange}
      name={name}
      receiver={useDiscountPeriodReceiver}
      error={error}
      optional={optional}
      label={label}
      value={value}
      disabled={disabled}
      clazzName={'pb-0 mb-0'}
      numberOfColumnsInRow={numberOfColumnsInRow}
    />
  );
};

export default SelectDiscountPeriod;
