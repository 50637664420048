import useCrudeApi from '../../../lib/useCrudeApi';
import { CompanyInfoList, UserInfoData } from '../types';
import { useParams } from 'react-router-dom';
import { RouterParams } from '../../../types/RouterParams';

const useBoxService = () => {
  const {
    getList: getActiveList,
    get,
    del: deactivateCompany,
    putId: activateCompany,
    post: addNewCompany
  } = useCrudeApi<CompanyInfoList>(`/ajax/company`);
  const { companyId } = useParams<RouterParams>();
  const { getList: getInactiveList } = useCrudeApi<CompanyInfoList>(`/ajax/removed-company`);
  const { getList: getUsersList } = useCrudeApi<UserInfoData[]>(`/ajax/user`);
  const {
    del: deactivateUser,
    putId: activateUser,
    post: addNewUser
  } = useCrudeApi<UserInfoData>(`/ajax/company/${companyId}/user`);

  function getCompanyList(active: boolean): Promise<CompanyInfoList[]> {
    return active ? getActiveList() : getInactiveList();
  }
  return {
    getCompanyList,
    get,
    activateCompany,
    deactivateCompany,
    addNewCompany,
    getUsersList,
    deactivateUser,
    activateUser,
    addNewUser
  };
};

export default useBoxService;
