import React from 'react';
import { Bottle } from '../../../../../winery/components/wine_storage/types/WineStorage';
import { useTranslation } from 'react-i18next';

interface IProps {
  bottle: Bottle;
  onClick: () => void;
  short: boolean;
  selectedBottle: Bottle;
}

export const BottlesRow: React.FC<IProps> = ({ bottle, onClick, short, selectedBottle }) => {
  const { t } = useTranslation();
  return (
    <tr
      className={
        'odd parent ' +
        (selectedBottle?.id === bottle.id ? 'bg-gray-lighter bg-gray-lighter-dark' : '')
      }
      style={{ cursor: 'pointer', userSelect: 'none', WebkitUserSelect: 'none' }}
      onClick={onClick}
    >
      <td className="text-bold">{bottle.name}</td>
      <td className={'text-center'}>{bottle.capacity}</td>
      <td className={'text-center'}>{bottle.forBottling ? t('answer.yes') : t('answer.no')}</td>
      {!short ? (
        <td
          className={
            'text-center ' + (bottle.approximateQuantity > 0 ? 'text-green' : 'text-danger')
          }
        >
          {bottle.approximateQuantity}
        </td>
      ) : null}
    </tr>
  );
};
