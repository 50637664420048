import { useCallback, useEffect, useState } from 'react';
import { ServiceError } from '../../../../../types/Service';
import { ResponseError } from '../../../../error/ResponseError';
import log from 'loglevel';
import { useParams } from 'react-router-dom';
import { RouterParams } from '../../../../../types/RouterParams';
import { usePushHistory } from '../../../../../hooks/usePushHistory';
import {
  DiscountCardReadModel,
  DiscountPeriod,
  DiscountType,
  useDiscountService
} from '../../../hooks/useDiscountService';
import { CompanyInfoList } from '../../../types';
import { useSelectedDiscountCard } from './useSelectedDiscountCard';

export type DiscountTab = 'list' | 'form';
export type SelectedDiscountCardMode = 'card' | 'edit';

interface ReturnType {
  actions: {
    addDiscountCard: (
      card: DiscountCardReadModel,
      period: DiscountPeriod,
      type: DiscountType,
      customCode?: string
    ) => void;
    toggleShowUsedCards: () => void;
    handleChangeTab: ({ newTab }: { newTab: DiscountTab }) => void;
    removeDiscountCard: (code: string) => void;
    invalidateCard: ({ code }: { code: string }) => void;
    notifyUsers: ({ code, subscriberId }: { code: string; subscriberId: number }) => void;
    updateDiscountCard: (code: string, card: DiscountCardReadModel) => void;
    selectDiscountCard: ({ code }: { code: string }) => void;
    getList: () => void;
    toggleSelectedDiscountCardMode: (newMode?: SelectedDiscountCardMode) => void;
  };
  state: {
    discountCards: DiscountCardReadModel[];
    showUsedCards: boolean;
    tab: DiscountTab;
    loading: boolean;
    error: ServiceError;
    selectedDiscountCard: DiscountCardReadModel;
    magicNumber: number;
    selectedDiscountCardMode: SelectedDiscountCardMode;
    companies: CompanyInfoList[];
    loadingCompanies: boolean;
  };
}

export function useDiscountCards(): ReturnType {
  const { discountCardCode } = useParams<RouterParams>();
  const [discountCards, setDiscountCards] = useState<DiscountCardReadModel[]>([]);
  const [loadingCompanies, setLoadingCompanies] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showUsedCards, setShowUsedCards] = useState(false);
  const [tab, setTab] = useState<DiscountTab>('list');
  const [error, setError] = useState<ServiceError>();
  const [selectedDiscountCard, selectDiscountCard] = useSelectedDiscountCard({ discountCards });

  const [companies, setCompanies] = useState<CompanyInfoList[]>([]);
  const getMagicNumber = useCallback((): number => {
    return Math.random();
  }, []);
  const [magicNumber, setMagicNumber] = useState<number>();
  const [selectedDiscountCardMode, setSelectedDiscountCardMode] =
    useState<SelectedDiscountCardMode>('card');

  const service = useDiscountService();
  const { tryToGoBack } = usePushHistory();

  const getCompanies = useCallback(async () => {
    try {
      setLoadingCompanies(true);
      setError(null);
      const result = await service.getCompaniesWithDiscount({
        discountCardId: selectedDiscountCard.discountCardId
      });
      setCompanies(result);
      setMagicNumber(getMagicNumber());
    } catch (e) {
      setError(new ResponseError(e));
      log.error('Cannot get list of Companies for discount card', e);
    } finally {
      setLoadingCompanies(false);
    }
  }, [selectedDiscountCard?.discountCardId, setCompanies]); // eslint-disable-line react-hooks/exhaustive-deps

  const getList = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const result = await service.getAllByValid(!showUsedCards);
      setDiscountCards(result);
      setMagicNumber(getMagicNumber());
    } catch (e) {
      setError(new ResponseError(e));
      log.error('Cannot get list of Discount Cards', e);
    } finally {
      setLoading(false);
    }
  }, [service, setError, getMagicNumber, showUsedCards]);

  useEffect(() => {
    if (!discountCardCode) {
      getList();
    }
  }, [showUsedCards, discountCardCode]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedDiscountCard?.discountCardId) {
      getCompanies();
    }
  }, [selectedDiscountCard?.discountCardId, getCompanies]);

  const addDiscountCard = useCallback(
    (
      discountCard: DiscountCardReadModel,
      period: DiscountPeriod,
      type: DiscountType,
      customCode?: string
    ) => {
      const submit = async () => {
        try {
          setLoading(true);
          if (type === DiscountType.PRICE) {
            await service.createNewPriceCard(
              {
                discountPrice: discountCard.discountPrice,
                name: discountCard.name,
                maxUsageCount: discountCard.maxUsageCount,
                subscriberId: discountCard.subscriberId,
                startDate: discountCard.startDate,
                customCode
              },
              discountCard.subscriptionType,
              period
            );
          } else if (type === DiscountType.PERCENTAGE) {
            await service.createNewPercentageCard(
              {
                discountPercentage: discountCard.discountPercent,
                name: discountCard.name,
                maxUsageCount: discountCard.maxUsageCount,
                subscriberId: discountCard.subscriberId,
                startDate: discountCard.startDate,
                customCode
              },
              discountCard.subscriptionType,
              period
            );
          }

          tryToGoBack();
          setTab('list');
          await getList();
          selectDiscountCard({ code: null });
        } catch (e) {
          setError(new ResponseError(e));
          log.error('Cannot add Discount Card', e);
        } finally {
          setLoading(false);
        }
      };
      submit();
    },
    [service, getList, tryToGoBack, selectDiscountCard]
  );

  const toggleSelectedDiscountCardMode = useCallback((newMode?: SelectedDiscountCardMode) => {
    if (newMode) {
      setSelectedDiscountCardMode(newMode);
    } else {
      setSelectedDiscountCardMode((prevState) => (prevState === 'card' ? 'edit' : 'card'));
    }
  }, []);

  const updateDiscountCard = useCallback(
    (code: string, card: DiscountCardReadModel) => {
      const submit = async () => {
        try {
          setLoading(true);
          const success = await service.updateDiscountCard(code, card);
          if (success) {
            await getList();
            toggleSelectedDiscountCardMode('card');
            selectDiscountCard({
              code,
              card: {
                ...selectedDiscountCard,
                name: card.name,
                subscriptionType: card.subscriptionType,
                discountPercent: card.discountPercent,
                discountPrice: card.discountPrice,
                maxUsageCount: card.maxUsageCount,
                valid: card.maxUsageCount > 0
              }
            });
            setMagicNumber(getMagicNumber());
          }
        } catch (e) {
          setError(new ResponseError(e));
          log.error('Cannot update Discount Card', e);
        } finally {
          setLoading(false);
        }
      };
      submit();
    },
    [
      service,
      getList,
      selectDiscountCard,
      toggleSelectedDiscountCardMode,
      getMagicNumber,
      selectedDiscountCard
    ]
  );

  const removeDiscountCard = useCallback(
    (code: string) => {
      const submit = async () => {
        try {
          setLoading(true);
          await service.del(code);
          setTab('list');
          selectDiscountCard({ code: null });
          await getList();
        } catch (e) {
          setError(new ResponseError(e));
          log.error('Cannot remove Discount Card', e);
        } finally {
          setLoading(false);
        }
      };
      submit();
    },
    [service, getList, selectDiscountCard]
  );

  const invalidateCard = useCallback(
    ({ code }: { code: string }) => {
      const submit = async () => {
        try {
          setLoading(true);
          const success = await service.invalidate(code);

          setTab('list');
          await getList();
          if (success) {
            selectDiscountCard({
              code,
              card: {
                ...selectedDiscountCard,
                maxUsageCount: -1,
                valid: false
              }
            });
            setMagicNumber(getMagicNumber());
          }
        } catch (e) {
          setError(new ResponseError(e));
          log.error('Cannot invalidate Discount Card', e);
        } finally {
          setLoading(false);
        }
      };
      submit();
    },
    [service, getList, selectDiscountCard, getMagicNumber, selectedDiscountCard]
  );

  const toggleShowUsedCards = useCallback(() => {
    setShowUsedCards((prevState) => !prevState);
  }, []);

  const handleChangeTab = useCallback(
    ({ newTab }: { newTab: DiscountTab }) => {
      if (tab === 'list' || tab !== newTab) setTab(newTab);
      else setTab('list');
    },
    [tab]
  );

  const notifyUsers = useCallback(
    ({ code, subscriberId }: { code: string; subscriberId: number }) => {
      const submit = async () => {
        try {
          setLoading(true);
          await service.notifyByEmail(code, subscriberId);
        } catch (e) {
          setError(new ResponseError(e));
          log.error('Cannot remove Discount Card', e);
        } finally {
          setLoading(false);
        }
      };
      submit();
    },
    [service]
  );

  return {
    actions: {
      addDiscountCard,
      toggleShowUsedCards,
      handleChangeTab,
      notifyUsers,
      removeDiscountCard,
      selectDiscountCard,
      getList,
      invalidateCard,
      updateDiscountCard,
      toggleSelectedDiscountCardMode
    },
    state: {
      discountCards,
      tab,
      showUsedCards,
      loading,
      error,
      selectedDiscountCard,
      magicNumber,
      selectedDiscountCardMode,
      companies,
      loadingCompanies
    }
  };
}
