import React from 'react';
import { useTranslation } from 'react-i18next';
import { Bottle } from '../../../../../winery/components/wine_storage/types/WineStorage';
import { ServiceError } from '../../../../../../types/Service';
import { SectionWrapper } from '../../../../../../layouts/SectionWrapper';
import { FormErrorMessage } from '../../../../../../components/notifications/FormErrorMessage';
import { InputColumn } from '../../../../../../components/forms/InputColumn';
import { TextareaColumn } from '../../../../../../components/forms/TextareaColumn';
import { useUpdateBottle } from '../../hooks/useUpdateBottle';
import { SelectedBottleMode } from '../../hooks/useBottles';
import { EntityLiveStatus } from '../../../../../../components/common/enums/EntityLiveStatus';

const Header = ({ selectedBottle, mode }: { selectedBottle: Bottle; mode: SelectedBottleMode }) => {
  const { t } = useTranslation();
  return (
    <div className={'d-flex justify-content-between align-items-top'}>
      <div className="float-left"></div>
      <div className="mb-2"></div>
      <small className="text-muted float right">
        {selectedBottle.forBottling && mode === 'card' ? (
          <span className={'text-green'}>{t('bottle.selected.forBottling')}</span>
        ) : null}
        {selectedBottle.liveStatus !== EntityLiveStatus.ARCHIVED &&
        !selectedBottle.forBottling &&
        mode === 'card' ? (
          <span className={'text-danger'}>{t('bottle.not.selected.forBottling')}</span>
        ) : null}
        {selectedBottle.liveStatus === EntityLiveStatus.ARCHIVED &&
        !selectedBottle.forBottling &&
        mode === 'card' ? (
          <span className={'text-danger'}>{t('bottle.archived')}</span>
        ) : null}
        {mode === 'edit' ? <span>{t('bottle.edit')}</span> : null}
      </small>
    </div>
  );
};

export const BottleSelected: React.FC<{
  selectedBottle: Bottle;
  selectedBottleMode: SelectedBottleMode;
  updateBottle: (id: number, bottle: Bottle) => void;
  loading: boolean;
  error: ServiceError;
}> = ({ selectedBottle, selectedBottleMode, updateBottle, loading, error }) => {
  const { t } = useTranslation();
  const [bottle, updateField] = useUpdateBottle(selectedBottle);

  return (
    <SectionWrapper loading={loading} hideSpinner>
      <div className="card">
        <div className="card-body pb-1">
          {selectedBottleMode === 'card' ? (
            <>
              <Header selectedBottle={selectedBottle} mode={selectedBottleMode} />
              <h4>{t('bottle.name')}</h4>
              <div className="dropdown-divider"></div>
              <p className={'text-gradient-blue-green'}>
                {selectedBottle.name} - {selectedBottle.capacity} l.
              </p>
              <h4 className={'pt-2'}>{t('bottle.approximateQuantity')}</h4>
              <div className="dropdown-divider"></div>
              <p
                className={
                  'fa-2x ' +
                  (selectedBottle.approximateQuantity > 0 ? 'text-green' : 'text-warning')
                }
              >
                {selectedBottle.approximateQuantity || 0}
              </p>
              <h4 className={'pt-2'}>{t('bottle.description')}</h4>
              <div className="dropdown-divider"></div>
              <p style={{ whiteSpace: 'pre-wrap' }}>{selectedBottle.info || t('common.NO_DATA')}</p>
              <FormErrorMessage
                error={error}
                messageType={'details'}
                clazzName={'p-1'}
                key={selectedBottle.id}
              />
            </>
          ) : null}
          {selectedBottleMode === 'edit' ? (
            <>
              <Header selectedBottle={selectedBottle} mode={selectedBottleMode} />
              <InputColumn
                label={t('bottles.table.name')}
                onChange={(e) => updateField({ name: e.target.name, value: e.target.value })}
                name={'name'}
                value={bottle.name}
                placeholder={t('bottles.table.name')}
                error={error}
              />
              <InputColumn
                label={t('bottles.table.capacity')}
                onChange={(e) => updateField({ name: e.target.name, value: e.target.value })}
                name={'capacity'}
                value={bottle.capacity}
                placeholder={t('bottles.table.capacity')}
                error={error}
                type={'number'}
              />
              <InputColumn
                label={t('bottles.table.approximateQuantity')}
                onChange={(e) => updateField({ name: e.target.name, value: e.target.value })}
                name={'approximateQuantity'}
                value={bottle.approximateQuantity}
                placeholder={t('bottles.table.approximateQuantity')}
                error={error}
                type={'number'}
              />
              <TextareaColumn
                label={t('bottles.table.info')}
                onChange={(e) => updateField({ name: e.target.name, value: e.target.value })}
                name={'info'}
                value={bottle.info}
                placeholder={t('bottles.table.info')}
                error={error}
              />
              <button
                className="btn btn-primary ml-3 mb-3 mt-2"
                type="button"
                onClick={() => updateBottle(bottle.id, bottle)}
              >
                {t('bottle.form.submit.update')}
              </button>
            </>
          ) : null}
        </div>
      </div>
    </SectionWrapper>
  );
};
