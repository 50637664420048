import { useCallback, useEffect, useState } from 'react';
import { ServiceError } from '../../../../../types/Service';
import { ResponseError } from '../../../../error/ResponseError';
import log from 'loglevel';
import {
  Bottle,
  WineStorageInBottleData
} from '../../../../winery/components/wine_storage/types/WineStorage';
import { useBottleService } from './useBottleService';
import { useSelectedBottle } from './useSelectedBottle';
import { EntityLiveStatus } from '../../../../../components/common/enums/EntityLiveStatus';
import { useParams } from 'react-router-dom';
import { RouterParams } from '../../../../../types/RouterParams';
import { usePushHistory } from '../../../../../hooks/usePushHistory';

export type BottlesTab = 'list' | 'form';
export type SelectedBottleMode = 'card' | 'edit';

interface ReturnType {
  actions: {
    addBottle: (bottle: Bottle) => void;
    toggleShowArchivedBottles: () => void;
    handleChangeTab: ({ newTab }: { newTab: BottlesTab }) => void;
    removeBottle: (id: number) => void;
    archiveBottle: (id: number) => void;
    updateBottle: (id: number, bottle: Bottle) => void;
    toggleForBottling: (id: number) => void;
    selectBottle: ({ id }: { id: number }) => void;
    getList: () => void;
    toggleSelectedBottleMode: (newMode?: SelectedBottleMode) => void;
  };
  state: {
    bottles: Bottle[];
    showArchivedBottles: boolean;
    tab: BottlesTab;
    loading: boolean;
    error: ServiceError;
    selectedBottle: Bottle;
    magicNumber: number;
    selectedBottleMode: SelectedBottleMode;
    wines: WineStorageInBottleData[];
    loadingWines: boolean;
  };
}

export function useBottles(): ReturnType {
  const { bottleId } = useParams<RouterParams>();
  const [bottles, setBottles] = useState<Bottle[]>([]);
  const [loadingWines, setLoadingWines] = useState(false);
  const [loading, setLoading] = useState(false);
  const [showArchivedBottles, setShowArchivedBottles] = useState(false);
  const [bottleListStatus, setBottleListStatus] = useState<EntityLiveStatus>(
    EntityLiveStatus.CREATED
  );
  const [tab, setTab] = useState<BottlesTab>('list');
  const [error, setError] = useState<ServiceError>();
  const [selectedBottle, selectBottle] = useSelectedBottle({ bottles });

  const [wines, setWines] = useState<WineStorageInBottleData[]>([]);
  const getMagicNumber = useCallback((): number => {
    return Math.random();
  }, []);
  const [magicNumber, setMagicNumber] = useState<number>();
  const [selectedBottleMode, setSelectedBottleMode] = useState<SelectedBottleMode>('card');

  const service = useBottleService();
  const { tryToGoBack } = usePushHistory();

  const getWines = useCallback(async () => {
    try {
      setLoadingWines(true);
      setError(null);
      const result = await service.getWineStoragesInBottle({ bottleId: selectedBottle.id });
      setWines(result);
      setMagicNumber(getMagicNumber());
    } catch (e) {
      setError(new ResponseError(e));
      log.error('Cannot get list of Wines in bottles', e);
    } finally {
      setLoadingWines(false);
    }
  }, [selectedBottle?.id, setWines]); // eslint-disable-line react-hooks/exhaustive-deps

  const getList = useCallback(async () => {
    try {
      setLoading(true);
      setError(null);
      const result = await service.getListByStatus({ status: bottleListStatus });
      setBottles(result);
      setMagicNumber(getMagicNumber());
    } catch (e) {
      setError(new ResponseError(e));
      log.error('Cannot get list of Bottles', e);
    } finally {
      setLoading(false);
    }
  }, [bottleListStatus, service, setError, getMagicNumber]);

  useEffect(() => {
    if (!bottleId) {
      getList();
    }
  }, [showArchivedBottles, bottleId]); // eslint-disable-line react-hooks/exhaustive-deps

  useEffect(() => {
    if (selectedBottle?.id) {
      getWines();
    }
  }, [selectedBottle?.id, getWines]);

  const addBottle = useCallback(
    (bottle: Bottle) => {
      const submit = async () => {
        try {
          setLoading(true);
          await service.addBottle(bottle);
          tryToGoBack();
          setTab('list');
          await getList();
        } catch (e) {
          setError(new ResponseError(e));
          log.error('Cannot add Bottle', e);
        } finally {
          setLoading(false);
        }
      };
      submit();
    },
    [service, getList, tryToGoBack]
  );

  const toggleSelectedBottleMode = useCallback((newMode?: SelectedBottleMode) => {
    if (newMode) {
      setSelectedBottleMode(newMode);
    } else {
      setSelectedBottleMode((prevState) => (prevState === 'card' ? 'edit' : 'card'));
    }
  }, []);

  const updateBottle = useCallback(
    (id: number, bottle: Bottle) => {
      const submit = async () => {
        try {
          setLoading(true);
          const response = await service.updateBottle(id, bottle);
          await getList();
          selectBottle({ id, bottle: response });
          toggleSelectedBottleMode('card');
        } catch (e) {
          setError(new ResponseError(e));
          log.error('Cannot update Bottle', e);
        } finally {
          setLoading(false);
        }
      };
      submit();
    },
    [service, getList, selectBottle, toggleSelectedBottleMode]
  );

  const toggleForBottling = useCallback(
    (id: number) => {
      const submit = async () => {
        try {
          setLoading(true);
          const bottle: Bottle = await service.toggleForBottling(id);
          setTab('list');
          await getList();
          selectBottle({ id, bottle });
        } catch (e) {
          setError(new ResponseError(e));
          log.error('Cannot toggle Bottle for Bottling', e);
        } finally {
          setLoading(false);
        }
      };
      submit();
    },
    [service, getList, selectBottle]
  );

  const removeBottle = useCallback(
    (bottleId: number) => {
      const submit = async () => {
        try {
          setLoading(true);
          await service.del(bottleId);
          setTab('list');
          selectBottle({ id: null });
          await getList();
        } catch (e) {
          setError(new ResponseError(e));
          log.error('Cannot remove bottle', e);
        } finally {
          setLoading(false);
        }
      };
      submit();
    },
    [service, getList, selectBottle]
  );

  const archiveBottle = useCallback(
    (bottleId: number) => {
      const submit = async () => {
        try {
          setLoading(true);
          const action =
            selectedBottle.liveStatus === EntityLiveStatus.CREATED
              ? service.archive
              : service.revertArchive;
          const bottle = await action(bottleId);

          setTab('list');
          await getList();
          selectBottle({ id: bottleId, bottle });
        } catch (e) {
          setError(new ResponseError(e));
          log.error('Cannot archive bottle', e);
        } finally {
          setLoading(false);
        }
      };
      submit();
    },
    [service, getList, selectBottle, selectedBottle?.liveStatus]
  );

  const toggleShowArchivedBottles = useCallback(() => {
    setShowArchivedBottles((prev) => !prev);
    setBottleListStatus((prevState) => {
      return prevState === EntityLiveStatus.CREATED
        ? EntityLiveStatus.ARCHIVED
        : EntityLiveStatus.CREATED;
    });
  }, []);

  const handleChangeTab = useCallback(
    ({ newTab }: { newTab: BottlesTab }) => {
      if (tab === 'list' || tab !== newTab) setTab(newTab);
      else setTab('list');
    },
    [tab]
  );

  return {
    actions: {
      addBottle,
      toggleShowArchivedBottles,
      handleChangeTab,
      removeBottle,
      selectBottle,
      getList,
      archiveBottle,
      updateBottle,
      toggleForBottling,
      toggleSelectedBottleMode
    },
    state: {
      bottles,
      tab,
      showArchivedBottles,
      loading,
      error,
      selectedBottle,
      magicNumber,
      selectedBottleMode,
      wines,
      loadingWines
    }
  };
}
