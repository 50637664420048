import React, { ChangeEvent, useRef } from 'react';
import { ServiceError } from '../../types/Service';
import { useFormFieldColumnCount } from '../../features/home/components/TaxBandsSummaryPage/hooks/useFormFieldColumnCount';
import { useAutosizeTextArea } from '../../hooks/useAutosizeTextArea';
import { Input } from 'reactstrap';

interface Props {
  label: string;
  name: string;
  placeholder?: string;
  value: string;
  onChange: (e: ChangeEvent<HTMLInputElement>) => void;
  error?: ServiceError;
  numberOfColumnsInRow?: 1 | 2 | 3 | 4 | 6;
  disabled?: boolean;
  optional?: boolean;
  maxSize?: string | number;
  maxLength?: number;
}

export const TextareaColumn: React.FC<Props> = ({
  label,
  name,
  placeholder,
  value,
  error,
  onChange,
  numberOfColumnsInRow = 1,
  disabled,
  optional,
  maxSize,
  maxLength
}) => {
  const numberOfColumnsClass = useFormFieldColumnCount({ numberOfColumnsInRow });
  const textAreaRef = useRef<HTMLTextAreaElement>(null);
  useAutosizeTextArea(textAreaRef, value);

  return (
    <div className={'mb-3 ' + numberOfColumnsClass}>
      <label htmlFor={name}>
        {label}
        <span className="text-danger" style={{ display: optional === true ? 'none' : '' }}>
          {' '}
          *
        </span>
      </label>
      <Input
        className={'form-control ' + (error?.hasError?.(name) ? 'is-invalid' : '')}
        id={name}
        name={name}
        type={'textarea'}
        placeholder={placeholder}
        defaultValue={value}
        onChange={onChange}
        disabled={disabled}
        max={maxSize}
        maxLength={maxLength}
        innerRef={textAreaRef}
        rows={1}
      />
      <span
        className="invalid-feedback"
        style={{ display: error?.hasError?.(name) ? 'block' : 'none' }}
      >
        {error?.getErrorMessage?.(name)}
      </span>
    </div>
  );
};
