import React from 'react';
import { Card, CardBody, Table } from 'reactstrap';
import CommonRow from '../../../../components/common/table/CommonRow';
import { useTranslation } from 'react-i18next';
import { AccessInfo } from '../../types';
import { SectionWrapper } from '../../../../layouts/SectionWrapper';

interface IProps {
  accessInfo: AccessInfo;
  title: string;
  loading: boolean;
}

export const CompanyAccessInfoCard: React.FC<IProps> = ({ accessInfo, title, loading }) => {
  const { t } = useTranslation();

  return (
    <SectionWrapper loading={loading} hideSpinner>
      <Card>
        <CardBody>
          <span className="text-muted float-left">{title}</span>
          <Table>
            <tbody>
              <CommonRow
                label={t('company.accessInfo.trialAllowed')}
                value={
                  <span
                    className={
                      'fa fa-' +
                      (accessInfo.trialAllowed ? 'unlock text-green' : 'lock text-danger')
                    }
                  />
                }
                valueColumnClassName={'text-right'}
              />
              <CommonRow
                label={t('company.accessInfo.hasNormalAccess')}
                value={
                  <span
                    className={
                      'fa fa-' +
                      (accessInfo.hasNormalAccess ? 'unlock text-green' : 'lock text-danger')
                    }
                  />
                }
                valueColumnClassName={'text-right'}
              />
              <CommonRow
                label={t('company.accessInfo.hasPremium')}
                value={
                  <span
                    className={
                      'fa fa-' +
                      (accessInfo.hasPremiumAccess ? 'unlock text-green' : 'lock text-danger')
                    }
                  />
                }
                valueColumnClassName={'text-right'}
              />
              <CommonRow
                label={t('company.summary.subscriptionType')}
                value={accessInfo.subscriptionType}
                valueColumnClassName={'text-right'}
              />
            </tbody>
          </Table>
        </CardBody>
      </Card>
    </SectionWrapper>
  );
};
